import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SipCfgDto, StatusAndConfigurationService } from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { Option, SipData } from 'src/app/core/models';
import { checkFormValidity } from 'src/app/core/utils';
import { SipService } from '../sip.service';

@Component({
  selector: 'app-sip-config',
  templateUrl: './sip-config.component.html',
  styleUrls: ['./sip-config.component.scss']
})
export class SipConfigComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  showAll = false;
  isEditing = false;
  isLoading = false;
  sipFormGroup: UntypedFormGroup;

  sipProviderIpControl = new UntypedFormControl('');
  serverPortControl = new UntypedFormControl('', Validators.pattern('[0-9]*'));
  sipProxy = new UntypedFormControl();
  sipProtocolControl = new UntypedFormControl();
  codecPrefControl = new UntypedFormControl();
  relay1Control = new UntypedFormControl();
  relay2Control = new UntypedFormControl();
  relay3Control = new UntypedFormControl();

  originalSipConf: SipCfgDto;
  sipProtocols: Option[] = [];

  codecList: string[] = ['PCMA PCMU', 'PCMU PCMA', 'PCMA', 'PCMU'];

  deviceId: string;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private sipService: SipService,
    private ts: TranslateService,
    private statusAndConfService: StatusAndConfigurationService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.sipProtocols = [
      {
        name: this.ts.instant('SIP.SIP_OVER_TCP'),
        value: true
      },
      {
        name: this.ts.instant('SIP.SIP_OVER_UDP'),
        value: false
      }
    ];
    this.deviceId = this.route.snapshot.data.device.resource.uuid;
    this.sipFormGroup = new UntypedFormGroup({
      audioCodecPriority: this.codecPrefControl,
      sipOverTcp: this.sipProtocolControl,
      sipPbxHostname: this.sipProviderIpControl,
      sipPbxPort: this.serverPortControl,
      sipPbxProxyAddress: this.sipProxy,
      v2v2sOpenRelay1DTMF: this.relay1Control,
      v2v2sOpenRelay2DTMF: this.relay2Control,
      v2v2sOpenRelay3DTMF: this.relay3Control,
      linesOpStatus: new UntypedFormControl()
    });

    this.sipFormGroup.disable();
    this.sipService.sipData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: SipData | undefined) => {
        if (res) {
          this.showAll = res.addConf.sipCfgMode === 0;
          this.originalSipConf = res.conf;
          this.sipFormGroup.setValue(res.conf);
        }
      });
  }

  editSip() {
    this.isEditing = true;
    this.sipFormGroup.enable();
  }

  updateSip() {
    if (checkFormValidity(this.sipFormGroup) && !this.isLoading) {
      this.isLoading = true;
      this.statusAndConfService
        .setSipCfg(
          this.deviceId,
          this.authService.getToken(),
          this.sipFormGroup.value
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.originalSipConf = this.sipFormGroup.value;
          this.toastr.success(
            this.ts.instant('SIP.SIPEDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.closeEdit();
        });
    }
  }

  closeEdit() {
    this.isEditing = false;
    //this.sipProviderControl.setValue(this.originalSipConf);
    this.sipFormGroup.disable();
  }
}
