<div class="card">
  <div class="card-header">
    <h2>{{ 'DASHBOARD.FAVORITE_SITES' | translate }}</h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div
        class="col-12 col-sm-6 col-md-3"
        *ngFor="let fav of favSitesList; let i = index"
      >
        <div
          id="favorite-site-{{ i }}"
          class="site"
          [routerLink]="['/sites', fav.authenticationId]"
        >
          <div class="site-title">{{ fav.name }}</div>
          <div class="site-description">
            {{ fav.address.addressLine1 }} <br />
            {{ fav.address.city }} {{ fav.address.zipCode }}
          </div>
          <h5>{{ 'SITE.DEVICE_STATUS' | translate }}</h5>

          <div class="site-statuses">
            <span *ngIf="fav.deviceNumber === 0"> -- </span>
            <ng-container *ngIf="fav.deviceNumber !== 0">
              <div class="small-counters">
                <label
                  ><i class="custom-badge-xs material-icons swap-horiz">check</i
                  >{{ fav.workingDevices! }}
                  {{ 'DEVICE.STATUSES.OPERATING' | translate }}</label
                >
                <label
                  ><i class="custom-badge-xs material-icons signal-wifi-off"
                    >signal_wifi_off</i
                  >{{ fav.offlineDevices }}
                  {{ 'DEVICE.STATUSES.OFFLINE' | translate }}</label
                >
                <label
                  ><i class="custom-badge-xs material-icons alarm-severity"
                    >warning</i
                  >{{ fav.deviceWithAnomalies }}
                  {{ 'DEVICE.STATUSES.ALERTS' | translate }}</label
                >
                <label *ngIf="fav.deviceWithAlarms"
                  ><i class="custom-badge-xs material-icons evacuation-severity"
                    >local_fire_department</i
                  >{{ fav.deviceWithAlarms }}
                  {{ 'DEVICE.STATUSES.ALARMS' | translate }}</label
                >
                <label>
                  <i class="custom-badge-xs material-icons warning">warning</i
                  >{{ fav.deviceWithErrors }}
                  {{ 'DEVICE.STATUSES.ERRORS' | translate }}</label
                >
                <label *ngIf="fav.privacySafeDevices"
                  ><i
                    class="custom-badge-xs material-icons settings-input-antenna"
                    >visibility_off</i
                  >{{ fav.privacySafeDevices }}
                  {{ 'DEVICE.STATUSES.PRIVACY_MODE' | translate }}</label
                >
              </div>
              <i
                *ngIf="fav.containsVip || fav.containsSbc"
                id="apartments-{{ i }}"
                class="material-icons single-menu-icon"
                (click)="$event.stopPropagation(); goToApts($event, fav)"
                [tooltip]="'SITE.APARTMENTS_LIST' | translate"
                >apartment</i
              >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="favSitesList.length === 0">{{
      'GLOBAL.NO_DATA' | translate
    }}</ng-container>
  </div>
  <div class="card-footer">
    <a id="favorite-sites-link" [routerLink]="['/sites']" class="link-primary"
      >{{ 'DASHBOARD.SITE_LIST' | translate
      }}<i class="material-icons">keyboard_arrow_right</i></a
    >
  </div>
</div>
