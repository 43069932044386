/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Contact data
 */
export interface ContactDto {
  /**
   * Contact name
   */
  name: string;
  /**
   * Contact surname
   */
  surname: string;
  /**
   * Contact phone
   */
  phone?: string;
  /**
   * Contact mobile phone
   */
  mobile?: string;
  /**
   * Contact email
   */
  email: string;
  /**
   * Contact role (optional)
   */
  role?: string;
}
