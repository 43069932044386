/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceErrorPayloadAllOf {
  /**
   * Numeric code associated to the error
   */
  readonly value?: number;
  /**
   * Error code associated to the error
   */
  readonly code?: DeviceErrorPayloadAllOf.CodeEnum;
  /**
   * Error message related to the code
   */
  readonly message?: string;
}
export namespace DeviceErrorPayloadAllOf {
  export type CodeEnum =
    | 'UNKNOWN_ERROR'
    | 'NO_ERROR'
    | 'GENERIC_ERROR'
    | 'TYPE_MISMATCH'
    | 'WRONG_ACTION'
    | 'WRONG_INDEX'
    | 'PERMISSION_DENIED'
    | 'WRITE_FAIL'
    | 'LOGIN_FAIL'
    | 'FILE_NOT_PRESENT'
    | 'CFG_LOCKED'
    | 'WRONG_PARAM'
    | 'MISSING_SEGMENT'
    | 'WRONG_UUID'
    | 'WRONG_SSID'
    | 'ACTION_NEEDS_CODE'
    | 'ARM_BLOCKED'
    | 'ACTION_BLOCKED_L2'
    | 'ACTION_BLOCKED_L3'
    | 'TOTAL_ARM_BLOCKED'
    | 'P1_ARM_BLOCKED'
    | 'P2_ARM_BLOCKED'
    | 'USER_UNKNOWN'
    | 'INSTALLER_NOT_AUT'
    | 'USER_NOT_AUT'
    | 'USER_NOT_AUT_ARM'
    | 'USER_NOT_AUT_DISARM'
    | 'USER_NOT_AUT_SINGLE_ARM'
    | 'USER_NOT_AUT_FORCED_ARM'
    | 'USER_NOT_AUT_DEV'
    | 'USER_NOT_AUT_AREA'
    | 'USER_TIMER_OFF'
    | 'USER_ACT_OVERFLOW'
    | 'EASTER_EGG'
    | 'KEY_NOT_VALID'
    | 'ACTION_UNKNOWN'
    | 'DEVICE_UNKNOWN'
    | 'ABSENT_OUT'
    | 'ABSENT_ZONE'
    | 'ZONE_NOT_EXCLUDABLE'
    | 'ZONE_NOT_ISOLABLE'
    | 'DEVICE_ERROR'
    | 'TECH_PROG'
    | 'UPGRADE_ERR'
    | 'AREAS_ARMED_ERR'
    | 'QUEUE_FULL'
    | 'BLOCKED_DEVICE'
    | 'UNKNOWN_COMMAND'
    | 'ALLARM_ONGOING'
    | 'REGISTRATION_BUSY'
    | 'USER_UNOTHORIZED'
    | 'INVALID_FORMAT'
    | 'MEMORY_ALLOC'
    | 'CMD_NOT_VALID'
    | 'PSW_GEN_ERROR'
    | 'CLOUD_NOT_READY'
    | 'PLN_ADD_ERR'
    | 'PLN_DEL_ERR'
    | 'PLN_DEL_EXC'
    | 'CLOUD_REJECT'
    | 'ACK_TIMEOUT'
    | 'UUID_WRONG'
    | 'ROLES_NOT_MATCHING'
    | 'DEVICE_BUSY';
  export const CodeEnum = {
    UNKNOWNERROR: 'UNKNOWN_ERROR' as CodeEnum,
    NOERROR: 'NO_ERROR' as CodeEnum,
    GENERICERROR: 'GENERIC_ERROR' as CodeEnum,
    TYPEMISMATCH: 'TYPE_MISMATCH' as CodeEnum,
    WRONGACTION: 'WRONG_ACTION' as CodeEnum,
    WRONGINDEX: 'WRONG_INDEX' as CodeEnum,
    PERMISSIONDENIED: 'PERMISSION_DENIED' as CodeEnum,
    WRITEFAIL: 'WRITE_FAIL' as CodeEnum,
    LOGINFAIL: 'LOGIN_FAIL' as CodeEnum,
    FILENOTPRESENT: 'FILE_NOT_PRESENT' as CodeEnum,
    CFGLOCKED: 'CFG_LOCKED' as CodeEnum,
    WRONGPARAM: 'WRONG_PARAM' as CodeEnum,
    MISSINGSEGMENT: 'MISSING_SEGMENT' as CodeEnum,
    WRONGUUID: 'WRONG_UUID' as CodeEnum,
    WRONGSSID: 'WRONG_SSID' as CodeEnum,
    ACTIONNEEDSCODE: 'ACTION_NEEDS_CODE' as CodeEnum,
    ARMBLOCKED: 'ARM_BLOCKED' as CodeEnum,
    ACTIONBLOCKEDL2: 'ACTION_BLOCKED_L2' as CodeEnum,
    ACTIONBLOCKEDL3: 'ACTION_BLOCKED_L3' as CodeEnum,
    TOTALARMBLOCKED: 'TOTAL_ARM_BLOCKED' as CodeEnum,
    P1ARMBLOCKED: 'P1_ARM_BLOCKED' as CodeEnum,
    P2ARMBLOCKED: 'P2_ARM_BLOCKED' as CodeEnum,
    USERUNKNOWN: 'USER_UNKNOWN' as CodeEnum,
    INSTALLERNOTAUT: 'INSTALLER_NOT_AUT' as CodeEnum,
    USERNOTAUT: 'USER_NOT_AUT' as CodeEnum,
    USERNOTAUTARM: 'USER_NOT_AUT_ARM' as CodeEnum,
    USERNOTAUTDISARM: 'USER_NOT_AUT_DISARM' as CodeEnum,
    USERNOTAUTSINGLEARM: 'USER_NOT_AUT_SINGLE_ARM' as CodeEnum,
    USERNOTAUTFORCEDARM: 'USER_NOT_AUT_FORCED_ARM' as CodeEnum,
    USERNOTAUTDEV: 'USER_NOT_AUT_DEV' as CodeEnum,
    USERNOTAUTAREA: 'USER_NOT_AUT_AREA' as CodeEnum,
    USERTIMEROFF: 'USER_TIMER_OFF' as CodeEnum,
    USERACTOVERFLOW: 'USER_ACT_OVERFLOW' as CodeEnum,
    EASTEREGG: 'EASTER_EGG' as CodeEnum,
    KEYNOTVALID: 'KEY_NOT_VALID' as CodeEnum,
    ACTIONUNKNOWN: 'ACTION_UNKNOWN' as CodeEnum,
    DEVICEUNKNOWN: 'DEVICE_UNKNOWN' as CodeEnum,
    ABSENTOUT: 'ABSENT_OUT' as CodeEnum,
    ABSENTZONE: 'ABSENT_ZONE' as CodeEnum,
    ZONENOTEXCLUDABLE: 'ZONE_NOT_EXCLUDABLE' as CodeEnum,
    ZONENOTISOLABLE: 'ZONE_NOT_ISOLABLE' as CodeEnum,
    DEVICEERROR: 'DEVICE_ERROR' as CodeEnum,
    TECHPROG: 'TECH_PROG' as CodeEnum,
    UPGRADEERR: 'UPGRADE_ERR' as CodeEnum,
    AREASARMEDERR: 'AREAS_ARMED_ERR' as CodeEnum,
    QUEUEFULL: 'QUEUE_FULL' as CodeEnum,
    BLOCKEDDEVICE: 'BLOCKED_DEVICE' as CodeEnum,
    UNKNOWNCOMMAND: 'UNKNOWN_COMMAND' as CodeEnum,
    ALLARMONGOING: 'ALLARM_ONGOING' as CodeEnum,
    REGISTRATIONBUSY: 'REGISTRATION_BUSY' as CodeEnum,
    USERUNOTHORIZED: 'USER_UNOTHORIZED' as CodeEnum,
    INVALIDFORMAT: 'INVALID_FORMAT' as CodeEnum,
    MEMORYALLOC: 'MEMORY_ALLOC' as CodeEnum,
    CMDNOTVALID: 'CMD_NOT_VALID' as CodeEnum,
    PSWGENERROR: 'PSW_GEN_ERROR' as CodeEnum,
    CLOUDNOTREADY: 'CLOUD_NOT_READY' as CodeEnum,
    PLNADDERR: 'PLN_ADD_ERR' as CodeEnum,
    PLNDELERR: 'PLN_DEL_ERR' as CodeEnum,
    PLNDELEXC: 'PLN_DEL_EXC' as CodeEnum,
    CLOUDREJECT: 'CLOUD_REJECT' as CodeEnum,
    ACKTIMEOUT: 'ACK_TIMEOUT' as CodeEnum,
    UUIDWRONG: 'UUID_WRONG' as CodeEnum,
    ROLESNOTMATCHING: 'ROLES_NOT_MATCHING' as CodeEnum,
    DEVICEBUSY: 'DEVICE_BUSY' as CodeEnum
  };
}
