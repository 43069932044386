/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Output command options
 */
export interface SafeOutputCommandOptionsDto {
  /**
   * State
   */
  state: SafeOutputCommandOptionsDto.StateEnum;
}
export namespace SafeOutputCommandOptionsDto {
  export type StateEnum = 'RESET' | 'SET' | 'UNSET';
  export const StateEnum = {
    RESET: 'RESET' as StateEnum,
    SET: 'SET' as StateEnum,
    UNSET: 'UNSET' as StateEnum
  };
}
