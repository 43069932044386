/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * This command is used to remove a user previously assigned to control panel.
 */
export interface SafeActionUserRemoveDto {
  /**
   * Index row of the user to remove in database’s users table
   */
  userId: number;
  /**
   * Type of user to remove
   */
  role: SafeActionUserRemoveDto.RoleEnum;
}
export namespace SafeActionUserRemoveDto {
  export type RoleEnum = 'USER' | 'INSTALLER';
  export const RoleEnum = {
    USER: 'USER' as RoleEnum,
    INSTALLER: 'INSTALLER' as RoleEnum
  };
}
