<div class="card-table mb-4">
  <div class="card-header">
    <h2>{{ 'DASHBOARD.SITES_WITH_ANOMALIES' | translate }}</h2>
  </div>

  <div class="card-body">
    <app-sites-table
      [onlyAnomalies]="true"
      [hidePagination]="true"
    ></app-sites-table>
  </div>
  <div class="card-footer">
    <a id="site-list-link" [routerLink]="['/sites']" class="link-primary"
      >{{ 'DASHBOARD.SITE_LIST' | translate }}
      <i class="material-icons">keyboard_arrow_right</i></a
    >
  </div>
</div>
