/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ErrorDto } from '../model/models';
import { SafeScenarioResponseDto } from '../model/models';

import { DPCP_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class RealtimeScenariosService {
  protected basePath =
    'https://usvc-preprod.cloud.comelitgroup.com/servicerest/dpcp';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(DPCP_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Retrieve a scenario
   * @param deviceUuid Device UUID
   * @param id Element to be retrieved
   * @param sSID Device SSID session identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getScenario(
    deviceUuid: string,
    id: number,
    sSID: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<SafeScenarioResponseDto>;
  public getScenario(
    deviceUuid: string,
    id: number,
    sSID: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<SafeScenarioResponseDto>>;
  public getScenario(
    deviceUuid: string,
    id: number,
    sSID: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<SafeScenarioResponseDto>>;
  public getScenario(
    deviceUuid: string,
    id: number,
    sSID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling getScenario.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getScenario.'
      );
    }
    if (sSID === null || sSID === undefined) {
      throw new Error(
        'Required parameter sSID was null or undefined when calling getScenario.'
      );
    }

    let headers = this.defaultHeaders;
    if (sSID !== undefined && sSID !== null) {
      headers = headers.set('SSID', String(sSID));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<SafeScenarioResponseDto>(
      `${this.configuration.basePath}/vedo/realtime/device/${encodeURIComponent(
        String(deviceUuid)
      )}/scenario/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all scenarios
   * @param deviceUuid Device UUID
   * @param sSID Device SSID session identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getScenarios(
    deviceUuid: string,
    sSID: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SafeScenarioResponseDto>>;
  public getScenarios(
    deviceUuid: string,
    sSID: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SafeScenarioResponseDto>>>;
  public getScenarios(
    deviceUuid: string,
    sSID: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SafeScenarioResponseDto>>>;
  public getScenarios(
    deviceUuid: string,
    sSID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling getScenarios.'
      );
    }
    if (sSID === null || sSID === undefined) {
      throw new Error(
        'Required parameter sSID was null or undefined when calling getScenarios.'
      );
    }

    let headers = this.defaultHeaders;
    if (sSID !== undefined && sSID !== null) {
      headers = headers.set('SSID', String(sSID));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SafeScenarioResponseDto>>(
      `${this.configuration.basePath}/vedo/realtime/device/${encodeURIComponent(
        String(deviceUuid)
      )}/scenarios`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
