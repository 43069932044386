/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PeripheralDto {
  /**
   * Hardware Id
   */
  readonly hardwareId?: string;
  /**
   * Peripheral description
   */
  readonly description?: string;
  /**
   * Hardware version
   */
  readonly hardwareVersion?: string;
  /**
   * Version code
   */
  readonly versionCode?: string;
  /**
   * Version string
   */
  readonly versionDescription?: string;
}
