<google-map
  #map
  *ngIf="!hideMap"
  id="map"
  [options]="options"
  [center]="{ lat: mapLatLng.latitude, lng: mapLatLng.longitude }"
  [zoom]="sites.length > 0 ? 10 : 3"
  (tilesloaded)="firstLoad ? fitBounds() : null"
  (authFailure)="authFailure()"
>
  <map-marker-clusterer
    #markerCluster
    [styles]="clusterStyles"
    [calculator]="calculator"
    (clusterClick)="clusterClick($event)"
  >
    <map-marker
      #somemarker="mapMarker"
      *ngFor="let item of sites"
      [position]="{
        lat: item.marker.position.latitude,
        lng: item.marker.position.longitude
      }"
      [title]="item.marker.title"
      [options]="{ icon: item.marker.icon, data: item.marker.data }"
      (mapClick)="clickedMarker(somemarker, item)"
    >
      <map-info-window #infowindow [disableAutoPan]="false">
        <ng-container *ngIf="selectedMarkerInfo">
          <ng-container
            [ngTemplateOutlet]="infoTemplate"
            [ngTemplateOutletContext]="{ $implicit: selectedMarkerInfo }"
          >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="overlapMarkers">
          <ng-container *ngFor="let overlap of overlapMarkers">
            <ng-container
              [ngTemplateOutlet]="infoTemplate"
              [ngTemplateOutletContext]="{ $implicit: overlap }"
            >
            </ng-container> </ng-container
        ></ng-container>
      </map-info-window>
    </map-marker>
  </map-marker-clusterer>
</google-map>

<ng-template #infoTemplate let-marker>
  <div
    *ngIf="marker.info.imageUrl"
    class="map-detail-image"
    [ngStyle]="{
      backgroundImage: marker.image
    }"
  ></div>
  <div class="map-detail-info">
    <b [routerLink]="['/sites', marker.info.authenticationId]">{{
      marker.info.name
    }}</b>
    <br />
    <label class="mt-2 mb-0 custom-label">{{
      'NAVIGATION.DEVICES' | translate
    }}</label>
    <br />
    <label class="mt-2 mb-0 custom-label">
      <div class="small-counters" *ngIf="marker.info.deviceNumber !== 0">
        <label
          ><i class="custom-badge-xs material-icons notranslate swap-horiz"
            >check</i
          >{{ marker.info.workingDevices! }}
          {{ 'DEVICE.STATUSES.OPERATING' | translate }}</label
        >
        <label
          ><i class="custom-badge-xs material-icons notranslate signal-wifi-off"
            >signal_wifi_off</i
          >{{ marker.info.offlineDevices }}
          {{ 'DEVICE.STATUSES.OFFLINE' | translate }}</label
        >
        <label
          ><i class="custom-badge-xs material-icons notranslate alarm-severity"
            >warning</i
          >{{ marker.info.deviceWithAnomalies }}
          {{ 'DEVICE.STATUSES.ALERTS' | translate }}</label
        >
        <label>
          <i class="custom-badge-xs material-icons notranslate warning"
            >warning</i
          >{{ marker.info.deviceWithErrors }}
          {{ 'DEVICE.STATUSES.ERRORS' | translate }}</label
        >
        <label *ngIf="marker.info.deviceWithAlarms"
          ><i
            class="custom-badge-xs material-icons notranslate evacuation-severity"
            >local_fire_department</i
          >{{ marker.info.deviceWithAlarms }}
          {{ 'DEVICE.STATUSES.ALARMS' | translate }}</label
        >
        <label *ngIf="marker.info.privacySafeDevices"
          ><i
            class="custom-badge-xs material-icons notranslate settings-input-antenna"
            >visibility_off</i
          >{{ marker.info.privacySafeDevices }}
          {{ 'DEVICE.STATUSES.PRIVACY_MODE' | translate }}</label
        >
      </div>
      <span *ngIf="marker.info.deviceNumber === 0">{{
        'GLOBAL.NO_DEVICES_ASSOCIATED' | translate
      }}</span>
    </label>
  </div>
</ng-template>
