import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SiteDto } from 'src/app/api/myportal/model/siteDto';
import { SitesListService } from '../sites-list.service';

@Component({
  selector: 'app-favourite-sites',
  templateUrl: './favourite-sites.component.html',
  styleUrls: ['./favourite-sites.component.scss']
})
export class FavouriteSitesComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  favSitesList: SiteDto[] = [];

  constructor(
    private siteListService: SitesListService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.siteListService.sites$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sites: SiteDto[]) => {
        this.favSitesList = sites.filter((s) => {
          if (s.userMetadata) {
            return s.userMetadata.preferred;
          }
        });
      });
  }

  goToApts(e: any, fav: SiteDto) {
    this.router.navigate(['/sites', fav.authenticationId, 'apartments']);
  }
}
