<div
  [style.backgroundImage]="imageUrl"
  #imageBox
  class="upload-image-box mb-4"
  [ngClass]="{ isDragging: isDragging }"
>
  <i
    *ngIf="imageValue || imageUrl"
    class="material-icons"
    (click)="deleteImage()"
    >close</i
  >
  <div
    tabindex="0"
    *ngIf="!imageValue && !imageUrl"
    class="upload-box"
    (click)="image.click()"
    (keyup.enter)="image.click()"
    (dragover)="onDragOver($event)"
    (dragleave)="isDragging = false"
    (drop)="imageDropped($event)"
  >
    <i class="material-icons">image</i>
    <span>{{ 'SITE.UPLOAD_PICTURE' | translate }}</span>
    <span class="error" *ngIf="imageTooBig">{{
      'SITE.PICTURE_WEIGHT_TOO_BIG' | translate
    }}</span>
    <span class="error" *ngIf="imageTooBigSize">{{
      'SITE.PICTURE_SIZE_TOO_BIG' | translate
    }}</span>
  </div>
</div>
<input
  #image
  type="file"
  accept="image/png, image/jpeg, image/gif, image/bmp, image/tiff, image/wbmp"
  class="d-none"
  (change)="imageUploadFromInput($event)"
/>
