<div class="container-fluid">
  <div id="header-btns"></div>
  <h1>{{ pageName | translate }}</h1>

  <div class="row mt-3">
    <div class="col-12 col-xl-9">
      <app-sip-activation></app-sip-activation>
      <app-sip-config></app-sip-config>
      <app-sip-phone-lines></app-sip-phone-lines>
    </div>

    <div class="col-12 col-xl-3 mt-3">
      <app-site-info [site]="site"></app-site-info>
    </div>
  </div>
</div>
