/**
 * License Manager API
 * API to manage licenses
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LicenseTypeDto {
  type: LicenseTypeDto.TypeEnum;
  active_apps: number;
}
export namespace LicenseTypeDto {
  export type TypeEnum =
    | 'lic_type_mft'
    | 'lic_type_mf'
    | 'lic_type_mso'
    | 'lic_type_s';
  export const TypeEnum = {
    Mft: 'lic_type_mft' as TypeEnum,
    Mf: 'lic_type_mf' as TypeEnum,
    Mso: 'lic_type_mso' as TypeEnum,
    S: 'lic_type_s' as TypeEnum
  };
}
