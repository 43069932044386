/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ResetPanelNamedDto {
  /**
   * Target parameter indicate the recipient of the command.
   */
  target: ResetPanelNamedDto.TargetEnum;
}
export namespace ResetPanelNamedDto {
  export type TargetEnum = 'CURRENT' | 'ALL';
  export const TargetEnum = {
    CURRENT: 'CURRENT' as TargetEnum,
    ALL: 'ALL' as TargetEnum
  };
}
