/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Zone extended state details
 */
export interface SafeZoneExtendedStateDto {
  /**
   * Opened
   */
  readonly opened?: boolean;
  /**
   * Alarm
   */
  readonly alarm?: boolean;
  /**
   * Fault
   */
  readonly fault?: boolean;
  /**
   * Sabotage
   */
  readonly sabotage?: boolean;
  /**
   * Not ready
   */
  readonly notReady?: boolean;
  /**
   * Inserted
   */
  readonly inserted?: boolean;
  /**
   * Bell
   */
  readonly bell?: boolean;
  /**
   * Excluded
   */
  readonly excluded?: boolean;
  /**
   * Opened
   */
  readonly state?: SafeZoneExtendedStateDto.StateEnum;
  /**
   * In test
   */
  readonly inTest?: boolean;
  /**
   * Masked
   */
  readonly masked?: boolean;
  /**
   * Low battery
   */
  readonly lowBattery?: boolean;
  /**
   * Supervised
   */
  readonly supervised?: boolean;
  /**
   * Inactivity
   */
  readonly inactivity?: boolean;
  /**
   * Inhibited
   */
  readonly inhibited?: boolean;
  /**
   * Stored
   */
  readonly stored?: boolean;
  /**
   * Tamper subzone
   */
  readonly tamperSubzone?: boolean;
  /**
   * No motion
   */
  readonly noMotion?: boolean;
  /**
   * Silent
   */
  readonly silent?: boolean;
}
export namespace SafeZoneExtendedStateDto {
  export type StateEnum = 'READY' | 'ISOLATED' | 'ABSENT';
  export const StateEnum = {
    READY: 'READY' as StateEnum,
    ISOLATED: 'ISOLATED' as StateEnum,
    ABSENT: 'ABSENT' as StateEnum
  };
}
