import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ApartmentsAndUsersService, UserDto } from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { UDTypes } from 'src/app/core/models';
import { checkFormValidity } from 'src/app/core/utils';
import { udTypesLabels, UserRole } from '../../utils/users';

@Component({
  selector: 'app-user-device-create',
  templateUrl: './user-device-create.component.html',
  styleUrls: ['./user-device-create.component.scss']
})
export class UserDeviceCreateComponent implements OnInit {
  userDevices: UserDto[];
  deviceId: string;
  aptId: number;
  isSbc = false;

  canAddVideo = false;
  videoLineAvailable: number;
  phoneLineAvailable: number;
  remainingLines: number;
  nextLineAvailable: number;
  nextLineAvailableNoVideo: number;
  aptType: any;

  udFormGroup: UntypedFormGroup;
  idControl = new UntypedFormControl(undefined, Validators.required);
  typeControl = new UntypedFormControl(2);
  descrControl = new UntypedFormControl('');
  emailControl = new UntypedFormControl(
    '',
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
  );
  extabnameControl = new UntypedFormControl('');
  extabControl = new UntypedFormControl(false);
  backupControl = new UntypedFormControl(false);
  directVideoControl = new UntypedFormControl(false);
  phoneControl = new UntypedFormControl('', Validators.pattern('[-0-9a-zA-Z.+_@:*]*'));
  phoneBackupControl = new UntypedFormControl(
    '',
    Validators.pattern('[-0-9a-zA-Z.+_@:*]*')
  );

  userDeviceCreated = new EventEmitter<void>();

  deviceTypes = Object.values(UserDto.DevtypeEnum);
  typesToUse: UDTypes[] = [];

  userRole: UserRole;

  isLoading = false;
  constructor(
    private modalRef: BsModalRef,
    private aptUserService: ApartmentsAndUsersService,
    private authService: AuthService,
    private toastr: ToastrService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.aptType === 3) {
      this.typeControl.setValue(1);
    }

    this.deviceTypes.forEach((element, index) => {
      if (element !== 'NONE') {
        if (this.aptType === 3) {
          if (element !== 'APP') {
            this.typesToUse.push({
              element,
              index,
              label: this.ts.instant(udTypesLabels[element])
            });
          }
        } else {
          this.typesToUse.push({
            element,
            index,
            label: this.ts.instant(udTypesLabels[element])
          });
        }
      }
    });

    if (
      [
        'BUILDINGMANAGER-COLLABORATOR',
        'BUILDINGMANAGER',
        'MAINTAINER'
      ].includes(this.userRole) ||
      this.isSbc
    ) {
      this.typesToUse.splice(
        this.typesToUse.findIndex((x) => x.element === 'INTERNAL'),
        1
      );
    }

    this.udFormGroup = new UntypedFormGroup({
      id: this.idControl,
      enable: new UntypedFormControl(true),
      devtype: this.typeControl,
      descr: this.descrControl,
      email: this.emailControl,
      sipnum: this.phoneControl,
      backen: this.backupControl,
      extab: this.extabControl,
      extabname: this.extabnameControl
    });

    this.typeControl.valueChanges.subscribe((v: number) => {
      switch (v) {
        case 1:
          this.descrControl.setValue('');
          this.emailControl.setValue('');
          this.backupControl.setValue(false);
          this.phoneBackupControl.setValue('');
          this.phoneControl.setValue('');
          break;
        case 3:
          this.emailControl.setValue('');
          this.backupControl.setValue(false);
          this.directVideoControl.setValue(false);
          this.phoneBackupControl.setValue('');
          break;
        default:
          break;
      }
    });

    this.backupControl.valueChanges.subscribe((v) => {
      if (!v) {
        this.phoneControl.setValue('');
      }
    });
  }

  save() {
    // Phones are last so if its a phone use phoneLineAvailable as id
    if (this.typeControl.value === 3) {
      this.idControl.setValue(this.phoneLineAvailable);
    }

    // Video is selected use videoLineAvailable as id
    if (this.directVideoControl.value) {
      this.idControl.setValue(this.videoLineAvailable);
    } else {
      // Video is NOT selected use nextLineAvailableNoVideo as id. if no no video spaces are available use nextLineAvailable
      this.idControl.setValue(
        this.nextLineAvailableNoVideo !== 0
          ? this.nextLineAvailableNoVideo
          : this.nextLineAvailable
      );
    }

    // Check phoneBackupControl too because it's not on the formGroup
    if (
      checkFormValidity(this.udFormGroup) &&
      !this.isLoading &&
      this.phoneBackupControl.valid
    ) {
      this.isLoading = true;
      const aptUsers = [this.udFormGroup.value];
      aptUsers[0].backen = false;

      if (this.backupControl.value) {
        aptUsers.push({
          id: this.phoneLineAvailable,
          devtype: 3,
          descr:
            this.ts.instant('USER_DEVICE.BACKUP_CALL_FOR') +
            this.descrControl.value,
          email: '',
          sipnum: this.phoneBackupControl.value,
          backen: true,
          extab: false,
          extabname: '',
          enable: true,
          backline: this.idControl.value
        });
      }
      this.aptUserService
        .setApartmentUsers(
          this.deviceId,
          this.aptId,
          this.authService.getToken(),
          {
            apartmentusers: aptUsers
          }
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('USER_DEVICE.USER_DEVICE_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.userDeviceCreated.next();
        });
    }
  }

  close() {
    this.modalRef.hide();
  }
}
