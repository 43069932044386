/**
 * User Document management API
 * API to interact with documents to be submitted to users
 *
 * The version of the OpenAPI document: 1.5.0+readPreferenceAtlasFix.r5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ListDocResDto {
  /**
   * Documents Id
   */
  documentId?: string;
  /**
   * Document Tag
   */
  tag?: string;
  /**
   * Document target
   */
  target?: ListDocResDto.TargetEnum;
  /**
   * Document status
   */
  status?: ListDocResDto.StatusEnum;
  /**
   * Document default message
   */
  defaultMessage?: string;
}
export namespace ListDocResDto {
  export type TargetEnum = 'fire' | 'safe';
  export const TargetEnum = {
    Fire: 'fire' as TargetEnum,
    Safe: 'safe' as TargetEnum
  };
  export type StatusEnum = 'unchecked' | 'checked';
  export const StatusEnum = {
    Unchecked: 'unchecked' as StatusEnum,
    Checked: 'checked' as StatusEnum
  };
}
