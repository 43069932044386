import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  /* IMAGE UPLOAD */
  @Input() imageUrl?: SafeStyle;
  imageTooBig = false;
  imageTooBigSize = false;

  @ViewChild('imageBox') imageBox: ElementRef<HTMLInputElement>;
  @ViewChild('image') image: ElementRef<HTMLInputElement>;
  isDragging = false;
  @Output() imageChanged = new EventEmitter<string>();
  imageValue: string | null = null;

  constructor() {}

  ngOnInit(): void {}

  imageDropped(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      this.imageUpload(event.dataTransfer.files[0]);
    }
  }

  imageUploadFromInput(event: HTMLInputEvent) {
    this.imageUpload(event.target.files ? event.target.files[0] : null);
  }

  onDragOver(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragging = true;
  }

  imageUpload(image: Blob | File | null) {
    this.imageTooBig = false;
    this.imageTooBigSize = false;

    if (image) {
      if (image.size < 900000) {
        const reader = new FileReader();
        reader.onload = (re) => {
          const img = new Image();
          img.src = re.target?.result as string;
          img.onload = () => {
            if (img.width <= 640 && img.height <= 480) {
              const base64 = (reader.result as string).replace(
                /data:(.*);base64,/,
                ''
              );
              this.imageValue = base64;
              this.updateVisibleBgImage(`url(${re.target!.result})`);

              this.imageChanged.emit(base64);
            } else {
              this.imageTooBigSize = true;
            }
          };
        };
        reader.readAsDataURL(image);
      } else {
        this.imageTooBig = true;
      }
    }
  }

  updateVisibleBgImage(path: string) {
    this.imageBox.nativeElement.style.backgroundImage = path;
  }

  deleteImage() {
    this.imageUrl = undefined;
    this.imageValue = null;
    this.imageChanged.emit(undefined);
    this.image.nativeElement.value = '';
    this.updateVisibleBgImage('');
  }
}
