import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ApartmentDto, ApartmentsAndUsersService } from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { mustMatch } from 'src/app/core/must-match.validator';
import { checkFormValidity } from 'src/app/core/utils';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  apartment: ApartmentDto;
  deviceId: string;
  passwordFormGroup: UntypedFormGroup;
  newPassword = new UntypedFormControl('', Validators.required);
  confirmPassword = new UntypedFormControl('', Validators.required);
  isLoading = false;

  passwordChanged = new EventEmitter<string>();

  constructor(
    private modalRef: BsModalRef,
    private aptService: ApartmentsAndUsersService,
    private authService: AuthService,
    private toastr: ToastrService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    this.passwordFormGroup = new UntypedFormGroup(
      {
        psw: this.newPassword,
        psw_conf: this.confirmPassword
      },
      mustMatch('psw', 'psw_conf')
    );
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    if (checkFormValidity(this.passwordFormGroup) && !this.isLoading) {
      this.isLoading = true;
      this.apartment.pwd = this.newPassword.value;
      this.aptService
        .setApartments(this.deviceId, this.authService.getToken(), {
          apartments: [this.apartment]
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('APARTMENT.APARTMENT_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.modalRef.hide();
          this.passwordChanged.next(this.newPassword.value);
        });
    }
  }
}
