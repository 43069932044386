<div class="modal-header">
  <h2>
    {{ 'APARTMENT.CREATE_APARTMENT' | translate }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="aptFormGroup" (keyup.enter)="save()">
    <label class="custom-label" for="device">{{
      'APARTMENT.DEVICE' | translate
    }}</label>
    <b *ngIf="deviceList.length === 1">{{ deviceList[0].resource.name }}</b>
    <ng-select
      *ngIf="deviceList.length > 1"
      id="device"
      [items]="deviceList"
      bindLabel="resource.name"
      bindValue="resource.uuid"
      [formControl]="deviceControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>
    <app-control-errors
      id="device-validator"
      controlName="device"
    ></app-control-errors>
    <label class="custom-label" for="apartment-id">{{
      sbcSelected
        ? ('APARTMENT.SBC_ADDRESS' | translate)
        : ((!forbiddenRoles.includes(userRole)
            ? 'APARTMENT.VIP_ADDRESS'
            : 'APARTMENT.APARTMENT_ID'
          ) | translate)
    }}</label>
    <input
      id="apartment-id"
      [formControl]="vipadrControl"
      [placeholder]="
        sbcSelected
          ? ('APARTMENT.SBC_ADDRESS' | translate)
          : ((!forbiddenRoles.includes(userRole)
              ? 'APARTMENT.VIP_ADDRESS'
              : 'APARTMENT.APARTMENT_ID'
            ) | translate)
      "
      type="text"
      class="custom-input"
      (blur)="add0Padding(vipadrControl, 8)"
    />
    <app-control-errors
      id="apartment-id-validator"
      controlName="vipaddr"
    ></app-control-errors>
    <label class="custom-label" for="enabling">{{
      'APARTMENT.ENABLING' | translate
    }}</label>
    <div class="switch-container">
      <span
        class="abilitation"
        [ngClass]="{ green: enableControl.value }"
      ></span>
      {{
        (enableControl.value ? 'APARTMENT.ENABLED' : 'APARTMENT.DISABLED')
          | translate
      }}
      <div class="switch">
        <app-switch
          [control]="enableControl"
          inputId="enable-switch"
          [disableLogic]="enableControl.disabled"
        ></app-switch>
      </div>
    </div>

    <label class="custom-label" for="description">{{
      'APARTMENT.DESCRIPTION' | translate
    }}</label>
    <input
      id="description"
      [formControl]="descrControl"
      [placeholder]="'APARTMENT.DESCRIPTION' | translate"
      type="text"
      class="custom-input"
    />

    <ng-container *ngIf="!sbcSelected">
      <label class="custom-label" for="licence-type">{{
        'APARTMENT.LICENCE_TYPE' | translate
      }}</label>
      <ng-select
        id="license-type"
        [items]="licenseListToUse"
        bindLabel="label"
        bindValue="index"
        [formControl]="typeControl"
        [searchable]="false"
        [clearable]="false"
      ></ng-select>
      <i *ngIf="typeControl.disabled" class="material-icons loading-type"
        >cached</i
      >
      <app-control-errors
        id="license-type-validator"
        controlName="lictype"
      ></app-control-errors>
    </ng-container>
    <ng-container *ngIf="adminEnabled && !sbcSelected">
      <label class="custom-label" for="email">{{
        'APARTMENT.EMAIL' | translate
      }}</label>
      <input
        id="email"
        [formControl]="emailControl"
        [placeholder]="'APARTMENT.EMAIL' | translate"
        type="text"
        class="custom-input"
      />
      <app-control-errors
        id="email-validator"
        controlName="email"
        [customMessages]="{
          pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
        }"
      ></app-control-errors>

      <label class="custom-label" for="password">{{
        'APARTMENT.PASSWORD' | translate
      }}</label>
      <input
        id="password"
        [placeholder]="'APARTMENT.PASSWORD' | translate"
        [formControl]="passwordControl"
        type="text"
        class="custom-input"
      />
    </ng-container>

    <h3 *ngIf="!sbcSelected" id="more" (click)="showMore = !showMore">
      {{ 'GLOBAL.MORE' | translate }}
      <i class="material-icons">{{
        showMore ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
      }}</i>
    </h3>
    <ng-container *ngIf="showMore || sbcSelected">
      <ng-container *ngIf="!sbcSelected">
        <label class="custom-label" for="address-book-switch">{{
          'APARTMENT.ADDRESS_BOOK' | translate
        }}</label>
        <div class="switch-container">
          <span
            class="abilitation"
            [ngClass]="{ green: extabControl.value }"
          ></span>
          {{
            (extabControl.value ? 'APARTMENT.ENABLED' : 'APARTMENT.DISABLED')
              | translate
          }}
          <div class="switch mt-2">
            <app-switch
              [control]="extabControl"
              inputId="address-book-switch"
              [disableLogic]="extabControl.disabled"
            ></app-switch>
          </div>
        </div>
      </ng-container>

      <label class="custom-label" for="address-book">{{
        'APARTMENT.DIRECTORY' | translate
      }}</label>
      <ng-select
        id="address-book"
        [items]="addrBookMap"
        bindLabel="descr"
        bindValue="dir"
        [placeholder]="'APARTMENT.DIRECTORY' | translate"
        [formControl]="extabValControl"
        [searchable]="false"
        [clearable]="false"
      ></ng-select>
      <i *ngIf="extabValControl.disabled" class="material-icons loading-type"
        >cached</i
      >

      <ng-container *ngIf="!sbcSelected">
        <label class="custom-label" for="address-call">{{
          'APARTMENT.ADDRESS_CALL' | translate
        }}</label>
        <div class="switch-container">
          <input
            id="address-call"
            [formControl]="backupValControl"
            [placeholder]="
              (!forbiddenRoles.includes(userRole)
                ? 'APARTMENT.ADDRESS_CALL_PLACEHOLDER_I'
                : 'APARTMENT.ADDRESS_CALL_PLACEHOLDER'
              ) | translate
            "
            type="text"
            class="custom-input"
            (blur)="add0Padding(backupValControl, 8)"
          />
          <div class="switch mt-2">
            <app-switch
              [control]="backupControl"
              inputId="backup-switch"
              [disableLogic]="backupControl.disabled"
            ></app-switch>
          </div>
          <app-control-errors
            id="address-call-validator"
            controlName="fwdaddr"
          ></app-control-errors>
        </div>
      </ng-container>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button id="cancel" class="button-secondary mr-3" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    id="save"
    buttonClass="button-primary"
    [isLoading]="isLoading"
    (click)="save()"
    buttonType="button"
    >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
  >
</div>
