<div class="modal-header">
  <h2>
    {{ 'CONNECTIVITY.TITLE' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <b class="d-block pb-3">{{ 'CONNECTIVITY.DESC' | translate }}</b>
  <button class="button-primary big mr-3" (click)="comelitConnection()">
    {{ 'CONNECTIVITY.YES' | translate }}
  </button>
  <button class="button-primary big" (click)="noComelitConnection()">
    {{ 'CONNECTIVITY.NO' | translate }}
  </button>
</div>
