import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OpendoorDirectoryDataDto } from 'src/app/api/mugconf';

@Component({
  selector: 'app-create-opendor-modal',
  templateUrl: './create-opendor-modal.component.html',
  styleUrls: ['./create-opendor-modal.component.scss']
})
export class CreateOpendorModalComponent implements OnInit {
  dataGroup: UntypedFormGroup;
  data: OpendoorDirectoryDataDto;
  title: string;
  created = new EventEmitter<OpendoorDirectoryDataDto>();
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.dataGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      dir: new UntypedFormControl(),
      id: new UntypedFormControl(),
      addr: new UntypedFormControl(),
      outputid: new UntypedFormControl(),
      securemode: new UntypedFormControl()
    });

    this.dataGroup.patchValue(this.data);
  }

  close() {
    this.modalRef.hide();
  }
  save() {
    if (this.dataGroup.valid) {
      this.created.next(this.dataGroup.value);
    }
  }
}
