<div class="card mt-3 pb-3" *ngIf="editAllowed">
  <div class="card-header">
    <h2>{{ 'SIP.SIP_THELEPHONE_ACTIVATION' | translate }}</h2>
    <button
      id="edit-activation"
      *ngIf="!isEditing"
      class="button-secondary"
      (click)="editSip()"
    >
      <i class="material-icons notranslate">edit</i>
      {{ 'USER_DEVICE.EDIT_DATA' | translate }}
    </button>
    <div class="button-box" *ngIf="isEditing">
      <button id="cancel" class="button-secondary mr-3" (click)="closeEdit()">
        {{ 'GLOBAL.CANCEL' | translate }}
      </button>
      <app-loading-button
        id="save"
        buttonClass="button-primary"
        [isLoading]="isLoading"
        (click)="updateSip()"
        buttonType="button"
        >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
      >
    </div>
  </div>
  <div class="card-body pt-2">
    <form [formGroup]="sipActivationFormGroup">
      <ul class="info-list">
        <li>
          <span>{{ 'SIP.SIP_PROVIDER' | translate }}</span>
          <b *ngIf="sipProviderControl.value === '' && !isEditing">--</b>
          <ng-select
            *ngIf="sipProviderControl.value !== '' || isEditing"
            id="type"
            [items]="sipProviderList"
            bindLabel="name"
            bindValue="value"
            [formControl]="sipProviderControl"
            [searchable]="false"
            [clearable]="false"
          ></ng-select>
          <a
            *ngIf="
              !isEditing &&
              sipData &&
              !sipData.addConf.sipLicenseExists &&
              this.originalSipProv !== 0
            "
            class="link-primary"
            href="javascript:void(0)"
            (click)="activate()"
            >{{ 'SIP.ACTIVATE' | translate }}</a
          >
        </li>
        <li *ngIf="showStatus">
          <span>{{ 'SIP.LICENSE_STATUS' | translate }}</span>
          <span>{{
            (status ? 'SIP.ACTIVE' : 'SIP.INACTIVE') | translate
          }}</span>
        </li>
      </ul>
    </form>
  </div>
</div>
