export * from './licenseActionCatalogManagement.service';
import { LicenseActionCatalogManagementService } from './licenseActionCatalogManagement.service';
export * from './licenseBundleCatalogManagement.service';
import { LicenseBundleCatalogManagementService } from './licenseBundleCatalogManagement.service';
export * from './licenseCatalogManagement.service';
import { LicenseCatalogManagementService } from './licenseCatalogManagement.service';
export * from './licenseInstanceManagement.service';
import { LicenseInstanceManagementService } from './licenseInstanceManagement.service';
export * from './licenseInstanceManagementDevice.service';
import { LicenseInstanceManagementDeviceService } from './licenseInstanceManagementDevice.service';
export * from './licenseInstanceManagementJobs.service';
import { LicenseInstanceManagementJobsService } from './licenseInstanceManagementJobs.service';
export * from './renewCodeManagement.service';
import { RenewCodeManagementService } from './renewCodeManagement.service';
export const APIS = [
  LicenseActionCatalogManagementService,
  LicenseBundleCatalogManagementService,
  LicenseCatalogManagementService,
  LicenseInstanceManagementService,
  LicenseInstanceManagementDeviceService,
  LicenseInstanceManagementJobsService,
  RenewCodeManagementService
];
