/**
 * Cloud Message Center API
 * API to interact with cloud message center
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RequestReadDto {
  /**
   * Filter clause
   */
  filter?: { [key: string]: object };
  /**
   * Sort clause
   */
  sort?: { [key: string]: object };
  /**
   * Page size
   */
  limit?: number;
  /**
   * Number of items to skip
   */
  skip?: number;
}
