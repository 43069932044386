<div class="modal-header">
  <h2>
    {{ 'SIP.SIP_PHONE_ACTIVATION' | translate }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="activationFormGroup" (keyup.enter)="save()">
    <label class="custom-label" for="site-name">{{
      'DASHBOARD.SITE' | translate
    }}</label>
    <input
      id="site-name"
      [formControl]="siteNameControl"
      type="text"
      class="custom-input"
    />

    <label class="custom-label" for="site-address">{{
      'SITE.ADDRESS' | translate
    }}</label>
    <input
      id="site-address"
      [formControl]="siteAddressControl"
      type="text"
      class="custom-input"
    />

    <label class="custom-label" for="site-city">{{
      'SITE.CITY' | translate
    }}</label>
    <input
      id="site-city"
      [formControl]="siteCityControl"
      type="text"
      class="custom-input"
    />

    <label class="custom-label" for="site-zip">{{
      'SITE.ZIP' | translate
    }}</label>
    <input
      id="site-zip"
      [formControl]="siteZipControl"
      type="text"
      class="custom-input"
    />

    <label class="custom-label" for="site-country">{{
      'SITE.COUNTRY' | translate
    }}</label>
    <input
      id="site-country"
      [formControl]="siteCountryControl"
      type="text"
      class="custom-input"
    />

    <label class="custom-label" for="dev-desc">{{
      'SIP.DEVICE_DESCRIPTION_CALLER_ID' | translate
    }}</label>
    <input
      id="dev-desc"
      [formControl]="devDescControl"
      type="text"
      [placeholder]="'SIP.DEVICE_DESCRIPTION_CALLER_ID' | translate"
      class="custom-input"
    />
    <label class="custom-label" for="email-contact-1">{{
      'SIP.EMAIL_CONTACT_1' | translate
    }}</label>
    <input
      id="email-contact-1"
      [formControl]="email1Control"
      type="text"
      [placeholder]="'SIP.EMAIL_CONTACT_1' | translate"
      class="custom-input"
    />
    <div
      *ngIf="
        email1Control.errors && (email1Control.touched || email1Control.dirty)
      "
      class="input-field-error"
    >
      {{ 'VALIDATE.EMAIL_NOT_CORRECT' | translate }}
    </div>

    <label class="custom-label" for="email-contact-2">{{
      'SIP.EMAIL_CONTACT_2' | translate
    }}</label>
    <input
      id="email-contact-2"
      [formControl]="email2Control"
      type="text"
      [placeholder]="'SIP.EMAIL_CONTACT_2' | translate"
      class="custom-input"
    />
    <div
      *ngIf="
        email2Control.errors && (email2Control.touched || email2Control.dirty)
      "
      class="input-field-error"
    >
      {{ 'VALIDATE.EMAIL_NOT_CORRECT' | translate }}
    </div>
  </form>
</div>
<div class="modal-footer">
  <button id="cancel" class="button-secondary mr-3" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    id="save"
    buttonClass="button-primary"
    [isLoading]="isLoading"
    (click)="save()"
    buttonType="button"
    >{{ 'GLOBAL.CONFIRM' | translate }}</app-loading-button
  >
</div>
