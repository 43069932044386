/**
 * Authentication - OAuth2 API
 * API to deal with grants obtained by the OAuth2 flow
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Grant Code data
 */
export interface OAuth2GetGrantCodeResponse {
  /**
   * Grant code
   */
  readonly authorizationCode?: string;
  /**
   * Authentication ID related to the entity who generated the grant code
   */
  readonly origAuthenticationId?: string;
  /**
   * Expiration date
   */
  readonly expiresAt?: string;
  /**
   * Authentication ID related to the target entity
   */
  readonly targetAuthId?: string;
  /**
   * Type of the target entity
   */
  readonly targetType?: OAuth2GetGrantCodeResponse.TargetTypeEnum;
  /**
   * Redirect URI
   */
  readonly redirectionUrl?: string;
  /**
   * URL to the external configuration services
   */
  readonly targetInterfaceUrl?: string;
  /**
   * URL to the oAuth2 access token service
   */
  readonly authorizationUrl?: string;
}
export namespace OAuth2GetGrantCodeResponse {
  export type TargetTypeEnum = 'site';
  export const TargetTypeEnum = {
    Site: 'site' as TargetTypeEnum
  };
}
