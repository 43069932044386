import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { StatusAndConfigurationService } from 'src/app/api/mugconf';
import { SiteDto } from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { Option, SipData } from 'src/app/core/models';
import { checkFormValidity } from 'src/app/core/utils';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { SipService } from '../sip.service';
import { SipActivationModalComponent } from './sip-activation-modal/sip-activation-modal.component';

@Component({
  selector: 'app-sip-activation',
  templateUrl: './sip-activation.component.html',
  styleUrls: ['./sip-activation.component.scss']
})
export class SipActivationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  site: SiteDto;
  isEditing = false;
  isLoading = false;
  sipActivationFormGroup: UntypedFormGroup;
  sipProviderControl = new UntypedFormControl();
  originalSipProv: number;
  showStatus = false;
  status = false;
  sipData: SipData;

  editAllowed = true;
  sipProviderList: Option[] = [];

  deviceId: string;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ts: TranslateService,
    private sipService: SipService,
    private statusAndConfService: StatusAndConfigurationService
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.sipProviderList = [
      {
        name: this.ts.instant('SIP.COMELIT_SIP_PROVIDER'),
        value: 1
      },
      {
        name: this.ts.instant('SIP.OTHER_SIP_PROVIDER'),
        value: 0
      }
    ];
    this.deviceId = this.route.snapshot.data.device.resource.uuid;
    this.site = this.route.snapshot.data.site;
    this.sipActivationFormGroup = new UntypedFormGroup({
      sipCfgMode: this.sipProviderControl
    });

    this.sipActivationFormGroup.disable();
    this.sipService.sipData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: SipData | undefined) => {
        if (res) {
          this.sipData = res;

          this.sipProviderControl.setValue(res.addConf.sipCfgMode);
          this.originalSipProv = res.addConf.sipCfgMode!;
          this.editAllowed = res.addConf.sipAutoAllowed || false;
          this.status = res.addConf.sipLicenseEnabled || false;
          this.showStatus =
            (this.originalSipProv !== 0 &&
              res.addConf.sipLicenseEnabled &&
              res.addConf.sipLicenseExists) ||
            false;
        }
      });
  }

  editSip() {
    this.isEditing = true;
    this.sipActivationFormGroup.enable();
  }

  activate() {
    const initialState: Partial<SipActivationModalComponent> = {
      site: this.site,
      deviceId: this.deviceId
    };
    const modalRef = this.modalService.show(SipActivationModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  updateSip() {
    if (checkFormValidity(this.sipActivationFormGroup)) {
      if (this.sipProviderControl.value !== this.originalSipProv) {
        const initialState: Partial<ConfirmModalComponent> = {
          title: this.ts.instant('SIP.CONFIRM_CHANGE'),
          description:
            this.sipProviderControl.value === 0
              ? this.ts.instant('SIP.CONFIRM_CHANGE_DESC_OTHER')
              : this.ts.instant('SIP.CONFIRM_CHANGE_DESC_COMELIT')
        };

        const modalRef = this.modalService.show(ConfirmModalComponent, {
          initialState,
          ignoreBackdropClick: true
        });

        modalRef.content!.confirmAction.subscribe(() => {
          modalRef.hide();
          this.isLoading = true;
          this.statusAndConfService
            .setSipAdditionalCfg(
              this.deviceId,
              this.authService.getToken(),
              this.sipActivationFormGroup.value
            )
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
              this.originalSipProv = this.sipProviderControl.value;
              this.toastr.success(
                this.ts.instant('SIP.SIPEDITED'),
                this.ts.instant('GLOBAL.SUCCESS')
              );
              this.closeEdit();
              this.updateSipData();
            });
        });
      } else {
        this.closeEdit();
      }
    }
  }

  closeEdit() {
    this.isEditing = false;
    this.sipProviderControl.setValue(this.originalSipProv);
    this.sipActivationFormGroup.disable();
  }

  updateSipData() {
    this.sipData.addConf.sipCfgMode = this.originalSipProv;
    this.sipService.sipData$.next(this.sipData);
  }
}
