<app-datatable
  *ngIf="!isMobile"
  [columns]="columns"
  [dataSource]="dataSource"
  [selectionType]="_selectionType"
  [selectionHandler]="selectedHandler"
  [selected]="selected"
  [hidePagination]="true"
  [messages]="{
    emptyMessage: 'DATATABLE.NO_DATA_DEVICES' | translate
  }"
></app-datatable>
<pagination
  *ngIf="!hidePagination"
  [totalItems]="total"
  [directionLinks]="!isMobile"
  [boundaryLinks]="true"
  [itemsPerPage]="limit"
  [ngModel]="dataSource.page + 1"
  [maxSize]="5"
  (pageChanged)="pageChange($event)"
></pagination>

<app-mobile-table *ngIf="isMobile" [dataSource]="dataSource">
  <div id="mobile-devices" class="mobile-table">
    <div
      *ngFor="let item of dataSource.items; let i = index"
      class="mobile-card device"
    >
      <span class="mobile-card-title">
        <ng-container
          *ngTemplateOutlet="nameTpl; context: { row: item, rowIndex: i }"
        ></ng-container>
      </span>
      <span class="mobile-card-subtitle" id="model-{{ i }}">{{
        item.resource.deviceModelDescription || '--'
      }}</span>
      <span *ngIf="!siteId" class="mobile-card-note">
        <ng-container
          *ngTemplateOutlet="siteTpl; context: { row: item, rowIndex: i }"
        ></ng-container>
      </span>
      <span class="mobile-card-icons">
        <ng-container
          *ngTemplateOutlet="
            connectionTpl;
            context: { value: item.status.status, row: item, rowIndex: i }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            anomaliesTpl;
            context: {
              row: item,
              rowIndex: i
            }
          "
        ></ng-container>
      </span>
      <span class="mobile-card-actions">
        <ng-container
          *ngTemplateOutlet="actionsTpl; context: { row: item, rowIndex: i }"
        ></ng-container>
      </span>
      <ng-container
        *ngTemplateOutlet="menuTpl; context: { row: item, rowIndex: i }"
      ></ng-container>
    </div>
  </div>
</app-mobile-table>

<ng-template #nameTpl let-row="row" let-rowIndex="rowIndex">
  <a
    id="name-{{ rowIndex }}"
    [class.disabled]="
      isInPrivacyMode(row) ||
      isUT(row.resource.deviceModelId) ||
      !hasStatus(row)
    "
    [routerLink]="
      row.resource.siteAuthenticationId
        ? [
            '/devices',
            row.resource.siteAuthenticationId,
            row.resource.authenticationId
          ]
        : ['/devices', row.resource.authenticationId]
    "
  >
    <app-device-image [modelId]="row.resource.deviceModelId"></app-device-image>
    <b>{{ row.resource.name || '--' }}</b></a
  >
</ng-template>
<ng-template #modelTpl let-row="row" let-rowIndex="rowIndex">
  <b id="model-{{ rowIndex }}">{{
    row.resource.deviceModelDescription || '--'
  }}</b>
</ng-template>
<ng-template #ownerTpl let-row="row" let-rowIndex="rowIndex">
  <a
    id="owner-{{ rowIndex }}"
    [class.not-clickable]="
      row.resource.ownerUserIdentifier === authService.getEmail()
    "
    [routerLink]="
      row.resource.ownerUserIdentifier === authService.getEmail()
        ? []
        : ['/users', row.resource.ownerAuthenticationId]
    "
    >{{ row.resource.ownerUserIdentifier || '--' }}</a
  >
</ng-template>
<ng-template #firmwareTpl let-row="row" let-rowIndex="rowIndex">
  <span
    *ngIf="
      !isInPrivacyMode(row) || !isSafeModel(row.resource.system);
      else privacyMode
    "
    id="firmware-{{ rowIndex }}"
  >
    {{ getFwVersion(row) }}</span
  >
</ng-template>

<ng-template #anomaliesTpl let-row="row" let-rowIndex="rowIndex">
  <ng-container
    *ngIf="
      !isUT(row.resource.deviceModelId) &&
      !isInPrivacyMode(row) &&
      row.status.status !== 'offline' &&
      hasStatus(row)
    "
  >
    <ng-container
      *ngIf="
        ['evacuation', 'prealarm', 'alarm'].includes(
          row.status.applicationStatus
        )
      "
    >
      <app-anomaly-status
        id="anomalies-{{ rowIndex }}"
        [severity]="'evacuation'"
      ></app-anomaly-status>
    </ng-container>
    <ng-container
      *ngIf="
        !['evacuation', 'prealarm', 'alarm'].includes(
          row.status.applicationStatus
        )
      "
    >
      <app-anomaly-status
        id="anomalies-{{ rowIndex }}"
        [severity]="row.resource.serviceStatus || 'ok'"
      ></app-anomaly-status>
    </ng-container>
  </ng-container>
  <span
    *ngIf="
      isUT(row.resource.deviceModelId) ||
      (!isInPrivacyMode(row) && row.status.status === 'offline') ||
      (!isInPrivacyMode(row) && !hasStatus(row))
    "
    class="pl-1"
    >--</span
  >

  <label
    class="mb-0"
    *ngIf="isInPrivacyMode(row) && row.status.status !== 'offline'"
    ><i class="custom-badge material-icons notranslate normal"
      >visibility_off</i
    >
    {{ 'DEVICE.PRIVACY_MODE' | translate }}</label
  >
</ng-template>
<ng-template
  #connectionTpl
  let-row="row"
  let-value="value"
  let-rowIndex="rowIndex"
>
  <ng-container *ngIf="!isUT(row.resource.deviceModelId)">
    <app-connection-status
      *ngIf="
        !isInPrivacyMode(row) ||
          (row.resource.system && !isSafeModel(row.resource.system));
        else privacyMode
      "
      id="connection-{{ rowIndex }}"
      [online]="row.status.status === 'ready'"
    ></app-connection-status>
  </ng-container>
  <label class="mb-0" *ngIf="isUT(row.resource.deviceModelId)"
    ><i
      [tooltip]="'DEVICE.BLUETOOTH_FUNC' | translate"
      class="custom-badge material-icons notranslate bl"
      >bluetooth</i
    >
  </label>
</ng-template>
<ng-template #siteTpl let-row="row" let-rowIndex="rowIndex">
  <a
    id="site-{{ rowIndex }}"
    *ngIf="row.resource.siteAuthenticationId"
    [routerLink]="['/sites', row.resource.siteAuthenticationId]"
  >
    <b>{{ row.resource.siteName }}</b></a
  >
  <span *ngIf="!row.resource.siteAuthenticationId">
    <button
      id="associate-site-{{ rowIndex }}"
      class="button-primary nowrap"
      (click)="associateSite(row)"
    >
      {{ 'GLOBAL.ASSOCIATE_SITES' | translate }}
    </button>
  </span>
</ng-template>
<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <i
    id="apartment-{{ rowIndex }}"
    *ngIf="
      row.resource.system &&
      row.resource.siteAuthenticationId &&
      isMugModel(row.status.system) &&
      !isInPrivacyMode(row) &&
      !isUT(row.resource.deviceModelId)
    "
    [routerLink]="
      row.status.status === 'ready'
        ? [
            '/devices',
            row.resource.siteAuthenticationId,
            row.resource.authenticationId,
            'apartments'
          ]
        : []
    "
    [ngClass]="{
      disabled: row.status.status !== 'ready'
    }"
    class="material-icons notranslate single-menu-icon mr-2"
    [tooltip]="
      (row.status.status === 'ready'
        ? 'SITE.APARTMENTS_LIST'
        : 'SITE.DEVICE_OFFLINE'
      ) | translate
    "
    >apartment</i
  >
  <i
    id="unlock-{{ rowIndex }}"
    *ngIf="isInPrivacyMode(row)"
    class="material-icons notranslate single-menu-icon mr-2"
    (click)="openUnlock()"
    [tooltip]="'DEVICE.UNLOCK_DEVICE' | translate"
    >lock_open</i
  >
</ng-template>
<ng-template #menuTpl let-row="row" let-rowIndex="rowIndex">
  <div
    *ngIf="!isInPrivacyMode(row)"
    (isOpenChange)="retrieveSiteUserInfo($event, row)"
    class="btn-group top"
    dropdown
    container="body"
  >
    <i
      id="menu-{{ rowIndex }}"
      class="material-icons notranslate menu-icon"
      dropdownToggle
      >more_vert</i
    >
    <ul
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right"
      role="menu"
      aria-labelledby="menu"
    >
      <i *ngIf="!canAct" class="material-icons notranslate loading-icon"
        >cached</i
      >
      <li>
        <a
          *ngIf="
            !isInPrivacyMode(row) &&
            !isUT(row.resource.deviceModelId) &&
            hasStatus(row)
          "
          id="view-{{ rowIndex }}"
          class="dropdown-item"
          [routerLink]="
            row.resource.siteAuthenticationId
              ? [
                  '/devices',
                  row.resource.siteAuthenticationId,
                  row.resource.authenticationId
                ]
              : ['/devices', row.resource.authenticationId]
          "
          >{{ 'GLOBAL.VIEW' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          isSafeModel(row.resource.system) &&
          userRoleForSelectedSite !== 'INSTALLER-COLLABORATOR' &&
          row.resource.siteAuthenticationId
        "
        role="menuitem"
      >
        <a
          id="unlink-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="unlinkDevice(row)"
          >{{ 'GLOBAL.UNLINK' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          userRoleForSelectedSite !== 'BUILDINGMANAGER-COLLABORATOR' &&
          userRoleForSelectedSite !== 'MAINTAINER' &&
          ((isSafeModel(row.resource.system) &&
            row.resource.siteAuthenticationId) ||
            !isSafeModel(row.resource.system))
        "
        role="menuitem"
      >
        <a
          id="edit-{{ rowIndex }}"
          href="javascript:void(0)"
          [attr.disabled]="!canAct"
          [ngClass]="{ disabled: !canAct }"
          class="dropdown-item"
          (click)="editDevice(row)"
          >{{ 'GLOBAL.EDIT' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          userRoleForSelectedSite === 'ADMIN' ||
          (isSafeModel(row.resource.system) &&
            userRoleForSelectedSite !== 'INSTALLER-COLLABORATOR')
        "
        role="menuitem"
      >
        <a
          *ngIf="!isUT(row.resource.deviceModelId)"
          id="delete-{{ rowIndex }}"
          href="javascript:void(0)"
          [attr.disabled]="!canAct"
          [ngClass]="{ disabled: !canAct }"
          class="dropdown-item"
          (click)="deleteDevice(row)"
          >{{ 'GLOBAL.DELETE' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          userRoleForSelectedSite === 'ADMIN' &&
          isSbc(row.resource.deviceModelId)
        "
        role="menuitem"
      >
        <a
          id="replace-{{ rowIndex }}"
          href="javascript:void(0)"
          [attr.disabled]="!canAct"
          [ngClass]="{ disabled: !canAct }"
          class="dropdown-item"
          (click)="replaceDevice(row)"
          >{{ 'DEVICE.MUGSBC.REPLACE_DEVICE' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          userRoleForSelectedSite === 'BUILDINGMANAGER-COLLABORATOR' &&
          !isSafeModel(row.resource.system) &&
          !hasStatus(row)
        "
        role="menuitem"
      >
        <span class="dropdown-item" href="javascript:void(0)">{{
          'GLOBAL.NO_ACTIONS' | translate
        }}</span>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #privacyMode>
  <span class="pl-1">-- </span>
</ng-template>
