import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  AddActuatorDirectoryDataDto,
  DirectoriesService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ComelitButtonModalComponent } from './comelit-button-modal/comelit-button-modal.component';

@Component({
  selector: 'app-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss']
})
export class ButtonCardComponent implements OnInit {
  @Input() deviceId: string;
  @Input() dirId: number;
  columns: TableColumn[] = [];
  addAct: AddActuatorDirectoryDataDto[] = [];
  dataSource = new ClientFiltrableDataSource<AddActuatorDirectoryDataDto>();
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('statusTpl', { static: true }) statusTpl: ElementRef<HTMLElement>;
  @ViewChild('addressTpl', { static: true }) addressTpl: ElementRef<
    HTMLElement
  >;

  get isMobile() {
    return isMobile;
  }
  constructor(
    private ts: TranslateService,
    private modalService: BsModalService,
    private dirService: DirectoriesService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'enabled',
        name: this.ts.instant('ADDRESSBOOK.STATUS'),
        cellTemplate: this.statusTpl
      },
      {
        prop: 'addr',
        name: this.ts.instant('ADDRESSBOOK.ADDRESS'),
        cellTemplate: this.addressTpl
      },
      {
        prop: 'id',
        cellTemplate: this.actionsTpl,
        name: this.ts.instant('GLOBAL.ACTIONS'),
        maxWidth: 150
      }
    ];

    this.getActuators();
  }

  getActuators() {
    this.dirService
      .getAddActuators(this.deviceId, this.dirId, this.authService.getToken())
      .subscribe((act: AddActuatorDirectoryDataDto[]) => {
        this.dataSource.setItems(act);
        this.addAct = act;
      });
  }

  addButton() {
    const initialState: Partial<ComelitButtonModalComponent> = {
      dirId: this.dirId
    };

    const modalRef = this.modalService.show(ComelitButtonModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: AddActuatorDirectoryDataDto) => {
      this.addAct.push(v);
      this.dirService
        .setAddActuators(
          this.deviceId,
          this.dirId,
          this.authService.getToken(),
          { addactuators: this.addAct }
        )
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.COMELIT_APP_BUTTON_ADDED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          modalRef.hide();
          this.getActuators();
        });
    });
  }

  delete(row: AddActuatorDirectoryDataDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_BUTTON'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_BUTTON_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.dirService
        .deleteAddActuator(
          this.deviceId,
          this.dirId,
          row.id,
          this.authService.getToken()
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.BUTTON_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getActuators();
        });
    });
  }

  edit(row: AddActuatorDirectoryDataDto) {
    const initialState: Partial<ComelitButtonModalComponent> = {
      data: row,
      dirId: this.dirId
    };

    const modalRef = this.modalService.show(ComelitButtonModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: AddActuatorDirectoryDataDto) => {
      this.addAct.splice(
        this.addAct.findIndex((x) => x.id === row.id),
        1,
        v
      );
      this.dirService
        .setAddActuators(
          this.deviceId,
          this.dirId,
          this.authService.getToken(),
          { addactuators: this.addAct }
        )
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.COMELIT_APP_BUTTON_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          modalRef.hide();
          this.getActuators();
        });
    });
  }
}
