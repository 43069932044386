import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SiteDto } from '../api/myportal/model/siteDto';
import { AuthService } from '../core/auth.service';
import { MobileService } from '../core/mobile.service';
import { CreateDeviceModalComponent } from '../devices/create-device-modal/create-device-modal.component';
import {
  DeviceListDto,
  DevicesListService
} from '../shared/devices-list.service';
import { SitesListService } from '../shared/sites-list.service';
import { CreateSiteModalComponent } from '../sites/create-site-modal/create-site-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  createDropDOpen = false;
  hideSiteAnomalies = false;
  hideDeviceAnomalies = false;
  siteList: SiteDto[] = [];

  totDevices = 0;
  totWorkingDevices = 0;
  totOfflineDevices = 0;
  totDevicesAlerts = 0;
  totDevicesAnomalies = 0;
  totDevicesErrors = 0;
  totDevicesPrivacyMode = 0;

  get pageName() {
    return this.route.snapshot.data.title;
  }

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public mobileService: MobileService,
    public ts: TranslateService,
    public modalService: BsModalService,
    private siteListService: SitesListService,
    private deviceListService: DevicesListService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.mobileService.appInfoSetup(
      { title: this.ts.instant(this.pageName) },
      { visibility: false }
    );

    this.siteListService.sites$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sites: SiteDto[]) => {
        this.siteList = sites;

        this.totDevices = 0;
        this.totWorkingDevices = 0;
        this.totOfflineDevices = 0;
        this.totDevicesAlerts = 0;
        this.totDevicesAnomalies = 0;
        this.totDevicesErrors = 0;
        this.totDevicesPrivacyMode = 0;

        this.siteList.forEach((element) => {
          this.totDevices += element.deviceNumber || 0;
          this.totWorkingDevices += element.workingDevices || 0;
          this.totOfflineDevices += element.offlineDevices || 0;
          this.totDevicesAlerts += element.deviceWithAlarms || 0;
          this.totDevicesAnomalies += element.deviceWithAnomalies || 0;
          this.totDevicesErrors += element.deviceWithErrors || 0;
          this.totDevicesPrivacyMode += element.privacySafeDevices || 0;
        });
        this.hideSiteAnomalies =
          sites.filter(
            (x) =>
              x.deviceWithAlarms !== 0 &&
              x.deviceWithErrors !== 0 &&
              x.deviceWithAnomalies !== 0
          ).length === 0;
      });

    this.deviceListService.devicesNEW$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((devices: DeviceListDto) => {
        this.hideDeviceAnomalies = devices.count === 0;
      });

    this.deviceListService.deviceListUpdated
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.siteListService.loadSites())
      )
      .subscribe();
  }

  createNewSite() {
    const initialState: Partial<CreateSiteModalComponent> = {};

    const modalRef = this.modalService.show(CreateSiteModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteCreated.subscribe(() => {
      this.siteListService.loadSites().subscribe();
    });
  }

  createNewDevice() {
    const initialState: Partial<CreateDeviceModalComponent> = {};

    const modalRef = this.modalService.show(CreateDeviceModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.deviceCreatedEv.subscribe(() => {
      this.deviceListService.loadDevicesNEW(
        undefined,
        false,
        false,
        10,
        0,
        undefined,
        undefined
      );
      this.deviceListService.deviceListUpdated.next();
    });
  }
}
