/**
 * User Document management API
 * API to interact with documents to be submitted to users
 *
 * The version of the OpenAPI document: 1.2.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ViolatedConstraintsDto {
  fieldName?: string;
  message?: string;
}
