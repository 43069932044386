import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  InvitationMailCfgDto,
  StatusAndConfigurationService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { Option } from 'src/app/core/models';
import { checkFormValidity } from 'src/app/core/utils';
import { DeviceDetailService } from 'src/app/devices/device-detail/device-detail.service';

@Component({
  selector: 'app-communication-modal',
  templateUrl: './communication-modal.component.html',
  styleUrls: ['./communication-modal.component.scss']
})
export class CommunicationModalComponent implements OnInit {
  isLoading = false;
  invitationFormGroup: UntypedFormGroup;
  siteControl = new UntypedFormControl('', Validators.required);
  emailControl = new UntypedFormControl('', [
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
    Validators.required
  ]);
  phoneControl = new UntypedFormControl('', Validators.pattern('[0-9 +]*'));
  timesControl = new UntypedFormControl(0, Validators.required);
  periodControl = new UntypedFormControl(0, Validators.required);
  infoControl = new UntypedFormControl('');
  enableControl = new UntypedFormControl(false);
  deviceId: string;
  languageControl = new UntypedFormControl('', Validators.required);
  deviceVersion: number;
  isSbc: boolean;
  languages: Option[];

  constructor(
    private modalRef: BsModalRef,
    private authService: AuthService,
    private toastr: ToastrService,
    private confService: StatusAndConfigurationService,
    private ts: TranslateService,
    private deviceDetailService: DeviceDetailService
  ) {}

  ngOnInit(): void {
    this.languages = [
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.EN'),
        value: -1
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.ES'),
        value: 0
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.FR'),
        value: 1
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.IT'),
        value: 2
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.NL'),
        value: 3
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.DE'),
        value: 4
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.PT'),
        value: 5
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.PL'),
        value: 6
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.RU'),
        value: 7
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.TR'),
        value: 8
      }
    ];

    this.invitationFormGroup = new UntypedFormGroup({
      inviteall: new UntypedFormControl(),
      invitedescr: this.siteControl,
      inviteinfo: this.infoControl,
      invitemail: this.emailControl,
      invitemax: this.timesControl,
      inviteperiod: this.periodControl,
      invitephone: this.phoneControl,
      inviteen: this.enableControl
    });

    if (this.deviceVersion >= 2.4 || this.isSbc) {
      this.invitationFormGroup.setControl(
        'invitationLanguage',
        this.languageControl
      );
    }

    if (this.deviceId) {
      this.confService
        .getInvitationMailCfg(this.deviceId, this.authService.getToken())
        .subscribe((r: InvitationMailCfgDto[]) => {
          this.invitationFormGroup.patchValue(r[0]);
        });
    }
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    if (
      checkFormValidity(this.invitationFormGroup) &&
      this.invitationFormGroup.dirty &&
      !this.isLoading
    ) {
      this.isLoading = true;
      this.confService
        .setInvitationMailCfg(
          this.deviceId,
          this.authService.getToken(),
          this.invitationFormGroup.value
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('COMMUNICATION.INVITATION_EMAIL_SETUP_SAVED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.modalRef.hide();
          this.deviceDetailService.updatedEmailStatus.next(
            this.enableControl.value
          );
        });
    }
  }
}
