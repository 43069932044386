import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export type DateRange = [string, string] | undefined;

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {
  selectedRange: DateRange;
  formToFormGroup: FormGroup;
  fromControl = new FormControl(undefined, [Validators.required]);
  toControl = new FormControl(undefined, [Validators.required]);
  @Input() filterText?: string;
  @Output() dateFilterChanged = new EventEmitter<DateRange>();
  @ViewChild('dropdown', { static: false }) dropdown: any;

  constructor() {}

  ngOnInit(): void {
    this.formToFormGroup = new FormGroup({
      from: this.fromControl,
      to: this.toControl
    });
  }

  selectRange() {
    this.selectedRange = [this.fromControl.value!, this.toControl.value!];
    this.dateFilterChanged.emit(this.selectedRange);
    this.dropdown.hide();
  }

  removeSelected() {
    this.selectedRange = undefined;
    this.dateFilterChanged.emit(this.selectedRange);
    this.formToFormGroup.reset();
  }
}
