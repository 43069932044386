import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import {
  ApartmentDto,
  ApartmentsAndUsersService,
  LicenseDto,
  LicensesService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { ApartmentCompleteDto, DeviceCompleteDto } from 'src/app/core/models';
import { licensesLabels } from 'src/app/shared/utils/users';

@Injectable({
  providedIn: 'root'
})
export class ApartmentsService {
  selectedDeviceChanged = new Subject<DeviceCompleteDto[]>();
  licenseListToUse = new EventEmitter<any>();

  constructor(
    private aptUserService: ApartmentsAndUsersService,
    private authService: AuthService,
    private licenseService: LicensesService,
    private ts: TranslateService
  ) {}

  resetApt(apt: ApartmentCompleteDto): ApartmentCompleteDto {
    apt.descr = '';
    apt.pwd = '';
    apt.email = '';
    apt.extab = false;
    apt.fwdbusy = false;
    apt.fwdaddr = '';
    apt.addrbook = 0;
    return apt;
  }

  getLicenses(deviceUuid: string) {
    this.aptUserService
      .getApartments(
        deviceUuid,
        this.authService.getToken(),
        undefined,
        undefined,
        ['lictype']
      )
      .subscribe((res) => {
        const licensesUsed = {
          master: 0,
          mtime: 0,
          phone: 0,
          slave: 0
        };
        res.forEach((element) => {
          switch (element.lictype as any) {
            case 1:
              licensesUsed.slave += 1;
              break;
            case 2:
              licensesUsed.master += 1;
              break;
            case 3:
              licensesUsed.phone += 1;
              break;
            case 4:
              licensesUsed.mtime += 1;
              break;
          }
        });

        return this.licenseService
          .getLicenses(deviceUuid, this.authService.getToken())
          .subscribe((r: LicenseDto[]) => {
            const licenses = {
              master: 0,
              mtime: 0,
              phone: 0,
              slave: 0
            };
            r.forEach((el) => {
              if (el.accepted) {
                licenses.master += el.master!;
                licenses.mtime += el.mtime!;
                licenses.phone += el.phone!;
                licenses.slave += el.slave!;
              }
            });

            const canAddLicences: any = {
              MASTER: licenses.master - licensesUsed.master > 0,
              MASTERTIME: licenses.mtime - licensesUsed.mtime > 0,
              PHONE: licenses.phone - licensesUsed.phone > 0,
              SLAVE: licenses.slave - licensesUsed.slave > 0,
              NONE: true
            };

            let licenseListToUse: any[] = [];
            const licenseList = Object.keys(ApartmentDto.LictypeEnum);
            licenseList.forEach((element, index) => {
              if (canAddLicences[element]) {
                licenseListToUse.push({
                  index,
                  element,
                  label: this.ts.instant(licensesLabels[element])
                });
              }
            });
            this.licenseListToUse.next(licenseListToUse);
          });
      });
  }
}
