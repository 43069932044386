<ngx-datatable
  class="material"
  [rowHeight]="undefined"
  [columns]="_columns"
  [rows]="dataSource.currentItems"
  [limit]="dataSource.limit"
  [offset]="dataSource.page"
  [count]="dataSource.totalItems"
  [externalPaging]="true"
  [externalSorting]="true"
  [scrollbarH]="true"
  columnMode="force"
  (page)="dataSource.onTablePage($event)"
  (sort)="dataSource.onTableSort($event)"
  [selectAllRowsOnPage]="false"
  (select)="elSelected.emit($event)"
  [messages]="messages"
  [rowClass]="getRowClass"
  [selected]="selected"
  [groupRowsBy]="groupRowsBy"
  [selectionType]="selectionType"
>
  <ng-content></ng-content>
</ngx-datatable>
<pagination
  *ngIf="!hidePagination"
  [totalItems]="dataSource.totalItems"
  [directionLinks]="true"
  [boundaryLinks]="true"
  [itemsPerPage]="dataSource.limit"
  [ngModel]="dataSource.page + 1"
  [maxSize]="5"
  (pageChanged)="dataSource.setPage($event.page - 1)"
></pagination>

<ng-template #checkboxTpl let-rowIndex="rowIndex" let-row="row">
  <div class="custom-control custom-checkbox">
    <input
      id="select-row-{{ rowIndex }}"
      type="checkbox"
      class="custom-control-input"
      [checked]="_selectionHandler!.isSelected(row)"
      (change)="
        _selectionHandler!.toggleItemSelected(row, $event.target.checked)
      "
    />
    <label
      class="custom-control-label text-hide"
      for="select-row-{{ rowIndex }}"
    ></label>
  </div>
</ng-template>
<ng-template #checkHeadTpl>
  <div id="all" class="custom-control custom-checkbox mb-1">
    <input
      id="select-all"
      type="checkbox"
      class="custom-control-input"
      (change)="selectAll()"
    />
    <label class="custom-control-label text-hide" for="select-all"></label>
  </div>
</ng-template>
