import { Component, Input, OnInit } from '@angular/core';
import { SiteDto, UsermetadataService } from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { SitesListService } from '../sites-list.service';

@Component({
  selector: 'app-favourite-button',
  templateUrl: './favourite-button.component.html',
  styleUrls: ['./favourite-button.component.scss']
})
export class FavouriteButtonComponent implements OnInit {
  @Input() site: SiteDto;
  isLoading = false;
  constructor(
    private authService: AuthService,
    private sitesListService: SitesListService,
    private usermetadataService: UsermetadataService
  ) {}

  ngOnInit(): void {}

  changeFavouriteStatus() {
    this.isLoading = true;
    const metadataExists = { ...this.site.userMetadata };
    if (!this.site.userMetadata) {
      this.site.userMetadata = {};
    }
    this.site.userMetadata.preferred = this.site.userMetadata.preferred
      ? !this.site.userMetadata.preferred
      : true;

    if (!metadataExists) {
      this.usermetadataService
        .addSiteUserMetadata(
          this.site.authenticationId!,
          this.authService.getToken(),
          this.site.userMetadata
        )
        .subscribe(() => {
          this.sitesListService.loadSites().subscribe(() => {
            this.isLoading = false;
          });
        });
    } else {
      this.usermetadataService
        .updateSiteUserMetadata(
          this.authService.getToken(),
          this.site.authenticationId!,
          this.site.userMetadata
        )
        .subscribe(() => {
          this.sitesListService.loadSites().subscribe(() => {
            this.isLoading = false;
          });
        });
    }
  }
}
