/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationDataDto } from './notificationDataDto';
import { TimeLicenseDataDto } from './timeLicenseDataDto';

/**
 * License Instance DTO
 */
export interface LicenseInstanceDto {
  /**
   * License instance UUID
   */
  readonly uuid?: string;
  /**
   * Referenced license type
   */
  referencedLicenseUuid: string;
  /**
   * License instance owner type
   */
  licenseOwnerType: LicenseInstanceDto.LicenseOwnerTypeEnum;
  /**
   * Licensed instance owner authentication ID
   */
  licenseOwnerAuthenticationId: string;
  /**
   * Licensed instance buyer authentication ID
   */
  licenseBuyerAuthenticationId: string;
  timeLicenseData: TimeLicenseDataDto;
  /**
   * Licensed instance status
   */
  status?: LicenseInstanceDto.StatusEnum;
  /**
   * Licensed instance optional custom data
   */
  optionalLicenseData?: { [key: string]: object };
  notificationData?: NotificationDataDto;
}
export namespace LicenseInstanceDto {
  export type LicenseOwnerTypeEnum = 'DEVICE, USER, APP, API';
  export const LicenseOwnerTypeEnum = {
    DEVICEUSERAPPAPI: 'DEVICE, USER, APP, API' as LicenseOwnerTypeEnum
  };
  export type StatusEnum =
    | 'CREATED'
    | 'ENABLED'
    | 'EXPIRED'
    | 'EXPIRING'
    | 'DISABLED'
    | 'DELETED'
    | 'RENEWED'
    | 'TRANSFERRED';
  export const StatusEnum = {
    CREATED: 'CREATED' as StatusEnum,
    ENABLED: 'ENABLED' as StatusEnum,
    EXPIRED: 'EXPIRED' as StatusEnum,
    EXPIRING: 'EXPIRING' as StatusEnum,
    DISABLED: 'DISABLED' as StatusEnum,
    DELETED: 'DELETED' as StatusEnum,
    RENEWED: 'RENEWED' as StatusEnum,
    TRANSFERRED: 'TRANSFERRED' as StatusEnum
  };
}
