<div class="modal-header">
  <h2>{{ 'DEVICE.SAFE.ADD_PIN' | translate }}</h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" class="modal-body" [formGroup]="pinGroup">
  <p>{{ 'DEVICE.SAFE.ADD_PIN_DESC' | translate }}</p>
  <input
    #pinInput
    id="password"
    type="password"
    autocomplete="new-password"
    class="custom-input"
    [formControl]="pinControl"
    [placeholder]="'DEVICE.SAFE.INSERT_PIN' | translate"
    (keyup.enter)="!pinControl.value ? '' : insert()"
  />
  <app-control-errors
    id="site-type-validator"
    controlName="pin"
  ></app-control-errors>
</form>

<div class="modal-footer">
  <button id="cancel" class="button-secondary big" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    id="insert"
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="insert()"
    buttonType="button"
    [disabled]="!pinControl.value"
  >
    {{ 'DEVICE.SAFE.INSERT' | translate }}
  </app-loading-button>
</div>
