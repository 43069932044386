/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Remote configuration options
 */
export interface RemoteCfgDto {
  /**
   * Whether the Remote Configuration Proxy (rcmpd) must be enabled or not
   */
  proxyEnable?: boolean;
  /**
   * Password to authenticate to the Remote Configuration Proxy with a Plain Password Authentication
   */
  password?: string;
}
