/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SafeSabotageStateDto {
  /**
   * Wired tamper (vedo: Y, hub: N, eclipse: ?)
   */
  wiredTamper?: boolean;
  /**
   * Bell tamper (vedo: Y, hub: N, eclipse: ?)
   */
  bellTamper?: boolean;
  /**
   * Jamming - Jamming occurring on a radio receiver (vedo: Y, hub: Y, eclipse: ?)
   */
  jamming?: boolean;
  /**
   * App phone (user) tamper (vedo: Y, hub: N, eclipse: ?)
   */
  appPhoneuserTamper?: boolean;
  /**
   * Zone tamper(wired + RF) (vedo: ?, hub: Y, eclipse: ?)
   */
  zoneTamper?: boolean;
  /**
   * Output tamper (wired + RF) (vedo: ?, hub: Y, eclipse: ?)
   */
  outputTamper?: boolean;
  /**
   * IO expansion tamper (vedo: Y, hub: N, eclipse: ?)
   */
  ioExpansionTamper?: boolean;
  /**
   * Keyboard tamper (vedo: Y, hub: N, eclipse: ?)
   */
  keyboardTamper?: boolean;
  /**
   * RFID reader tamper (vedo: Y, hub: N, eclipse: ?)
   */
  rfidReaderTamper?: boolean;
  /**
   * Safetouch tamper (vedo: Y, hub: N, eclipse: ?)
   */
  safetouchTamper?: boolean;
  /**
   * Generic touch tamper - Tamper of a generic touch device (Planux, Minitouch, Icona or Maxi) (vedo: Y, hub: N, eclipse: ?)
   */
  genericTouchTamper?: boolean;
  /**
   * Bus power supply tamper (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyTamper?: boolean;
  /**
   * Bus radio expansion tamper (vedo: Y, hub: N, eclipse: ?)
   */
  busRadioExpansionTamper?: boolean;
  /**
   * Bus bell tamper (vedo: Y, hub: N, eclipse: ?)
   */
  busBellTamper?: boolean;
  /**
   * Bus alarms notification device tamper (vedo: Y, hub: N, eclipse: ?)
   */
  busAlarmsNotificationDeviceTamper?: boolean;
  /**
   * Ip expansion tamper (vedo: Y, hub: N, eclipse: ?)
   */
  ipExpansionTamper?: boolean;
  /**
   * Video IP expansion tamper (vedo: Y, hub: N, eclipse: ?)
   */
  videoIpExpansionTamper?: boolean;
  /**
   * Panel tamper (vedo: ?, hub: Y, eclipse: ?)
   */
  panelTamper?: boolean;
  /**
   * Repeater tamper (vedo: ?, hub: Y, eclipse: ?)
   */
  repeaterTamper?: boolean;
  /**
   * Remote communications tamper (vedo: Y, hub: N, eclipse: ?)
   */
  remoteCommunicationsTamper?: boolean;
  /**
   * GSM jamming (vedo: Y, hub: N, eclipse: ?)
   */
  gsmJamming?: boolean;
  /**
   * Wrong code sabotage (vedo: Y, hub: Y, eclipse: ?)
   */
  wrongCodeSabotage?: boolean;
}
