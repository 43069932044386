<button
  class="{{ buttonClass }}"
  type="{{ buttonType }}"
  [disabled]="disabled || isLoading"
>
  <span
    *ngIf="isLoading"
    class="spinner-border spinner-border-sm mr-2"
    role="status"
    aria-hidden="true"
  ></span>

  <ng-content></ng-content>
</button>
