<div class="modal-header">
  <h2>
    {{ 'DEVICE.MUGSBC.RENEW' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="renewForm">
  <p class="my-3 text-center">
    <b>{{ 'DEVICE.MUGSBC.RENEW_DESC' | translate }}</b>
  </p>
  <label class="custom-label" for="device-description">{{
    'DEVICE.MUGSBC.CODICE_ICCID_SIM' | translate
  }}</label>
  <div class="row mb-4">
    <div class="col-10">
      <input
        id="device-iccid"
        type="text"
        class="custom-input"
        [placeholder]="'DEVICE.MUGSBC.CODICE_ICCID_SIM' | translate"
        [formControl]="deviceCodeControl"
      />
      <!-- <app-control-errors
        id="deviceCode"
        controlName="deviceCodeControl"
      ></app-control-errors> -->
    </div>
    <div class="col-2">
      <app-scanner-button
        (codeRetrieved)="codeRetrieved($event)"
      ></app-scanner-button>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button
    id="close"
    class="button-secondary big mr-3"
    (click)="close()"
    type="button"
  >
    {{ 'GLOBAL.CLOSE' | translate }}
  </button>
  <app-loading-button
    id="activate"
    buttonClass="button-primary big"
    [isLoading]="false"
    (click)="renew()"
    buttonType="button"
    [disabled]="!renewForm.valid"
    >{{ 'GLOBAL.RENEW' | translate }}</app-loading-button
  >
</div>
