import { Component, Input, OnInit } from '@angular/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';

@Component({
  selector: 'app-mobile-table',
  templateUrl: './mobile-table.component.html',
  styleUrls: ['./mobile-table.component.scss']
})
export class MobileTableComponent implements OnInit {
  @Input() hidePagination = false;
  @Input() dataSource: ClientFiltrableDataSource<any>;
  constructor() {}

  ngOnInit(): void {}
}
