/**
 * CCAPI HTTP /servicerest/log
 * CCAPI HTTP /servicerest/log
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ErrorDto } from '../model/models';
import { GetTracesResponseBodyDto } from '../model/models';

import { LOGS_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class CCAPIHTTPServicerestGetTracesService {
  protected basePath = 'https://usvc-preprod.cloud.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(LOGS_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * log/getTraces
   * Retrieve traces from db
   * @param type Target type
   * @param id Target
   * @param authorization Authorization with ccstoken
   * @param apikey Authorization with apikey
   * @param offset Offset
   * @param limit Limit
   * @param eventCode Unique codes of events
   * @param startDate Format examples: yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ or yyyy-MM-dd\&#39;T\&#39;HH:mm:ssZ
   * @param endDate Format examples: yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ or yyyy-MM-dd\&#39;T\&#39;HH:mm:ssZ
   * @param severity Severity parameters
   * @param status Trace status
   * @param orderBy Sort parameters
   * @param language Language. Available values: FR, EN, IT. Default value: FR
   * @param deviceName Device name
   * @param tag Tag or type. Available values (now): ACCESS, OPERATIONAL
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public logGetTraces(
    type: string,
    id: string,
    authorization?: string,
    apikey?: string,
    offset?: number,
    limit?: number,
    eventCode?: Array<string>,
    startDate?: string,
    endDate?: string,
    severity?: Array<
      'INFO' | 'WARNING' | 'CRITICAL' | 'DEBUG' | 'ALERT' | 'ERROR'
    >,
    status?: 'SUCCESS' | 'FAIL',
    orderBy?: Array<
      | 'EVENTDATE_ASC'
      | 'EVENTDATE_DESC'
      | 'EVENTCODE_ASC'
      | 'EVENTCODE_DESC'
      | 'TRACEID_ASC'
      | 'TRACEID_DESC'
      | 'SEVERITY_ASC'
      | 'SEVERITY_DESC'
      | 'TAG_ASC'
      | 'TAG_DESC'
    >,
    language?: string,
    deviceName?: string,
    tag?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<GetTracesResponseBodyDto>;
  public logGetTraces(
    type: string,
    id: string,
    authorization?: string,
    apikey?: string,
    offset?: number,
    limit?: number,
    eventCode?: Array<string>,
    startDate?: string,
    endDate?: string,
    severity?: Array<
      'INFO' | 'WARNING' | 'CRITICAL' | 'DEBUG' | 'ALERT' | 'ERROR'
    >,
    status?: 'SUCCESS' | 'FAIL',
    orderBy?: Array<
      | 'EVENTDATE_ASC'
      | 'EVENTDATE_DESC'
      | 'EVENTCODE_ASC'
      | 'EVENTCODE_DESC'
      | 'TRACEID_ASC'
      | 'TRACEID_DESC'
      | 'SEVERITY_ASC'
      | 'SEVERITY_DESC'
      | 'TAG_ASC'
      | 'TAG_DESC'
    >,
    language?: string,
    deviceName?: string,
    tag?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<GetTracesResponseBodyDto>>;
  public logGetTraces(
    type: string,
    id: string,
    authorization?: string,
    apikey?: string,
    offset?: number,
    limit?: number,
    eventCode?: Array<string>,
    startDate?: string,
    endDate?: string,
    severity?: Array<
      'INFO' | 'WARNING' | 'CRITICAL' | 'DEBUG' | 'ALERT' | 'ERROR'
    >,
    status?: 'SUCCESS' | 'FAIL',
    orderBy?: Array<
      | 'EVENTDATE_ASC'
      | 'EVENTDATE_DESC'
      | 'EVENTCODE_ASC'
      | 'EVENTCODE_DESC'
      | 'TRACEID_ASC'
      | 'TRACEID_DESC'
      | 'SEVERITY_ASC'
      | 'SEVERITY_DESC'
      | 'TAG_ASC'
      | 'TAG_DESC'
    >,
    language?: string,
    deviceName?: string,
    tag?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<GetTracesResponseBodyDto>>;
  public logGetTraces(
    type: string,
    id: string,
    authorization?: string,
    apikey?: string,
    offset?: number,
    limit?: number,
    eventCode?: Array<string>,
    startDate?: string,
    endDate?: string,
    severity?: Array<
      'INFO' | 'WARNING' | 'CRITICAL' | 'DEBUG' | 'ALERT' | 'ERROR'
    >,
    status?: 'SUCCESS' | 'FAIL',
    orderBy?: Array<
      | 'EVENTDATE_ASC'
      | 'EVENTDATE_DESC'
      | 'EVENTCODE_ASC'
      | 'EVENTCODE_DESC'
      | 'TRACEID_ASC'
      | 'TRACEID_DESC'
      | 'SEVERITY_ASC'
      | 'SEVERITY_DESC'
      | 'TAG_ASC'
      | 'TAG_DESC'
    >,
    language?: string,
    deviceName?: string,
    tag?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling logGetTraces.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling logGetTraces.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (eventCode) {
      eventCode.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'eventCode'
        );
      });
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>startDate,
        'startDate'
      );
    }
    if (endDate !== undefined && endDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>endDate,
        'endDate'
      );
    }
    if (severity) {
      severity.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'severity'
        );
      });
    }
    if (status !== undefined && status !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>status,
        'status'
      );
    }
    if (orderBy) {
      orderBy.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'orderBy'
        );
      });
    }
    if (language !== undefined && language !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>language,
        'language'
      );
    }
    if (deviceName !== undefined && deviceName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>deviceName,
        'deviceName'
      );
    }
    if (tag !== undefined && tag !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>tag, 'tag');
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<GetTracesResponseBodyDto>(
      `${
        this.configuration.basePath
      }/servicerest/log/getTraces/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
