/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of output.
 */
export interface OutputStatusBitMaskDto {
  moduleFault?: boolean;
  outputSounderOn?: boolean;
  outputSounderFault?: boolean;
  outputSounderDelayInProgress?: boolean;
  outputFireOn?: boolean;
  outputFireFault?: boolean;
  outputFireDelayInProgress?: boolean;
  outputExtinguishOn?: boolean;
  outputExtinguishFault?: boolean;
  outputExtinguishDelayInProgress?: boolean;
  outputFaultOn?: boolean;
  outputFaultFault?: boolean;
  relay1On?: boolean;
  relay2On?: boolean;
  relay3On?: boolean;
  relay4On?: boolean;
}
