<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <i
    id="actuators-edit-{{ rowIndex }}"
    class="material-icons notranslate single-menu-icon"
    (click)="edit(row)"
    >edit</i
  >
  <i
    id="actuators-delete-{{ rowIndex }}"
    class="material-icons notranslate single-menu-icon ml-2"
    (click)="delete(row)"
    >delete</i
  >
</ng-template>

<div class="card-table mt-3">
  <div class="card-header">
    <h2>{{ 'ADDRESSBOOK.ACTUATOR_MODULES' | translate }}</h2>
    <button id="add-actuator" class="button-secondary" (click)="addActuator()">
      <i class="material-icons notranslate">add</i>
      {{ 'GLOBAL.ADD' | translate }}
    </button>
  </div>
  <div class="card-body">
    <app-datatable
      *ngIf="!isMobile"
      [columns]="columns"
      [dataSource]="dataSource"
      [hidePagination]="true"
    ></app-datatable>
    <app-mobile-table *ngIf="isMobile" [dataSource]="dataSource">
      <div id="mobile-actuators" class="mobile-table">
        <div
          *ngFor="let item of dataSource.items; let i = index"
          class="mobile-card actuator"
        >
          <span class="mobile-card-title" id="actuator-name-{{ i }}">{{
            item.name
          }}</span>
          <span class="mobile-card-subtitle">
            <ng-container
              *ngTemplateOutlet="
                addrTpl;
                context: { value: item.addr, rowIndex: i }
              "
            ></ng-container>
          </span>
          <span class="mobile-card-actions">
            <ng-container
              *ngTemplateOutlet="
                actionsTpl;
                context: { row: item, rowIndex: i }
              "
            ></ng-container>
          </span>
        </div>
      </div>
    </app-mobile-table>
  </div>
</div>
<ng-template #addrTpl let-value="value" let-rowIndex="rowIndex">
  <span id="actuators-address-{{ rowIndex }}">{{
    value.split('SBACT')[1]
  }}</span>
</ng-template>
