import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration, ErrorDto } from 'src/app/api/dpcp';
import { CustomHttpParameterCodec } from 'src/app/api/dpcp/encoder';
import { StatusAndConfigurationService } from 'src/app/api/mugconf';
import { CreateMobexSIPLicense, SIP_BASE_PATH } from 'src/app/api/sip';
import { AuthService } from 'src/app/core/auth.service';
import { SipData } from 'src/app/core/models';

@Injectable({
  providedIn: 'root'
})
export class SipService {
  sipData$ = new BehaviorSubject<SipData | undefined>(undefined);
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  protected basePath = 'https://usvc-preprod.cloud.comelitgroup.com';
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(SIP_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private statusAndConfService: StatusAndConfigurationService,
    private authService: AuthService
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  createLicense(
    tuid: string,
    body: CreateMobexSIPLicense,
    options?: { httpHeaderAccept?: 'application/json'; token: string }
  ): Observable<ErrorDto> {
    if (tuid === null || tuid === undefined) {
      throw new Error(
        'Required parameter tuid was null or undefined when calling createLicense.'
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', `${options?.token}`);

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<ErrorDto>(
      `${
        this.configuration.basePath
      }/servicerest/licensemanager/mobex/${encodeURIComponent(
        String(tuid)
      )}/licenses/create`,
      body,
      {
        responseType: responseType as any,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe: 'body',
        reportProgress: false
      }
    );
  }

  getSipData(deviceId: string) {
    forkJoin([
      this.statusAndConfService.getSipAdditionalCfg(
        deviceId,
        this.authService.getToken()
      ),
      this.statusAndConfService.getSipCfg(
        deviceId,
        this.authService.getToken()
      ),
      this.statusAndConfService.getSipLinesCfg(
        deviceId,
        this.authService.getToken()
      )
    ])
      .pipe(
        map(
          ([addConf, conf, lines]): SipData => {
            return { addConf: addConf[0], conf: conf[0], lines };
          }
        )
      )
      .subscribe((r) => {
        this.sipData$.next(r);
      });
  }
}
