<div class="modal-header">
  <h2>{{ title }}</h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="noEmit ? null : modalRef.hide(); cancelAction.next()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ description }}
</div>

<div class="modal-footer">
  <button
    id="cancel"
    class="button-secondary big"
    (click)="noEmit ? null : modalRef.hide(); cancelAction.next()"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    id="confirm"
    class="button-primary big"
    (click)="confirmAction.next()"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</div>
