/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetPropertyDtoIsoLanguage6391 {
  value?: SetPropertyDtoIsoLanguage6391.ValueEnum;
  unset?: boolean;
}
export namespace SetPropertyDtoIsoLanguage6391 {
  export type ValueEnum =
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  export const ValueEnum = {
    Aa: 'aa' as ValueEnum,
    Ab: 'ab' as ValueEnum,
    Ae: 'ae' as ValueEnum,
    Af: 'af' as ValueEnum,
    Ak: 'ak' as ValueEnum,
    Am: 'am' as ValueEnum,
    An: 'an' as ValueEnum,
    Ar: 'ar' as ValueEnum,
    As: 'as' as ValueEnum,
    Av: 'av' as ValueEnum,
    Ay: 'ay' as ValueEnum,
    Az: 'az' as ValueEnum,
    Ba: 'ba' as ValueEnum,
    Be: 'be' as ValueEnum,
    Bg: 'bg' as ValueEnum,
    Bh: 'bh' as ValueEnum,
    Bi: 'bi' as ValueEnum,
    Bm: 'bm' as ValueEnum,
    Bn: 'bn' as ValueEnum,
    Bo: 'bo' as ValueEnum,
    Br: 'br' as ValueEnum,
    Bs: 'bs' as ValueEnum,
    Ca: 'ca' as ValueEnum,
    Ce: 'ce' as ValueEnum,
    Ch: 'ch' as ValueEnum,
    Co: 'co' as ValueEnum,
    Cr: 'cr' as ValueEnum,
    Cs: 'cs' as ValueEnum,
    Cu: 'cu' as ValueEnum,
    Cv: 'cv' as ValueEnum,
    Cy: 'cy' as ValueEnum,
    Da: 'da' as ValueEnum,
    De: 'de' as ValueEnum,
    Dv: 'dv' as ValueEnum,
    Dz: 'dz' as ValueEnum,
    Ee: 'ee' as ValueEnum,
    El: 'el' as ValueEnum,
    En: 'en' as ValueEnum,
    Eo: 'eo' as ValueEnum,
    Es: 'es' as ValueEnum,
    Et: 'et' as ValueEnum,
    Eu: 'eu' as ValueEnum,
    Fa: 'fa' as ValueEnum,
    Ff: 'ff' as ValueEnum,
    Fi: 'fi' as ValueEnum,
    Fj: 'fj' as ValueEnum,
    Fo: 'fo' as ValueEnum,
    Fr: 'fr' as ValueEnum,
    Fy: 'fy' as ValueEnum,
    Ga: 'ga' as ValueEnum,
    Gd: 'gd' as ValueEnum,
    Gl: 'gl' as ValueEnum,
    Gn: 'gn' as ValueEnum,
    Gu: 'gu' as ValueEnum,
    Gv: 'gv' as ValueEnum,
    Ha: 'ha' as ValueEnum,
    He: 'he' as ValueEnum,
    Hi: 'hi' as ValueEnum,
    Ho: 'ho' as ValueEnum,
    Hr: 'hr' as ValueEnum,
    Ht: 'ht' as ValueEnum,
    Hu: 'hu' as ValueEnum,
    Hy: 'hy' as ValueEnum,
    Hz: 'hz' as ValueEnum,
    Ia: 'ia' as ValueEnum,
    Id: 'id' as ValueEnum,
    Ie: 'ie' as ValueEnum,
    Ig: 'ig' as ValueEnum,
    Ii: 'ii' as ValueEnum,
    Ik: 'ik' as ValueEnum,
    Io: 'io' as ValueEnum,
    Is: 'is' as ValueEnum,
    It: 'it' as ValueEnum,
    Iu: 'iu' as ValueEnum,
    Ja: 'ja' as ValueEnum,
    Jv: 'jv' as ValueEnum,
    Ka: 'ka' as ValueEnum,
    Kg: 'kg' as ValueEnum,
    Ki: 'ki' as ValueEnum,
    Kj: 'kj' as ValueEnum,
    Kk: 'kk' as ValueEnum,
    Kl: 'kl' as ValueEnum,
    Km: 'km' as ValueEnum,
    Kn: 'kn' as ValueEnum,
    Ko: 'ko' as ValueEnum,
    Kr: 'kr' as ValueEnum,
    Ks: 'ks' as ValueEnum,
    Ku: 'ku' as ValueEnum,
    Kv: 'kv' as ValueEnum,
    Kw: 'kw' as ValueEnum,
    Ky: 'ky' as ValueEnum,
    La: 'la' as ValueEnum,
    Lb: 'lb' as ValueEnum,
    Lg: 'lg' as ValueEnum,
    Li: 'li' as ValueEnum,
    Ln: 'ln' as ValueEnum,
    Lo: 'lo' as ValueEnum,
    Lt: 'lt' as ValueEnum,
    Lu: 'lu' as ValueEnum,
    Lv: 'lv' as ValueEnum,
    Mg: 'mg' as ValueEnum,
    Mh: 'mh' as ValueEnum,
    Mi: 'mi' as ValueEnum,
    Mk: 'mk' as ValueEnum,
    Ml: 'ml' as ValueEnum,
    Mn: 'mn' as ValueEnum,
    Mr: 'mr' as ValueEnum,
    Ms: 'ms' as ValueEnum,
    Mt: 'mt' as ValueEnum,
    My: 'my' as ValueEnum,
    Na: 'na' as ValueEnum,
    Nb: 'nb' as ValueEnum,
    Nd: 'nd' as ValueEnum,
    Ne: 'ne' as ValueEnum,
    Ng: 'ng' as ValueEnum,
    Nl: 'nl' as ValueEnum,
    Nn: 'nn' as ValueEnum,
    No: 'no' as ValueEnum,
    Nr: 'nr' as ValueEnum,
    Nv: 'nv' as ValueEnum,
    Ny: 'ny' as ValueEnum,
    Oc: 'oc' as ValueEnum,
    Oj: 'oj' as ValueEnum,
    Om: 'om' as ValueEnum,
    Or: 'or' as ValueEnum,
    Os: 'os' as ValueEnum,
    Pa: 'pa' as ValueEnum,
    Pi: 'pi' as ValueEnum,
    Pl: 'pl' as ValueEnum,
    Ps: 'ps' as ValueEnum,
    Pt: 'pt' as ValueEnum,
    Qu: 'qu' as ValueEnum,
    Rm: 'rm' as ValueEnum,
    Rn: 'rn' as ValueEnum,
    Ro: 'ro' as ValueEnum,
    Ru: 'ru' as ValueEnum,
    Rw: 'rw' as ValueEnum,
    Sa: 'sa' as ValueEnum,
    Sc: 'sc' as ValueEnum,
    Sd: 'sd' as ValueEnum,
    Se: 'se' as ValueEnum,
    Sg: 'sg' as ValueEnum,
    Si: 'si' as ValueEnum,
    Sk: 'sk' as ValueEnum,
    Sl: 'sl' as ValueEnum,
    Sm: 'sm' as ValueEnum,
    Sn: 'sn' as ValueEnum,
    So: 'so' as ValueEnum,
    Sq: 'sq' as ValueEnum,
    Sr: 'sr' as ValueEnum,
    Ss: 'ss' as ValueEnum,
    St: 'st' as ValueEnum,
    Su: 'su' as ValueEnum,
    Sv: 'sv' as ValueEnum,
    Sw: 'sw' as ValueEnum,
    Ta: 'ta' as ValueEnum,
    Te: 'te' as ValueEnum,
    Tg: 'tg' as ValueEnum,
    Th: 'th' as ValueEnum,
    Ti: 'ti' as ValueEnum,
    Tk: 'tk' as ValueEnum,
    Tl: 'tl' as ValueEnum,
    Tn: 'tn' as ValueEnum,
    To: 'to' as ValueEnum,
    Tr: 'tr' as ValueEnum,
    Ts: 'ts' as ValueEnum,
    Tt: 'tt' as ValueEnum,
    Tw: 'tw' as ValueEnum,
    Ty: 'ty' as ValueEnum,
    Ug: 'ug' as ValueEnum,
    Uk: 'uk' as ValueEnum,
    Ur: 'ur' as ValueEnum,
    Uz: 'uz' as ValueEnum,
    Ve: 've' as ValueEnum,
    Vi: 'vi' as ValueEnum,
    Vo: 'vo' as ValueEnum,
    Wa: 'wa' as ValueEnum,
    Wo: 'wo' as ValueEnum,
    Xh: 'xh' as ValueEnum,
    Yi: 'yi' as ValueEnum,
    Yo: 'yo' as ValueEnum,
    Za: 'za' as ValueEnum,
    Zh: 'zh' as ValueEnum,
    Zu: 'zu' as ValueEnum
  };
}
