/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Area state details
 */
export interface SafeAreaStateDto {
  /**
   * Disarmed
   */
  readonly disarmed?: boolean;
  /**
   * Totally armed
   */
  readonly armedT?: boolean;
  /**
   * P1 armed
   */
  readonly armedP1?: boolean;
  /**
   * P2 armed
   */
  readonly armedP2?: boolean;
  /**
   * Not ready for arming
   */
  readonly notReadyForArming?: boolean;
  /**
   * In alarm (1)
   */
  readonly inAlarm1?: boolean;
  /**
   * In sabotage
   */
  readonly inSabotage?: boolean;
  /**
   * Anomaly
   */
  readonly anomaly?: boolean;
  /**
   * Alarm memory
   */
  readonly alarmMem?: boolean;
  /**
   * Not ready for total arming
   */
  readonly notReadyForTotalArming?: boolean;
  /**
   * Not ready for P1 arming
   */
  readonly notReadyForP1Arming?: boolean;
  /**
   * Not ready for P2 arming
   */
  readonly notReadyForP2Arming?: boolean;
  /**
   * In alarm (2)
   */
  readonly inAlarm2?: boolean;
  /**
   * Exclusion on area
   */
  readonly exclusionOnArea?: boolean;
  /**
   * Input delay time active
   */
  readonly inputDelayTimeActive?: boolean;
  /**
   * Output delay time active
   */
  readonly outputDelayTimeActive?: boolean;
  /**
   * Arming failed
   */
  readonly armingFailed?: boolean;
  /**
   * Arming completed
   */
  readonly armingCompleted?: boolean;
  /**
   * Disarming completed
   */
  readonly disarmingCompleted?: boolean;
  /**
   * No burglar zones with P1
   */
  readonly noBurglarZonesWithP1?: boolean;
  /**
   * No burglar zones with P2
   */
  readonly noBurglarZonesWithP2?: boolean;
  /**
   * No burglar zones on area
   */
  readonly noBurglarZonesOnArea?: boolean;
  /**
   * Open Zone
   */
  readonly openZone?: boolean;
  /**
   * Output on
   */
  readonly outputOn?: boolean;
  /**
   * Alarm P1
   */
  readonly alarmP1?: boolean;
  /**
   * Alarm P2
   */
  readonly alarmP2?: boolean;
  /**
   * Memory P1
   */
  readonly memoryP1?: boolean;
  /**
   * Memory P2
   */
  readonly memoryP2?: boolean;
}
