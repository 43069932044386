/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EventsCountersDto {
  /**
   * Number of alarms
   */
  alarms?: number;
  /**
   * Number of faults
   */
  faults?: number;
  /**
   * Number of disablements
   */
  disablements?: number;
  /**
   * Number of warnings
   */
  warnings?: number;
  /**
   * Number of tests
   */
  tests?: number;
  /**
   * Number of maintenances
   */
  maintenances?: number;
}
