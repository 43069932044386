import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SiteDto } from '../api/myportal';
import { SitesListService } from './sites-list.service';

@Injectable({
  providedIn: 'root'
})
export class SitesListResolverService implements Resolve<SiteDto[]> {
  constructor(private sitesListService: SitesListService) {}

  resolve(): Observable<SiteDto[]> | Observable<never> {
    return this.sitesListService.loadSites().pipe(
      take(1),
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
