/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * IO Modules channel properties.
 */
export interface IOModulesChannelBitMaskDto {
  isChannelPresent?: boolean;
  isChannelOutput?: boolean;
  isOutputLatched?: boolean;
  isOutputInverted?: boolean;
  outputType?: IOModulesChannelBitMaskDto.OutputTypeEnum;
}
export namespace IOModulesChannelBitMaskDto {
  export type OutputTypeEnum =
    | 'OUTPUT_IS_CONTINUOUS'
    | 'OUTPUT_IS_ONE_PULSE'
    | 'OUTPUT_IS_PULSED';
  export const OutputTypeEnum = {
    CONTINUOUS: 'OUTPUT_IS_CONTINUOUS' as OutputTypeEnum,
    ONEPULSE: 'OUTPUT_IS_ONE_PULSE' as OutputTypeEnum,
    PULSED: 'OUTPUT_IS_PULSED' as OutputTypeEnum
  };
}
