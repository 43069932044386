/**
 * License Manager API
 * API to manage licenses
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Violated constraints DTO
 */
export interface ViolatedConstraintsDto {
  /**
   * Violated constraints field name
   */
  fieldName?: string;
  /**
   * Violated constraints message
   */
  message?: string;
}
