<div class="modal-header">
  <h2>
    {{ 'SIP.CREATE_PHONE_LINE' | translate }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="lineFormGroup" (keyup.enter)="save()">
    <label class="custom-label" for="username">{{
      'SIP.LINE.USERNAME' | translate
    }}</label>
    <input
      id="username"
      [formControl]="usernameControl"
      type="text"
      [placeholder]="'SIP.LINE.USERNAME' | translate"
      class="custom-input"
    />
    <app-control-errors
      id="username-validator"
      controlName="sipUsername"
    ></app-control-errors>

    <label class="custom-label" for="password">{{
      'SIP.LINE.PASSWORD' | translate
    }}</label>
    <input
      id="password"
      [formControl]="passwordControl"
      type="password"
      [placeholder]="'SIP.LINE.PASSWORD' | translate"
      class="custom-input"
    />
    <app-control-errors
      id="password-validator"
      controlName="sipPassword"
    ></app-control-errors>

    <label class="custom-label" for="user-id">{{
      'SIP.LINE.USER_ID' | translate
    }}</label>
    <input
      id="user-id"
      [formControl]="userIdControl"
      type="user-id"
      [placeholder]="'SIP.LINE.USER_ID' | translate"
      class="custom-input"
    />
    <app-control-errors
      id="user-id-validator"
      controlName="sipUserAuth"
    ></app-control-errors>
  </form>
</div>
<div class="modal-footer">
  <button id="cancel" class="button-secondary mr-3" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    id="save"
    buttonClass="button-primary"
    [isLoading]="isLoading"
    (click)="save()"
    [disabled]="lineFormGroup.invalid"
    buttonType="button"
    >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
  >
</div>
