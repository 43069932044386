/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceStatus2BitMaskDto } from './deviceStatus2BitMaskDto';
import { DeviceStatus1BitMaskDto } from './deviceStatus1BitMaskDto';

export interface DeviceDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Name is a string containing the name of device.
   */
  name?: string;
  /**
   * Loop is an integer value containing the loop index the device belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of device inside its loop.
   */
  address?: number;
  /**
   * Type of device.
   */
  type?: DeviceDto.TypeEnum;
  status1?: DeviceStatus1BitMaskDto;
  status2?: DeviceStatus2BitMaskDto;
  /**
   * Zone is an integer value containing the zone index the device belongs to.
   */
  zone?: number;
  /**
   * Zone name is a string containing the zone name to whom device belongs.
   */
  zoneName?: string;
  /**
   * Communication quality is an integer value representing the quality of communication between device and control panel.
   */
  communicationQuality?: number;
  /**
   * Serial is a string containing serial number of device.
   */
  serial?: string;
  /**
   * Zone/Zonal Address of the device.
   */
  zonalAddress?: number;
}
export namespace DeviceDto {
  export type TypeEnum =
    | 'CALL_POINT_MCP150'
    | 'ZONE_MONITOR'
    | 'ADDR_MODUL_MC_D'
    | 'WSOU'
    | 'WSOU_IS'
    | 'WSST'
    | 'WSST_IS'
    | 'BSOU'
    | 'BSOU_IS'
    | 'BSST'
    | 'BSST_IS'
    | 'MIO40'
    | 'MIO22'
    | 'MIO22M'
    | 'MIO04'
    | 'MOUT'
    | 'MOUT240'
    | 'MINP'
    | 'MIMIC'
    | 'MINI_OUT'
    | 'MI420'
    | 'CSOU'
    | 'CSOU_IS'
    | 'CSOU_VAD'
    | 'CSOU_IS_VAD'
    | 'CSST'
    | 'CSST_IS'
    | 'WE_A'
    | 'MCP'
    | 'WSS'
    | 'COM_41PAM000_41PAE020'
    | 'COM_41ISC000'
    | 'COM_41RBX020'
    | 'COM_41SAI000'
    | 'COM_41SAI000_INDOOR_SIREN'
    | 'COM_41SCI000'
    | 'COM_41SCI000_INDOOR_SIREN_STROBE'
    | 'COM_41SAB100'
    | 'COM_41SAB100_BASE_WITH_SIREN'
    | 'COM_41SCB100_BASE_SIREN_AND_STROBE'
    | 'COM_41SCB100_BASE_SIREN_AND_STROBE_IS'
    | 'COM_41IOM040'
    | 'COM_41IOM022'
    | 'COM_41IOM122'
    | 'COM_41IOM004'
    | 'COM_41IOM000'
    | 'COM_41IOM000_240'
    | 'COM_41IOM010'
    | 'COM_41LED032'
    | 'COM_41IOM001'
    | 'COM_MI420'
    | 'COM_41SAB101'
    | 'COM_K41VAD100L'
    | 'COM_41SCB101'
    | 'COM_45TRA100'
    | 'COM_45PAM100'
    | 'COM_45SCI100';
  export const TypeEnum = {
    CALLPOINTMCP150: 'CALL_POINT_MCP150' as TypeEnum,
    ZONEMONITOR: 'ZONE_MONITOR' as TypeEnum,
    ADDRMODULMCD: 'ADDR_MODUL_MC_D' as TypeEnum,
    WSOU: 'WSOU' as TypeEnum,
    WSOUIS: 'WSOU_IS' as TypeEnum,
    WSST: 'WSST' as TypeEnum,
    WSSTIS: 'WSST_IS' as TypeEnum,
    BSOU: 'BSOU' as TypeEnum,
    BSOUIS: 'BSOU_IS' as TypeEnum,
    BSST: 'BSST' as TypeEnum,
    BSSTIS: 'BSST_IS' as TypeEnum,
    MIO40: 'MIO40' as TypeEnum,
    MIO22: 'MIO22' as TypeEnum,
    MIO22M: 'MIO22M' as TypeEnum,
    MIO04: 'MIO04' as TypeEnum,
    MOUT: 'MOUT' as TypeEnum,
    MOUT240: 'MOUT240' as TypeEnum,
    MINP: 'MINP' as TypeEnum,
    MIMIC: 'MIMIC' as TypeEnum,
    MINIOUT: 'MINI_OUT' as TypeEnum,
    MI420: 'MI420' as TypeEnum,
    CSOU: 'CSOU' as TypeEnum,
    CSOUIS: 'CSOU_IS' as TypeEnum,
    CSOUVAD: 'CSOU_VAD' as TypeEnum,
    CSOUISVAD: 'CSOU_IS_VAD' as TypeEnum,
    CSST: 'CSST' as TypeEnum,
    CSSTIS: 'CSST_IS' as TypeEnum,
    WEA: 'WE_A' as TypeEnum,
    MCP: 'MCP' as TypeEnum,
    WSS: 'WSS' as TypeEnum,
    COM41PAM00041PAE020: 'COM_41PAM000_41PAE020' as TypeEnum,
    COM41ISC000: 'COM_41ISC000' as TypeEnum,
    COM41RBX020: 'COM_41RBX020' as TypeEnum,
    COM41SAI000: 'COM_41SAI000' as TypeEnum,
    COM41SAI000INDOORSIREN: 'COM_41SAI000_INDOOR_SIREN' as TypeEnum,
    COM41SCI000: 'COM_41SCI000' as TypeEnum,
    COM41SCI000INDOORSIRENSTROBE: 'COM_41SCI000_INDOOR_SIREN_STROBE' as TypeEnum,
    COM41SAB100: 'COM_41SAB100' as TypeEnum,
    COM41SAB100BASEWITHSIREN: 'COM_41SAB100_BASE_WITH_SIREN' as TypeEnum,
    COM41SCB100BASESIRENANDSTROBE: 'COM_41SCB100_BASE_SIREN_AND_STROBE' as TypeEnum,
    COM41SCB100BASESIRENANDSTROBEIS: 'COM_41SCB100_BASE_SIREN_AND_STROBE_IS' as TypeEnum,
    COM41IOM040: 'COM_41IOM040' as TypeEnum,
    COM41IOM022: 'COM_41IOM022' as TypeEnum,
    COM41IOM122: 'COM_41IOM122' as TypeEnum,
    COM41IOM004: 'COM_41IOM004' as TypeEnum,
    COM41IOM000: 'COM_41IOM000' as TypeEnum,
    COM41IOM000240: 'COM_41IOM000_240' as TypeEnum,
    COM41IOM010: 'COM_41IOM010' as TypeEnum,
    COM41LED032: 'COM_41LED032' as TypeEnum,
    COM41IOM001: 'COM_41IOM001' as TypeEnum,
    COMMI420: 'COM_MI420' as TypeEnum,
    COM41SAB101: 'COM_41SAB101' as TypeEnum,
    COMK41VAD100L: 'COM_K41VAD100L' as TypeEnum,
    COM41SCB101: 'COM_41SCB101' as TypeEnum,
    COM45TRA100: 'COM_45TRA100' as TypeEnum,
    COM45PAM100: 'COM_45PAM100' as TypeEnum,
    COM45SCI100: 'COM_45SCI100' as TypeEnum
  };
}
