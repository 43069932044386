/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PowerSuppliersBitMaskDto } from './powerSuppliersBitMaskDto';

export interface PowerSuppliersDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Name is a string that contains name visualized for the peripheral.
   */
  name?: string;
  /**
   * Peripheral address is an integer value containing the address of the peripheral.
   */
  peripheralAddress?: number;
  /**
   * Peripheral address is an integer value containing the address of the peripheral.
   */
  deviceType?: PowerSuppliersDto.DeviceTypeEnum;
  status?: PowerSuppliersBitMaskDto;
  /**
   * Battery voltage is an 8 bits unsigned integer - Battery voltage in units of 0.1V read from battery by PSU
   */
  batteryVoltage?: number;
  /**
   * Charge voltage is an 8 bits unsigned integer - Charger voltage in units of 0.1V.
   */
  chargeVoltage?: number;
  /**
   * Charge current is an 8 bits unsigned integer - Charger current in units of 0.1A.
   */
  chargeCurrent?: number;
  /**
   * Software revision is a string that represents software version programmed on peripheral.
   */
  swVersion?: string;
}
export namespace PowerSuppliersDto {
  export type DeviceTypeEnum =
    | 'PERI_ID_MAIN_BOARD'
    | 'PERI_ID_POWER_SUPPLY'
    | 'PERI_ID_OUTPUTS1'
    | 'PERI_ID_LOOP_1'
    | 'PERI_ID_LOOP_2'
    | 'PERI_ID_LOOP_3'
    | 'PERI_ID_LOOP_4'
    | 'PERI_ID_LOOP_5'
    | 'PERI_ID_LOOP_6'
    | 'PERI_ID_LOOP_7'
    | 'PERI_ID_LOOP_8'
    | 'PERI_ID_POWER_SUPPLY_EXT'
    | 'PERI_ID_OUTPUTS2';
  export const DeviceTypeEnum = {
    MAINBOARD: 'PERI_ID_MAIN_BOARD' as DeviceTypeEnum,
    POWERSUPPLY: 'PERI_ID_POWER_SUPPLY' as DeviceTypeEnum,
    OUTPUTS1: 'PERI_ID_OUTPUTS1' as DeviceTypeEnum,
    LOOP1: 'PERI_ID_LOOP_1' as DeviceTypeEnum,
    LOOP2: 'PERI_ID_LOOP_2' as DeviceTypeEnum,
    LOOP3: 'PERI_ID_LOOP_3' as DeviceTypeEnum,
    LOOP4: 'PERI_ID_LOOP_4' as DeviceTypeEnum,
    LOOP5: 'PERI_ID_LOOP_5' as DeviceTypeEnum,
    LOOP6: 'PERI_ID_LOOP_6' as DeviceTypeEnum,
    LOOP7: 'PERI_ID_LOOP_7' as DeviceTypeEnum,
    LOOP8: 'PERI_ID_LOOP_8' as DeviceTypeEnum,
    POWERSUPPLYEXT: 'PERI_ID_POWER_SUPPLY_EXT' as DeviceTypeEnum,
    OUTPUTS2: 'PERI_ID_OUTPUTS2' as DeviceTypeEnum
  };
}
