/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Device HTTP configuration
 */
export interface HttpCfgDto {
  /**
   * HTTP port
   */
  port?: number;
  /**
   * HTTPS port
   */
  sslport?: number;
  /**
   * Enable internal server
   */
  enable?: boolean;
  /**
   * Enable compression on channel
   */
  compression?: boolean;
}
