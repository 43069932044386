/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Report element
 */
export interface ReportDto {
  /**
   * Caller authentication ID
   */
  readonly callerAuthenticationId?: string;
  /**
   * Site authentication ID
   */
  readonly siteAuthenticationId?: string;
  /**
   * UUID of the device to which this report refers
   */
  readonly deviceTargetUuid?: string;
  /**
   * Timestamp of generation of the report
   */
  readonly timestamp?: string;
  /**
   * Message of the report
   */
  readonly message?: string;
  /**
   * ID of the task to which this report refers
   */
  readonly taskId?: string;
  /**
   * Type- of the report
   */
  readonly type?: ReportDto.TypeEnum;
}
export namespace ReportDto {
  export type TypeEnum = 'info' | 'warn' | 'error';
  export const TypeEnum = {
    Info: 'info' as TypeEnum,
    Warn: 'warn' as TypeEnum,
    Error: 'error' as TypeEnum
  };
}
