/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.7+develop-018
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Data about a device
 */
export interface DeviceInfoListReqDto {
  /**
   * ID Type
   */
  idType?: DeviceInfoListReqDto.IdTypeEnum;
  /**
   * Items
   */
  items?: Array<string>;
}
export namespace DeviceInfoListReqDto {
  export type IdTypeEnum = 'UUID' | 'AUTH_ID' | 'ID32';
  export const IdTypeEnum = {
    UUID: 'UUID' as IdTypeEnum,
    AUTHID: 'AUTH_ID' as IdTypeEnum,
    ID32: 'ID32' as IdTypeEnum
  };
}
