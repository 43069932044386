import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-sites',
  templateUrl: './dashboard-sites.component.html',
  styleUrls: ['./dashboard-sites.component.scss']
})
export class DashboardSitesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
