<div class="modal-header">
  <h2>
    {{ 'COMMUNICATION.PERIODIC_INVITATION_MAIL' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="invitationFormGroup" (keyup.enter)="save()">
    <div class="switch-container">
      <label for="enable">
        <app-switch [control]="enableControl" inputId="enable"></app-switch>
        <b class="ml-2 mt-1 d-inline-block">{{
          'COMMUNICATION.ENABLE' | translate
        }}</b>
        <br />
        <small>{{ 'COMMUNICATION.ENABLE_MAIL_DESC' | translate }}</small>
      </label>
    </div>

    <label class="custom-label" for="site">{{
      'COMMUNICATION.SITE_DESCRIPTION' | translate
    }}</label>
    <input
      id="site"
      type="text"
      class="custom-input"
      [placeholder]="'COMMUNICATION.SITE_DESCRIPTION' | translate"
      [formControl]="siteControl"
    />
    <app-control-errors
      id="site-validator"
      controlName="invitedescr"
    ></app-control-errors>

    <ng-container *ngIf="deviceVersion >= 2.4 || isSbc">
      <label class="custom-label" for="language">{{
        'COMMUNICATION.LANGUAGE' | translate
      }}</label>
      <ng-select
        id="language"
        [placeholder]="'COMMUNICATION.LANGUAGE' | translate"
        [items]="languages"
        bindLabel="name"
        bindValue="value"
        [formControl]="languageControl"
        [searchable]="false"
        [clearable]="false"
      ></ng-select>
      <app-control-errors
        id="site-validator"
        controlName="invitationLanguage"
      ></app-control-errors>
    </ng-container>

    <label class="custom-label" for="email">{{
      'COMMUNICATION.CONTACT_EMAIL' | translate
    }}</label>
    <input
      id="email"
      type="text"
      class="custom-input"
      [placeholder]="'COMMUNICATION.CONTACT_EMAIL' | translate"
      [formControl]="emailControl"
    />
    <app-control-errors
      id="email-validator"
      controlName="invitemail"
      [customMessages]="{
        pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
      }"
    ></app-control-errors>
    <label class="custom-label" for="phone">{{
      'COMMUNICATION.PHONE' | translate
    }}</label>
    <input
      id="phone"
      type="text"
      class="custom-input"
      [placeholder]="'COMMUNICATION.PHONE' | translate"
      [formControl]="phoneControl"
    />
    <app-control-errors
      id="phone-validator"
      controlName="invitephone"
      [customMessages]="{
        pattern: 'USER_DEVICE.PHONE_VALIDATION_MESSAGE' | translate
      }"
    ></app-control-errors>

    <label class="custom-label" for="max-invitations">{{
      'COMMUNICATION.MAXIMUM_INVITE_ATTEMPTS' | translate
    }}</label>
    <ng-select
      id="max-invitations"
      [placeholder]="'COMMUNICATION.MAXIMUM_INVITE_ATTEMPTS' | translate"
      [items]="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]"
      [formControl]="timesControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>
    <small>{{
      'COMMUNICATION.MAXIMUM_INVITE_ATTEMPTS_DESC' | translate
    }}</small>
    <br />

    <label class="custom-label" for="invitation-period">{{
      'COMMUNICATION.INVITATION_PERIOD' | translate
    }}</label>
    <ng-select
      id="invitation-period"
      [placeholder]="'COMMUNICATION.INVITATION_PERIOD' | translate"
      [items]="[1, 2, 3, 4]"
      [formControl]="periodControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>
    <small>{{ 'COMMUNICATION.INVITATION_PERIOD_DESC' | translate }}</small>
    <br />

    <label class="custom-label" for="info">{{
      'COMMUNICATION.ADDITIONAL_INFO' | translate
    }}</label>
    <textarea
      id="info"
      class="custom-input"
      [placeholder]="'COMMUNICATION.ADDITIONAL_INFO' | translate"
      [formControl]="infoControl"
    ></textarea>
    <!-- <small>{{ 'COMMUNICATION.ADDITIONAL_INFO_DESC' | translate }}</small> -->
  </form>
</div>

<div class="modal-footer">
  <app-loading-button
    id="save"
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="save()"
    buttonType="button"
    >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
  >
</div>
