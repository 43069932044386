/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * App link info to be used by the app
 */
export interface AppLinkDto {
  /**
   * Device UUID
   */
  readonly deviceUuid?: string;
  /**
   * Apartment slave identifier
   */
  readonly slave?: number;
  /**
   * Apartment identifier
   */
  readonly aptId?: number;
  /**
   * Activation code, generated by device
   */
  readonly actcode?: string;
}
