<div class="modal-header">
  <h2>
    {{ 'DEVICE.MUGSBC.CONDITIONS.TITLE' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div id="pdfw-box">
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      style="display: block;"
      [fit-to-page]="true"
    ></pdf-viewer>
  </div>
  <a
    id="download"
    class="button-secondary big"
    target="_blank"
    [href]="pdfSrc"
    >{{ 'GLOBAL.DOWNLOAD' | translate }}</a
  >
</div>
<div class="modal-footer justify-content-center">
  <button
    id="close"
    class="button-secondary big mr-3"
    (click)="close()"
    type="button"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    id="close"
    class="{{ accepted ? 'button-primary' : 'button-secondary' }} big mr-3"
    (click)="accepted = true"
    type="button"
  >
    {{ 'GLOBAL.ACCEPT' | translate }}
  </button>
  <button
    id="close"
    class="button-primary big mr-3"
    [disabled]="!accepted"
    (click)="read()"
    type="button"
  >
    {{ 'GLOBAL.CONTINUE' | translate }}
  </button>
</div>
