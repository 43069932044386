import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { GroupService, GroupUserRequestDto } from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { checkFormValidity } from 'src/app/core/utils';
import {
  Roles,
  RolesWLabel,
  User,
  UserRole,
  rolesLabels
} from '../utils/users';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-permission-modal',
  templateUrl: './user-permission-modal.component.html',
  styleUrls: ['./user-permission-modal.component.scss']
})
export class UserPermissionModalComponent implements OnInit {
  isLoading = false;
  user: User;
  set userRole(ur: UserRole | undefined) {
    if (
      ur === 'INSTALLER' ||
      ur === 'INSTALLER-COLLABORATOR' ||
      ur === 'MONITOR-USER'
    ) {
      this.roles = this.roles.filter(
        (x) =>
          x.name !== 'BUILDINGMANAGER-COLLABORATOR' &&
          x.name !== 'BUILDINGMANAGER' &&
          x.name !== 'MAINTAINER'
      );
    }
  }

  roles: RolesWLabel[];
  set roleList(r: Roles[]) {
    this.roles = [];
    r.forEach((x) => {
      if (x.name !== 'ADMIN') {
        if (x.name === 'MONITOR-USER' && environment.client === 'teletek') {
          this.roles.push({
            ...x,
            label: this.ts.instant(rolesLabels[x.name])
          });
        } else if (x.name !== 'MONITOR-USER') {
          this.roles.push({
            ...x,
            label: this.ts.instant(rolesLabels[x.name])
          });
        }
      }
    });
  }

  userFormGroup: UntypedFormGroup;
  emailControl = new UntypedFormControl({ value: '', disabled: true });
  roleControl = new UntypedFormControl('', Validators.required);

  userEdited = new EventEmitter<void>();

  constructor(
    private modalRef: BsModalRef,
    private ts: TranslateService,
    private groupService: GroupService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userFormGroup = new UntypedFormGroup({
      email: this.emailControl,
      roleAuthId: this.roleControl
    });

    this.userFormGroup.patchValue(this.user);
  }

  save() {
    if (checkFormValidity(this.userFormGroup) && !this.isLoading) {
      this.isLoading = true;

      const share: GroupUserRequestDto = {
        loginEmail: this.emailControl.value
      };

      this.groupService
        .addUserToGroup(
          this.roleControl.value,
          false,
          share,
          this.authService.getToken()
        )
        .subscribe(() => {
          this.groupService
            .deleteUserFromGroup(
              this.user.roleAuthId,
              false,
              this.authService.getToken(),
              undefined,
              undefined,
              this.user.authId
            )
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
              this.userEdited.next();
              this.modalRef.hide();
            });
        });
    }
  }

  close() {
    this.modalRef.hide();
  }
}
