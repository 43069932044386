/**
 * User Document management API
 * API to interact with documents to be submitted to users
 *
 * The version of the OpenAPI document: 1.5.0+readPreferenceAtlasFix.r5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DocDto {
  /**
   * document id
   */
  documentId?: string;
  /**
   * document code
   */
  code?: string;
  /**
   * document version
   */
  version?: number;
  /**
   * document type
   */
  type?: string;
  /**
   * document media type
   */
  mediaType?: string;
  /**
   * document language
   */
  language?: string;
  /**
   * document file UUID
   */
  fileUUID?: string;
  /**
   * document file name
   */
  fileName?: string;
  /**
   * document file url
   */
  fileUrl?: string;
}
