import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocDto, DocumentsService } from 'src/app/api/document';
import { AuthService } from 'src/app/core/auth.service';
import { getUserLanguage } from '../../app-init';

@Component({
  selector: 'app-conditions-modal',
  templateUrl: './conditions-modal.component.html',
  styleUrls: ['./conditions-modal.component.scss']
})
export class ConditionsModalComponent implements OnInit {
  pdfSrc = '';
  pdf: DocDto;
  accepted = false;
  iccid: string;
  deviceAuthId: string;
  conditionsRead = new EventEmitter<boolean>();
  constructor(
    private modalRef: BsModalRef,
    private authService: AuthService,
    private documentsService: DocumentsService
  ) {}

  ngOnInit(): void {
    const langList = ['NL', 'ES', 'FR', 'DE', 'PT', 'EN', 'IT'];
    const lang = getUserLanguage().toUpperCase();

    this.documentsService
      .getAgreementsByStatus(
        'pending',
        langList.indexOf(lang) !== -1 ? lang : 'EN',
        'mugsbc',
        undefined,
        undefined,
        [this.deviceAuthId, this.iccid]
      )
      .subscribe((res: any) => {
        if (res.length !== 0) {
          this.pdf = res[0];
          this.pdfSrc = res[0].fileUrl || '';
        } else {
          this.conditionsRead.next(true);
          this.modalRef.hide();
          //TODO  what to do if no pending agreements
        }
      });
  }

  read() {
    this.documentsService
      .submitPendingAgreements({
        keys: [this.deviceAuthId, this.iccid],
        documentId: this.pdf.documentId,
        fileUuid: this.pdf.fileUUID
      })
      .subscribe(() => {
        this.conditionsRead.next(this.accepted);
        this.modalRef.hide();
      });
  }

  close() {
    this.modalRef.hide();
  }
}
