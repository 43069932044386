import { Subject } from 'rxjs';

export class SelectedItemsHandler<T> {
  get items(): T[] {
    return this._items;
  }

  set items(value: T[]) {
    this._items = value;
    this.itemsChanged$.next();
  }

  idField: string;
  itemsChanged$ = new Subject<void>();

  private _items: T[];

  constructor(idField = 'id', items: T[] = []) {
    this.idField = idField;
    this.items = items;
  }

  findIndex(item: T): number {
    return this.items.findIndex(
      i => (i as any)[this.idField] === (item as any)[this.idField]
    );
  }

  toggleItemSelected(item: T, selected: boolean) {
    if (selected) {
      if (!this.isSelected(item)) {
        this.items = this.items.concat(item);
      }
    } else {
      const index = this.findIndex(item);
      if (index !== -1) {
        this.items.splice(index, 1);
        this.items = [...this.items];
      }
    }
    this.itemsChanged$.next();
  }

  selectSingleItem(item: T) {
    this.items = [item];
    this.itemsChanged$.next();
  }

  isSelected(item: T) {
    return this.findIndex(item) !== -1;
  }
}
