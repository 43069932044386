/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.7+develop-018
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * DEtails of node to be shown in tree view
 */
export interface EntryInfoDto {
  /**
   * Node label
   */
  label: string;
  /**
   * Element UUID
   */
  uuid: string;
  /**
   * Device current status (ready/poweroff). Only for nodes representing devices
   */
  status: EntryInfoDto.StatusEnum;
  /**
   * List of sub-elements (child containers and/or devices)
   */
  elements?: Array<EntryInfoDto>;
  /**
   * True if the current user can only view this elements without operating on it.
   */
  readonly: boolean;
  /**
   * True if the current user is the owner of the element.
   */
  is_owner: boolean;
  /**
   * Type of container
   */
  container_type?: EntryInfoDto.ContainerTypeEnum;
}
export namespace EntryInfoDto {
  export type StatusEnum = 'ready' | 'offline';
  export const StatusEnum = {
    Ready: 'ready' as StatusEnum,
    Offline: 'offline' as StatusEnum
  };
  export type ContainerTypeEnum =
    | 'GENERIC'
    | 'SITE'
    | 'BUILDING'
    | 'STAIRCASE'
    | 'FLOOR'
    | 'HOUSE';
  export const ContainerTypeEnum = {
    GENERIC: 'GENERIC' as ContainerTypeEnum,
    SITE: 'SITE' as ContainerTypeEnum,
    BUILDING: 'BUILDING' as ContainerTypeEnum,
    STAIRCASE: 'STAIRCASE' as ContainerTypeEnum,
    FLOOR: 'FLOOR' as ContainerTypeEnum,
    HOUSE: 'HOUSE' as ContainerTypeEnum
  };
}
