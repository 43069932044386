/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Log entries
 */
export interface SafeLogResponseDto {
  /**
   * Log row ID
   */
  id: number;
  /**
   * Numeric univoque identifier of an event in device\'s events log (itemId: 12).
   */
  uniqueId?: number;
  /**
   * Event type (itemId: 0)
   */
  readonly logEventType?: SafeLogResponseDto.LogEventTypeEnum;
  /**
   * Event code (itemId: 1)
   */
  readonly logEventCode?: string;
  /**
   * Event action (itemId: 2)
   */
  readonly logEventAction?: SafeLogResponseDto.LogEventActionEnum;
  /**
   * Log time (itemId: 3)
   */
  readonly logTime?: string;
  /**
   * Log mask type (itemId: 4)
   */
  readonly logMaskType?: SafeLogResponseDto.LogMaskTypeEnum;
  /**
   * Log mask (itemId: 5)
   */
  readonly logMask?: number;
  /**
   * Log agent (itemId: 6)
   */
  readonly logAgent?: number;
  /**
   * Log agent ID (itemId: 7)
   */
  readonly logAgentId?: number;
  /**
   * Log message (itemId: 9)
   */
  readonly msg?: string;
  /**
   * [deprecated] Message hash (itemId: 3, 9)
   */
  readonly hash?: string;
}
export namespace SafeLogResponseDto {
  export type LogEventTypeEnum =
    | 'TIPO_AVV_NONE'
    | 'TIPO_AVV_OTHER'
    | 'TIPO_AVV_EXLUSION'
    | 'TIPO_AVV_ANOMALIES'
    | 'TIPO_AVV_SABOTAGES'
    | 'TIPO_AVV_ALARMS'
    | 'TIPO_AVV_BURGLAR_ALARMS';
  export const LogEventTypeEnum = {
    NONE: 'TIPO_AVV_NONE' as LogEventTypeEnum,
    OTHER: 'TIPO_AVV_OTHER' as LogEventTypeEnum,
    EXLUSION: 'TIPO_AVV_EXLUSION' as LogEventTypeEnum,
    ANOMALIES: 'TIPO_AVV_ANOMALIES' as LogEventTypeEnum,
    SABOTAGES: 'TIPO_AVV_SABOTAGES' as LogEventTypeEnum,
    ALARMS: 'TIPO_AVV_ALARMS' as LogEventTypeEnum,
    BURGLARALARMS: 'TIPO_AVV_BURGLAR_ALARMS' as LogEventTypeEnum
  };
  export type LogEventActionEnum =
    | 'EVENT_AZ_NONE'
    | 'EVENT_AZ_SET'
    | 'EVENT_AZ_RESET'
    | 'EVENT_AZ_ALARM'
    | 'EVENT_AZ_RESTORE'
    | 'EVENT_AZ_FORCED_EXCL'
    | 'EVENT_AZ_ALARM_TEST'
    | 'EVENT_AZ_RESTORE_TEST';
  export const LogEventActionEnum = {
    NONE: 'EVENT_AZ_NONE' as LogEventActionEnum,
    SET: 'EVENT_AZ_SET' as LogEventActionEnum,
    RESET: 'EVENT_AZ_RESET' as LogEventActionEnum,
    ALARM: 'EVENT_AZ_ALARM' as LogEventActionEnum,
    RESTORE: 'EVENT_AZ_RESTORE' as LogEventActionEnum,
    FORCEDEXCL: 'EVENT_AZ_FORCED_EXCL' as LogEventActionEnum,
    ALARMTEST: 'EVENT_AZ_ALARM_TEST' as LogEventActionEnum,
    RESTORETEST: 'EVENT_AZ_RESTORE_TEST' as LogEventActionEnum
  };
  export type LogMaskTypeEnum =
    | 'MASK_TYPE_NONE'
    | 'MASK_TYPE_AREAS'
    | 'MASK_TYPE_APP_ID'
    | 'MASK_TYPE_KEYB_ID'
    | 'MASK_TYPE_RFID_ID'
    | 'MASK_TYPE_ST_ID'
    | 'MASK_TYPE_GT_ID'
    | 'MASK_TYPE_OUT_ID'
    | 'MASK_TYPE_USER_ID'
    | 'MASK_TYPE_BRIDGE_ID';
  export const LogMaskTypeEnum = {
    NONE: 'MASK_TYPE_NONE' as LogMaskTypeEnum,
    AREAS: 'MASK_TYPE_AREAS' as LogMaskTypeEnum,
    APPID: 'MASK_TYPE_APP_ID' as LogMaskTypeEnum,
    KEYBID: 'MASK_TYPE_KEYB_ID' as LogMaskTypeEnum,
    RFIDID: 'MASK_TYPE_RFID_ID' as LogMaskTypeEnum,
    STID: 'MASK_TYPE_ST_ID' as LogMaskTypeEnum,
    GTID: 'MASK_TYPE_GT_ID' as LogMaskTypeEnum,
    OUTID: 'MASK_TYPE_OUT_ID' as LogMaskTypeEnum,
    USERID: 'MASK_TYPE_USER_ID' as LogMaskTypeEnum,
    BRIDGEID: 'MASK_TYPE_BRIDGE_ID' as LogMaskTypeEnum
  };
}
