/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { AppSetupDto } from '../model/models';
import { Ccapi2ErrDto } from '../model/models';
import { DeviceManagerDto } from '../model/models';
import { DeviceStatusDto } from '../model/models';
import { DevicesStatusDto } from '../model/models';
import { HttpCfgDto } from '../model/models';
import { InvitationMailCfgDto } from '../model/models';
import { RemoteCfgDto } from '../model/models';
import { SipAdditionalCfgDto } from '../model/models';
import { SipCfgDto } from '../model/models';
import { SipLineCfgDto } from '../model/models';
import { SipLinesCfgDto } from '../model/models';

import { MUGCONF_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class StatusAndConfigurationService {
  protected basePath = 'https://api.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(MUGCONF_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Retrieve application setup configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 30 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppSetup(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<AppSetupDto>>;
  public getAppSetup(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<AppSetupDto>>>;
  public getAppSetup(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<AppSetupDto>>>;
  public getAppSetup(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getAppSetup.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getAppSetup.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<AppSetupDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/appsetup`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve device manager configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 30 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceManager(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<DeviceManagerDto>>;
  public getDeviceManager(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<DeviceManagerDto>>>;
  public getDeviceManager(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<DeviceManagerDto>>>;
  public getDeviceManager(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getDeviceManager.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getDeviceManager.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<DeviceManagerDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/devicemanager`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the current device status
   * &lt;b&gt;No throttling&lt;/b&gt;
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param apikey Api key
   * @param withStats Retrieve also counters (if they exists)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceStatus(
    deviceId: string,
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceStatusDto>;
  public getDeviceStatus(
    deviceId: string,
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceStatusDto>>;
  public getDeviceStatus(
    deviceId: string,
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceStatusDto>>;
  public getDeviceStatus(
    deviceId: string,
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getDeviceStatus.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (withStats !== undefined && withStats !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>withStats,
        'withStats'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<DeviceStatusDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/status`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the current device status
   * &lt;b&gt;No throttling&lt;/b&gt;
   * @param authorization Authorization token
   * @param apikey Api key
   * @param withStats Retrieve also counters (if they exists)
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevicesStatus(
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    body?: DevicesStatusDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<DeviceStatusDto>>;
  public getDevicesStatus(
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    body?: DevicesStatusDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<DeviceStatusDto>>>;
  public getDevicesStatus(
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    body?: DevicesStatusDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<DeviceStatusDto>>>;
  public getDevicesStatus(
    authorization?: string,
    apikey?: string,
    withStats?: boolean,
    body?: DevicesStatusDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (withStats !== undefined && withStats !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>withStats,
        'withStats'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Array<DeviceStatusDto>>(
      `${this.configuration.basePath}/servicerest/mugapi/devices/status`,
      body,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve HTTP server configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 30 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHttpCfg(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<HttpCfgDto>>;
  public getHttpCfg(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<HttpCfgDto>>>;
  public getHttpCfg(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<HttpCfgDto>>>;
  public getHttpCfg(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getHttpCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getHttpCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<HttpCfgDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/httpcfg`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve invitation email configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInvitationMailCfg(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<InvitationMailCfgDto>>;
  public getInvitationMailCfg(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<InvitationMailCfgDto>>>;
  public getInvitationMailCfg(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<InvitationMailCfgDto>>>;
  public getInvitationMailCfg(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getInvitationMailCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getInvitationMailCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<InvitationMailCfgDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/invitationEmail`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve remote configuration options
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRemoteCfg(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<RemoteCfgDto>>;
  public getRemoteCfg(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<RemoteCfgDto>>>;
  public getRemoteCfg(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<RemoteCfgDto>>>;
  public getRemoteCfg(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getRemoteCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getRemoteCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<RemoteCfgDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/remoteconf`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve SIP additional configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SipAdditionalCfgDto>>;
  public getSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SipAdditionalCfgDto>>>;
  public getSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SipAdditionalCfgDto>>>;
  public getSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getSipAdditionalCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getSipAdditionalCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SipAdditionalCfgDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/sipextra`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve SIP configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSipCfg(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SipCfgDto>>;
  public getSipCfg(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SipCfgDto>>>;
  public getSipCfg(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SipCfgDto>>>;
  public getSipCfg(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getSipCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getSipCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SipCfgDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(String(deviceId))}/sip`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve SIP lines configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSipLinesCfg(
    deviceId: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SipLineCfgDto>>;
  public getSipLinesCfg(
    deviceId: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SipLineCfgDto>>>;
  public getSipLinesCfg(
    deviceId: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SipLineCfgDto>>>;
  public getSipLinesCfg(
    deviceId: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getSipLinesCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getSipLinesCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SipLineCfgDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/siplines`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set application setup configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 30 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setAppSetup(
    deviceId: string,
    authorization: string,
    body?: AppSetupDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setAppSetup(
    deviceId: string,
    authorization: string,
    body?: AppSetupDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setAppSetup(
    deviceId: string,
    authorization: string,
    body?: AppSetupDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setAppSetup(
    deviceId: string,
    authorization: string,
    body?: AppSetupDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setAppSetup.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setAppSetup.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/appsetup`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set device manager configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 30 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setDeviceManager(
    deviceId: string,
    authorization: string,
    body?: DeviceManagerDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setDeviceManager(
    deviceId: string,
    authorization: string,
    body?: DeviceManagerDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setDeviceManager(
    deviceId: string,
    authorization: string,
    body?: DeviceManagerDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setDeviceManager(
    deviceId: string,
    authorization: string,
    body?: DeviceManagerDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setDeviceManager.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setDeviceManager.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/devicemanager`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set HTTP server configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 30 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setHttpCfg(
    deviceId: string,
    authorization: string,
    body?: HttpCfgDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setHttpCfg(
    deviceId: string,
    authorization: string,
    body?: HttpCfgDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setHttpCfg(
    deviceId: string,
    authorization: string,
    body?: HttpCfgDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setHttpCfg(
    deviceId: string,
    authorization: string,
    body?: HttpCfgDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setHttpCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setHttpCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/httpcfg`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set invitation email configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setInvitationMailCfg(
    deviceId: string,
    authorization: string,
    body?: InvitationMailCfgDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setInvitationMailCfg(
    deviceId: string,
    authorization: string,
    body?: InvitationMailCfgDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setInvitationMailCfg(
    deviceId: string,
    authorization: string,
    body?: InvitationMailCfgDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setInvitationMailCfg(
    deviceId: string,
    authorization: string,
    body?: InvitationMailCfgDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setInvitationMailCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setInvitationMailCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/invitationEmail`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set remote configuration options
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setRemoteCfg(
    deviceId: string,
    authorization: string,
    body?: RemoteCfgDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setRemoteCfg(
    deviceId: string,
    authorization: string,
    body?: RemoteCfgDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setRemoteCfg(
    deviceId: string,
    authorization: string,
    body?: RemoteCfgDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setRemoteCfg(
    deviceId: string,
    authorization: string,
    body?: RemoteCfgDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setRemoteCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setRemoteCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/remoteconf`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set SIP additional configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    body?: SipAdditionalCfgDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    body?: SipAdditionalCfgDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    body?: SipAdditionalCfgDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setSipAdditionalCfg(
    deviceId: string,
    authorization: string,
    body?: SipAdditionalCfgDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setSipAdditionalCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setSipAdditionalCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/sipextra`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set SIP configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setSipCfg(
    deviceId: string,
    authorization: string,
    body?: SipCfgDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setSipCfg(
    deviceId: string,
    authorization: string,
    body?: SipCfgDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setSipCfg(
    deviceId: string,
    authorization: string,
    body?: SipCfgDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setSipCfg(
    deviceId: string,
    authorization: string,
    body?: SipCfgDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setSipCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setSipCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(String(deviceId))}/sip`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set SIP lines configuration
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setSipLinesCfg(
    deviceId: string,
    authorization: string,
    body?: SipLinesCfgDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setSipLinesCfg(
    deviceId: string,
    authorization: string,
    body?: SipLinesCfgDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setSipLinesCfg(
    deviceId: string,
    authorization: string,
    body?: SipLinesCfgDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setSipLinesCfg(
    deviceId: string,
    authorization: string,
    body?: SipLinesCfgDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setSipLinesCfg.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setSipLinesCfg.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/siplines`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
