/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License time-related data DTO
 */
export interface TimeLicenseDataDto {
  /**
   * License UUID
   */
  autoRenew?: boolean;
  /**
   * License last notified expiration date.
   */
  expirationLastNotified?: string;
  /**
   * License expires
   */
  expires?: boolean;
  /**
   * License expiration date
   */
  expirationDate?: string;
  /**
   * Licensed resource type
   */
  validFrom?: string;
}
