<div class="modal-header">
  <h2>{{ title }}</h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ description }}
</div>

<div class="modal-footer">
  <button
    id="cancel"
    class="button-secondary big"
    *ngIf="cancelLabel"
    (click)="cancelAction.next()"
  >
    {{ cancelLabel }}
  </button>
  <button
    id="confirm"
    class="button-primary big"
    (click)="confirmAction.next()"
  >
    {{ confirmLabel }}
  </button>
</div>
