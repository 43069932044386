<div *ngIf="showLines" class="card-table mt-3 pb-3">
  <div class="card-header">
    <h2>{{ 'SIP.SIP_PHONE_LINES' | translate }}</h2>
    <button
      id="new-device"
      class="button-secondary"
      (click)="createLine()"
      [disabled]="dataSource.totalItems === 15"
    >
      <i class="material-icons notranslate">add</i>
      {{ 'SIP.ADD_PHONE_LINE' | translate }}
    </button>
  </div>
  <div class="card-body">
    <app-datatable
      *ngIf="!isMobile"
      [columns]="columns"
      [dataSource]="dataSource"
      [hidePagination]="true"
      [messages]="{
        emptyMessage: 'DATATABLE.NO_DATA_SIP_PHONE_LINES' | translate
      }"
    ></app-datatable>

    <app-mobile-table *ngIf="isMobile" [dataSource]="dataSource">
      <div id="mobile-sip-lines" class="mobile-table">
        <div
          *ngFor="let item of dataSource.items; let i = index"
          class="mobile-card sip-line"
        >
          <span class="mobile-card-title"> {{ item.sipUsername }}</span>
          <span class="mobile-card-subtitle"> {{ item.sipUserAuth }}</span>
          <span class="mobile-card-note">
            <ng-container
              *ngTemplateOutlet="statusTpl; context: { value: item.status }"
            ></ng-container
          ></span>
          <ng-container
            *ngTemplateOutlet="actionsTpl; context: { row: item, rowIndex: i }"
          ></ng-container>
        </div>
      </div>
    </app-mobile-table>
  </div>
  <div class="card-footer">
    <b
      >{{ 15 - (dataSource.totalItems || 0) }}
      {{ 'SIP.SIP_LINES_QTYS' | translate }}</b
    >
  </div>
</div>
<ng-template #nullableTpl let-value="value">
  {{ value || '--' }}
</ng-template>
<ng-template #indexTpl let-value="value">
  {{ value + 1 }}
</ng-template>
<ng-template #statusTpl let-value="value">
  {{ value | translate }}
</ng-template>

<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <div class="btn-group" dropdown container="body">
    <i
      id="menu-{{ rowIndex }}"
      class="material-icons notranslate menu-icon"
      (click)="$event.stopPropagation()"
      dropdownToggle
      >more_vert</i
    >
    <ul
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right"
      role="menu"
      aria-labelledby="menu"
    >
      <li role="menuitem">
        <a
          id="edit-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="editLine(row)"
          >{{ 'GLOBAL.EDIT' | translate }}</a
        >
      </li>
      <li role="menuitem">
        <a
          id="delete-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="deleteLine(row)"
          >{{ 'GLOBAL.DELETE' | translate }}</a
        >
      </li>
    </ul>
  </div>
</ng-template>
