/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * PIN command parameters
 */
export interface SafeActionRequestPinDto {
  /**
   * Index row of the user to add in database’s users table
   */
  userId: number;
  /**
   * Type of user for whom PIN is requested
   */
  role: SafeActionRequestPinDto.RoleEnum;
}
export namespace SafeActionRequestPinDto {
  export type RoleEnum = 'USER' | 'INSTALLER';
  export const RoleEnum = {
    USER: 'USER' as RoleEnum,
    INSTALLER: 'INSTALLER' as RoleEnum
  };
}
