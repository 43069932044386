/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetPropertyDtoPreferredContactMethodEnum {
  value?: SetPropertyDtoPreferredContactMethodEnum.ValueEnum;
  unset?: boolean;
}
export namespace SetPropertyDtoPreferredContactMethodEnum {
  export type ValueEnum = 'EMAIL';
  export const ValueEnum = {
    EMAIL: 'EMAIL' as ValueEnum
  };
}
