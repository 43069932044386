/**
 * Cloud Message Center API
 * API to interact with cloud message center
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CountDto } from '../model/models';
import { ErrorDto } from '../model/models';
import { MessageDeleteManyResultDto } from '../model/models';
import { MessageReadV2Dto } from '../model/models';
import { MessageUpdateDto } from '../model/models';

import { MC_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class V2messageService {
  protected basePath =
    'https://usvc-preprod.cloud.comelitgroup.com/servicerest/messagecenter';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(MC_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Read many message
   * @param id
   * @param fromDate
   * @param toDate
   * @param ack
   * @param deviceUuid
   * @param category
   * @param eventType
   * @param priority
   * @param param
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public countMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<CountDto>;
  public countMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<CountDto>>;
  public countMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<CountDto>>;
  public countMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (id) {
      id.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'id'
        );
      });
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>fromDate,
        'fromDate'
      );
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>toDate,
        'toDate'
      );
    }
    if (ack !== undefined && ack !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>ack, 'ack');
    }
    if (deviceUuid) {
      deviceUuid.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'deviceUuid'
        );
      });
    }
    if (category) {
      category.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'category'
        );
      });
    }
    if (eventType) {
      eventType.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'eventType'
        );
      });
    }
    if (priority) {
      priority.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'priority'
        );
      });
    }
    if (param) {
      param.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'param'
        );
      });
    }

    let headers = this.defaultHeaders;

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<CountDto>(
      `${this.configuration.basePath}/v2/message/count`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete message
   * @param id Message Id to be removed
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteManyMessages(
    id?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<MessageDeleteManyResultDto>;
  public deleteManyMessages(
    id?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<MessageDeleteManyResultDto>>;
  public deleteManyMessages(
    id?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<MessageDeleteManyResultDto>>;
  public deleteManyMessages(
    id?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (id) {
      id.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'id'
        );
      });
    }

    let headers = this.defaultHeaders;

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<MessageDeleteManyResultDto>(
      `${this.configuration.basePath}/v2/message/delete`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Read many message
   * @param id
   * @param fromDate
   * @param toDate
   * @param ack
   * @param deviceUuid
   * @param category
   * @param eventType
   * @param priority
   * @param param
   * @param skip
   * @param limit
   * @param sort
   * @param acceptLanguage Accepted language(s)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public readManyMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    skip?: number,
    limit?: number,
    sort?: Array<
      | 'DATE_ASC'
      | 'DATE_DESC'
      | 'TITLE_ASC'
      | 'TITLE_DESC'
      | 'DESCRIPTION_ASC'
      | 'DESCRIPTION_DESC'
      | 'ACK_ASC'
      | 'ACK_DESC'
      | 'EXPIREDATE_ASC'
      | 'EXPIREDATE_DESC'
      | 'DESTAUTHID_ASC'
      | 'DESTAUTHID_DESC'
      | 'SOURCEDEVICEID_ASC'
      | 'SOURCEDEVICEID_DESC'
      | 'CATEGORY_ASC'
      | 'CATEGORY_DESC'
      | 'PRIORITY_ASC'
      | 'PRIORITY_DESC'
    >,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<MessageReadV2Dto>>;
  public readManyMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    skip?: number,
    limit?: number,
    sort?: Array<
      | 'DATE_ASC'
      | 'DATE_DESC'
      | 'TITLE_ASC'
      | 'TITLE_DESC'
      | 'DESCRIPTION_ASC'
      | 'DESCRIPTION_DESC'
      | 'ACK_ASC'
      | 'ACK_DESC'
      | 'EXPIREDATE_ASC'
      | 'EXPIREDATE_DESC'
      | 'DESTAUTHID_ASC'
      | 'DESTAUTHID_DESC'
      | 'SOURCEDEVICEID_ASC'
      | 'SOURCEDEVICEID_DESC'
      | 'CATEGORY_ASC'
      | 'CATEGORY_DESC'
      | 'PRIORITY_ASC'
      | 'PRIORITY_DESC'
    >,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<MessageReadV2Dto>>>;
  public readManyMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    skip?: number,
    limit?: number,
    sort?: Array<
      | 'DATE_ASC'
      | 'DATE_DESC'
      | 'TITLE_ASC'
      | 'TITLE_DESC'
      | 'DESCRIPTION_ASC'
      | 'DESCRIPTION_DESC'
      | 'ACK_ASC'
      | 'ACK_DESC'
      | 'EXPIREDATE_ASC'
      | 'EXPIREDATE_DESC'
      | 'DESTAUTHID_ASC'
      | 'DESTAUTHID_DESC'
      | 'SOURCEDEVICEID_ASC'
      | 'SOURCEDEVICEID_DESC'
      | 'CATEGORY_ASC'
      | 'CATEGORY_DESC'
      | 'PRIORITY_ASC'
      | 'PRIORITY_DESC'
    >,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<MessageReadV2Dto>>>;
  public readManyMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    skip?: number,
    limit?: number,
    sort?: Array<
      | 'DATE_ASC'
      | 'DATE_DESC'
      | 'TITLE_ASC'
      | 'TITLE_DESC'
      | 'DESCRIPTION_ASC'
      | 'DESCRIPTION_DESC'
      | 'ACK_ASC'
      | 'ACK_DESC'
      | 'EXPIREDATE_ASC'
      | 'EXPIREDATE_DESC'
      | 'DESTAUTHID_ASC'
      | 'DESTAUTHID_DESC'
      | 'SOURCEDEVICEID_ASC'
      | 'SOURCEDEVICEID_DESC'
      | 'CATEGORY_ASC'
      | 'CATEGORY_DESC'
      | 'PRIORITY_ASC'
      | 'PRIORITY_DESC'
    >,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (id) {
      id.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'id'
        );
      });
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>fromDate,
        'fromDate'
      );
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>toDate,
        'toDate'
      );
    }
    if (ack !== undefined && ack !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>ack, 'ack');
    }
    if (deviceUuid) {
      deviceUuid.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'deviceUuid'
        );
      });
    }
    if (category) {
      category.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'category'
        );
      });
    }
    if (eventType) {
      eventType.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'eventType'
        );
      });
    }
    if (priority) {
      priority.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'priority'
        );
      });
    }
    if (param) {
      param.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'param'
        );
      });
    }
    if (skip !== undefined && skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>skip,
        'skip'
      );
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'sort'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('accept-language', String(acceptLanguage));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<MessageReadV2Dto>>(
      `${this.configuration.basePath}/v2/message/read`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get message
   * @param messageId
   * @param acceptLanguage Accepted language(s)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public readOneMessage(
    messageId: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<MessageReadV2Dto>;
  public readOneMessage(
    messageId: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<MessageReadV2Dto>>;
  public readOneMessage(
    messageId: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<MessageReadV2Dto>>;
  public readOneMessage(
    messageId: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (messageId === null || messageId === undefined) {
      throw new Error(
        'Required parameter messageId was null or undefined when calling readOneMessage.'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('accept-language', String(acceptLanguage));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<MessageReadV2Dto>(
      `${this.configuration.basePath}/v2/message/read/${encodeURIComponent(
        String(messageId)
      )}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update message
   * @param messageId
   * @param acceptLanguage Accepted language(s)
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateOneMessage(
    messageId: string,
    acceptLanguage?: string,
    body?: MessageUpdateDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<MessageReadV2Dto>;
  public updateOneMessage(
    messageId: string,
    acceptLanguage?: string,
    body?: MessageUpdateDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<MessageReadV2Dto>>;
  public updateOneMessage(
    messageId: string,
    acceptLanguage?: string,
    body?: MessageUpdateDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<MessageReadV2Dto>>;
  public updateOneMessage(
    messageId: string,
    acceptLanguage?: string,
    body?: MessageUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (messageId === null || messageId === undefined) {
      throw new Error(
        'Required parameter messageId was null or undefined when calling updateOneMessage.'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('accept-language', String(acceptLanguage));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<MessageReadV2Dto>(
      `${this.configuration.basePath}/v2/message/update/${encodeURIComponent(
        String(messageId)
      )}`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Watch for message
   * @param id
   * @param fromDate
   * @param toDate
   * @param ack
   * @param deviceUuid
   * @param category
   * @param eventType
   * @param priority
   * @param param
   * @param acceptLanguage Accepted language(s)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public watchMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<MessageReadV2Dto>;
  public watchMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<MessageReadV2Dto>>;
  public watchMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<MessageReadV2Dto>>;
  public watchMessages(
    id?: Array<string>,
    fromDate?: string,
    toDate?: string,
    ack?: 'TRUE' | 'FALSE',
    deviceUuid?: Array<string>,
    category?: Array<
      | 'NOT_RECOGNIZED'
      | 'GENERIC_EVENT'
      | 'SAFE_EVENT'
      | 'PCAL_EVENT'
      | 'FIRE_EVENT'
    >,
    eventType?: Array<
      | 'NOT_RECOGNIZED'
      | 'NO_TYPE'
      | 'OTHER'
      | 'EXCLUSIONS'
      | 'ANOMALIES'
      | 'SABOTAGE'
      | 'ALARMS'
      | 'BURGLAR_ALARMS'
      | 'FIRE_EVENT'
      | 'USER_ADD_ACKNOWLEDGMENT'
      | 'PIN_GENERATION'
      | 'DEFAULT_POLICY_UPDATE'
      | 'CUSTOM_POLICY_UPDATE'
      | 'RCV_PROVIDER_REVOKE'
    >,
    priority?: Array<number>,
    param?: Array<string>,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (id) {
      id.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'id'
        );
      });
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>fromDate,
        'fromDate'
      );
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>toDate,
        'toDate'
      );
    }
    if (ack !== undefined && ack !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>ack, 'ack');
    }
    if (deviceUuid) {
      deviceUuid.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'deviceUuid'
        );
      });
    }
    if (category) {
      category.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'category'
        );
      });
    }
    if (eventType) {
      eventType.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'eventType'
        );
      });
    }
    if (priority) {
      priority.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'priority'
        );
      });
    }
    if (param) {
      param.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'param'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('accept-language', String(acceptLanguage));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<MessageReadV2Dto>(
      `${this.configuration.basePath}/v2/message/watch`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
