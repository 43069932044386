import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';
import {
  CheckedDocReqDto,
  CheckedDocResDto,
  DocumentsService,
  ListDocResDto
} from 'src/app/api/document';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-fire-info-modal',
  templateUrl: './fire-info-modal.component.html',
  styleUrls: ['./fire-info-modal.component.scss']
})
export class FireInfoModalComponent implements OnInit {
  docs: ListDocResDto[] = [];
  isLoading: boolean = false;
  acceptControl = new FormControl(false);
  closeModal = new EventEmitter<void>();

  messagesText = {
    REAL_TIME_INFO: _('DEVICE.FIRE.DOCS.REAL_TIME_INFO')
  };

  constructor(
    public modalRef: BsModalRef,
    public documentsService: DocumentsService
  ) {}
  ngOnInit(): void {}

  close() {
    this.modalRef.hide();
    this.closeModal.emit();
  }

  goOn() {
    if (this.acceptControl.value) {
      const docsAcc: CheckedDocReqDto[] = this.docs.map((x) => {
        return { documentId: x.documentId! };
      });
      this.isLoading = true;
      this.documentsService
        .setDocumentsStatus(docsAcc)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((res: CheckedDocResDto[]) => {
          this.closeModal.emit();
        });
    } else {
      this.closeModal.emit();
    }
  }
}
