import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwitchboardDirectoryDataDto } from 'src/app/api/mugconf';

@Component({
  selector: 'app-create-switchboard-modal',
  templateUrl: './create-switchboard-modal.component.html',
  styleUrls: ['./create-switchboard-modal.component.scss']
})
export class CreateSwitchboardModalComponent implements OnInit {
  dataGroup: UntypedFormGroup;
  id: number;
  dirId: number;
  data: SwitchboardDirectoryDataDto;
  title: string;
  addrList = [
    {
      name: this.ts.instant('ADDRESSBOOK.SWITCHBOARD_PRIMARY'),
      value: '008'
    },
    {
      name: this.ts.instant('ADDRESSBOOK.SWITCHBOARD_SECONDARY'),
      value: '007'
    },
    {
      name: this.ts.instant('ADDRESSBOOK.SWITCHBOARD_GUARDIAN'),
      value: '009'
    }
  ];
  created = new EventEmitter<SwitchboardDirectoryDataDto>();
  constructor(private modalRef: BsModalRef, private ts: TranslateService) {}

  ngOnInit(): void {
    this.dataGroup = new UntypedFormGroup({
      addr: new UntypedFormControl('', [
        Validators.min(1),
        Validators.max(255),
        Validators.maxLength(3),
        Validators.minLength(3),
        Validators.pattern('[0-9]*'),
        Validators.required
      ]),
      name: new UntypedFormControl('', Validators.required),
      dir: new UntypedFormControl(this.dirId),
      id: new UntypedFormControl(this.id),
      emcall: new UntypedFormControl(false)
    });

    if (this.data) {
      this.dataGroup.patchValue(this.data);
      this.dataGroup.controls['addr'].setValue(
        this.data.addr?.split('SBCPS')[1]
      );
    }
  }

  close() {
    this.modalRef.hide();
  }
  save() {
    if (this.dataGroup.valid) {
      this.created.next({
        ...this.dataGroup.value,
        addr: `SBCPS${this.dataGroup.controls['addr'].value}`
      });
    }
  }
}
