import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SiteDto, SiteService } from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { checkFormValidity, mustBeDiffFromOwner } from 'src/app/core/utils';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Roles, RolesWLabel, rolesLabels } from '../utils/users';

interface SelectionListElement {
  value: number;
  label: string;
}

@Component({
  selector: 'app-transfer-ownership-modal',
  templateUrl: './transfer-ownership-modal.component.html',
  styleUrls: ['./transfer-ownership-modal.component.scss']
})
export class TransferOwnershipModalComponent implements OnInit {
  isLoading = false;
  transferOwnershipGroup: UntypedFormGroup;
  futureOwnerControl = new UntypedFormControl('');
  transferTypeControl = new UntypedFormControl({ value: 0, disabled: false });
  roleControl = new UntypedFormControl();
  currentOwner: string;

  transferred = new EventEmitter<void>();
  transferTypes: SelectionListElement[] = [
    {
      value: 0,
      label: this.ts.instant('TRANSFER.COMPLETE')
    },
    {
      value: 1,
      label: this.ts.instant('TRANSFER.PARTIAL')
    }
  ];

  @Input() site: SiteDto;
  allRoles: RolesWLabel[] = [];
  rolesToShow: RolesWLabel[] = [];
  set roles(r: Roles[]) {
    this.rolesToShow = [];
    this.allRoles = [];
    r.forEach((x) => {
      this.allRoles.push({
        ...x,
        label: this.ts.instant(rolesLabels[x.name])
      });
      if (x.name !== 'ADMIN' && x.name !== 'MONITOR-USER') {
        this.rolesToShow.push({
          ...x,
          label: this.ts.instant(rolesLabels[x.name])
        });
      }
    });
  }

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private sitesService: SiteService,
    private toastr: ToastrService,
    private ts: TranslateService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.transferOwnershipGroup = new UntypedFormGroup({
      newOwnerEmail: this.futureOwnerControl,
      role: this.roleControl
    });

    this.futureOwnerControl.setValidators([
      mustBeDiffFromOwner(this.currentOwner),
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
      Validators.required
    ]);

    this.transferTypeControl.valueChanges.subscribe((v) => {
      this.roleControl.clearValidators();
      if (v) {
        this.roleControl.setValidators(Validators.required);
        this.roleControl.updateValueAndValidity();
      }
    });
  }

  close() {
    this.modalRef.hide();
  }

  send() {
    if (checkFormValidity(this.transferOwnershipGroup)) {
      const initialState: Partial<ConfirmModalComponent> = {
        title: this.ts.instant('USER.CONFIRM_TRANSFER'),
        description: this.ts.instant('USER.CONFIRM_TRANSFER_DESC')
      };

      const modalRef = this.modalService.show(ConfirmModalComponent, {
        initialState,
        ignoreBackdropClick: true
      });

      modalRef.content!.confirmAction.subscribe(() => {
        this.isLoading = true;
        const transfer = {
          newOwnerEmail: this.futureOwnerControl.value,
          groupsAuthIdsToGive: this.allRoles
            .filter((x) => x.name === 'ADMIN')
            .map((x) => x.authId),
          groupsAuthIdsToKeep:
            this.transferTypeControl.value === 0 ? [] : [this.roleControl.value]
        };

        this.sitesService
          .transferSiteOwnership(
            this.site.authenticationId!,
            transfer,
            this.authService.getToken()
          )
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            () => {
              modalRef.hide();
              this.modalRef.hide();
              this.transferred.next();
            },
            (error) => {
              if (error.error.error_code === 'FAILED_CONSTRAINT') {
                modalRef.hide();
                this.toastr.clear();
                this.toastr.error(
                  this.ts.instant('DEVICE.HTTP_ERROR_TRANSFER'),
                  this.ts.instant('GLOBAL.ERROR')
                );
              }
            }
          );
      });
    }
  }
}
