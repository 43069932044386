/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Zone command options
 */
export interface SafeZoneCommandOptionsDto {
  /**
   * Action
   */
  action: SafeZoneCommandOptionsDto.ActionEnum;
}
export namespace SafeZoneCommandOptionsDto {
  export type ActionEnum =
    | 'ZONE_EXCLUSION'
    | 'ZONE_INCLUSION'
    | 'ZONE_ISOLATION'
    | 'ZONE_ACTIVATION'
    | 'ZONE_BYPASS'
    | 'ZONE_UNBYPASS';
  export const ActionEnum = {
    EXCLUSION: 'ZONE_EXCLUSION' as ActionEnum,
    INCLUSION: 'ZONE_INCLUSION' as ActionEnum,
    ISOLATION: 'ZONE_ISOLATION' as ActionEnum,
    ACTIVATION: 'ZONE_ACTIVATION' as ActionEnum,
    BYPASS: 'ZONE_BYPASS' as ActionEnum,
    UNBYPASS: 'ZONE_UNBYPASS' as ActionEnum
  };
}
