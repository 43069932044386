/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Resident config data
 */
export interface ResidentDto {
  /**
   * UUID of the resident. It\'s supposed to be unique into the same site.
   */
  ResidentId: string;
  /**
   * The first name of the resident.
   */
  FirstName: string;
  /**
   * The last name of the resident.
   */
  LastName: string;
  /**
   * The middle name of the resident.
   */
  MiddleName?: string;
  /**
   * The home\'s phone number of the resident.
   */
  HomePhoneNumber?: string;
  /**
   * The workplace\'s phone number of the resident. Actually not used.
   */
  WorkPhoneNumber?: string;
  /**
   * The extension of the workplace\'s phone number of the resident. Actually not used.
   */
  WorkPhoneNumberExtension?: string;
  /**
   * The phone number of the resident. Actually not used.
   */
  CellPhone?: string;
  /**
   * The email address of the resident.
   */
  Email?: string;
  /**
   * List of all units (aka VIP address) assigned to resident, separated by comma
   */
  Units?: string;
}
