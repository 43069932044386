/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.7+develop-018
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Details about transfer element ownership to a new user
 */
export interface TransferOwnershipObjectDto {
  /**
   * Destination user identifier (email)
   */
  destinationUserUuid: string;
}
