/**
 * Authentication - OAuth2 API
 * API to deal with grants obtained by the OAuth2 flow
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Grant Code data
 */
export interface OAuth2ThirdPartyIntegration {
  /**
   * Client ID
   */
  readonly clientId?: string;
  /**
   * Client name
   */
  readonly clientName?: string;
  /**
   * Authentication ID related to the target entity
   */
  readonly targetAuthId?: string;
  /**
   * Type of the target entity
   */
  readonly targetType?: OAuth2ThirdPartyIntegration.TargetTypeEnum;
  /**
   * Redirect URI
   */
  readonly redirectionUrl?: string;
  /**
   * \"true\" if the third party is using this integration, otherwise \"false\"
   */
  readonly active?: boolean;
}
export namespace OAuth2ThirdPartyIntegration {
  export type TargetTypeEnum = 'site';
  export const TargetTypeEnum = {
    Site: 'site' as TargetTypeEnum
  };
}
