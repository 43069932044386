/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * SIP line configuration data
 */
export interface SipLineCfgDto {
  /**
   * SIP line ID
   */
  id: number;
  /**
   * SIP line username
   */
  sipUsername?: string;
  /**
   * SIP line password
   */
  sipPassword?: string;
  /**
   * SIP user authentication
   */
  sipUserAuth?: string;
}
