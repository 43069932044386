import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

let ENGLISH_LANG: any;

function translateInit(ts: TranslateService): Promise<void> {
  let lang = getUserLanguage();

  const enPromise = import(`../assets/i18n/${environment.client}/en.json`).then(
    (res) => {
      ENGLISH_LANG = res.default;
      ts.setTranslation('en', process(res.default, 'en', ENGLISH_LANG));
      ts.setDefaultLang('en');
    },
    () => {
      lang = 'en';
    }
  );

  if (lang === 'en') {
    return enPromise.then(() => {
      ts.use('en');
    });
  } else {
    return enPromise.then(() =>
      import(`../assets/i18n/${environment.client}/${lang}.json`).then(
        (res) => {
          ts.setTranslation(lang, process(res.default, lang, ENGLISH_LANG));
          ts.use(lang);
        },
        () => {
          ts.use('en');
        }
      )
    );
  }
}

function process(object: any, lang: string, englishObj: any): any {
  return Object.keys(object)
    .filter((key) =>
      lang === 'en' ? object.hasOwnProperty(key) && object[key] !== '' : key
    )
    .map((key) => {
      if (object[key] === '') {
        return englishObj[key];
      }
      return key;
    })
    .reduce(
      (result: any, key) => (
        (result[key] =
          typeof object[key] === 'object'
            ? process(object[key], lang, englishObj[key])
            : object[key]),
        result
      ),
      {}
    );
}

export function appInit(ts: TranslateService): () => Promise<void> {
  return () => translateInit(ts);
}

export function getUserLanguage() {
  return (
    localStorage.getItem('language') ||
    (navigator.language ? navigator.language.split('-')[0] : 'en')
  );
}
