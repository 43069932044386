/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * PIN command parameters
 */
export interface SafeActionEmailAssignmentDto {
  /**
   * Otp to be used for the operation
   */
  pin: string;
  /**
   * Type of user
   */
  role: SafeActionEmailAssignmentDto.RoleEnum;
}
export namespace SafeActionEmailAssignmentDto {
  export type RoleEnum = 'USER' | 'INSTALLER';
  export const RoleEnum = {
    USER: 'USER' as RoleEnum,
    INSTALLER: 'INSTALLER' as RoleEnum
  };
}
