import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { finalize, toArray } from 'rxjs/operators';
import { LicenseInstanceManagementDeviceService } from 'src/app/api/licenses';
import { DeviceListElementDto, TreeService } from 'src/app/api/tree';
import { AuthService } from 'src/app/core/auth.service';
import { DeviceCompleteDto } from 'src/app/core/models';
import { notInResourceList } from 'src/app/core/utils';

@Component({
  selector: 'app-replace-device-modal',
  templateUrl: './replace-device-modal.component.html',
  styleUrls: ['./replace-device-modal.component.scss']
})
export class ReplaceDeviceModalComponent implements OnInit {
  devices: DeviceListElementDto[];
  device: DeviceCompleteDto;
  isLoading = false;
  deviceFormGroup: UntypedFormGroup;
  deviceCodeControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(32),
    Validators.minLength(32)
  ]);

  deviceReplaced = new EventEmitter<void>();

  constructor(
    private modalRef: BsModalRef,
    private licensesService: LicenseInstanceManagementDeviceService,
    private treeService: TreeService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.deviceCodeControl.setValidators([
      Validators.required,
      Validators.maxLength(32),
      Validators.minLength(32),
      notInResourceList(this.device.resource.deviceId32!, this.devices)
    ]);
    this.deviceFormGroup = new UntypedFormGroup({
      deviceId32: this.deviceCodeControl
    });
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    this.isLoading = true;

    const selectedDevice = this.devices.find(
      (x) => x.deviceId32 === this.deviceCodeControl.value
    );

    if (selectedDevice) {
      this.replaceDevice(
        selectedDevice.siteAuthenticationId ===
          this.device.resource.siteAuthenticationId
          ? 1
          : 2,
        selectedDevice
      );
    } else {
      this.replaceDevice(0);
    }

    // this.licensesService
    //   .migrateDeviceLicenseInstance(this.device.resource.uuid!, {
    //     deviceId32: this.deviceCodeControl.value
    //   })
    //   .subscribe(
    //     () => this.replaceDevice(),
    //     (error) => {
    //       this.isLoading = false;
    //     }
    //   );
  }

  replaceDevice(mode: number, selectedDevice?: DeviceListElementDto) {
    const ob1 = this.treeService.unlinkDevice(
      this.device.resource.uuid!,
      this.authService.getToken()
    );

    const ob2 = this.treeService.removeResourceListEntry(
      this.device.resource.authenticationId!,
      this.authService.getToken()
    );

    const ob3 = this.licensesService.migrateDeviceLicenseInstance(
      this.device.resource.uuid!,
      {
        deviceId32: this.deviceCodeControl.value
      }
    );

    let result;
    switch (mode) {
      case 0:
        const deviceOpt: DeviceListElementDto = {
          deviceId32: this.deviceCodeControl.value,
          resourceContext: 'user',
          name: this.device.resource.name,
          siteAuthenticationId: this.device.resource.siteAuthenticationId
        };

        const ob4 = this.treeService.addDeviceToResourceList(
          this.authService.getToken(),
          undefined,
          false,
          deviceOpt
        );

        result = concat(ob4, ob3, ob1, ob2);
        break;
      case 2:
        const deviceOptNew: DeviceListElementDto = {
          deviceId32: selectedDevice!.deviceId32,
          resourceContext: 'user',
          name: selectedDevice!.name,
          siteAuthenticationId: this.device.resource.siteAuthenticationId
        };

        const ob5 = this.treeService.unlinkDevice(
          selectedDevice!.uuid!,
          this.authService.getToken()
        );
        const ob6 = this.treeService.removeResourceListEntry(
          selectedDevice!.authenticationId!,
          this.authService.getToken()
        );
        const ob7 = this.treeService.addDeviceToResourceList(
          this.authService.getToken(),
          undefined,
          false,
          deviceOptNew
        );
        result = concat(ob3, ob1, ob2, ob5, ob6, ob7);
        break;
      case 1:
      default:
        result = concat(ob3, ob1, ob2);
        break;
    }

    result
      .pipe(
        toArray(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(() => {
        this.deviceReplaced.next();
      });
  }

  codeRetrieved(res: string) {
    this.deviceCodeControl.setValue(res);
  }
}
