<div class="container-fluid">
  <div id="header-btns">
    <button
      id="add"
      class="button-secondary big"
      (click)="createDropDOpen = !createDropDOpen"
    >
      <i class="material-icons notranslate notranslate">add</i
      >{{ 'GLOBAL.ADD' | translate }}
    </button>
    <div id="add-menu" *ngIf="createDropDOpen">
      <h3>{{ 'DASHBOARD.ADD_NEW' | translate }}</h3>
      <ul>
        <li id="add-device" (click)="createNewDevice()">
          <i class="material-icons notranslate">settings_input_antenna</i>
          <span>{{ 'DASHBOARD.DEVICE' | translate }}</span>
          <i class="material-icons notranslate arrow-icon"
            >keyboard_arrow_right</i
          >
        </li>
        <li id="add-site" (click)="createNewSite()">
          <i class="material-icons notranslate">business</i>
          <span>{{ 'DASHBOARD.SITE' | translate }}</span>
          <i class="material-icons notranslate arrow-icon"
            >keyboard_arrow_right</i
          >
        </li>
      </ul>
    </div>
  </div>
  <h1>{{ pageName | translate }}</h1>

  <div class="row mt-4 mb-3">
    <div class="col-12 col-sm-12 col-md-9 col-lg-10">
      <div
        id="map-container"
        [class.wtPrivacyDev]="totDevicesPrivacyMode"
        [class.wtAlerts]="totDevicesAlerts"
      >
        <app-sites-map [siteList]="siteList"></app-sites-map>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-3 col-lg-2 counters">
      <div class="counter">
        <i class="custom-badge-md material-icons notranslate swap-horiz"
          >check</i
        >
        <div id="operating-devices" class="counter-value">
          {{ totWorkingDevices }}
        </div>
        <div class="counter-description">
          {{ 'SITE.OPERATING_DEVICES' | translate }}
        </div>
      </div>
      <div class="counter">
        <i class="custom-badge-md material-icons notranslate signal-wifi-off"
          >signal_wifi_off</i
        >
        <div class="counter-value">
          <span id="offline-devices">{{ totOfflineDevices }}</span>
        </div>
        <div class="counter-description">
          {{ 'DASHBOARD.OFFLINE_DEVICES' | translate }}
        </div>
      </div>
      <div class="counter">
        <i class="custom-badge-md material-icons notranslate alarm-severity"
          >warning</i
        >
        <div id="anomalies-reported" class="counter-value">
          {{ totDevicesAnomalies }}
        </div>
        <div class="counter-description">
          {{ 'SITE.DEVICE_WITH_ALERTS' | translate }}
        </div>
      </div>
      <div class="counter">
        <i class="custom-badge-md material-icons notranslate warning"
          >warning</i
        >
        <div id="errors-reported" class="counter-value">
          {{ totDevicesErrors }}
        </div>
        <div class="counter-description">
          {{ 'SITE.DEVICE_WITH_ERRORS' | translate }}
        </div>
      </div>
      <div *ngIf="totDevicesAlerts > 0" class="counter">
        <i class="custom-badge-md material-icons notranslate warning"
          >local_fire_department</i
        >
        <div id="alerts-reported" class="counter-value">
          {{ totDevicesAlerts }}
        </div>
        <div class="counter-description">
          {{ 'SITE.DEVICE_WITH_ALARMS' | translate }}
        </div>
      </div>
      <div *ngIf="totDevicesPrivacyMode" class="counter">
        <i
          class="custom-badge-md material-icons notranslate settings-input-antenna"
          >visibility_off</i
        >
        <div id="privacy-devices" class="counter-value">
          {{ totDevicesPrivacyMode }}
        </div>
        <div class="counter-description">
          {{ 'SITE.PRIVACY_MODE_DEVICES' | translate }}
        </div>
      </div>
    </div>
  </div>

  <app-dashboard-sites
    *ngIf="authService.permissions.building_manager_view && !hideSiteAnomalies"
  ></app-dashboard-sites>
  <app-dashboard-devices *ngIf="!hideDeviceAnomalies"></app-dashboard-devices>

  <app-favourite-sites></app-favourite-sites>
</div>
