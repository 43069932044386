<div class="modal-header">
  <h2>
    {{ 'USER.EDIT_USER_PERMISSIONS' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="userFormGroup" (keyup.enter)="save()">
    <label class="custom-label" for="user-email">{{
      'USER.EMAIL' | translate
    }}</label>
    <input
      id="user-email"
      type="text"
      class="custom-input"
      [placeholder]="'USER.EMAIL' | translate"
      [formControl]="emailControl"
    />
    <label class="custom-label" for="user-role">{{
      'USER.ROLE' | translate
    }}</label>

    <ng-select
      [placeholder]="'USER.ROLE' | translate"
      id="user-role"
      [items]="roles"
      bindLabel="label"
      bindValue="authId"
      [formControl]="roleControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>
    <app-control-errors
      id="user-role-validator"
      controlName="roleAuthId"
    ></app-control-errors>
  </form>
</div>
<div class="modal-footer">
  <div class="mt-5 d-flex justify-content-end">
    <button id="cancel" class="button-secondary big mr-2" (click)="close()">
      {{ 'GLOBAL.CANCEL' | translate }}
    </button>
    <app-loading-button
      id="save"
      buttonClass="button-primary big"
      [isLoading]="isLoading"
      (click)="save()"
      buttonType="button"
    >
      {{ 'GLOBAL.SAVE' | translate }}
    </app-loading-button>
  </div>
</div>
