/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reboot command options
 */
export interface SafeRebootCommandOptionsDto {
  /**
   * Reboot type
   */
  rebootType: SafeRebootCommandOptionsDto.RebootTypeEnum;
}
export namespace SafeRebootCommandOptionsDto {
  export type RebootTypeEnum =
    | 'REBOOT_MAIN_UNIT'
    | 'REBOOT_MAIN_UNIT_RESET_CODES'
    | 'REBOOT_MAIN_UNIT_RESET_ALL';
  export const RebootTypeEnum = {
    UNIT: 'REBOOT_MAIN_UNIT' as RebootTypeEnum,
    UNITRESETCODES: 'REBOOT_MAIN_UNIT_RESET_CODES' as RebootTypeEnum,
    UNITRESETALL: 'REBOOT_MAIN_UNIT_RESET_ALL' as RebootTypeEnum
  };
}
