import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  SipLineCfgDto,
  StatusAndConfigurationService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { SipData, SipLineCfgDtoExpanded } from 'src/app/core/models';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { HiddenPasswordPipe } from '../../hidden-password.pipe';
import { SipService } from '../sip.service';
import { SipLineModalComponent } from './sip-line-modal/sip-line-modal.component';

@Component({
  selector: 'app-sip-phone-lines',
  templateUrl: './sip-phone-lines.component.html',
  styleUrls: ['./sip-phone-lines.component.scss']
})
export class SipPhoneLinesComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  dataSource = new ClientFiltrableDataSource<SipLineCfgDtoExpanded>();
  columns: TableColumn[] = [];
  deviceId: string;
  showLines = false;
  totalLines: SipLineCfgDto[];

  statuses: { [key: number]: string } = {
    0: _('SIP.STATUS.INIZIALISING'),
    1: _('SIP.STATUS.REGISTERING'),
    2: _('SIP.STATUS.STANDING_BY'),
    10: _('SIP.STATUS.INVITING'),
    20: _('SIP.STATUS.ALERTING'),
    50: _('SIP.STATUS.CONNECTED'),
    60: _('SIP.STATUS.PAUSED'),
    80: _('SIP.STATUS.TERMINATING'),
    99: _('SIP.STATUS.SHUTTING_DOWN'),
    404: _('SIP.STATUS.UNDEFINED')
  };

  get isMobile() {
    return isMobile;
  }

  @ViewChild('nullableTpl', { static: true }) nullableTpl: TemplateRef<
    HTMLElement
  >;
  @ViewChild('actionsTpl', { static: true }) actionsTpl: TemplateRef<
    HTMLElement
  >;
  @ViewChild('statusTpl', { static: true }) statusTpl: TemplateRef<HTMLElement>;
  @ViewChild('indexTpl', { static: true }) indexTpl: TemplateRef<HTMLElement>;

  constructor(
    private ts: TranslateService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private passwordPipe: HiddenPasswordPipe,
    private authService: AuthService,
    private statusConfService: StatusAndConfigurationService,
    private sipService: SipService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.data.device.resource.uuid;
    this.columns = [
      {
        prop: 'index',
        name: this.ts.instant('SIP.LINE.ID'),
        cellTemplate: this.indexTpl
      },
      {
        prop: 'sipUsername',
        name: this.ts.instant('SIP.LINE.USERNAME'),
        cellTemplate: this.nullableTpl,
        sortable: false
      },
      {
        prop: 'sipPassword',
        name: this.ts.instant('SIP.LINE.PASSWORD'),
        pipe: this.passwordPipe,
        sortable: false
      },
      {
        prop: 'sipUserAuth',
        name: this.ts.instant('SIP.LINE.USER_ID'),
        cellTemplate: this.nullableTpl,
        sortable: false
      },
      {
        prop: 'status',
        name: this.ts.instant('SIP.LINE.STATUS'),
        cellTemplate: this.statusTpl,
        sortable: false
      },
      {
        name: this.ts.instant('GLOBAL.ACTIONS'),
        cellTemplate: this.actionsTpl,
        sortable: false,
        maxWidth: 150
      }
    ];

    this.sipService.sipData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: SipData | undefined) => {
        if (res) {
          this.showLines = res.addConf.sipCfgMode === 0;
          this.dataSource.limit = 15;
          this.totalLines = res.lines;

          const linesWstatus: SipLineCfgDtoExpanded[] = [];
          res.lines.forEach((element, index) => {
            linesWstatus.push({
              ...element,
              status: this.statuses[+res.conf.linesOpStatus![index]],
              index
            });
          });
          const lines = linesWstatus.filter((x) => x.sipUsername !== '');
          this.dataSource.setItems(lines);
        }
      });
  }

  createLine() {
    const initialState: Partial<SipLineModalComponent> = {
      sipLines: this.totalLines,
      deviceId: this.deviceId
    };

    const modalRef = this.modalService.show(SipLineModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.lineCreated.subscribe(() => {
      this.sipService.getSipData(this.deviceId);
    });
  }

  editLine(row: SipLineCfgDtoExpanded) {
    const initialState: Partial<SipLineModalComponent> = {
      sipLines: this.totalLines,
      deviceId: this.deviceId,
      sipLine: row
    };

    const modalRef = this.modalService.show(SipLineModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.lineCreated.subscribe(() => {
      this.sipService.getSipData(this.deviceId);
    });
  }

  deleteLine(row: SipLineCfgDtoExpanded) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('SIP.SIP_LINE_DELETE'),
      description: this.ts.instant('SIP.SIP_LINE_DELETE_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      const linesToSave = [...this.totalLines];
      linesToSave[row.index].sipUsername = '';
      linesToSave[row.index].sipPassword = '';
      linesToSave[row.index].sipUserAuth = '';

      this.statusConfService
        .setSipLinesCfg(this.deviceId, this.authService.getToken(), {
          sipLines: linesToSave
        })
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('SIP.SIP_LINE_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.sipService.getSipData(this.deviceId);
          modalRef.hide();
        });
    });
  }
}
