<div
  dropdown
  class="filter-container"
  [insideClick]="true"
  #dropdown="bs-dropdown"
>
  <button
    id="filters"
    class="button-secondary small mr-2 mr-sm-3"
    dropdownToggle
    container="body"
  >
    <i class="material-icons">filter_list</i>
    {{ filterText || 'GLOBAL.FILTER' | translate }}
  </button>
  <ul *dropdownMenu class="dropdown-menu" id="filter">
    <li *ngFor="let opt of options">
      <label for="{{ opt.name }}"
        ><input
          id="{{ opt.name }}"
          [type]="onlyOne ? 'radio' : 'checkbox'"
          [checked]="checkIfSelected(opt)"
          (change)="changeOption($event.target.checked, opt)"
        />
        {{ opt.label | translate }}</label
      >
    </li>
    <i class="close-filters material-icons" (click)="dropdown.hide()">close</i>
  </ul>
</div>
<div class="selected-filters">
  <div class="tag" *ngFor="let filter of selectedFilters; let i = index">
    {{ filter.label | translate }}
    <i class="material-icons" (click)="removeSelected(filter)">close</i>
  </div>
</div>
