/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License Duration DTO
 */
export interface LicenseDurationDto {
  /**
   * License duration unit
   */
  licenseDurationUnit: LicenseDurationDto.LicenseDurationUnitEnum;
  /**
   * License duration value
   */
  value: number;
}
export namespace LicenseDurationDto {
  export type LicenseDurationUnitEnum =
    | 'MINUTES'
    | 'HOURS'
    | 'DAYS'
    | 'MONTHS'
    | 'YEARS'
    | 'FIXED_DATE'
    | 'PERPETUAL';
  export const LicenseDurationUnitEnum = {
    MINUTES: 'MINUTES' as LicenseDurationUnitEnum,
    HOURS: 'HOURS' as LicenseDurationUnitEnum,
    DAYS: 'DAYS' as LicenseDurationUnitEnum,
    MONTHS: 'MONTHS' as LicenseDurationUnitEnum,
    YEARS: 'YEARS' as LicenseDurationUnitEnum,
    FIXEDDATE: 'FIXED_DATE' as LicenseDurationUnitEnum,
    PERPETUAL: 'PERPETUAL' as LicenseDurationUnitEnum
  };
}
