/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GenericOperationOutcome {
  out: GenericOperationOutcome.OutEnum;
}
export namespace GenericOperationOutcome {
  export type OutEnum = 'ACCEPTED';
  export const OutEnum = {
    ACCEPTED: 'ACCEPTED' as OutEnum
  };
}
