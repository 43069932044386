<nav>
  <ul id="main-menu-mobile">
    <li id="dashboard" [routerLink]="['/dashboard']" routerLinkActive="active">
      <i class="material-icons notranslate">dashboard</i>
      <span>{{ 'NAVIGATION.DASHBOARD' | translate }}</span>
    </li>
    <li id="sites" [routerLink]="['/sites']" routerLinkActive="active">
      <i class="material-icons notranslate">business</i>
      <span>{{ 'NAVIGATION.SITES' | translate }}</span>
    </li>
    <li id="devices" [routerLink]="['/devices']" routerLinkActive="active">
      <i class="material-icons notranslate">settings_input_antenna</i>
      <span>{{ 'NAVIGATION.DEVICES' | translate }}</span>
    </li>
    <li id="users" [routerLink]="['/users']" routerLinkActive="active">
      <i class="material-icons notranslate">person</i>
      <span>{{ 'NAVIGATION.USERS' | translate }}</span>
    </li>
  </ul>
</nav>
