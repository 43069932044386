/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * App config metadata for a given site
 */
export interface SiteAppConfigMetadataDto {
  /**
   * Site authenticationId to which the data refers to
   */
  readonly siteAuthenticationId?: string;
  /**
   * App config data related to a site
   */
  appConfig?: { [key: string]: object };
}
