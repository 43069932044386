/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.7+develop-018
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Data about user permissions
 */
export interface PermissionDto {
  /**
   * Role name
   */
  roleName: string;
  /**
   * List of actions related to the role
   */
  actions: Array<string>;
}
