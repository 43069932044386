<div class="modal-header">
  <h2>
    {{ 'GLOBAL.CHANGE_PASSWORD' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="passwordFormGroup" (keyup.enter)="save()">
    <label class="custom-label" for="new-password">{{
      'GLOBAL.NEW_PASSWORD' | translate
    }}</label>
    <input
      id="new-password"
      type="text"
      class="custom-input"
      [placeholder]="'GLOBAL.NEW_PASSWORD' | translate"
      [formControl]="newPassword"
    />
    <app-control-errors
      id="new-password-validator"
      controlName="psw"
      [customMessages]="{
        required: 'VALIDATE.PASSWORD_REQUIRED' | translate
      }"
    ></app-control-errors>

    <label class="custom-label" for="confirm-password">{{
      'GLOBAL.CONFIRM_PASSWORD' | translate
    }}</label>
    <input
      id="confirm-password"
      type="text"
      class="custom-input"
      [placeholder]="'GLOBAL.CONFIRM_PASSWORD' | translate"
      [formControl]="confirmPassword"
    />
    <app-control-errors
      id="confirm-password-validator"
      controlName="psw_conf"
      [customMessages]="{
        required: 'VALIDATE.PASSWORD_REQUIRED' | translate,
        mustMatch: 'VALIDATE.PASSWORDS_NOT_EQUAL' | translate
      }"
    ></app-control-errors>
  </form>
</div>
<div class="modal-footer">
  <button class="button-secondary big" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="save()"
    buttonType="button"
    >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
  >
</div>
