/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Invitation mail configuration data
 */
export interface InvitationMailCfgDto {
  /**
   * Enable invitation mail to send periodic invitation email to users who did not activated the app yet
   */
  inviteen?: boolean;
  /**
   * Invitation email language
   */
  invitationLanguage?: InvitationMailCfgDto.InvitationLanguageEnum;
  /**
   * Maximum number of invitation mail to be sent in a single slot
   */
  invitemax?: number;
  /**
   * Frequency for sending invitatoin email, in weeks
   */
  inviteperiod?: number;
  /**
   * Invitation contact email
   */
  invitemail?: string;
  /**
   * Invitation cotnact phone
   */
  invitephone?: string;
  /**
   * Invitation description
   */
  invitedescr?: string;
  /**
   * Invitation custom information
   */
  inviteinfo?: string;
  /**
   * Invite all users
   */
  inviteall?: number;
}
export namespace InvitationMailCfgDto {
  export type InvitationLanguageEnum =
    | 'ENGLISH'
    | 'SPANISH'
    | 'FRENCH'
    | 'ITALIAN'
    | 'DUTCH'
    | 'DEUTSCH'
    | 'PORTUGUESE'
    | 'POLISH'
    | 'RUSSIAN'
    | 'TURKISH';
  export const InvitationLanguageEnum = {
    ENGLISH: 'ENGLISH' as InvitationLanguageEnum,
    SPANISH: 'SPANISH' as InvitationLanguageEnum,
    FRENCH: 'FRENCH' as InvitationLanguageEnum,
    ITALIAN: 'ITALIAN' as InvitationLanguageEnum,
    DUTCH: 'DUTCH' as InvitationLanguageEnum,
    DEUTSCH: 'DEUTSCH' as InvitationLanguageEnum,
    PORTUGUESE: 'PORTUGUESE' as InvitationLanguageEnum,
    POLISH: 'POLISH' as InvitationLanguageEnum,
    RUSSIAN: 'RUSSIAN' as InvitationLanguageEnum,
    TURKISH: 'TURKISH' as InvitationLanguageEnum
  };
}
