import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

interface Breadcrumb {
  url: string;
  data: any;
  params: Params;
  label: string;
  hide: boolean;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[];
  navEnd: Observable<NavigationEnd>;
  subscription: Subscription;
  hide: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.createBreadcrumb();

    this.navEnd = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    this.subscription = this.navEnd.subscribe((e) => this.createBreadcrumb());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createBreadcrumb() {
    this.breadcrumbs = [];
    let currentRoute: ActivatedRoute | undefined = this.activatedRoute.root;
    let url = '';

    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = undefined;

      childrenRoutes.forEach((route) => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          const routeUrl = routeSnapshot.url
            .map((segment) => segment.path)
            .join('/');

          if (routeUrl) {
            url += '/' + routeUrl;
          }

          const routeConfig = routeSnapshot.routeConfig;

          if (routeConfig && routeConfig.data) {
            this.hide = routeConfig.data.hide || false;

            if (routeConfig.data.breadcrumb) {
              this.breadcrumbs.push({
                label: routeConfig.data.breadcrumb,
                data: route.snapshot.data,
                params: route.snapshot.params,
                url,
                hide: this.hide
              });
            }
          }

          currentRoute = route;
        }
      });
    } while (currentRoute);
  }
}
