import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-anomaly-status',
  templateUrl: './anomaly-status.component.html',
  styleUrls: ['./anomaly-status.component.scss']
})
export class AnomalyStatusComponent implements OnInit {
  @Input() severity: string = 'ok';
  @Input() small?: boolean = false;

  constructor(private ts: TranslateService) {}

  ngOnInit(): void {}

  getSeverityLevel() {
    if (this.severity === 'ok') {
      return this.ts.instant('GLOBAL.FUNCTIONING');
    } else if (this.severity === 'evacuation') {
      return this.ts.instant('GLOBAL.ALARM');
    }

    return this.severity === 'warning'
      ? this.ts.instant('GLOBAL.ALERTS')
      : this.ts.instant('GLOBAL.SABOTAGES');
  }

  getSeverityIcon() {
    switch (this.severity) {
      case 'evacuation':
        return 'local_fire_department';
      case 'ok':
        return 'check';
      case 'warning':
      case 'error':
      default:
        return 'warning';
    }
  }
}
