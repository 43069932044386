/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License Action QoS DTO
 */
export interface LicenseActionQoSDto {
  /**
   * Audio QoS
   */
  audio: LicenseActionQoSDto.AudioEnum;
  /**
   * Video QoS
   */
  video: LicenseActionQoSDto.VideoEnum;
  /**
   * Realtime enabled
   */
  realtime: boolean;
}
export namespace LicenseActionQoSDto {
  export type AudioEnum = 'SD' | 'HD' | 'SD, HD';
  export const AudioEnum = {
    SD: 'SD' as AudioEnum,
    HD: 'HD' as AudioEnum,
    SDHD: 'SD, HD' as AudioEnum
  };
  export type VideoEnum = 'SD' | 'HD' | 'SD, HD';
  export const VideoEnum = {
    SD: 'SD' as VideoEnum,
    HD: 'HD' as VideoEnum,
    SDHD: 'SD, HD' as VideoEnum
  };
}
