<div
  dropdown
  class="filter-container"
  [insideClick]="true"
  #dropdown="bs-dropdown"
>
  <button
    id="filters"
    class="button-secondary small mr-2 mr-sm-3"
    dropdownToggle
    container="body"
  >
    <i class="material-icons notranslate">date_range</i>
    {{ filterText || 'GLOBAL.DATE' | translate }}
  </button>
  <div
    *dropdownMenu
    class="dropdown-menu"
    id="date-filter"
    [formGroup]="formToFormGroup"
  >
    <input type="date" [formControl]="fromControl" class="form-control" />
    <input type="date" [formControl]="toControl" class="form-control" />
    <button
      [disabled]="!formToFormGroup.valid"
      class="button-secondary"
      (click)="selectRange()"
    >
      {{ 'GLOBAL.FILTER' | translate }}
    </button>
    <i
      class="close-filters material-icons notranslate"
      (click)="dropdown.hide()"
      >close</i
    >
  </div>
</div>

<div *ngIf="selectedRange" class="selected-filters">
  <div class="tag">
    {{ selectedRange[0] | date }} - {{ selectedRange[1] | date }}
    <i class="material-icons notranslate" (click)="removeSelected()">close</i>
  </div>
</div>
