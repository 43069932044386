import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hiddenPassword'
})
export class HiddenPasswordPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    const psw = value.split('').map((v, index) => {
      return index === value.length - 1 ? v : '*';
    });

    return psw.join('');
  }
}
