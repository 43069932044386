<div class="modal-header">
  <h2>
    {{ 'DEVICE.REPLACE_DEVICE' | translate }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="deviceFormGroup">
    <p class="mb-0 mt-3">{{ 'DEVICE.CURRENT_DEVICE' | translate }}</p>
    <b>{{ device.resource.deviceModelDescription }}</b>
    <p class="mb-0 mt-3">{{ 'DEVICE.ID32' | translate }}</p>
    <b>{{ device.resource.deviceId32 }}</b>
    <!-- <p class="mb-0 mt-3">{{ 'DEVICE.BACKUP' | translate }}</p>
    <b>--</b> -->
    <hr />

    <p>{{ 'DEVICE.CREATE_NEW_DEVICE_DESCRIPTION' | translate }}</p>
    <label class="custom-label" for="device-id32">{{
      'DEVICE.ID32_CODE' | translate
    }}</label>
    <div class="row">
      <div class="col-10">
        <input
          id="device-id32"
          type="text"
          class="custom-input"
          [placeholder]="'DEVICE.ID32_CODE' | translate"
          [formControl]="deviceCodeControl"
        />
        <app-control-errors
          id="deviceId32"
          controlName="deviceId32"
          [customMessages]="{
            sameId: 'VALIDATE.SAME_ID32' | translate,
            alreadyLinked: 'VALIDATE.ALREADY_LINKED' | translate
          }"
        ></app-control-errors>
      </div>
      <div class="col-2">
        <app-scanner-button
          (codeRetrieved)="codeRetrieved($event)"
        ></app-scanner-button>
      </div>
    </div>

    <div class="mt-5 mb-3 d-flex justify-content-end">
      <button id="cancel" class="button-secondary big" (click)="close()">
        {{ 'GLOBAL.CANCEL' | translate }}
      </button>
      <app-loading-button
        id="save"
        buttonClass="button-primary big ml-2"
        [isLoading]="isLoading"
        (click)="save()"
        [disabled]="!deviceCodeControl.valid"
        buttonType="button"
        >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
      >
    </div>
  </form>
</div>
