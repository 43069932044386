/**
 * Cloud Message Center API
 * API to interact with cloud message center
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Message read DTO
 */
export interface MessageReadDto {
  /**
   * Message Id
   */
  readonly id?: string;
  /**
   * Message creation date
   */
  readonly date?: string;
  /**
   * Message title
   */
  readonly title?: string;
  /**
   * Message description
   */
  readonly description?: string;
  /**
   * Message is acknowledged
   */
  ack?: boolean;
  /**
   * Message expiration date
   */
  readonly expireDate?: string;
  /**
   * Recipient user id
   */
  readonly destUserAuthId?: string;
  /**
   * Source Device ID
   */
  readonly sourceDeviceId?: string;
  /**
   * Message category
   */
  readonly category?: string;
  /**
   * Message event type
   */
  readonly eventTypeDescr?: string;
  /**
   * Message priority
   */
  readonly priority?: number;
  /**
   * Message parameters
   */
  readonly parameters?: { [key: string]: string };
}
