export * from './crud.service';
import { CrudService } from './crud.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './eventType.service';
import { EventTypeService } from './eventType.service';
export * from './message.service';
import { MessageService } from './message.service';
export * from './v2message.service';
import { V2messageService } from './v2message.service';
export const APIS = [
  CrudService,
  DefaultService,
  EventTypeService,
  MessageService,
  V2messageService
];
