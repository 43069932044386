/**
 * Authentication - OAuth2 API
 * API to deal with grants obtained by the OAuth2 flow
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ErrorDto } from '../model/models';
import { OAuth2GetGrantCodeResponse } from '../model/models';
import { OAuth2ThirdPartyIntegration } from '../model/models';

import { THIRDPART_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class Oauth2Service {
  protected basePath = 'https://api.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(THIRDPART_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Generate a new grant code
   * @param authorization Authorization
   * @param clientId Client ID
   * @param targetAuthId Target authentication ID
   * @param targetType Target type
   * @param roles Role list
   * @param redirectUri Redirect URI
   * @param scopes Scopes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    roles: Array<string>,
    redirectUri?: string,
    scopes?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<OAuth2GetGrantCodeResponse>;
  public getGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    roles: Array<string>,
    redirectUri?: string,
    scopes?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<OAuth2GetGrantCodeResponse>>;
  public getGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    roles: Array<string>,
    redirectUri?: string,
    scopes?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<OAuth2GetGrantCodeResponse>>;
  public getGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    roles: Array<string>,
    redirectUri?: string,
    scopes?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getGrantCode.'
      );
    }
    if (clientId === null || clientId === undefined) {
      throw new Error(
        'Required parameter clientId was null or undefined when calling getGrantCode.'
      );
    }
    if (targetAuthId === null || targetAuthId === undefined) {
      throw new Error(
        'Required parameter targetAuthId was null or undefined when calling getGrantCode.'
      );
    }
    if (targetType === null || targetType === undefined) {
      throw new Error(
        'Required parameter targetType was null or undefined when calling getGrantCode.'
      );
    }
    if (roles === null || roles === undefined) {
      throw new Error(
        'Required parameter roles was null or undefined when calling getGrantCode.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (clientId !== undefined && clientId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>clientId,
        'client_id'
      );
    }
    if (targetAuthId !== undefined && targetAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>targetAuthId,
        'target_auth_id'
      );
    }
    if (targetType !== undefined && targetType !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>targetType,
        'target_type'
      );
    }
    if (roles) {
      roles.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'roles'
        );
      });
    }
    if (redirectUri !== undefined && redirectUri !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>redirectUri,
        'redirectUri'
      );
    }
    if (scopes) {
      scopes.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'scopes'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<OAuth2GetGrantCodeResponse>(
      `${this.configuration.basePath}/o-auth-2/grant-code`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of existing third party integrations
   * @param authorization Authorization
   * @param targetAuthId Target authentication ID
   * @param targetType Target type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getThirdPartyIntegrations(
    authorization: string,
    targetAuthId: string,
    targetType: 'site',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<Array<OAuth2ThirdPartyIntegration>>;
  public getThirdPartyIntegrations(
    authorization: string,
    targetAuthId: string,
    targetType: 'site',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<Array<OAuth2ThirdPartyIntegration>>>;
  public getThirdPartyIntegrations(
    authorization: string,
    targetAuthId: string,
    targetType: 'site',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<Array<OAuth2ThirdPartyIntegration>>>;
  public getThirdPartyIntegrations(
    authorization: string,
    targetAuthId: string,
    targetType: 'site',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getThirdPartyIntegrations.'
      );
    }
    if (targetAuthId === null || targetAuthId === undefined) {
      throw new Error(
        'Required parameter targetAuthId was null or undefined when calling getThirdPartyIntegrations.'
      );
    }
    if (targetType === null || targetType === undefined) {
      throw new Error(
        'Required parameter targetType was null or undefined when calling getThirdPartyIntegrations.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (targetAuthId !== undefined && targetAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>targetAuthId,
        'target_auth_id'
      );
    }
    if (targetType !== undefined && targetType !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>targetType,
        'target_type'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<OAuth2ThirdPartyIntegration>>(
      `${this.configuration.basePath}/o-auth-2/third-party-integration`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Revoke an existing third party integration
   * @param authorization Authorization
   * @param clientId Client ID
   * @param targetAuthId Target authentication ID
   * @param targetType Target type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public revokeGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<ErrorDto>;
  public revokeGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<ErrorDto>>;
  public revokeGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<ErrorDto>>;
  public revokeGrantCode(
    authorization: string,
    clientId: string,
    targetAuthId: string,
    targetType: 'site',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling revokeGrantCode.'
      );
    }
    if (clientId === null || clientId === undefined) {
      throw new Error(
        'Required parameter clientId was null or undefined when calling revokeGrantCode.'
      );
    }
    if (targetAuthId === null || targetAuthId === undefined) {
      throw new Error(
        'Required parameter targetAuthId was null or undefined when calling revokeGrantCode.'
      );
    }
    if (targetType === null || targetType === undefined) {
      throw new Error(
        'Required parameter targetType was null or undefined when calling revokeGrantCode.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (clientId !== undefined && clientId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>clientId,
        'client_id'
      );
    }
    if (targetAuthId !== undefined && targetAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>targetAuthId,
        'target_auth_id'
      );
    }
    if (targetType !== undefined && targetType !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>targetType,
        'target_type'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<ErrorDto>(
      `${this.configuration.basePath}/o-auth-2/third-party-integration`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
