import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthGuard } from './core/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { SettingsComponent } from './settings/settings.component';
import { NotAllowedComponent } from './shared/not-allowed/not-allowed.component';
import { SitesListResolverService } from './shared/sites-list-resolver.service';

const routes: Routes = [
  {
    path: 'not-allowed',
    component: NotAllowedComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: _('NAVIGATION.DASHBOARD')
        },
        resolve: {
          sites: SitesListResolverService
        }
      },
      {
        path: 'sites',
        data: {
          breadcrumb: _('NAVIGATION.SITES'),
          title: _('NAVIGATION.SITES')
        },
        loadChildren: () =>
          import('./sites/sites.module').then((m) => m.SitesModule)
      },
      {
        path: 'devices',
        data: {
          breadcrumb: _('NAVIGATION.DEVICES'),
          title: _('NAVIGATION.DEVICES')
        },
        loadChildren: () =>
          import('./devices/devices.module').then((m) => m.DevicesModule)
      },
      {
        path: 'users',
        data: {
          hide: true,
          breadcrumb: _('NAVIGATION.USERS'),
          title: _('NAVIGATION.USERS')
        },
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: _('NAVIGATION.SETTINGS')
        }
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
