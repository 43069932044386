import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { SiteDto } from 'src/app/api/myportal/model/siteDto';
import { SearchService } from '../search-input/search.service';
import {
  SiteMarker,
  calculator,
  clusterStyles,
  mapStyles
} from './../../core/map-options';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Marker } from '@googlemaps/markerclusterer/dist/marker-utils';
import { Cluster } from '@googlemaps/markerclusterer';
import { isEmpty } from 'src/app/core/utils';

@Component({
  selector: 'app-sites-map',
  templateUrl: './sites-map.component.html',
  styleUrls: ['./sites-map.component.scss']
})
export class SitesMapComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  allSites: SiteMarker[] = [];
  sites: SiteMarker[] = [];
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  firstLoad = true;
  hideMap = false;
  options: google.maps.MapOptions = {
    disableDefaultUI: false,
    controlSize: 20,
    zoomControl: true,
    scaleControl: true,
    rotateControl: true,
    panControl: true,
    styles: mapStyles,
    streetViewControl: false
  };
  mapLatLng: {
    latitude: number | null;
    longitude: number | null;
  } = {
    latitude: null,
    longitude: null
  };

  @Input() set siteList(sites: SiteDto[]) {
    if (sites.length === 0) {
      window.navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.mapLatLng = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
          };
        },
        (error) => {
          this.mapLatLng = {
            latitude: 0,
            longitude: 0
          };
        }
      );
    } else {
      this.mapLatLng = {
        latitude: !isEmpty(sites[0].address.latitude)
          ? sites[0].address.longitude!
          : null,
        longitude: !isEmpty(sites[0].address.longitude)
          ? sites[0].address.longitude!
          : null
      };
    }

    this.sites = [];
    sites.forEach((element) => {
      const marker = {
        position: {
          latitude: !isEmpty(element.address.latitude)
            ? element.address.latitude!
            : 0,
          longitude: !isEmpty(element.address.longitude)
            ? element.address.longitude!
            : 0
        },
        icon: {
          url: `assets/images/icons/${this.getSiteStatus(element)}.svg`,
          scaledSize: {
            width: 50,
            height: 50
          }
        },
        data: element.authenticationId,
        title: this.getSiteStatus(element)
      };

      this.sites.push({
        info: element,
        marker,
        image: `url('${element.imageUrl}')`
      });
    });

    this.allSites = this.sites;
  }
  selectedMarkerInfo: SiteMarker | undefined;
  overlapMarkers: SiteMarker[];

  calculator = calculator;
  clusterStyles = clusterStyles;

  constructor(private searchService: SearchService) {}

  clusterClick(e: any) {
    let sites: SiteMarker[] = [];
    e.getMarkers().forEach((element: any) => {
      sites.push(
        this.allSites.find((x) => x.info.authenticationId === element.data)!
      );
    });

    if (this.checkIfMarkersOverlap(sites || [])) {
      if (this.selectedMarkerInfo) {
        this.infoWindow.close();
        this.selectedMarkerInfo = undefined;
      }
      this.overlapMarkers = sites!;
      this.infoWindow.position = {
        lat: sites[0]!.marker.position.latitude,
        lng: sites[0]!.marker.position.longitude
      };
      this.infoWindow.open();
    }
  }

  checkIfMarkersOverlap(markers: SiteMarker[]) {
    let overlap = true;
    let position = { lat: null, lng: null };

    markers.forEach((mark: any, index: number) => {
      if (overlap) {
        if (index === 0) {
          position = {
            lat: mark.marker.position.latitude,
            lng: mark.marker.position.longitude
          };
        } else {
          overlap =
            position.lat === mark.marker.position.latitude &&
            position.lng === mark.marker.position.longitude;
        }
      }
    });
    return overlap;
  }

  fitBounds() {
    this.firstLoad = false;
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < this.allSites.length; i++) {
      bounds.extend({
        lat: this.allSites[i].marker.position.latitude,
        lng: this.allSites[i].marker.position.longitude
      });
    }
    this.map.fitBounds(bounds);
  }

  authFailure() {
    this.hideMap = true;
  }

  ngOnInit(): void {
    this.searchService.searchValueChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        v = v.toString().toLowerCase();
        const sites = [...this.allSites];
        this.sites = sites.filter(
          (item: SiteMarker) =>
            item.info.name.toLowerCase()?.indexOf(v) !== -1 ||
            item.info.address.city.toLowerCase()?.indexOf(v) !== -1 ||
            item.info.address.addressLine1.toLowerCase()?.indexOf(v) !== -1 ||
            item.info.address.zipCode?.indexOf(v) !== -1 ||
            item.info.address.country.toLowerCase()?.indexOf(v) !== -1
        );
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clickedMarker(marker: MapMarker, info: SiteMarker) {
    this.overlapMarkers = [];
    if (this.infoWindow || this.selectedMarkerInfo) {
      this.infoWindow.close();
    }
    this.selectedMarkerInfo = info;
    this.infoWindow.open(marker);
  }

  getSiteStatus(el: SiteDto): string {
    if (el.deviceNumber === 0) {
      return 'nodev';
    } else {
      if (
        el.deviceWithErrors === 0 &&
        el.deviceWithAlarms === 0 &&
        el.deviceWithAnomalies === 0
      ) {
        return el.offlineDevices === 0 ? 'normal' : 'wifi';
      } else {
        return el.deviceWithAlarms !== 0
          ? 'evacuation'
          : el.deviceWithErrors !== 0
          ? 'danger'
          : 'alarm';
      }
    }
  }
}
