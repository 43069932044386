<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <i
    id="opendoor-edit-{{ rowIndex }}"
    class="material-icons single-menu-icon"
    (click)="edit(row)"
    >edit</i
  >
</ng-template>

<div class="card-table mt-3">
  <div class="card-header">
    <h2>{{ 'ADDRESSBOOK.OPENDOOR' | translate }}</h2>
  </div>
  <div class="card-body">
    <app-datatable
      *ngIf="!isMobile"
      [columns]="columns"
      [dataSource]="dataSource"
      [hidePagination]="true"
    ></app-datatable>
    <app-mobile-table *ngIf="isMobile" [dataSource]="dataSource">
      <div id="mobile-opendoors" class="mobile-table">
        <div
          *ngFor="let item of dataSource.items; let i = index"
          class="mobile-card opendoor"
        >
          <span class="mobile-card-title" id="opendoor-name-{{ i }}">{{
            item.name
          }}</span>
          <span class="mobile-card-actions">
            <ng-container
              *ngTemplateOutlet="
                actionsTpl;
                context: { row: item, rowIndex: i }
              "
            ></ng-container>
          </span>
        </div>
      </div>
    </app-mobile-table>
  </div>
</div>
