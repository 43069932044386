import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DatatableComponent as NgxDatatableComponent,
  SelectionType,
  TableColumn
} from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { AuthService } from 'src/app/core/auth.service';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { UserRole } from '../utils/users';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  @Input() groupRowsBy?: string;
  @Input() hidePagination? = false;
  @Input() disabledLines?: boolean;
  @Input() dataSource: ClientFiltrableDataSource<any>;
  _selectionHandler?: SelectedItemsHandler<any>;
  @Input() set selectionHandler(val: SelectedItemsHandler<any>) {
    this._selectionHandler = val;
    this.parseColumns();
  }
  @Input()
  set columns(val: TableColumn[]) {
    this._columns = val.map((x) => {
      return { ...x, headerClass: 'translate' };
    });
  }
  @Input() selected: any[] = [];
  @Input() selectionType?: SelectionType = undefined;
  @Output() elSelected = new EventEmitter<any>();

  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  @ViewChild('checkHeadTpl', { static: true }) checkHeadTpl: TemplateRef<any>;
  @ViewChild(NgxDatatableComponent, { static: true })
  ngxDatatable: NgxDatatableComponent;
  @Input() messages? = { emptyMessage: this.ts.instant('GLOBAL.NO_DATA') };
  _columns: TableColumn[] = [];
  userRole: UserRole | undefined;

  constructor(
    private ts: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.userRole = this.route.snapshot.data.userRole;

    if (this._selectionHandler) {
      this._selectionHandler.itemsChanged$.subscribe(() => {
        // force ngx-datatable to execute change detection to reflect selection state
        this.selected = [...this.selected];
      });
    }
  }

  getRowClass = (row: any) => {
    if (this._selectionHandler) {
      return {
        active: this._selectionHandler.isSelected(row)
      };
    } else if (this.disabledLines) {
      return {
        disabled:
          (row.devtype === 1 &&
            (this.userRole === 'BUILDINGMANAGER' ||
              this.userRole === 'MAINTAINER' ||
              this.userRole === 'BUILDINGMANAGER-COLLABORATOR')) ||
          (row.devtype === 3 && row.backline !== 0)
      };
    }
    return {};
  };

  private parseColumns() {
    if (this._selectionHandler) {
      this._columns = [
        {
          width: 40,
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizeable: false,
          cellTemplate: this.checkboxTpl,
          headerTemplate: this.checkHeadTpl
        },
        ...this._columns
      ];
    }
  }

  selectAll() {
    this.dataSource.currentItems.forEach((r) => {
      this._selectionHandler?.toggleItemSelected(r, true);
    });
  }
}
