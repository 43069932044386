/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetPropertyDtoIsoCountry31661 {
  value?: SetPropertyDtoIsoCountry31661.ValueEnum;
  unset?: boolean;
}
export namespace SetPropertyDtoIsoCountry31661 {
  export type ValueEnum =
    | 'AF'
    | 'AX'
    | 'AL'
    | 'DZ'
    | 'AS'
    | 'AD'
    | 'AO'
    | 'AI'
    | 'AQ'
    | 'AG'
    | 'AR'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BS'
    | 'BH'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BM'
    | 'BT'
    | 'BO'
    | 'BQ'
    | 'BA'
    | 'BW'
    | 'BV'
    | 'BR'
    | 'IO'
    | 'BN'
    | 'BG'
    | 'BF'
    | 'BI'
    | 'KH'
    | 'CM'
    | 'CA'
    | 'CV'
    | 'KY'
    | 'CF'
    | 'TD'
    | 'CL'
    | 'CN'
    | 'CX'
    | 'CC'
    | 'CO'
    | 'KM'
    | 'CG'
    | 'CD'
    | 'CK'
    | 'CR'
    | 'CI'
    | 'HR'
    | 'CU'
    | 'CW'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DJ'
    | 'DM'
    | 'DO'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'GQ'
    | 'ER'
    | 'EE'
    | 'ET'
    | 'FK'
    | 'FO'
    | 'FJ'
    | 'FI'
    | 'FR'
    | 'GF'
    | 'PF'
    | 'TF'
    | 'GA'
    | 'GM'
    | 'GE'
    | 'DE'
    | 'GH'
    | 'GI'
    | 'GR'
    | 'GL'
    | 'GD'
    | 'GP'
    | 'GU'
    | 'GT'
    | 'GG'
    | 'GN'
    | 'GW'
    | 'GY'
    | 'HT'
    | 'HM'
    | 'VA'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IM'
    | 'IL'
    | 'IT'
    | 'JM'
    | 'JP'
    | 'JE'
    | 'JO'
    | 'KZ'
    | 'KE'
    | 'KI'
    | 'KP'
    | 'KR'
    | 'KW'
    | 'KG'
    | 'LA'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MO'
    | 'MK'
    | 'MG'
    | 'MW'
    | 'MY'
    | 'MV'
    | 'ML'
    | 'MT'
    | 'MH'
    | 'MQ'
    | 'MR'
    | 'MU'
    | 'YT'
    | 'MX'
    | 'FM'
    | 'MD'
    | 'MC'
    | 'MN'
    | 'ME'
    | 'MS'
    | 'MA'
    | 'MZ'
    | 'MM'
    | 'NA'
    | 'NR'
    | 'NP'
    | 'NL'
    | 'NC'
    | 'NZ'
    | 'NI'
    | 'NE'
    | 'NG'
    | 'NU'
    | 'NF'
    | 'MP'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PW'
    | 'PS'
    | 'PA'
    | 'PG'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PN'
    | 'PL'
    | 'PT'
    | 'PR'
    | 'QA'
    | 'RE'
    | 'RO'
    | 'RU'
    | 'RW'
    | 'BL'
    | 'SH'
    | 'KN'
    | 'LC'
    | 'MF'
    | 'PM'
    | 'VC'
    | 'WS'
    | 'SM'
    | 'ST'
    | 'SA'
    | 'SN'
    | 'RS'
    | 'SC'
    | 'SL'
    | 'SG'
    | 'SX'
    | 'SK'
    | 'SI'
    | 'SB'
    | 'SO'
    | 'ZA'
    | 'GS'
    | 'SS'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SR'
    | 'SJ'
    | 'SZ'
    | 'SE'
    | 'CH'
    | 'SY'
    | 'TW'
    | 'TJ'
    | 'TZ'
    | 'TH'
    | 'TL'
    | 'TG'
    | 'TK'
    | 'TO'
    | 'TT'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'TC'
    | 'TV'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UM'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'VG'
    | 'VI'
    | 'WF'
    | 'EH'
    | 'YE'
    | 'ZM'
    | 'ZW';
  export const ValueEnum = {
    AF: 'AF' as ValueEnum,
    AX: 'AX' as ValueEnum,
    AL: 'AL' as ValueEnum,
    DZ: 'DZ' as ValueEnum,
    AS: 'AS' as ValueEnum,
    AD: 'AD' as ValueEnum,
    AO: 'AO' as ValueEnum,
    AI: 'AI' as ValueEnum,
    AQ: 'AQ' as ValueEnum,
    AG: 'AG' as ValueEnum,
    AR: 'AR' as ValueEnum,
    AM: 'AM' as ValueEnum,
    AW: 'AW' as ValueEnum,
    AU: 'AU' as ValueEnum,
    AT: 'AT' as ValueEnum,
    AZ: 'AZ' as ValueEnum,
    BS: 'BS' as ValueEnum,
    BH: 'BH' as ValueEnum,
    BD: 'BD' as ValueEnum,
    BB: 'BB' as ValueEnum,
    BY: 'BY' as ValueEnum,
    BE: 'BE' as ValueEnum,
    BZ: 'BZ' as ValueEnum,
    BJ: 'BJ' as ValueEnum,
    BM: 'BM' as ValueEnum,
    BT: 'BT' as ValueEnum,
    BO: 'BO' as ValueEnum,
    BQ: 'BQ' as ValueEnum,
    BA: 'BA' as ValueEnum,
    BW: 'BW' as ValueEnum,
    BV: 'BV' as ValueEnum,
    BR: 'BR' as ValueEnum,
    IO: 'IO' as ValueEnum,
    BN: 'BN' as ValueEnum,
    BG: 'BG' as ValueEnum,
    BF: 'BF' as ValueEnum,
    BI: 'BI' as ValueEnum,
    KH: 'KH' as ValueEnum,
    CM: 'CM' as ValueEnum,
    CA: 'CA' as ValueEnum,
    CV: 'CV' as ValueEnum,
    KY: 'KY' as ValueEnum,
    CF: 'CF' as ValueEnum,
    TD: 'TD' as ValueEnum,
    CL: 'CL' as ValueEnum,
    CN: 'CN' as ValueEnum,
    CX: 'CX' as ValueEnum,
    CC: 'CC' as ValueEnum,
    CO: 'CO' as ValueEnum,
    KM: 'KM' as ValueEnum,
    CG: 'CG' as ValueEnum,
    CD: 'CD' as ValueEnum,
    CK: 'CK' as ValueEnum,
    CR: 'CR' as ValueEnum,
    CI: 'CI' as ValueEnum,
    HR: 'HR' as ValueEnum,
    CU: 'CU' as ValueEnum,
    CW: 'CW' as ValueEnum,
    CY: 'CY' as ValueEnum,
    CZ: 'CZ' as ValueEnum,
    DK: 'DK' as ValueEnum,
    DJ: 'DJ' as ValueEnum,
    DM: 'DM' as ValueEnum,
    DO: 'DO' as ValueEnum,
    EC: 'EC' as ValueEnum,
    EG: 'EG' as ValueEnum,
    SV: 'SV' as ValueEnum,
    GQ: 'GQ' as ValueEnum,
    ER: 'ER' as ValueEnum,
    EE: 'EE' as ValueEnum,
    ET: 'ET' as ValueEnum,
    FK: 'FK' as ValueEnum,
    FO: 'FO' as ValueEnum,
    FJ: 'FJ' as ValueEnum,
    FI: 'FI' as ValueEnum,
    FR: 'FR' as ValueEnum,
    GF: 'GF' as ValueEnum,
    PF: 'PF' as ValueEnum,
    TF: 'TF' as ValueEnum,
    GA: 'GA' as ValueEnum,
    GM: 'GM' as ValueEnum,
    GE: 'GE' as ValueEnum,
    DE: 'DE' as ValueEnum,
    GH: 'GH' as ValueEnum,
    GI: 'GI' as ValueEnum,
    GR: 'GR' as ValueEnum,
    GL: 'GL' as ValueEnum,
    GD: 'GD' as ValueEnum,
    GP: 'GP' as ValueEnum,
    GU: 'GU' as ValueEnum,
    GT: 'GT' as ValueEnum,
    GG: 'GG' as ValueEnum,
    GN: 'GN' as ValueEnum,
    GW: 'GW' as ValueEnum,
    GY: 'GY' as ValueEnum,
    HT: 'HT' as ValueEnum,
    HM: 'HM' as ValueEnum,
    VA: 'VA' as ValueEnum,
    HN: 'HN' as ValueEnum,
    HK: 'HK' as ValueEnum,
    HU: 'HU' as ValueEnum,
    IS: 'IS' as ValueEnum,
    IN: 'IN' as ValueEnum,
    ID: 'ID' as ValueEnum,
    IR: 'IR' as ValueEnum,
    IQ: 'IQ' as ValueEnum,
    IE: 'IE' as ValueEnum,
    IM: 'IM' as ValueEnum,
    IL: 'IL' as ValueEnum,
    IT: 'IT' as ValueEnum,
    JM: 'JM' as ValueEnum,
    JP: 'JP' as ValueEnum,
    JE: 'JE' as ValueEnum,
    JO: 'JO' as ValueEnum,
    KZ: 'KZ' as ValueEnum,
    KE: 'KE' as ValueEnum,
    KI: 'KI' as ValueEnum,
    KP: 'KP' as ValueEnum,
    KR: 'KR' as ValueEnum,
    KW: 'KW' as ValueEnum,
    KG: 'KG' as ValueEnum,
    LA: 'LA' as ValueEnum,
    LV: 'LV' as ValueEnum,
    LB: 'LB' as ValueEnum,
    LS: 'LS' as ValueEnum,
    LR: 'LR' as ValueEnum,
    LY: 'LY' as ValueEnum,
    LI: 'LI' as ValueEnum,
    LT: 'LT' as ValueEnum,
    LU: 'LU' as ValueEnum,
    MO: 'MO' as ValueEnum,
    MK: 'MK' as ValueEnum,
    MG: 'MG' as ValueEnum,
    MW: 'MW' as ValueEnum,
    MY: 'MY' as ValueEnum,
    MV: 'MV' as ValueEnum,
    ML: 'ML' as ValueEnum,
    MT: 'MT' as ValueEnum,
    MH: 'MH' as ValueEnum,
    MQ: 'MQ' as ValueEnum,
    MR: 'MR' as ValueEnum,
    MU: 'MU' as ValueEnum,
    YT: 'YT' as ValueEnum,
    MX: 'MX' as ValueEnum,
    FM: 'FM' as ValueEnum,
    MD: 'MD' as ValueEnum,
    MC: 'MC' as ValueEnum,
    MN: 'MN' as ValueEnum,
    ME: 'ME' as ValueEnum,
    MS: 'MS' as ValueEnum,
    MA: 'MA' as ValueEnum,
    MZ: 'MZ' as ValueEnum,
    MM: 'MM' as ValueEnum,
    NA: 'NA' as ValueEnum,
    NR: 'NR' as ValueEnum,
    NP: 'NP' as ValueEnum,
    NL: 'NL' as ValueEnum,
    NC: 'NC' as ValueEnum,
    NZ: 'NZ' as ValueEnum,
    NI: 'NI' as ValueEnum,
    NE: 'NE' as ValueEnum,
    NG: 'NG' as ValueEnum,
    NU: 'NU' as ValueEnum,
    NF: 'NF' as ValueEnum,
    MP: 'MP' as ValueEnum,
    NO: 'NO' as ValueEnum,
    OM: 'OM' as ValueEnum,
    PK: 'PK' as ValueEnum,
    PW: 'PW' as ValueEnum,
    PS: 'PS' as ValueEnum,
    PA: 'PA' as ValueEnum,
    PG: 'PG' as ValueEnum,
    PY: 'PY' as ValueEnum,
    PE: 'PE' as ValueEnum,
    PH: 'PH' as ValueEnum,
    PN: 'PN' as ValueEnum,
    PL: 'PL' as ValueEnum,
    PT: 'PT' as ValueEnum,
    PR: 'PR' as ValueEnum,
    QA: 'QA' as ValueEnum,
    RE: 'RE' as ValueEnum,
    RO: 'RO' as ValueEnum,
    RU: 'RU' as ValueEnum,
    RW: 'RW' as ValueEnum,
    BL: 'BL' as ValueEnum,
    SH: 'SH' as ValueEnum,
    KN: 'KN' as ValueEnum,
    LC: 'LC' as ValueEnum,
    MF: 'MF' as ValueEnum,
    PM: 'PM' as ValueEnum,
    VC: 'VC' as ValueEnum,
    WS: 'WS' as ValueEnum,
    SM: 'SM' as ValueEnum,
    ST: 'ST' as ValueEnum,
    SA: 'SA' as ValueEnum,
    SN: 'SN' as ValueEnum,
    RS: 'RS' as ValueEnum,
    SC: 'SC' as ValueEnum,
    SL: 'SL' as ValueEnum,
    SG: 'SG' as ValueEnum,
    SX: 'SX' as ValueEnum,
    SK: 'SK' as ValueEnum,
    SI: 'SI' as ValueEnum,
    SB: 'SB' as ValueEnum,
    SO: 'SO' as ValueEnum,
    ZA: 'ZA' as ValueEnum,
    GS: 'GS' as ValueEnum,
    SS: 'SS' as ValueEnum,
    ES: 'ES' as ValueEnum,
    LK: 'LK' as ValueEnum,
    SD: 'SD' as ValueEnum,
    SR: 'SR' as ValueEnum,
    SJ: 'SJ' as ValueEnum,
    SZ: 'SZ' as ValueEnum,
    SE: 'SE' as ValueEnum,
    CH: 'CH' as ValueEnum,
    SY: 'SY' as ValueEnum,
    TW: 'TW' as ValueEnum,
    TJ: 'TJ' as ValueEnum,
    TZ: 'TZ' as ValueEnum,
    TH: 'TH' as ValueEnum,
    TL: 'TL' as ValueEnum,
    TG: 'TG' as ValueEnum,
    TK: 'TK' as ValueEnum,
    TO: 'TO' as ValueEnum,
    TT: 'TT' as ValueEnum,
    TN: 'TN' as ValueEnum,
    TR: 'TR' as ValueEnum,
    TM: 'TM' as ValueEnum,
    TC: 'TC' as ValueEnum,
    TV: 'TV' as ValueEnum,
    UG: 'UG' as ValueEnum,
    UA: 'UA' as ValueEnum,
    AE: 'AE' as ValueEnum,
    GB: 'GB' as ValueEnum,
    US: 'US' as ValueEnum,
    UM: 'UM' as ValueEnum,
    UY: 'UY' as ValueEnum,
    UZ: 'UZ' as ValueEnum,
    VU: 'VU' as ValueEnum,
    VE: 'VE' as ValueEnum,
    VN: 'VN' as ValueEnum,
    VG: 'VG' as ValueEnum,
    VI: 'VI' as ValueEnum,
    WF: 'WF' as ValueEnum,
    EH: 'EH' as ValueEnum,
    YE: 'YE' as ValueEnum,
    ZM: 'ZM' as ValueEnum,
    ZW: 'ZW' as ValueEnum
  };
}
