<div class="modal-header">
  <h2>
    {{ 'TRANSFER.TRANSFER_OWNERSHIP' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="transferOwnershipGroup" (keyup.enter)="send()">
    <label class="custom-label" for="current-site-name">{{
      'SITE.SITE' | translate
    }}</label>
    <input
      id="current-site-name"
      type="text"
      class="custom-input"
      [value]="site.name"
      [disabled]="true"
    />
    <label class="custom-label" for="current-owner">{{
      'TRANSFER.CURRENT_OWNER' | translate
    }}</label>
    <input
      id="current-owner"
      type="text"
      class="custom-input"
      [value]="currentOwner"
      [disabled]="true"
    />

    <label class="custom-label" for="future-owner">{{
      'TRANSFER.FUTURE_OWNER' | translate
    }}</label>
    <input
      id="future-owner"
      type="email"
      class="custom-input"
      [placeholder]="'TRANSFER.FUTURE_OWNER' | translate"
      [formControl]="futureOwnerControl"
    />
    <app-control-errors
      id="future-owner-validator"
      controlName="newOwnerEmail"
      [customMessages]="{
        pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate,
        sameOwner: 'VALIDATE.INSERTED_EMAIL_ALREADY_OWNER' | translate
      }"
    ></app-control-errors>
    <small>{{ 'TRANSFER.FUTURE_OWNER_DESC' | translate }}</small>

    <label class="custom-label" for="transfer-type">{{
      'TRANSFER.TRANSFER_TYPE' | translate
    }}</label>
    <ng-select
      id="transfer-type"
      [placeholder]="'TRANSFER.TRANSFER_TYPE' | translate"
      [items]="transferTypes"
      bindLabel="label"
      bindValue="value"
      [formControl]="transferTypeControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>

    <ng-container *ngIf="transferTypeControl.value !== 0">
      <label class="custom-label" for="recipient-role">{{
        'TRANSFER.RECIPIENT_ROLE' | translate
      }}</label>
      <ng-select
        id="recipient-role"
        [placeholder]="'TRANSFER.RECIPIENT_ROLE' | translate"
        [items]="rolesToShow"
        bindLabel="label"
        bindValue="authId"
        [formControl]="roleControl"
        [searchable]="false"
        [clearable]="false"
        [multiple]="false"
      ></ng-select>
      <app-control-errors
        id="recipient-role"
        controlName="role"
      ></app-control-errors>
      <small>{{ 'TRANSFER.RECIPIENT_ROLE_DESC' | translate }}</small>
    </ng-container>
  </form>
</div>

<div class="modal-footer">
  <app-loading-button
    id="save"
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="send()"
    buttonType="button"
    >{{ 'GLOBAL.SEND' | translate }}</app-loading-button
  >
</div>
