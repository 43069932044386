/**
 * License Manager API
 * API to manage licenses
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LicStaRequestBodyDto } from './licStaRequestBodyDto';

export interface RetrieveLicenseResponseBodyDto {
  viplicenses?: LicStaRequestBodyDto;
  sipcfgmode: RetrieveLicenseResponseBodyDto.SipcfgmodeEnum;
}
export namespace RetrieveLicenseResponseBodyDto {
  export type SipcfgmodeEnum = 'AUTO' | 'MANUAL';
  export const SipcfgmodeEnum = {
    AUTO: 'AUTO' as SipcfgmodeEnum,
    MANUAL: 'MANUAL' as SipcfgmodeEnum
  };
}
