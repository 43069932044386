<nav id="mobile-nav">
  <i class="material-icons" (click)="menuOpen = !menuOpen">menu</i>
  <img
    id="logo-mobile"
    [routerLink]="['/']"
    [src]="image"
    [alt]="'GLOBAL.COMELIT_LOGO' | translate"
  />
</nav>
<aside [ngClass]="{ menuOpen: menuOpen }">
  <img
    id="logo"
    [routerLink]="['/']"
    [src]="image"
    [alt]="'GLOBAL.COMELIT_LOGO' | translate"
  />
  <div id="user-box" [ngClass]="{ open: userInfoIsOpen }">
    <div id="user-image" [style.backgroundImage]="userImage"></div>
    <div id="user-name-logged">{{ userName }}</div>
    <i
      class="mi"
      [ngClass]="userInfoIsOpen ? 'mi-expand-less' : 'mi-expand-more'"
    ></i>
    <ul id="user-menu" *ngIf="userInfoIsOpen">
      <li id="user-profile">
        <i class="material-icons">person</i>
        <span>{{ 'NAVIGATION.USER_PROFILE' | translate }}</span>
      </li>
    </ul>
  </div>
  <ul id="main-menu" (click)="menuOpen = false">
    <li id="dashboard" [routerLink]="['/dashboard']" routerLinkActive="active">
      <i class="material-icons">dashboard</i>
      <span>{{ 'NAVIGATION.DASHBOARD' | translate }}</span>
    </li>
    <li id="sites" [routerLink]="['/sites']" routerLinkActive="active">
      <i class="material-icons">business</i>
      <span>{{ 'NAVIGATION.SITES' | translate }}</span>
    </li>
    <li id="devices" [routerLink]="['/devices']" routerLinkActive="active">
      <i class="material-icons">settings_input_antenna</i>
      <span>{{ 'NAVIGATION.DEVICES' | translate }}</span>
    </li>
    <!-- <li id="notifications">
      <i class="material-icons">notifications</i>
      <span>{{ 'NAVIGATION.NOTIFICATION' | translate }}</span>
    </li>
    <li id="maintenance" *ngIf="authService.permissions.installator_view">
      <i class="material-icons">event_note</i>
      <span>{{ 'NAVIGATION.MAINTENANCE' | translate }}</span>
    </li> -->
    <li id="users" [routerLink]="['/users']" routerLinkActive="active">
      <i class="material-icons">person</i>
      <span>{{ 'NAVIGATION.USERS' | translate }}</span>
    </li>
    <li id="settings" [routerLink]="['/settings']" routerLinkActive="active">
      <i class="material-icons">settings</i>
      <span>{{ 'NAVIGATION.SETTINGS' | translate }}</span>
    </li>
    <li id="logout" (click)="authService.logout()">
      <i class="material-icons">exit_to_app</i>
      <span>{{ 'GLOBAL.LOGOUT' | translate }}</span>
    </li>
  </ul>

  <div id="links-box">
    <a
      *ngIf="environment.client === 'comelit'"
      id="guide-link"
      href="javascript:void(0)"
      (click)="openDoc()"
      ><i class="material-icons">help_outline</i>
      {{ 'NAVIGATION.USE_GUIDE' | translate }}</a
    >
    <a
      id="mycomelit-link"
      [href]="
        environment.client === 'comelit'
          ? 'https://pro.comelitgroup.com/'
          : 'https://teletek-electronics.com/'
      "
      target="_blank"
    >
      <img
        id="logo-mobile"
        [routerLink]="['/']"
        [src]="secondaryImage"
        [alt]="
          environment.client === 'comelit'
            ? ('GLOBAL.COMELIT_LOGO' | translate)
            : ('GLOBAL.TELETEK_LOGO' | translate)
        "
      />
      <i class="material-icons">open_in_new</i>
    </a>
  </div>
</aside>
