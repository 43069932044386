import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ApartmentsAndUsersService,
  AppSetupDto,
  BasicDirectoryDataDto,
  DirectoriesService,
  LicensesService,
  StatusAndConfigurationService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import {
  DeviceCompleteDto,
  FreeAptList,
  LicenseOption
} from 'src/app/core/models';
import { checkFormValidity, isSbc } from 'src/app/core/utils';
import { UserRole } from 'src/app/shared/utils/users';
import { ApartmentsService } from '../apartments.service';

@Component({
  selector: 'app-apartment-create-modal',
  templateUrl: './apartment-create-modal.component.html',
  styleUrls: ['./apartment-create-modal.component.scss']
})
export class ApartmentCreateModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() deviceList: DeviceCompleteDto[] = [];
  @Input() apartments: FreeAptList;
  userRole: UserRole;
  forbiddenRoles = [
    'BUILDINGMANAGER-COLLABORATOR',
    'BUILDINGMANAGER',
    'MAINTAINER'
  ];

  isLoading = false;
  adminEnabled = false;
  showMore = false;

  licenseListToUse: LicenseOption[] = [];

  addrBookMap: BasicDirectoryDataDto[];

  aptFormGroup: UntypedFormGroup;
  idControl = new UntypedFormControl('', Validators.required);
  deviceControl = new UntypedFormControl('', Validators.required);
  vipadrControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(8),
    Validators.minLength(8),
    Validators.pattern('[0-9A-Z ]*')
  ]);
  enableControl = new UntypedFormControl(true);
  descrControl = new UntypedFormControl('');
  emailControl = new UntypedFormControl(
    '',
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
  );
  passwordControl = new UntypedFormControl('');
  typeControl = new UntypedFormControl('', Validators.required);
  extabControl = new UntypedFormControl(false);
  extabValControl = new UntypedFormControl(0);
  backupControl = new UntypedFormControl(false);
  backupValControl = new UntypedFormControl('', [
    Validators.maxLength(8),
    Validators.minLength(8),
    Validators.pattern('[0-9A-Z ]*')
  ]);
  cmdControl = new UntypedFormControl(0);
  sbcSelected = false;

  aptCreated = new EventEmitter<void>();

  constructor(
    private modalRef: BsModalRef,
    private directoriesService: DirectoriesService,
    public aptUserService: ApartmentsAndUsersService,
    public authService: AuthService,
    public licenseService: LicensesService,
    private statusService: StatusAndConfigurationService,
    private aptService: ApartmentsService,
    private ts: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.aptFormGroup = new UntypedFormGroup({
      id: this.idControl,
      device: this.deviceControl,
      vipaddr: this.vipadrControl,
      enable: this.enableControl,
      descr: this.descrControl,
      email: this.emailControl,
      password: this.passwordControl,
      lictype: this.typeControl,
      extab: this.extabControl,
      addrbook: this.extabValControl,
      fwdbusy: this.backupControl,
      fwdaddr: this.backupValControl,
      cmdcode: this.cmdControl
    });

    this.deviceControl.valueChanges.subscribe((v) => {
      this.statusService
        .getAppSetup(this.deviceControl.value, this.authService.getToken())
        .subscribe((r: AppSetupDto[]) => {
          this.adminEnabled = r[0].aptadmin || false;
        });
      this.getDirectories(this.deviceControl.value);

      this.vipadrControl.clearValidators();
      this.sbcSelected = isSbc(
        this.deviceList.find(
          (x) => x.resource.uuid === this.deviceControl.value
        )?.resource.deviceModelId
      );
      if (this.sbcSelected) {
        this.vipadrControl.setValidators([
          Validators.min(1),
          Validators.max(255),
          Validators.maxLength(3),
          Validators.minLength(3),
          Validators.pattern('[0-9]*'),
          Validators.required
        ]);
        this.typeControl.setValidators([]);
      } else {
        this.vipadrControl.setValidators([
          Validators.required,
          Validators.maxLength(8),
          Validators.minLength(8),
          Validators.pattern('[0-9A-Z ]*')
        ]);
        this.typeControl.setValidators([Validators.required]);
      }
      this.vipadrControl.updateValueAndValidity();

      this.idControl.setValue(this.apartments[this.deviceControl.value].id);
      this.getLicenses();
    });

    if (this.deviceList.length === 1) {
      this.deviceControl.setValue(this.deviceList[0].resource.uuid);
    }
  }

  getDirectories(uuid: string) {
    this.extabValControl.disable();
    this.directoriesService
      .getDirs(uuid, this.authService.getToken())
      .subscribe((r) => {
        this.addrBookMap = r;
        this.extabValControl.enable();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLicenses() {
    this.typeControl.disable();
    this.aptService.getLicenses(this.deviceControl.value);

    this.aptService.licenseListToUse
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        this.licenseListToUse = v;

        if (this.licenseListToUse[0]) {
          if (this.sbcSelected) {
            this.typeControl.setValue(
              this.licenseListToUse.find((x) => x.element === 'MASTER')?.index
            );
          } else {
            this.typeControl.setValue(this.licenseListToUse[0].index);
          }
        }

        this.typeControl.enable();
      });
  }

  add0Padding(n: UntypedFormControl, width: number) {
    if (n.dirty && n.value !== '') {
      if (
        !isSbc(
          this.deviceList.find(
            (x) => x.resource.uuid === this.deviceControl.value
          )?.resource.deviceModelId
        )
      ) {
        n.setValue(
          n.value.length >= width
            ? n.value
            : new Array(width - n.value.length + 1).join('0') + n.value
        );
      } else {
        n.setValue(
          n.value.length >= 3
            ? n.value
            : new Array(3 - n.value.length + 1).join('0') + n.value
        );
      }
    }
  }

  save() {
    if (checkFormValidity(this.aptFormGroup) && !this.isLoading) {
      this.isLoading = true;
      let aptFormData = { ...this.aptFormGroup.value };

      if (
        isSbc(
          this.deviceList.find(
            (x) => x.resource.uuid === this.deviceControl.value
          )?.resource.deviceModelId
        )
      ) {
        const vipaddr = this.aptFormGroup.controls['vipaddr'];
        aptFormData.vipaddr = 'SBVIP' + vipaddr.value;
        aptFormData.lictype = 2; // If Sbc Device lictype MUST be 2
      }

      delete aptFormData.device;
      this.aptUserService
        .setApartments(this.deviceControl.value, this.authService.getToken(), {
          apartments: [aptFormData]
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.modalRef.hide();
          this.toastr.success(
            this.ts.instant('APARTMENT.APARTMENT_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.aptCreated.next();
        });
    }
  }

  close() {
    this.modalRef.hide();
  }
}
