import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getUserLanguage } from './../app-init';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  excludedUrls = [];
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('Authorization')) {
      return next.handle(request);
    }

    const kcReq = request.clone({
      headers: request.headers
        .append('Authorization', this.auth.getToken())
        .append('Accept-Language', getUserLanguage())
    });
    return next.handle(kcReq);
  }
}
