/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PanelStatusDto } from './panelStatusDto';
import { ConfCapabiltyDto } from './confCapabiltyDto';
import { DeviceAlertDto } from './deviceAlertDto';

/**
 * Details about the device current status
 */
export interface DeviceStatusDto {
  /**
   * Device UUID
   */
  readonly uuid?: string;
  /**
   * Device ID32
   */
  readonly id32?: string;
  /**
   * Product ID
   */
  readonly pid?: string;
  /**
   * Model ID
   */
  readonly modelId?: string;
  /**
   * Model Description
   */
  readonly modelDescription?: string;
  /**
   * Model Group
   */
  readonly modelGroup?: string;
  /**
   * Model System
   */
  readonly system?: string;
  /**
   * Device serial
   */
  readonly serial?: string;
  /**
   * Registration date
   */
  readonly registrationDate?: number;
  /**
   * Current public IPv4 address
   */
  readonly ipv4?: string;
  /**
   * Current public IPv6 address
   */
  readonly ipv6?: string;
  /**
   * Device MAC address
   */
  readonly macaddr?: string;
  /**
   * Device current location (obtained by IP)
   */
  readonly location?: string;
  /**
   * MQTT broket to which the device is currently connected
   */
  readonly brokerId?: string;
  /**
   * Cluster to which the device is currently related to
   */
  readonly clusterId?: string;
  /**
   * Current MQTT client id
   */
  readonly clientId?: string;
  /**
   * Device HW version
   */
  readonly hwVersion?: string;
  /**
   * Bootloader and FW currently installed on the device
   */
  readonly currentVersions?: { [key: string]: string };
  /**
   * Current device status
   */
  readonly status?: DeviceStatusDto.StatusEnum;
  /**
   * Reason related to the current device status
   */
  readonly reason?: DeviceStatusDto.ReasonEnum;
  /**
   * Current device service status
   */
  readonly serviceStatus?: DeviceStatusDto.ServiceStatusEnum;
  /**
   * Current device application status
   */
  readonly applicationStatus?: DeviceStatusDto.ApplicationStatusEnum;
  /**
   * Device uptime in seconds
   */
  readonly uptime?: number;
  /**
   * Device configuration capabilitise
   */
  readonly confCapabilities?: Array<ConfCapabiltyDto>;
  panelStatus?: PanelStatusDto;
  /**
   * Device alerts
   */
  readonly alerts?: Array<DeviceAlertDto>;
  /**
   * Device is in privacy mode, so it will report its status only on locally enabled accounts
   */
  readonly privacySafeModeEnabled?: boolean;
  /**
   * TRUE if the device is in privacy safe mode and the user has no right to access to it
   */
  readonly privacySafeModeActive?: boolean;
  /**
   * Counters reported by the device in the update status
   */
  readonly counters?: { [key: string]: number };
}
export namespace DeviceStatusDto {
  export type StatusEnum = 'ready' | 'offline';
  export const StatusEnum = {
    Ready: 'ready' as StatusEnum,
    Offline: 'offline' as StatusEnum
  };
  export type ReasonEnum =
    | 'powerup'
    | 'reboot'
    | 'fwupdate'
    | 'reconnection'
    | 'connection_lost'
    | 'disconnection'
    | 'restart'
    | 'resume'
    | 'forced'
    | 'configchanged'
    | 'none'
    | 'unkwown'
    | 'stop'
    | 'suspend'
    | 'not_yet_installed';
  export const ReasonEnum = {
    Powerup: 'powerup' as ReasonEnum,
    Reboot: 'reboot' as ReasonEnum,
    Fwupdate: 'fwupdate' as ReasonEnum,
    Reconnection: 'reconnection' as ReasonEnum,
    ConnectionLost: 'connection_lost' as ReasonEnum,
    Disconnection: 'disconnection' as ReasonEnum,
    Restart: 'restart' as ReasonEnum,
    Resume: 'resume' as ReasonEnum,
    Forced: 'forced' as ReasonEnum,
    Configchanged: 'configchanged' as ReasonEnum,
    None: 'none' as ReasonEnum,
    Unkwown: 'unkwown' as ReasonEnum,
    Stop: 'stop' as ReasonEnum,
    Suspend: 'suspend' as ReasonEnum,
    NotYetInstalled: 'not_yet_installed' as ReasonEnum
  };
  export type ServiceStatusEnum = 'error' | 'warning' | 'ok';
  export const ServiceStatusEnum = {
    Error: 'error' as ServiceStatusEnum,
    Warning: 'warning' as ServiceStatusEnum,
    Ok: 'ok' as ServiceStatusEnum
  };
  export type ApplicationStatusEnum =
    | 'standby'
    | 'prealarm'
    | 'alarm'
    | 'evacuation';
  export const ApplicationStatusEnum = {
    Standby: 'standby' as ApplicationStatusEnum,
    Prealarm: 'prealarm' as ApplicationStatusEnum,
    Alarm: 'alarm' as ApplicationStatusEnum,
    Evacuation: 'evacuation' as ApplicationStatusEnum
  };
}
