/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GenerateMaintenanceReportPortalTranslationsDto {
  maintenanceReport?: string;
  maintenanceReportComplete?: string;
  maintenanceReportPartialFrom?: string;
  maintenanceReportPartialTo?: string;
  client?: string;
  test?: string;
  additionalTests?: string;
  whoPerform?: string;
  date?: string;
  place?: string;
  businessName?: string;
  address?: string;
  buildingManager?: string;
  typeOfSystem?: string;
  testType?: string;
  commissioning?: string;
  firstMaintenance?: string;
  semestral?: string;
  nonOrdinary?: string;
  others?: string;
  testNotes?: string;
  nonCompliances?: string;
  nonCompliancesFixed?: string;
  deviceReplaced?: string;
  clientInformed?: string;
  yes?: string;
  no?: string;
  rowTestType?: string;
  rowLocation?: string;
  rowNotes?: string;
  comments?: string;
  fullName?: string;
  jobTitle?: string;
  signature?: string;
  loop?: string;
  add?: string;
  zone?: string;
  zoneName?: string;
  deviceName?: string;
  deviceType?: string;
  tested?: string;
  dust?: string;
  dustLevels?: string;
  reportSignature?: string;
}
