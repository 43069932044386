import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MobileService } from '../../core/mobile.service';
import { isApp, mobileAndTabletCheck } from '../../core/utils';

@Component({
  selector: 'app-scanner-button',
  templateUrl: './scanner-button.component.html',
  styleUrls: ['./scanner-button.component.scss']
})
export class ScannerButtonComponent implements OnInit {
  showScanner = false;
  @Input() isBarcode?: boolean;
  checkMobile = mobileAndTabletCheck;
  @Output() codeRetrieved = new EventEmitter<string>();

  get isApp() {
    return isApp;
  }

  constructor(private mobileService: MobileService) {}

  ngOnInit(): void {
    (window as any).scanComplete = this.getCode.bind(this);
  }

  openScanner() {
    if (this.isApp) {
      this.mobileService.openScan();
    } else {
      if (this.checkMobile()) {
        this.showScanner = true;
      }
    }
  }

  getCode(res: string) {
    this.codeRetrieved.next(res.replace(/\0/g, ''));
    this.showScanner = false;
  }
}
