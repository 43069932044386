/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LicenseDurationDto } from './licenseDurationDto';

/**
 * License DTO
 */
export interface LicenseDto {
  /**
   * License UUID
   */
  readonly uuid?: string;
  /**
   * License type
   */
  licenseType: string;
  /**
   * License mode
   */
  licenseMode: LicenseDto.LicenseModeEnum;
  /**
   * Licensed resource type
   */
  licensedResourceType: string;
  /**
   * Licensed resource
   */
  licensedResource?: string;
  /**
   * License name
   */
  licenseName: string;
  /**
   * Available durations for the license
   */
  availableDurations: Array<LicenseDurationDto>;
  /**
   * Actions constrained by license
   */
  actions?: Array<string>;
}
export namespace LicenseDto {
  export type LicenseModeEnum = 'TIME_BASED, PAY_PER_USE';
  export const LicenseModeEnum = {
    TIMEBASEDPAYPERUSE: 'TIME_BASED, PAY_PER_USE' as LicenseModeEnum
  };
}
