import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BasicDirectoryDataDto, DirectoriesService } from 'src/app/api/mugconf';
import { SiteDto } from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { DeviceCompleteDto } from 'src/app/core/models';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { AddressBookCreateComponent } from './address-book-create/address-book-create.component';

@Component({
  selector: 'app-address-book-conf',
  templateUrl: './address-book-conf.component.html',
  styleUrls: ['./address-book-conf.component.scss']
})
export class AddressBookConfComponent implements OnInit {
  get pageName() {
    return this.route.snapshot.data.title;
  }
  site: SiteDto;
  device: DeviceCompleteDto;

  get deviceId() {
    return this.device.resource.uuid!;
  }
  dirId?: number;

  addrBookList: BasicDirectoryDataDto[] = [];
  addrBookControl = new UntypedFormControl();
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private dirService: DirectoriesService,
    private ts: TranslateService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.site = this.route.snapshot.data.site;
    this.device = this.route.snapshot.data.device;
    this.getDirs();

    this.addrBookControl.valueChanges.subscribe((v) => {
      this.dirId = undefined;
      setTimeout(() => {
        this.dirId = v.dir;
      }, 100);
    });
  }

  getDirs() {
    this.dirService
      .getDirs(this.device.resource.uuid!, this.authService.getToken())
      .subscribe((res: BasicDirectoryDataDto[]) => {
        this.addrBookList = res;
        if (res[0]) {
          this.addrBookControl.setValue(res[0]);
        }
      });
  }

  new() {
    const initialState: Partial<AddressBookCreateComponent> = {
      addrBookList: this.addrBookList,
      device: this.device,
      id: this.calcNextId()
    };

    const modalRef = this.modalService.show(AddressBookCreateComponent, {
      initialState
    });

    modalRef.content!.created.subscribe(() => {
      this.getDirs();
    });
  }

  edit() {
    const initialState: Partial<AddressBookCreateComponent> = {
      addrBookList: this.addrBookList,
      addrBook: this.addrBookControl.value,
      device: this.device
    };

    const modalRef = this.modalService.show(AddressBookCreateComponent, {
      initialState
    });

    modalRef.content!.created.subscribe(() => {
      this.getDirs();
    });
  }

  copy() {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DUPLICATE_ADDRESSBOOK'),
      description: this.ts.instant('ADDRESSBOOK.DUPLICATE_ADDRESSBOOK_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      const duplicatedAddrBook = {
        descr: `${this.addrBookControl.value.descr} 1`,
        enedit: this.addrBookControl.value.enedit,
        dir: this.calcNextId()
      };
      const addrbookListUpdated = this.addrBookList.concat([
        duplicatedAddrBook as any
      ]);
      this.dirService
        .setDirs(this.deviceId, this.authService.getToken(), {
          dirs: addrbookListUpdated
        })
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.ADDRESSBOOK_DUPLICATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getDirs();
          modalRef.hide();
        });
    });
  }

  delete() {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_ADDRESSBOOK'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_ADDRESSBOOK_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.dirService
        .deleteDir(this.deviceId, this.dirId!, this.authService.getToken())
        .subscribe(() => {
          this.getDirs();
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.ADDRESSBOOK_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        });
    });
  }

  calcNextId() {
    let maxIndex = 0;
    this.addrBookList.forEach((x) => {
      if (x.dir > maxIndex) {
        maxIndex = x.dir;
      }
    });

    return this.addrBookList.length === 0 ? 0 : maxIndex + 1;
  }
}
