import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  DirectoriesService,
  OpendoorDirectoryDataDto
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { CreateOpendorModalComponent } from './create-opendor-modal/create-opendor-modal.component';

@Component({
  selector: 'app-opendoor-card',
  templateUrl: './opendoor-card.component.html',
  styleUrls: ['./opendoor-card.component.scss']
})
export class OpendoorCardComponent implements OnInit {
  @Input() deviceId: string;
  @Input() dirId: number;
  columns: TableColumn[] = [];
  opendoors: OpendoorDirectoryDataDto[] = [];
  dataSource = new ClientFiltrableDataSource<OpendoorDirectoryDataDto>();
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;

  get isMobile() {
    return isMobile;
  }

  constructor(
    private ts: TranslateService,
    private toastr: ToastrService,
    private dirService: DirectoriesService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('ADDRESSBOOK.DESCRIPTION')
      },
      {
        prop: 'id',
        cellTemplate: this.actionsTpl,
        maxWidth: 150,
        name: this.ts.instant('GLOBAL.ACTIONS')
      }
    ];

    this.getOpendoor();
  }

  getOpendoor() {
    this.dirService
      .getOpendoors(this.deviceId, this.dirId, this.authService.getToken())
      .subscribe((data: OpendoorDirectoryDataDto[]) => {
        this.dataSource.setItems(data);
        this.opendoors = data;
      });
  }

  edit(row: OpendoorDirectoryDataDto) {
    const initialState: Partial<CreateOpendorModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.OPENDOOR'),
      data: row
    };

    const modalRef = this.modalService.show(CreateOpendorModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: OpendoorDirectoryDataDto) => {
      this.opendoors.splice(
        this.opendoors.findIndex((x) => x.id === row.id),
        1,
        v
      );

      this.dirService
        .setOpendoors(this.deviceId, this.dirId, this.authService.getToken(), {
          opendoors: this.opendoors
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.OPENDOOR_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getOpendoor();
        });
    });
  }

  delete(row: OpendoorDirectoryDataDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_OPENDOOR'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_OPENDOOR_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.opendoors = this.opendoors.splice(
        this.opendoors.findIndex((x) => x.id === row.id),
        1
      );
      this.dirService
        .setOpendoors(this.deviceId, this.dirId, this.authService.getToken(), {
          opendoors: this.opendoors
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.OPENDOOR_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getOpendoor();
        });
    });
  }
}
