/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EventDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Type of event.
   */
  eventId?: EventDto.EventIdEnum;
  /**
   * Event text is a string containing the textual description of the event.
   */
  eventText?: string;
  /**
   * Loop name is a string containing the name set for the loop where event happened.
   */
  loopName?: string;
  /**
   * Loop address is an integer value containing the address of the peripheral which manages the loop itself.
   */
  loopAddress?: number;
  /**
   * Device name is a string containing the name of device that generated the event.
   */
  deviceName?: string;
  /**
   * Device address is an integer value containing the index of device inside its loop.
   */
  deviceAddress?: number;
  /**
   * Zone name is a string containing the zone name to whom device belongs.
   */
  zoneName?: string;
  /**
   * Zone address is an integer value containing the zone index the device belongs to.
   */
  zoneAddress?: number;
  /**
   * Time is a string containing timestamp associated to event.
   */
  time?: string;
  /**
   * Id is an integer representing an univoque identifier of the event in device’s events log.
   */
  uniqueId?: number;
  /**
   * Id is an integer representing an univoque identifier of the user in device’s events log.
   */
  userId?: number;
  /**
   * Email is string representing the email address retrieved by user shortId
   */
  email?: string;
}
export namespace EventDto {
  export type EventIdEnum =
    | 'LOOP_DEVICE_TYPE_ERROR'
    | 'LOOP_DEVICE_FAULT'
    | 'LOOP_DEVICE_DOUBLE_ADDRESS'
    | 'LOOP_DEVICE_DISABLED'
    | 'NEW_LOOP_DEVICE'
    | 'LOOP_DEVICE_EXTERNAL_POWER_SUPPLY_FAULT'
    | 'LOOP_DEVICE_OUTPUT_FAULT'
    | 'LOOP_DEVICE_OUTPUT_ACTIVE'
    | 'LOOP_DEVICE_OUTPUT_TYPE_ERROR'
    | 'LOOP_DEVICE_INPUT_ON'
    | 'LOOP_DEVICE_INPUT_FAULT'
    | 'ZONE_DISABLED'
    | 'ZONE_TEST'
    | 'ZONE_EVACUATE'
    | 'ZONE_ALARM_FIRE'
    | 'ZONE_ALARM_PANIC'
    | 'ZONE_ALARM_GAS'
    | 'ZONE_ALARM_ALARM_FROM_MODULE_INPUT'
    | 'ZONE_ALARM_EVACUATE_FROM_MODULE_INPUT'
    | 'ZONE_ALARM_TEST_FIRE'
    | 'ZONE_IN_MAINTENANCE'
    | 'LOOP_DEVICE_CLEAN_REQUEST_FIRE_SENSOR'
    | 'LOOP_DEVICE_CHAMBER_FAULT'
    | 'LOOP_DEVICE_SIGNAL_BLOCK'
    | 'LOOP_DEVICE_HIGH_SIGNAL'
    | 'LOOP_DEVICE_DRIFT_ALERT'
    | 'LOOP_HI_RESISTANCE'
    | 'LOOP_HI_LEAKAGE'
    | 'PANEL_USER_LOGIN'
    | 'PANEL_USER_LOG_OUT'
    | 'PANEL_INSTALLER_LOGIN'
    | 'PANEL_INSTALLER_LOG_OUT'
    | 'PANEL_REDUNDANT_PROCESSOR_FAULT'
    | 'PANEL_RAM_ERROR'
    | 'PANEL_FLASH_ERROR'
    | 'PANEL_CONTROL_PANEL_RESET'
    | 'PANEL_RESTORE_DEFAULT_SETTINGS'
    | 'PANEL_LOG_CLEAR'
    | 'PANEL_EARTH_FAULT_DISABLED'
    | 'PANEL_BUZZER_DISABLED'
    | 'PANEL_PRE_ALARM'
    | 'PANEL_EVACUATE'
    | 'PANEL_SILENT_ALARM'
    | 'OUTPUT_ACTIVATED'
    | 'NEW_PERIPHERY_DEVICE'
    | 'PERIPHERY_DEVICE_FAULT'
    | 'PERIPHERY_DEVICE_TYPE_ERROR'
    | 'SIRENS_ON'
    | 'SOUNDER_FAULT'
    | 'SOUNDER_DISABLED'
    | 'SOUNDER_OUTPUT_HI_RESISTANCE'
    | 'SOUNDER_OUTPUT_HI_LEAKAGE'
    | 'SOUNDER1_OUTPUT_SHORT'
    | 'SOUNDER2_OUTPUT_SHORT'
    | 'SOUNDER1_OUTPUT_OPEN'
    | 'SOUNDER2_OUTPUT_OPEN'
    | 'FIRE_BRIGADE_ON'
    | 'FIRE_BRIGADE_OUTPUT_FAULT'
    | 'FIRE_BRIGADE_OUTPUT_DISABLED'
    | 'FIRE_BRIGADE_OUTPUT_SHORT'
    | 'FIRE_BRIGADE_OUTPUT_OPEN'
    | 'FIRE_PROTECTION_ON'
    | 'FIRE_PROTECTION_OUTPUT_FAULT'
    | 'FIRE_PROTECTION_OUTPUT_DISABLED'
    | 'FAULT_OUTPUT_FAULT'
    | 'FAULT_OUTPUT_DISABLED'
    | 'FAULT_ROUTING_OUTPUT_SHORT'
    | 'FAULT_ROUTING_OUTPUT_OPEN'
    | 'FAULT_PROTECTION_PANEL_INPUT_DISABLED'
    | 'PERIPHERY_RELAY_OUTPUT_ON'
    | 'PERIPHERY_DEVICE_DISABLED'
    | 'AUX_FAULT_24V'
    | 'FIRE_CONFIRMED_INPUT_ACTIVATED'
    | 'FIRE_CONFIRMED_INPUT_SHORT'
    | 'FIRE_CONFIRMED_INPUT_OPEN'
    | 'FIRE_CONFIRMED_INPUT_DISABLED'
    | 'PROTECTION_ALARM_CONFIRMED_INPUT_ACTIVATED'
    | 'PROTECTION_ALARM_CONFIRMED_INPUT_SHORT'
    | 'PROTECTION_ALARM_CONFIRMED_INPUT_OPEN'
    | 'PROTECTION_ALARM_CONFIRMED_INPUT_DISABLED'
    | 'PROTECTION_ALARM_CONFIRMATION_INPUT_DISABLED'
    | 'PROTECTION_PANEL_FAULT_INPUT_ACTIVATED'
    | 'PROTECTION_PANEL_FAULT_INPUT_SHORT'
    | 'PROTECTION_PANEL_FAULT_INPUT_OPEN'
    | 'PROTECTION_PANEL_FAULT_INPUT_DISABLED'
    | 'PSU_AC_LOSS'
    | 'PSU_BATTERY_LOSS'
    | 'PSU_BATTERY_LOW'
    | 'PSU_BATTERY_HIGH_RESISTANCE'
    | 'PSU_CHARGER_FAULT'
    | 'PSU_EXTERNAL_POWER_SUPPLY_FAULT'
    | 'PSU_EARTH_FAULT_PANEL_EARTH_FAULT'
    | 'PSU_BATTERY_HIGH_RESISTANCE_FAULT_DISABLED'
    | 'LOOP_BREAK'
    | 'LOOP_SHORT'
    | 'LOOP_ZERO_ADDRESS'
    | 'NEW_NETWORK_PANEL'
    | 'NETWORK_PANEL_DOUBLE_IP'
    | 'NETWORK_PANEL_DOUBLE_PANEL_NUMBER'
    | 'NETWORK_PANEL_DOUBLE_IP_NUMBER'
    | 'NETWORK_LOOP_BROKEN'
    | 'NETWORK_PANEL_FAULT'
    | 'MAINTENANCE_ALARM'
    | 'TAMPER_ON'
    | 'DO_CLASS_CHANGE'
    | 'DISABLED_ZONAL_SOUNDERS'
    | 'SOUNDERS_MODE_STROBE_LAMP'
    | 'VOICE_SILENSED'
    | 'MAINTENANCE_OVERDUE'
    | 'MAINTENANCE_IN_2_WEEKS'
    | 'MAINTENANCE_IN_1_WEEK'
    | 'MAINTENANCE_IN_1_DAY'
    | 'ALARM_CONFIRMATION_INPUT_DISABLED'
    | 'POWER_TOTAL_LOSS'
    | 'EXTINGUISH_OUTPUT_SHORT'
    | 'EXTINGUISH_OUTPUT_OPEN';
  export const EventIdEnum = {
    LOOPDEVICETYPEERROR: 'LOOP_DEVICE_TYPE_ERROR' as EventIdEnum,
    LOOPDEVICEFAULT: 'LOOP_DEVICE_FAULT' as EventIdEnum,
    LOOPDEVICEDOUBLEADDRESS: 'LOOP_DEVICE_DOUBLE_ADDRESS' as EventIdEnum,
    LOOPDEVICEDISABLED: 'LOOP_DEVICE_DISABLED' as EventIdEnum,
    NEWLOOPDEVICE: 'NEW_LOOP_DEVICE' as EventIdEnum,
    LOOPDEVICEEXTERNALPOWERSUPPLYFAULT: 'LOOP_DEVICE_EXTERNAL_POWER_SUPPLY_FAULT' as EventIdEnum,
    LOOPDEVICEOUTPUTFAULT: 'LOOP_DEVICE_OUTPUT_FAULT' as EventIdEnum,
    LOOPDEVICEOUTPUTACTIVE: 'LOOP_DEVICE_OUTPUT_ACTIVE' as EventIdEnum,
    LOOPDEVICEOUTPUTTYPEERROR: 'LOOP_DEVICE_OUTPUT_TYPE_ERROR' as EventIdEnum,
    LOOPDEVICEINPUTON: 'LOOP_DEVICE_INPUT_ON' as EventIdEnum,
    LOOPDEVICEINPUTFAULT: 'LOOP_DEVICE_INPUT_FAULT' as EventIdEnum,
    ZONEDISABLED: 'ZONE_DISABLED' as EventIdEnum,
    ZONETEST: 'ZONE_TEST' as EventIdEnum,
    ZONEEVACUATE: 'ZONE_EVACUATE' as EventIdEnum,
    ZONEALARMFIRE: 'ZONE_ALARM_FIRE' as EventIdEnum,
    ZONEALARMPANIC: 'ZONE_ALARM_PANIC' as EventIdEnum,
    ZONEALARMGAS: 'ZONE_ALARM_GAS' as EventIdEnum,
    ZONEALARMALARMFROMMODULEINPUT: 'ZONE_ALARM_ALARM_FROM_MODULE_INPUT' as EventIdEnum,
    ZONEALARMEVACUATEFROMMODULEINPUT: 'ZONE_ALARM_EVACUATE_FROM_MODULE_INPUT' as EventIdEnum,
    ZONEALARMTESTFIRE: 'ZONE_ALARM_TEST_FIRE' as EventIdEnum,
    ZONEINMAINTENANCE: 'ZONE_IN_MAINTENANCE' as EventIdEnum,
    LOOPDEVICECLEANREQUESTFIRESENSOR: 'LOOP_DEVICE_CLEAN_REQUEST_FIRE_SENSOR' as EventIdEnum,
    LOOPDEVICECHAMBERFAULT: 'LOOP_DEVICE_CHAMBER_FAULT' as EventIdEnum,
    LOOPDEVICESIGNALBLOCK: 'LOOP_DEVICE_SIGNAL_BLOCK' as EventIdEnum,
    LOOPDEVICEHIGHSIGNAL: 'LOOP_DEVICE_HIGH_SIGNAL' as EventIdEnum,
    LOOPDEVICEDRIFTALERT: 'LOOP_DEVICE_DRIFT_ALERT' as EventIdEnum,
    LOOPHIRESISTANCE: 'LOOP_HI_RESISTANCE' as EventIdEnum,
    LOOPHILEAKAGE: 'LOOP_HI_LEAKAGE' as EventIdEnum,
    PANELUSERLOGIN: 'PANEL_USER_LOGIN' as EventIdEnum,
    PANELUSERLOGOUT: 'PANEL_USER_LOG_OUT' as EventIdEnum,
    PANELINSTALLERLOGIN: 'PANEL_INSTALLER_LOGIN' as EventIdEnum,
    PANELINSTALLERLOGOUT: 'PANEL_INSTALLER_LOG_OUT' as EventIdEnum,
    PANELREDUNDANTPROCESSORFAULT: 'PANEL_REDUNDANT_PROCESSOR_FAULT' as EventIdEnum,
    PANELRAMERROR: 'PANEL_RAM_ERROR' as EventIdEnum,
    PANELFLASHERROR: 'PANEL_FLASH_ERROR' as EventIdEnum,
    PANELCONTROLPANELRESET: 'PANEL_CONTROL_PANEL_RESET' as EventIdEnum,
    PANELRESTOREDEFAULTSETTINGS: 'PANEL_RESTORE_DEFAULT_SETTINGS' as EventIdEnum,
    PANELLOGCLEAR: 'PANEL_LOG_CLEAR' as EventIdEnum,
    PANELEARTHFAULTDISABLED: 'PANEL_EARTH_FAULT_DISABLED' as EventIdEnum,
    PANELBUZZERDISABLED: 'PANEL_BUZZER_DISABLED' as EventIdEnum,
    PANELPREALARM: 'PANEL_PRE_ALARM' as EventIdEnum,
    PANELEVACUATE: 'PANEL_EVACUATE' as EventIdEnum,
    PANELSILENTALARM: 'PANEL_SILENT_ALARM' as EventIdEnum,
    OUTPUTACTIVATED: 'OUTPUT_ACTIVATED' as EventIdEnum,
    NEWPERIPHERYDEVICE: 'NEW_PERIPHERY_DEVICE' as EventIdEnum,
    PERIPHERYDEVICEFAULT: 'PERIPHERY_DEVICE_FAULT' as EventIdEnum,
    PERIPHERYDEVICETYPEERROR: 'PERIPHERY_DEVICE_TYPE_ERROR' as EventIdEnum,
    SIRENSON: 'SIRENS_ON' as EventIdEnum,
    SOUNDERFAULT: 'SOUNDER_FAULT' as EventIdEnum,
    SOUNDERDISABLED: 'SOUNDER_DISABLED' as EventIdEnum,
    SOUNDEROUTPUTHIRESISTANCE: 'SOUNDER_OUTPUT_HI_RESISTANCE' as EventIdEnum,
    SOUNDEROUTPUTHILEAKAGE: 'SOUNDER_OUTPUT_HI_LEAKAGE' as EventIdEnum,
    SOUNDER1OUTPUTSHORT: 'SOUNDER1_OUTPUT_SHORT' as EventIdEnum,
    SOUNDER2OUTPUTSHORT: 'SOUNDER2_OUTPUT_SHORT' as EventIdEnum,
    SOUNDER1OUTPUTOPEN: 'SOUNDER1_OUTPUT_OPEN' as EventIdEnum,
    SOUNDER2OUTPUTOPEN: 'SOUNDER2_OUTPUT_OPEN' as EventIdEnum,
    FIREBRIGADEON: 'FIRE_BRIGADE_ON' as EventIdEnum,
    FIREBRIGADEOUTPUTFAULT: 'FIRE_BRIGADE_OUTPUT_FAULT' as EventIdEnum,
    FIREBRIGADEOUTPUTDISABLED: 'FIRE_BRIGADE_OUTPUT_DISABLED' as EventIdEnum,
    FIREBRIGADEOUTPUTSHORT: 'FIRE_BRIGADE_OUTPUT_SHORT' as EventIdEnum,
    FIREBRIGADEOUTPUTOPEN: 'FIRE_BRIGADE_OUTPUT_OPEN' as EventIdEnum,
    FIREPROTECTIONON: 'FIRE_PROTECTION_ON' as EventIdEnum,
    FIREPROTECTIONOUTPUTFAULT: 'FIRE_PROTECTION_OUTPUT_FAULT' as EventIdEnum,
    FIREPROTECTIONOUTPUTDISABLED: 'FIRE_PROTECTION_OUTPUT_DISABLED' as EventIdEnum,
    FAULTOUTPUTFAULT: 'FAULT_OUTPUT_FAULT' as EventIdEnum,
    FAULTOUTPUTDISABLED: 'FAULT_OUTPUT_DISABLED' as EventIdEnum,
    FAULTROUTINGOUTPUTSHORT: 'FAULT_ROUTING_OUTPUT_SHORT' as EventIdEnum,
    FAULTROUTINGOUTPUTOPEN: 'FAULT_ROUTING_OUTPUT_OPEN' as EventIdEnum,
    FAULTPROTECTIONPANELINPUTDISABLED: 'FAULT_PROTECTION_PANEL_INPUT_DISABLED' as EventIdEnum,
    PERIPHERYRELAYOUTPUTON: 'PERIPHERY_RELAY_OUTPUT_ON' as EventIdEnum,
    PERIPHERYDEVICEDISABLED: 'PERIPHERY_DEVICE_DISABLED' as EventIdEnum,
    AUXFAULT24V: 'AUX_FAULT_24V' as EventIdEnum,
    FIRECONFIRMEDINPUTACTIVATED: 'FIRE_CONFIRMED_INPUT_ACTIVATED' as EventIdEnum,
    FIRECONFIRMEDINPUTSHORT: 'FIRE_CONFIRMED_INPUT_SHORT' as EventIdEnum,
    FIRECONFIRMEDINPUTOPEN: 'FIRE_CONFIRMED_INPUT_OPEN' as EventIdEnum,
    FIRECONFIRMEDINPUTDISABLED: 'FIRE_CONFIRMED_INPUT_DISABLED' as EventIdEnum,
    PROTECTIONALARMCONFIRMEDINPUTACTIVATED: 'PROTECTION_ALARM_CONFIRMED_INPUT_ACTIVATED' as EventIdEnum,
    PROTECTIONALARMCONFIRMEDINPUTSHORT: 'PROTECTION_ALARM_CONFIRMED_INPUT_SHORT' as EventIdEnum,
    PROTECTIONALARMCONFIRMEDINPUTOPEN: 'PROTECTION_ALARM_CONFIRMED_INPUT_OPEN' as EventIdEnum,
    PROTECTIONALARMCONFIRMEDINPUTDISABLED: 'PROTECTION_ALARM_CONFIRMED_INPUT_DISABLED' as EventIdEnum,
    PROTECTIONALARMCONFIRMATIONINPUTDISABLED: 'PROTECTION_ALARM_CONFIRMATION_INPUT_DISABLED' as EventIdEnum,
    PROTECTIONPANELFAULTINPUTACTIVATED: 'PROTECTION_PANEL_FAULT_INPUT_ACTIVATED' as EventIdEnum,
    PROTECTIONPANELFAULTINPUTSHORT: 'PROTECTION_PANEL_FAULT_INPUT_SHORT' as EventIdEnum,
    PROTECTIONPANELFAULTINPUTOPEN: 'PROTECTION_PANEL_FAULT_INPUT_OPEN' as EventIdEnum,
    PROTECTIONPANELFAULTINPUTDISABLED: 'PROTECTION_PANEL_FAULT_INPUT_DISABLED' as EventIdEnum,
    PSUACLOSS: 'PSU_AC_LOSS' as EventIdEnum,
    PSUBATTERYLOSS: 'PSU_BATTERY_LOSS' as EventIdEnum,
    PSUBATTERYLOW: 'PSU_BATTERY_LOW' as EventIdEnum,
    PSUBATTERYHIGHRESISTANCE: 'PSU_BATTERY_HIGH_RESISTANCE' as EventIdEnum,
    PSUCHARGERFAULT: 'PSU_CHARGER_FAULT' as EventIdEnum,
    PSUEXTERNALPOWERSUPPLYFAULT: 'PSU_EXTERNAL_POWER_SUPPLY_FAULT' as EventIdEnum,
    PSUEARTHFAULTPANELEARTHFAULT: 'PSU_EARTH_FAULT_PANEL_EARTH_FAULT' as EventIdEnum,
    PSUBATTERYHIGHRESISTANCEFAULTDISABLED: 'PSU_BATTERY_HIGH_RESISTANCE_FAULT_DISABLED' as EventIdEnum,
    LOOPBREAK: 'LOOP_BREAK' as EventIdEnum,
    LOOPSHORT: 'LOOP_SHORT' as EventIdEnum,
    LOOPZEROADDRESS: 'LOOP_ZERO_ADDRESS' as EventIdEnum,
    NEWNETWORKPANEL: 'NEW_NETWORK_PANEL' as EventIdEnum,
    NETWORKPANELDOUBLEIP: 'NETWORK_PANEL_DOUBLE_IP' as EventIdEnum,
    NETWORKPANELDOUBLEPANELNUMBER: 'NETWORK_PANEL_DOUBLE_PANEL_NUMBER' as EventIdEnum,
    NETWORKPANELDOUBLEIPNUMBER: 'NETWORK_PANEL_DOUBLE_IP_NUMBER' as EventIdEnum,
    NETWORKLOOPBROKEN: 'NETWORK_LOOP_BROKEN' as EventIdEnum,
    NETWORKPANELFAULT: 'NETWORK_PANEL_FAULT' as EventIdEnum,
    MAINTENANCEALARM: 'MAINTENANCE_ALARM' as EventIdEnum,
    TAMPERON: 'TAMPER_ON' as EventIdEnum,
    DOCLASSCHANGE: 'DO_CLASS_CHANGE' as EventIdEnum,
    DISABLEDZONALSOUNDERS: 'DISABLED_ZONAL_SOUNDERS' as EventIdEnum,
    SOUNDERSMODESTROBELAMP: 'SOUNDERS_MODE_STROBE_LAMP' as EventIdEnum,
    VOICESILENSED: 'VOICE_SILENSED' as EventIdEnum,
    MAINTENANCEOVERDUE: 'MAINTENANCE_OVERDUE' as EventIdEnum,
    MAINTENANCEIN2WEEKS: 'MAINTENANCE_IN_2_WEEKS' as EventIdEnum,
    MAINTENANCEIN1WEEK: 'MAINTENANCE_IN_1_WEEK' as EventIdEnum,
    MAINTENANCEIN1DAY: 'MAINTENANCE_IN_1_DAY' as EventIdEnum,
    ALARMCONFIRMATIONINPUTDISABLED: 'ALARM_CONFIRMATION_INPUT_DISABLED' as EventIdEnum,
    POWERTOTALLOSS: 'POWER_TOTAL_LOSS' as EventIdEnum,
    EXTINGUISHOUTPUTSHORT: 'EXTINGUISH_OUTPUT_SHORT' as EventIdEnum,
    EXTINGUISHOUTPUTOPEN: 'EXTINGUISH_OUTPUT_OPEN' as EventIdEnum
  };
}
