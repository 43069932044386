<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <i
    id="external-edit-{{ rowIndex }}"
    class="material-icons notranslate single-menu-icon"
    (click)="edit(row)"
    >edit</i
  >
</ng-template>

<div class="card-table mt-3">
  <div class="card-header">
    <h2>{{ 'ADDRESSBOOK.INTERNAL_PLACE' | translate }}</h2>
  </div>
  <div class="card-body">
    <app-datatable
      *ngIf="!isMobile"
      [columns]="columns"
      [dataSource]="dataSource"
      [hidePagination]="true"
    ></app-datatable>
  </div>

  <app-mobile-table *ngIf="isMobile" [dataSource]="dataSource">
    <div id="mobile-extplaces" class="mobile-table">
      <div
        *ngFor="let item of dataSource.items; let i = index"
        class="mobile-card extplace"
      >
        <span class="mobile-card-title" id="external-name-{{ i }}">{{
          item.name
        }}</span>
        <span class="mobile-card-actions">
          <ng-container
            *ngTemplateOutlet="actionsTpl; context: { row: item, rowIndex: i }"
          ></ng-container>
        </span>
      </div>
    </div>
  </app-mobile-table>
</div>
