/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ZoneStatusBitMaskDto } from './zoneStatusBitMaskDto';

export interface ZoneDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Name is a string containing the name set for that zone.
   */
  name?: string;
  status?: ZoneStatusBitMaskDto;
  /**
   * Modes of the zone.
   */
  mode?: ZoneDto.ModeEnum;
  /**
   * Number of alarms is an integer value counting how many alarms have been generated for that zone since last alarms reset.
   */
  alarmsNr?: number;
  /**
   * Last alarm date is a string value containing the date and time of the last alarm generated by a device belonging to that zone.
   */
  lastAlarmDate?: string;
  /**
   * Last alarm device is a integer value containing the loop of device that has generated last alarm.
   */
  lastAlarmLoop?: number;
  /**
   * Last alarm device is a integer value containing the index of device that has generated last alarm in devices list.
   */
  lastAlarmDevice?: number;
  /**
   * Loop of device generated the most alarms is a integer value containing the index of the loop that has generated the most alarms.
   */
  loopMostAlarms?: number;
  /**
   * Address of device generated the most alarms is a integer value containing the address of the device that has generated the most alarms, within the loop, 1 to 250.
   */
  addressMostAlarms?: number;
  /**
   * Number of devices is an integer value containing the number of devices associated to that zone.
   */
  devicesNr?: number;
}
export namespace ZoneDto {
  export type ModeEnum = 'NORMAL' | 'TWO_DEVICES' | 'DOUBLE';
  export const ModeEnum = {
    NORMAL: 'NORMAL' as ModeEnum,
    TWODEVICES: 'TWO_DEVICES' as ModeEnum,
    DOUBLE: 'DOUBLE' as ModeEnum
  };
}
