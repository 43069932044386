/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Alarm command options
 */
export interface SafeAlarmCommandOptionsDto {
  /**
   * Alarm type
   */
  alarmType: SafeAlarmCommandOptionsDto.AlarmTypeEnum;
}
export namespace SafeAlarmCommandOptionsDto {
  export type AlarmTypeEnum =
    | 'BURGLAR'
    | 'H24'
    | 'FIRE'
    | 'PANIC'
    | 'ROBBERY'
    | 'FLOODING'
    | 'GAS'
    | 'MEDICAL'
    | 'DURESS';
  export const AlarmTypeEnum = {
    BURGLAR: 'BURGLAR' as AlarmTypeEnum,
    H24: 'H24' as AlarmTypeEnum,
    FIRE: 'FIRE' as AlarmTypeEnum,
    PANIC: 'PANIC' as AlarmTypeEnum,
    ROBBERY: 'ROBBERY' as AlarmTypeEnum,
    FLOODING: 'FLOODING' as AlarmTypeEnum,
    GAS: 'GAS' as AlarmTypeEnum,
    MEDICAL: 'MEDICAL' as AlarmTypeEnum,
    DURESS: 'DURESS' as AlarmTypeEnum
  };
}
