/**
 * Cloud Message Center API
 * API to interact with cloud message center
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Violated constraints reporting DTO
 */
export interface ViolatedConstraintsDto {
  /**
   * Violated field name
   */
  readonly fieldName?: string;
  /**
   * Violation description message
   */
  readonly message?: string;
}
