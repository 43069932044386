/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License Action Constraint DTO
 */
export interface LicenseActionConstraintDto {
  /**
   * Constraint type
   */
  constraintType: LicenseActionConstraintDto.ConstraintTypeEnum;
  /**
   * Constraint value
   */
  constraintValue: number;
  /**
   * Constraint unit
   */
  constraintUnit: LicenseActionConstraintDto.ConstraintUnitEnum;
}
export namespace LicenseActionConstraintDto {
  export type ConstraintTypeEnum = 'CALL_LIMIT';
  export const ConstraintTypeEnum = {
    CALLLIMIT: 'CALL_LIMIT' as ConstraintTypeEnum
  };
  export type ConstraintUnitEnum =
    | 'SECOND'
    | 'MINUTE'
    | 'HOUR'
    | 'DAY'
    | 'WEEK'
    | 'MONTH'
    | 'YEAR'
    | 'SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR';
  export const ConstraintUnitEnum = {
    SECOND: 'SECOND' as ConstraintUnitEnum,
    MINUTE: 'MINUTE' as ConstraintUnitEnum,
    HOUR: 'HOUR' as ConstraintUnitEnum,
    DAY: 'DAY' as ConstraintUnitEnum,
    WEEK: 'WEEK' as ConstraintUnitEnum,
    MONTH: 'MONTH' as ConstraintUnitEnum,
    YEAR: 'YEAR' as ConstraintUnitEnum,
    SECONDMINUTEHOURDAYWEEKMONTHYEAR: 'SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR' as ConstraintUnitEnum
  };
}
