/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Details about the device current status
 */
export interface PanelStatusDto {
  /**
   * Power line fault
   */
  readonly powerLineFault?: boolean;
  /**
   * Battery fault
   */
  readonly batteryFault?: boolean;
  /**
   * Another fault (besides power line and battery)
   */
  readonly generalFaults?: boolean;
  /**
   * A sabotage (or tamper) event is present
   */
  readonly sabotages?: boolean;
  /**
   * An alarm is present
   */
  readonly alarms?: boolean;
}
