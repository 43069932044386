/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Resident id data
 */
export interface ResidentIdDto {
  /**
   * UUID of the resident. It\'s supposed to be unique into the same site.
   */
  ResidentId: string;
}
