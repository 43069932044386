/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Opendoor action related data
 */
export interface OpendoorActionDirectoryDataDto {
  /**
   * Directory identifier (not required if new, otherwise required)
   */
  dir: number;
  /**
   * Subelement identifier (not required if new, otherwise required; present if data refers to subelement, otherwise not present)
   */
  id: number;
  /**
   * Opendoor action name
   */
  name?: OpendoorActionDirectoryDataDto.NameEnum;
  /**
   * Opendoor action VIP address
   */
  addr?: string;
  /**
   * Opendoor action output ID
   */
  outputid?: number;
}
export namespace OpendoorActionDirectoryDataDto {
  export type NameEnum = 'disabled' | 'peer' | 'fixed-addr';
  export const NameEnum = {
    Disabled: 'disabled' as NameEnum,
    Peer: 'peer' as NameEnum,
    FixedAddr: 'fixed-addr' as NameEnum
  };
}
