import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  formControl = new UntypedFormControl('');
  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
      this.searchService.searchValueChanged.next(v);
    });
  }
}
