/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * SIP configuration data
 */
export interface SipCfgDto {
  /**
   * Audio codec priority
   */
  audioCodecPriority?: string;
  /**
   * Line status
   */
  readonly linesOpStatus?: Array<string>;
  /**
   * SIP over TCP
   */
  sipOverTcp?: boolean;
  /**
   * SIP PBX hostname
   */
  sipPbxHostname?: string;
  /**
   * SIP PBX port
   */
  sipPbxPort?: number;
  /**
   * SIP PBX proxy address
   */
  sipPbxProxyAddress?: string;
  /**
   * DTMF sequence to activate relay #1
   */
  v2v2sOpenRelay1DTMF?: string;
  /**
   * DTMF sequence to activate relay #2
   */
  v2v2sOpenRelay2DTMF?: string;
  /**
   * DTMF sequence to activate relay #3
   */
  v2v2sOpenRelay3DTMF?: string;
}
