import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AreaCommandsService } from './api/areaCommands.service';
import { GeneralCommandsService } from './api/generalCommands.service';
import { LogService } from './api/log.service';
import { LoginService } from './api/login.service';
import { LogoutService } from './api/logout.service';
import { OutputCommandsService } from './api/outputCommands.service';
import { PeripheralsService } from './api/peripherals.service';
import { ProsteCommandsService } from './api/prosteCommands.service';
import { RealtimeAnomaliesService } from './api/realtimeAnomalies.service';
import { RealtimeAreasService } from './api/realtimeAreas.service';
import { RealtimeCamerasService } from './api/realtimeCameras.service';
import { RealtimeControlPanelUsersService } from './api/realtimeControlPanelUsers.service';
import { RealtimeGenericsService } from './api/realtimeGenerics.service';
import { RealtimeOutputsService } from './api/realtimeOutputs.service';
import { RealtimeRepeatersService } from './api/realtimeRepeaters.service';
import { RealtimeRfidKeysService } from './api/realtimeRfidKeys.service';
import { RealtimeSabotagesService } from './api/realtimeSabotages.service';
import { RealtimeScenariosService } from './api/realtimeScenarios.service';
import { RealtimeUserAccountsService } from './api/realtimeUserAccounts.service';
import { RealtimeZoneAlarmsService } from './api/realtimeZoneAlarms.service';
import { RealtimeZonesService } from './api/realtimeZones.service';
import { TestCommandService } from './api/testCommand.service';
import { TestPostService } from './api/testPost.service';
import { TestRealtimeService } from './api/testRealtime.service';
import { ZoneCommandsService } from './api/zoneCommands.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: []
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
