/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ActuatorDirectoryDataDto } from '../model/models';
import { ActuatorListDirectoryDataDto } from '../model/models';
import { AddActuatorDirectoryDataDto } from '../model/models';
import { AddActuatorListDirectoryDataDto } from '../model/models';
import { BasicDirectoryDataDto } from '../model/models';
import { BasicDirectoryDataListDto } from '../model/models';
import { CameraDirectoryDataDto } from '../model/models';
import { CameraListDirectoryDataDto } from '../model/models';
import { Ccapi2ErrDto } from '../model/models';
import { DirActionCommandDto } from '../model/models';
import { EntranceDirectoryDataDto } from '../model/models';
import { EntranceListDirectoryDataDto } from '../model/models';
import { IntercomDirectoryDataDto } from '../model/models';
import { IntercomListDirectoryDataDto } from '../model/models';
import { OpendoorActionDirectoryDataDto } from '../model/models';
import { OpendoorActionListDirectoryDataDto } from '../model/models';
import { OpendoorDirectoryDataDto } from '../model/models';
import { OpendoorListDirectoryDataDto } from '../model/models';
import { RtspCameraDirectoryDataDto } from '../model/models';
import { RtspCameraListDirectoryDataDto } from '../model/models';
import { SwitchboardDirectoryDataDto } from '../model/models';
import { SwitchboardListDirectoryDataDto } from '../model/models';

import { MUGCONF_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class DirectoriesService {
  protected basePath = 'https://api.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(MUGCONF_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Delete an actuator
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Actuator ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteActuator.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteActuator.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteActuator.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteActuator.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/actuators/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete an additional actuator
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Additional Actuator ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteAddActuator.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteAddActuator.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteAddActuator.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteAddActuator.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/addactuators/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete a VIP camera
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id VIP Camera ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteCamera.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteCamera.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteCamera.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteCamera.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/cameras/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete a directory
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDir(
    deviceId: string,
    directoryId: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteDir(
    deviceId: string,
    directoryId: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteDir(
    deviceId: string,
    directoryId: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteDir(
    deviceId: string,
    directoryId: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteDir.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteDir.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteDir.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete an entrance
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Entrance ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteEntrance.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteEntrance.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteEntrance.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteEntrance.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/entrances/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete an intercom
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Intercom ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteIntercom.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteIntercom.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteIntercom.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteIntercom.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/intercoms/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete an opendoor
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Opendoor ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteOpendoor.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteOpendoor.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteOpendoor.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteOpendoor.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/opendoors/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete an opendoor action
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Opendoor Action ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteOpendoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteOpendoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteOpendoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteOpendoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteOpendoorAction.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteOpendoorAction.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteOpendoorAction.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteOpendoorAction.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/opendooractions/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete a RTSP camera
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id RTSP Camera ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteRtspCamera.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteRtspCamera.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteRtspCamera.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteRtspCamera.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/rtspcameras/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete a switchboard
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Switchboard ID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSwitchboard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public deleteSwitchboard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public deleteSwitchboard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public deleteSwitchboard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling deleteSwitchboard.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling deleteSwitchboard.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteSwitchboard.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteSwitchboard.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/switchboards/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured actuators
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Actuator ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<ActuatorDirectoryDataDto>>;
  public getActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<ActuatorDirectoryDataDto>>>;
  public getActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<ActuatorDirectoryDataDto>>>;
  public getActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getActuator.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getActuator.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getActuator.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getActuator.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<ActuatorDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/actuators/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured actuators
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<ActuatorDirectoryDataDto>>;
  public getActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<ActuatorDirectoryDataDto>>>;
  public getActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<ActuatorDirectoryDataDto>>>;
  public getActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getActuators.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getActuators.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getActuators.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<ActuatorDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/actuators`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured additional actuators
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Additional Actuator ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<AddActuatorDirectoryDataDto>>;
  public getAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<AddActuatorDirectoryDataDto>>>;
  public getAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<AddActuatorDirectoryDataDto>>>;
  public getAddActuator(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getAddActuator.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getAddActuator.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAddActuator.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getAddActuator.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<AddActuatorDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/addactuators/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured additional actuators
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<AddActuatorDirectoryDataDto>>;
  public getAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<AddActuatorDirectoryDataDto>>>;
  public getAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<AddActuatorDirectoryDataDto>>>;
  public getAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getAddActuators.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getAddActuators.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getAddActuators.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<AddActuatorDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/addactuators`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured VIP cameras
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id VIP camera ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<CameraDirectoryDataDto>>;
  public getCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<CameraDirectoryDataDto>>>;
  public getCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<CameraDirectoryDataDto>>>;
  public getCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getCamera.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getCamera.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getCamera.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getCamera.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CameraDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/cameras/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured VIP cameras
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<CameraDirectoryDataDto>>;
  public getCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<CameraDirectoryDataDto>>>;
  public getCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<CameraDirectoryDataDto>>>;
  public getCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getCameras.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getCameras.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getCameras.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CameraDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/cameras`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured directories
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDirs(
    deviceId: string,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<BasicDirectoryDataDto>>;
  public getDirs(
    deviceId: string,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<BasicDirectoryDataDto>>>;
  public getDirs(
    deviceId: string,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<BasicDirectoryDataDto>>>;
  public getDirs(
    deviceId: string,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getDirs.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getDirs.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<BasicDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured entrances
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Entrance ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<EntranceDirectoryDataDto>>;
  public getEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<EntranceDirectoryDataDto>>>;
  public getEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<EntranceDirectoryDataDto>>>;
  public getEntrance(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getEntrance.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getEntrance.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getEntrance.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getEntrance.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<EntranceDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/entrances/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured entrances
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<EntranceDirectoryDataDto>>;
  public getEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<EntranceDirectoryDataDto>>>;
  public getEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<EntranceDirectoryDataDto>>>;
  public getEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getEntrances.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getEntrances.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getEntrances.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<EntranceDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/entrances`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured intercoms
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Intercom ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<IntercomDirectoryDataDto>>;
  public getIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<IntercomDirectoryDataDto>>>;
  public getIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<IntercomDirectoryDataDto>>>;
  public getIntercom(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getIntercom.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getIntercom.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getIntercom.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getIntercom.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<IntercomDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/intercoms/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured intercoms
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<IntercomDirectoryDataDto>>;
  public getIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<IntercomDirectoryDataDto>>>;
  public getIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<IntercomDirectoryDataDto>>>;
  public getIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getIntercoms.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getIntercoms.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getIntercoms.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<IntercomDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/intercoms`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured opendoor actions
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Opendoor Action ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOpenDoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<OpendoorActionDirectoryDataDto>>;
  public getOpenDoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<OpendoorActionDirectoryDataDto>>>;
  public getOpenDoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<OpendoorActionDirectoryDataDto>>>;
  public getOpenDoorAction(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getOpenDoorAction.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getOpenDoorAction.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getOpenDoorAction.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getOpenDoorAction.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<OpendoorActionDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/opendooractions/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured opendoor actions
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOpenDoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<OpendoorActionDirectoryDataDto>>;
  public getOpenDoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<OpendoorActionDirectoryDataDto>>>;
  public getOpenDoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<OpendoorActionDirectoryDataDto>>>;
  public getOpenDoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getOpenDoorActions.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getOpenDoorActions.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getOpenDoorActions.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<OpendoorActionDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/opendooractions`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured opendoors
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Opendoor ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<OpendoorDirectoryDataDto>>;
  public getOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<OpendoorDirectoryDataDto>>>;
  public getOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<OpendoorDirectoryDataDto>>>;
  public getOpendoor(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getOpendoor.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getOpendoor.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getOpendoor.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getOpendoor.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<OpendoorDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/opendoors/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured opendoors
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<OpendoorDirectoryDataDto>>;
  public getOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<OpendoorDirectoryDataDto>>>;
  public getOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<OpendoorDirectoryDataDto>>>;
  public getOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getOpendoors.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getOpendoors.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getOpendoors.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<OpendoorDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/opendoors`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured RTSP cameras
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id RTSP Camera ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<RtspCameraDirectoryDataDto>>;
  public getRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<RtspCameraDirectoryDataDto>>>;
  public getRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<RtspCameraDirectoryDataDto>>>;
  public getRtspCamera(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getRtspCamera.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getRtspCamera.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getRtspCamera.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getRtspCamera.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<RtspCameraDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/rtspcameras/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured RTSP cameras
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRtspCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<RtspCameraDirectoryDataDto>>;
  public getRtspCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<RtspCameraDirectoryDataDto>>>;
  public getRtspCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<RtspCameraDirectoryDataDto>>>;
  public getRtspCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getRtspCameras.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getRtspCameras.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getRtspCameras.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<RtspCameraDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/rtspcameras`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured switchboards
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param id Switchboard ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSwitchBoard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SwitchboardDirectoryDataDto>>;
  public getSwitchBoard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SwitchboardDirectoryDataDto>>>;
  public getSwitchBoard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SwitchboardDirectoryDataDto>>>;
  public getSwitchBoard(
    deviceId: string,
    directoryId: number,
    id: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getSwitchBoard.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getSwitchBoard.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getSwitchBoard.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getSwitchBoard.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SwitchboardDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(
        String(directoryId)
      )}/switchboards/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve all the configured switchboards
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param limit Maximum number of rows to retrieve
   * @param offset Starting offset
   * @param item List of items (columns) to be retrieved
   * @param row List of rows to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSwitchBoards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SwitchboardDirectoryDataDto>>;
  public getSwitchBoards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SwitchboardDirectoryDataDto>>>;
  public getSwitchBoards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SwitchboardDirectoryDataDto>>>;
  public getSwitchBoards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    limit?: number,
    offset?: number,
    item?: Array<string>,
    row?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getSwitchBoards.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling getSwitchBoards.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getSwitchBoards.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (item) {
      item.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'item'
        );
      });
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SwitchboardDirectoryDataDto>>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/switchboards`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Send a directory action command
   * &lt;b&gt;Throttling&lt;/b&gt;: 1 request(s) per second
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param dirType Directory element type
   * @param entryId Directory element ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendDirActionCmd(
    deviceId: string,
    directoryId: number,
    dirType: 'OPENDOORS' | 'ACTUATORS',
    entryId: number,
    authorization: string,
    body?: DirActionCommandDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public sendDirActionCmd(
    deviceId: string,
    directoryId: number,
    dirType: 'OPENDOORS' | 'ACTUATORS',
    entryId: number,
    authorization: string,
    body?: DirActionCommandDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public sendDirActionCmd(
    deviceId: string,
    directoryId: number,
    dirType: 'OPENDOORS' | 'ACTUATORS',
    entryId: number,
    authorization: string,
    body?: DirActionCommandDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public sendDirActionCmd(
    deviceId: string,
    directoryId: number,
    dirType: 'OPENDOORS' | 'ACTUATORS',
    entryId: number,
    authorization: string,
    body?: DirActionCommandDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling sendDirActionCmd.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling sendDirActionCmd.'
      );
    }
    if (dirType === null || dirType === undefined) {
      throw new Error(
        'Required parameter dirType was null or undefined when calling sendDirActionCmd.'
      );
    }
    if (entryId === null || entryId === undefined) {
      throw new Error(
        'Required parameter entryId was null or undefined when calling sendDirActionCmd.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling sendDirActionCmd.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/${encodeURIComponent(
        String(dirType)
      )}/${encodeURIComponent(String(entryId))}/command`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit actuators
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: ActuatorListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: ActuatorListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: ActuatorListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: ActuatorListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setActuators.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setActuators.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setActuators.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/actuators`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit additional actuators
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: AddActuatorListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: AddActuatorListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: AddActuatorListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setAddActuators(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: AddActuatorListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setAddActuators.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setAddActuators.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setAddActuators.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/addactuators`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit VIP cameras
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: CameraListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: CameraListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: CameraListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setCameras(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: CameraListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setCameras.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setCameras.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setCameras.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/cameras`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add new directories
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setDirs(
    deviceId: string,
    authorization: string,
    body?: BasicDirectoryDataListDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setDirs(
    deviceId: string,
    authorization: string,
    body?: BasicDirectoryDataListDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setDirs(
    deviceId: string,
    authorization: string,
    body?: BasicDirectoryDataListDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setDirs(
    deviceId: string,
    authorization: string,
    body?: BasicDirectoryDataListDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setDirs.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setDirs.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit entrances
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: EntranceListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: EntranceListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: EntranceListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setEntrances(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: EntranceListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setEntrances.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setEntrances.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setEntrances.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/entrances`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit intercoms
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: IntercomListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: IntercomListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: IntercomListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setIntercoms(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: IntercomListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setIntercoms.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setIntercoms.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setIntercoms.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/intercoms`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit opendoor actions
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setOpendoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorActionListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setOpendoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorActionListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setOpendoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorActionListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setOpendoorActions(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorActionListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setOpendoorActions.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setOpendoorActions.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setOpendoorActions.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/opendooractions`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit opendoors
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setOpendoors(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: OpendoorListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setOpendoors.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setOpendoors.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setOpendoors.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/opendoors`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit RTSP cameras
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setRtspCamera(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: RtspCameraListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setRtspCamera(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: RtspCameraListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setRtspCamera(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: RtspCameraListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setRtspCamera(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: RtspCameraListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setRtspCamera.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setRtspCamera.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setRtspCamera.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/rtspcameras`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add/edit switchboards
   * &lt;b&gt;Throttling&lt;/b&gt;: 20 request(s) per minute
   * @param deviceId Device UUID
   * @param directoryId Directory ID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setSwitchboards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: SwitchboardListDirectoryDataDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Ccapi2ErrDto>;
  public setSwitchboards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: SwitchboardListDirectoryDataDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Ccapi2ErrDto>>;
  public setSwitchboards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: SwitchboardListDirectoryDataDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Ccapi2ErrDto>>;
  public setSwitchboards(
    deviceId: string,
    directoryId: number,
    authorization: string,
    body?: SwitchboardListDirectoryDataDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling setSwitchboards.'
      );
    }
    if (directoryId === null || directoryId === undefined) {
      throw new Error(
        'Required parameter directoryId was null or undefined when calling setSwitchboards.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setSwitchboards.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<Ccapi2ErrDto>(
      `${
        this.configuration.basePath
      }/servicerest/mugapi/devices/${encodeURIComponent(
        String(deviceId)
      )}/dirs/${encodeURIComponent(String(directoryId))}/switchboards`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
