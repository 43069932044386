import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalService } from '../core/modal.service';
import { appAndMobileCheck, isApp } from '../core/utils';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  get isApp() {
    return isApp;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    appAndMobileCheck();
  }

  constructor(private router: Router, private modalService: ModalService) {}

  ngOnInit(): void {
    appAndMobileCheck();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.modalService.hideAll();
        window.scrollTo({ top: 0 });
      }
    });
  }
}
