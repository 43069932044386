import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CameraDirectoryDataDto } from 'src/app/api/mugconf';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss']
})
export class CameraModalComponent implements OnInit {
  cameraGroup: UntypedFormGroup;
  camera?: CameraDirectoryDataDto;
  title: string;
  dirId: number;
  id: number;
  created = new EventEmitter<CameraDirectoryDataDto>();
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.cameraGroup = new UntypedFormGroup({
      dir: new UntypedFormControl(this.dirId),
      id: new UntypedFormControl(this.id),
      subaddr: new UntypedFormControl(),
      name: new UntypedFormControl('', Validators.required),
      addr: new UntypedFormControl('', [
        Validators.maxLength(3),
        Validators.minLength(3),
        Validators.pattern('255|(22[0-7])'),
        Validators.required
      ])
    });

    if (this.camera) {
      this.cameraGroup.patchValue(this.camera);
      this.cameraGroup.controls['addr'].setValue(
        this.camera.addr?.split('SBCAM')[1]
      );
    }
  }

  close() {
    this.modalRef.hide();
  }
  save() {
    if (this.cameraGroup.valid) {
      this.created.next({
        ...this.cameraGroup.value,
        addr: `SBCAM${this.cameraGroup.controls['addr'].value}`
      });
    }
  }
}
