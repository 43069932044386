/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Intercom related data
 */
export interface IntercomDirectoryDataDto {
  /**
   * Directory identifier (not required if new, otherwise required)
   */
  dir: number;
  /**
   * Subelement identifier (not required if new, otherwise required; present if data refers to subelement, otherwise not present)
   */
  id: number;
  /**
   * Emergency call enabled
   */
  emcall?: boolean;
  /**
   * Intercom subaddress
   */
  subaddr?: number;
  /**
   * Intercom name
   */
  name?: string;
  /**
   * Intercom VIP address
   */
  addr?: string;
}
