/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Camera details
 */
export interface SafeCameraResponseDto {
  /**
   * Camera row ID
   */
  id: number;
  /**
   * contains the name of the camera (set from database).
   */
  description?: string;
  /**
   * List of areas to which the camera is associated
   */
  assignedAreas?: Array<boolean>;
  /**
   * Contains type of agent associated to that camera.
   */
  agent?: SafeCameraResponseDto.AgentEnum;
  /**
   * Indicates the single user if more than one is connectable to main unit.
   */
  agentId?: number;
}
export namespace SafeCameraResponseDto {
  export type AgentEnum = 'PAL_IP' | 'NOT_RECOGNIZED';
  export const AgentEnum = {
    PALIP: 'PAL_IP' as AgentEnum,
    NOTRECOGNIZED: 'NOT_RECOGNIZED' as AgentEnum
  };
}
