/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SafeZoneAlarmStateDto {
  /**
   * Burglar alarm - On premise at least one zone is generating a burglar alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly burglarAlarm?: boolean;
  /**
   * 24H alarm - On premise at least one zone is generating a 24h alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly alarm24h?: boolean;
  /**
   * Fire - On premise at least one zone is generating a fire alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly fire?: boolean;
  /**
   * Panic - On premise at least one zone is generating a panic alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly panic?: boolean;
  /**
   * Robbery - On premise at least one zone is generating a robbery alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly robbery?: boolean;
  /**
   * Flood - On premise at least one zone is generating a flood alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly flood?: boolean;
  /**
   * Gas - On premise at least one zone is generating a gas alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly gas?: boolean;
  /**
   * Medic - On premise at least one zone is generating a medic alarm (vedo: Y, hub: Y, eclipse: ?)
   */
  readonly medic?: boolean;
  /**
   * Duress (vedo: ?, hub: Y, eclipse: ?)
   */
  readonly duress?: boolean;
}
