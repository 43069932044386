import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
  title: string;
  description: string;
  confirmLabel: string = this.ts.instant('GLOBAL.COMPLETE');
  cancelLabel?: string;
  confirmAction = new EventEmitter<void>();
  cancelAction? = new EventEmitter<void>();

  constructor(public modalRef: BsModalRef, private ts: TranslateService) {}

  ngOnInit(): void {}
}
