<div class="container-fluid">
  <h1>{{ pageName | translate }}</h1>

  <div class="row mt-3">
    <div class="col-12 col-md-8">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-6">
              <label class="custom-label" for="language">{{
                'SETTINGS.VERSION' | translate
              }}</label>
              <b>{{ version }}</b>
              <br />
              <label class="custom-label" for="language">{{
                'SETTINGS.LANGUAGE' | translate
              }}</label>
              <ng-select
                id="language"
                [placeholder]="'SETTINGS.LANGUAGE' | translate"
                [items]="languages"
                bindLabel="name"
                bindValue="value"
                [formControl]="languageControl"
                [searchable]="false"
                [clearable]="false"
              ></ng-select>
              <!-- HIDDEN FOR THE MOMENT -->
              <!-- <label class="custom-label" for="language">{{
                'SETTINGS.ROLE' | translate
              }}</label>
              <ng-select
                id="roles"
                [placeholder]="'SETTINGS.ROLE' | translate"
                [items]="roles"
                bindLabel="name"
                bindValue="value"
                [formControl]="roleControl"
                [searchable]="false"
                [clearable]="false"
              ></ng-select> -->
            </div>
          </div>
          <app-loading-button
            id="save"
            buttonClass="button-primary big mt-3"
            [isLoading]="isLoading"
            (click)="save()"
            buttonType="button"
            >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
          >
        </div>
      </div>
    </div>
  </div>
</div>
