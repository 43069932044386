import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AppconfigService } from './api/appconfig.service';
import { CompaniesService } from './api/companies.service';
import { CompanyService } from './api/company.service';
import { CompanyPermService } from './api/companyPerm.service';
import { CompanyResourceService } from './api/companyResource.service';
import { FirebaseService } from './api/firebase.service';
import { GroupService } from './api/group.service';
import { GroupPermService } from './api/groupPerm.service';
import { GroupsService } from './api/groups.service';
import { SiteService } from './api/site.service';
import { SitesService } from './api/sites.service';
import { UserCompanyService } from './api/userCompany.service';
import { UsermetadataService } from './api/usermetadata.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: []
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
