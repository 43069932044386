import { Pipe, PipeTransform } from '@angular/core';
import { AddressDto } from '../api/myportal/model/addressDto';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  transform(value: AddressDto, args?: any): any {
    return `${value.addressLine1} ${value.zipCode} ${value.city} - ${value.country}`;
  }
}
