import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddActuatorDirectoryDataDto } from 'src/app/api/mugconf';

@Component({
  selector: 'app-comelit-button-modal',
  templateUrl: './comelit-button-modal.component.html',
  styleUrls: ['./comelit-button-modal.component.scss']
})
export class ComelitButtonModalComponent implements OnInit {
  dataGroup: UntypedFormGroup;
  dirId: number;
  data: AddActuatorDirectoryDataDto;
  abilitationControl = new UntypedFormControl(false);
  created = new EventEmitter<AddActuatorDirectoryDataDto>();
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.dataGroup = new UntypedFormGroup({
      dir: new UntypedFormControl(this.dirId),
      id: new UntypedFormControl(0),
      addr: new UntypedFormControl('', [
        Validators.min(1),
        Validators.max(255),
        Validators.maxLength(3),
        Validators.minLength(3),
        Validators.pattern('[0-9]*'),
        Validators.required
      ]),
      outputid: new UntypedFormControl(1),
      moduleid: new UntypedFormControl(1),
      enabled: this.abilitationControl
    });

    if (this.data) {
      this.dataGroup.patchValue(this.data);
      this.dataGroup.controls['addr'].setValue(
        this.data.addr?.split('SBACT')[1]
      );
    }
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    this.created.next({
      ...this.dataGroup.value,
      addr: `SBACT${this.dataGroup.controls['addr'].value}`
    });
  }
}
