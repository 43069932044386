import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, finalize, forkJoin } from 'rxjs';
import { DeviceStatusDto, StatusAndConfigurationService } from '../api/mugconf';
import { DeviceListElementDto, TreeService } from '../api/tree';
import { AuthService } from '../core/auth.service';
import { DeviceCompleteDto } from '../core/models';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

export type DeviceListDto = {
  count: number;
  values: DeviceCompleteDto[];
};

@Injectable({
  providedIn: 'root'
})
export class DevicesListService {
  devices$ = new BehaviorSubject<DeviceCompleteDto[]>([]);
  devicesNEW$ = new Subject<DeviceListDto>();
  isLoadingDevices = false;
  progressRef: NgProgressRef;
  deviceLinkUpdated = new EventEmitter<void>();
  deviceListUpdated = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private ngProgress: NgProgress,
    private treeService: TreeService,
    private statusConfService: StatusAndConfigurationService
  ) {
    this.progressRef = this.ngProgress.ref();
  }

  loadDevicesNEW(
    siteId?: string,
    noStatus?: boolean,
    onlyAnomalies?: boolean,
    top?: number,
    offset?: number,
    sort?: any,
    filter?: string
  ) {
    this.progressRef.start();
    const sortMap: any = {
      name: 'DESCRIPTION',
      deviceModelDescription: 'MODEL',
      status: 'STATUS',
      serviceStatus: 'SERVICE_STATUS',
      currentFWVersion: 'FW_VER',
      siteName: 'SITE',
      ownerUserIdentifier: 'OWNER'
    };

    const obs = forkJoin([
      this.treeService.getUserResourceListCount(
        this.authService.getToken(),
        undefined,
        siteId || undefined,
        filter || undefined,
        !onlyAnomalies,
        ['pro_buildingmanager', 'pro_installer']
      ),
      this.treeService.getUserResourceList(
        this.authService.getToken(),
        undefined,
        siteId || undefined,
        undefined,
        top || undefined,
        offset || undefined,
        sort ? sortMap[sort.prop] : undefined,
        sort ? sort.dir.toUpperCase() : undefined,
        filter || undefined,
        !noStatus,
        !onlyAnomalies,
        ['pro_buildingmanager', 'pro_installer']
      )
    ]);

    obs.subscribe({
      next: ([count, val]) => {
        const devicesUuid = val.map((x) => x.uuid!);
        let devicesComplete: DeviceCompleteDto[] = [];
        if (devicesUuid.length > 0 && !noStatus) {
          this.progressRef.start();
          this.statusConfService
            .getDevicesStatus(this.authService.getToken(), undefined, false, {
              devicesUuid
            })
            .pipe(finalize(() => this.progressRef.complete()))
            .subscribe({
              next: (statuses: Array<DeviceStatusDto>) => {
                val.forEach((element) => {
                  const currDevice = statuses.find(
                    (x) => x.uuid === element.uuid
                  );
                  devicesComplete.push({
                    status: currDevice || {},
                    resource: element,
                    id: element.authenticationId!
                  });
                });
                this.devicesNEW$.next({
                  count: count.count || 0,
                  values: devicesComplete || []
                });
              },
              error: (err) => {
                this.isLoadingDevices = false;
              }
            });
        } else {
          this.progressRef.complete();
          this.devicesNEW$.next({
            count: count.count || 0,
            values:
              val.map((x) => {
                return { status: {}, resource: x, id: x.authenticationId! };
              }) || []
          });
        }
      },
      error: (err) => {
        this.progressRef.complete();
        this.isLoadingDevices = false;
      }
    });
  }
}
