import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SiteDto } from 'src/app/api/myportal';
import { MobileService } from 'src/app/core/mobile.service';
import { SipService } from './sip.service';

@Component({
  selector: 'app-sip-provider',
  templateUrl: './sip-provider.component.html',
  styleUrls: ['./sip-provider.component.scss']
})
export class SipProviderComponent implements OnInit {
  site: SiteDto;
  deviceId: string;

  get pageName() {
    return this.route.snapshot.data.title;
  }
  constructor(
    private route: ActivatedRoute,
    private sipService: SipService,
    private ts: TranslateService,
    private mobileService: MobileService
  ) {}

  ngOnInit(): void {
    this.site = this.route.snapshot.data.site;
    this.deviceId = this.route.snapshot.data.device.resource.uuid;

    this.sipService.getSipData(this.deviceId);

    this.mobileService.appInfoSetup(
      { title: this.ts.instant(this.pageName) },
      { visibility: true }
    );
  }
}
