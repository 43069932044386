<app-datatable
  *ngIf="!isMobile"
  [dataSource]="siteTableList"
  [columns]="columns"
  [hidePagination]="hidePagination"
  [messages]="{
    emptyMessage: 'DATATABLE.NO_DATA_SITE' | translate
  }"
>
</app-datatable>

<app-mobile-table *ngIf="isMobile" [dataSource]="siteTableList">
  <div id="mobile-sites" class="mobile-table">
    <div
      *ngFor="let item of siteTableList.items; let i = index"
      class="mobile-card site"
    >
      <span class="mobile-card-title">
        <ng-container
          *ngTemplateOutlet="nameTpl; context: { row: item, rowIndex: i }"
        ></ng-container>
      </span>
      <span class="mobile-card-subtitle" id="address-{{ i }}">
        {{ item.address.city }} - {{ item.address.addressLine1 }}</span
      >
      <span *ngIf="item.deviceNumber !== 0" class="mobile-card-icons">
        <ng-container
          *ngTemplateOutlet="
            connectionTpl;
            context: { value: item.offlineDevices, row: item, rowIndex: i }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="anomaliesTpl; context: { row: item, rowIndex: i }"
        ></ng-container>
      </span>
      <span class="mobile-card-actions">
        <ng-container
          *ngTemplateOutlet="actionsTpl; context: { row: item, rowIndex: i }"
        ></ng-container>
      </span>

      <ng-container
        *ngTemplateOutlet="menuTpl; context: { row: item, rowIndex: i }"
      ></ng-container>
    </div>
  </div>
</app-mobile-table>

<ng-template #nameTpl let-row="row" let-rowIndex="rowIndex">
  <app-favourite-button
    id="favourite-{{ rowIndex }}"
    [site]="row"
    class="mr-3"
  ></app-favourite-button>
  <a id="name-{{ rowIndex }}" [routerLink]="['/sites', row.authenticationId]"
    ><b>{{ row.name }}</b></a
  >
</ng-template>
<ng-template #ownerTpl let-row="row" let-rowIndex="rowIndex">
  <a
    id="owner-{{ rowIndex }}"
    [class.not-clickable]="row.ownerUserIdentifier === authService.getEmail()"
    [routerLink]="
      row.ownerUserIdentifier === authService.getEmail()
        ? []
        : ['/users', row.ownerAuthenticationId]
    "
    >{{ row.ownerUserIdentifier }}</a
  >
</ng-template>
<ng-template #addressTpl let-row="row" let-rowIndex="rowIndex">
  <span id="address-{{ rowIndex }}">
    {{ row.address.city }} -
    {{ row.address.addressLine1 }}
  </span>
</ng-template>
<ng-template #menuTpl let-row="row" let-rowIndex="rowIndex">
  <div class="btn-group top" dropdown container="body">
    <i
      id="menu-{{ rowIndex }}"
      class="material-icons menu-icon"
      dropdownToggle
      (click)="retrieveSiteUserInfo($event, row)"
      >more_vert</i
    >
    <ul
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right"
      role="menu"
      aria-labelledby="menu"
    >
      <li>
        <a
          id="view-{{ rowIndex }}"
          class="dropdown-item"
          [routerLink]="['/sites', row.authenticationId]"
          >{{ 'GLOBAL.VIEW' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          userRoleForSelectedSite !== 'BUILDINGMANAGER-COLLABORATOR' &&
          userRoleForSelectedSite !== 'MAINTAINER'
        "
        role="menuitem"
      >
        <a
          id="edit-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="editSite(row)"
          >{{ 'GLOBAL.EDIT' | translate }}</a
        >
      </li>
      <li *ngIf="userRoleForSelectedSite === 'ADMIN'" role="menuitem">
        <a
          id="delete-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="deleteSite(row)"
          >{{ 'GLOBAL.DELETE' | translate }}</a
        >
      </li>
      <li
        *ngIf="
          userRoleForSelectedSite !== 'BUILDINGMANAGER-COLLABORATOR' &&
          userRoleForSelectedSite !== 'INSTALLER-COLLABORATOR' &&
          userRoleForSelectedSite !== 'MAINTAINER'
        "
        role="menuitem"
      >
        <a
          id="share-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="shareSite(row)"
          >{{ 'GLOBAL.SHARE' | translate }}</a
        >
      </li>
      <li *ngIf="userRoleForSelectedSite === 'ADMIN'" role="menuitem">
        <a
          id="transfer-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="tranfertOwnership(row)"
          >{{ 'GLOBAL.TRANSFER' | translate }}</a
        >
      </li>
      <!-- <li
        *ngIf="
          userRoleForSelectedSite === 'BUILDINGMANAGER-COLLABORATOR' ||
          userRoleForSelectedSite === 'MAINTAINER'
        "
        role="menuitem"
      >
        <span class="dropdown-item" href="javascript:void(0)">{{
          'GLOBAL.NO_ACTIONS' | translate
        }}</span>
      </li> -->
    </ul>
  </div>
</ng-template>
<ng-template #dateTpl let-value="value">
  DATE TO ADD
</ng-template>
<ng-template
  #anomaliesTpl
  let-row="row"
  let-value="value"
  let-rowIndex="rowIndex"
>
  <span
    class="pl-1"
    *ngIf="
      row.deviceNumber === 0 || row.offlineDevices === row.deviceNumber;
      else showAnomalies
    "
  >
    --
  </span>
  <ng-template #showAnomalies>
    <app-anomaly-status
      id="anomalies-{{ rowIndex }}"
      [severity]="
        row.deviceWithAlarms !== 0
          ? 'evacuation'
          : row.deviceWithErrors !== 0
          ? 'error'
          : row.deviceWithAnomalies !== 0
          ? 'warning'
          : 'ok'
      "
    ></app-anomaly-status>
  </ng-template>
</ng-template>
<ng-template
  #connectionTpl
  let-row="row"
  let-value="value"
  let-rowIndex="rowIndex"
>
  <span class="pl-1" *ngIf="row.deviceNumber === 0"> -- </span>
  <app-connection-status
    *ngIf="row.deviceNumber !== 0"
    id="connection-{{ rowIndex }}"
    [online]="value === 0"
  ></app-connection-status>
</ng-template>
<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <i
    *ngIf="row.containsVip || row.containsSbc"
    id="apartments-{{ rowIndex }}"
    class="material-icons single-menu-icon mr-2"
    [routerLink]="['/sites', row.authenticationId, 'apartments']"
    [tooltip]="'SITE.APARTMENTS_LIST' | translate"
    >apartment</i
  >
</ng-template>
