import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { finalize } from 'rxjs/operators';
import { LogService, SafeLogResponseDto } from 'src/app/api/dpcp';
import { MobileService } from 'src/app/core/mobile.service';
import { DeviceCompleteDto, OptionToEdit } from 'src/app/core/models';
import { DeviceDetailService } from 'src/app/devices/device-detail/device-detail.service';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit, OnDestroy {
  tableHeight = 500;
  isLoadingStart = false;
  isLoading = false;
  device: DeviceCompleteDto;
  notificationList: SafeLogResponseDto[] = [];
  currPage = 0;
  currFilter: 'FIRST_ZONE' | 'ALARM' | undefined;
  columns: TableColumn[] = [];
  ssid: number;
  filterOptions: OptionToEdit[] = [
    {
      label: _('NOTIFICATION.FILTERS.FIRST_ZONE'),
      name: 'first-zone',
      value: 0
    },
    { label: _('NOTIFICATION.FILTERS.ALARM'), name: 'alarm', value: 1 }
  ];

  get pageName() {
    return this.route.snapshot.data.title;
  }

  public eventLogType = {
    TIPO_AVV_NONE: _('NOTIFICATION.EVENT_TYPE.TIPO_AVV_NONE'),
    TIPO_AVV_OTHER: _('NOTIFICATION.EVENT_TYPE.TIPO_AVV_OTHER'),
    TIPO_AVV_EXLUSION: _('NOTIFICATION.EVENT_TYPE.TIPO_AVV_EXLUSION'),
    TIPO_AVV_ANOMALIES: _('NOTIFICATION.EVENT_TYPE.TIPO_AVV_ANOMALIES'),
    TIPO_AVV_SABOTAGES: _('NOTIFICATION.EVENT_TYPE.TIPO_AVV_SABOTAGES'),
    TIPO_AVV_ALARMS: _('NOTIFICATION.EVENT_TYPE.TIPO_AVV_ALARMS'),
    TIPO_AVV_BURGLAR_ALARMS: _(
      'NOTIFICATION.EVENT_TYPE.TIPO_AVV_BURGLAR_ALARMS'
    )
  };

  public eventActions = {
    EVENT_AZ_NONE: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_NONE'),
    EVENT_AZ_SET: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_SET'),
    EVENT_AZ_RESET: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_RESET'),
    EVENT_AZ_ALARM: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_ALARM'),
    EVENT_AZ_RESTORE: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_RESTORE'),
    EVENT_AZ_FORCED_EXCL: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_FORCED_EXCL'),
    EVENT_AZ_ALARM_TEST: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_ALARM_TEST'),
    EVENT_AZ_RESTORE_TEST: _('NOTIFICATION.ACTION_TYPE.EVENT_AZ_RESTORE_TEST')
  };

  @ViewChild('typeTpl', { static: true }) typeTpl: ElementRef<HTMLElement>;
  @ViewChild('msgTpl', { static: true }) msgTpl: ElementRef<HTMLElement>;

  constructor(
    private logService: LogService,
    private route: ActivatedRoute,
    private deviceDetailService: DeviceDetailService,
    private mobileService: MobileService,
    private ts: TranslateService
  ) {}

  ngOnDestroy() {
    this.deviceDetailService.logout(this.device.resource.uuid!);
  }

  ngOnInit(): void {
    this.device = this.route.snapshot.data.device;
    this.columns = [
      {
        prop: 'id',
        name: this.ts.instant('NOTIFICATION.ID'),
        maxWidth: 200,
        sortable: false
      },
      {
        prop: 'logEventType',
        name: this.ts.instant('NOTIFICATION.TYPE'),
        cellTemplate: this.typeTpl,
        maxWidth: 200,
        sortable: false
      },
      {
        prop: 'logEventAction',
        name: this.ts.instant('NOTIFICATION.DESCRIPTION'),
        cellTemplate: this.msgTpl,
        sortable: false
      }
    ];

    this.mobileService.appInfoSetup(
      { title: this.ts.instant(this.pageName) },
      { visibility: true }
    );

    this.isLoadingStart = true;
    this.deviceDetailService
      .login(this.device.resource.uuid!)
      .subscribe((ssid: number) => {
        this.ssid = ssid;
        this.loadNotifications();
      });
  }

  filtersChanged(opts: OptionToEdit[]) {
    this.notificationList = [];
    this.currPage = 0;

    if (opts.length === 1) {
      this.currFilter = opts[0].value === 0 ? 'FIRST_ZONE' : 'ALARM';
    } else {
      this.currFilter = undefined;
    }
    this.loadNotifications();
  }

  loadNotifications() {
    this.isLoadingStart = true;
    this.logService
      .getLogs(
        this.device.resource.uuid!,
        this.ssid!,
        20,
        this.currPage === 0 ? 0 : 20 * this.currPage,
        undefined,
        undefined,
        this.currFilter
      )
      .pipe(finalize(() => (this.isLoadingStart = false)))
      .subscribe((r) => {
        const newNotif = this.filterLogsAlreadyPresent(r);
        this.notificationList = this.notificationList.concat(newNotif);
        this.isLoading = false;
      });
  }

  scroll(e: any) {
    if (e.offsetY && !this.isLoading) {
      if (
        e.offsetY + this.tableHeight + 100 >
        60 * this.notificationList.length
      ) {
        this.isLoading = true;
        this.currPage++;
        this.loadNotifications();
      }
    }
  }

  filterLogsAlreadyPresent(logs: SafeLogResponseDto[]): SafeLogResponseDto[] {
    const filteredLogs = logs.filter(
      (x) =>
        this.notificationList.findIndex((y) => y.uniqueId === x.uniqueId) === -1
    );
    return filteredLogs;
  }
}
