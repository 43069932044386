import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-image',
  templateUrl: './device-image.component.html',
  styleUrls: ['./device-image.component.scss']
})
export class DeviceImageComponent implements OnInit, OnChanges {
  @Input() modelId: string;
  @Input() big? = false;
  imageName: string = '';
  constructor() {}

  ngOnChanges() {
    this.updateImageName();
  }

  ngOnInit(): void {
    this.updateImageName();
  }

  updateImageName() {
    switch (this.modelId) {
      case 'Mu18':
      case 'Mu34':
      case 'MuTy':
      case 'Mu2H':
        this.imageName = 'VEDO';
        break;
      case 'HL32':
        this.imageName = 'SECUR_HUB';
        break;
      case 'AG16':
        this.imageName = 'AGILE';
        break;
      case 'UlTo':
        this.imageName = 'UT9270';
        break;
      case 'Udir':
        this.imageName = 'UT9260M';
        break;
      case 'UT9260M':
        this.imageName = 'UT9260M_UT8020';
        break;
      case 'UT8020':
        this.imageName = 'UT8020';
      case '41CPE112':
        this.imageName = '41CPE112';
      case '41CPE118':
      case 'SIMPO':
      case 'ATEE':
      case 'ATE8':
        this.imageName = '41CPE118';
        break;
      case 'IRI4':
        this.imageName = 'IRIS4';
        break;
      case 'IRI8':
        this.imageName = 'IRIS8';
        break;
      case 'SBEx':
      case 'SBEn':
        this.imageName = '1406';
        break;
      case 'Maxi':
      case 'MxWi':
        this.imageName = '6802W';
        break;
      case 'MnHF':
        this.imageName = '6722W';
        break;
      case 'MnWi':
        this.imageName = '6742W';
        break;
      case 'UviA':
        this.imageName = 'UT8010';
        break;
      case 'UviV':
        this.imageName = 'UT8020';
        break;
      case 'SIMX':
      case 'LOF2':
        this.imageName = 'SIMX';
        break;
      case 'ExBM':
      case 'ExBS':
        this.imageName = '1456S';
        break;

      // TODO to retrieve new images
      case 'HSrv':
      case 'InTS':
      case 'InTV':
      case 'MSVF':
      case 'V2SB':
      case 'VCPS':
      case 'Mxgr':
      case 'QuaM':
      case 'Quad':
      case 'Vist':
      case 'VstE':
      case 'iKhd':

      case 'ExtB':
      case 'ExtBM':
      case 'CdrA':
      case 'ExtL':
      case 'Extf':
      case 'V2VM':
      default:
        this.imageName = '1456';
        break;
    }
  }
}
