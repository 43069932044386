import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as packageInfo from '../../../package.json';
import { getUserLanguage } from '../app-init';
import { AuthService } from '../core/auth.service';
import { MobileService } from '../core/mobile.service';
import { Option } from '../core/models';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  isLoading = false;
  languages: Option[];
  roles: Option[] = [];
  languageControl = new UntypedFormControl('', Validators.required);
  roleControl = new UntypedFormControl('', Validators.required);
  public version: string = (packageInfo as any).default.version;

  get pageName() {
    return this.route.snapshot.data.title;
  }

  constructor(
    private ts: TranslateService,
    private authService: AuthService,
    private mobileService: MobileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mobileService.appInfoSetup(
      { title: this.ts.instant(this.pageName) },
      { visibility: false }
    );

    this.languages = [
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.EN'),
        value: 'en'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.IT'),
        value: 'it'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.DE'),
        value: 'de'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.ES'),
        value: 'es'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.FR'),
        value: 'fr'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.NL'),
        value: 'nl'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.PL'),
        value: 'pl'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.PT'),
        value: 'pt'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.RU'),
        value: 'ru'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.TR'),
        value: 'tr'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.JP'),
        value: 'jp'
      },
      {
        name: this.ts.instant('GLOBAL.LANGUAGES.HE'),
        value: 'he'
      }
    ];

    if (!environment.production) {
      this.languages.push({
        name: this.ts.instant('GLOBAL.LANGUAGES.KEYS'),
        value: 'key'
      });
    }

    this.roleControl.setValue(
      localStorage.getItem('role') ||
        this.authService.userProfile.data!.userType
    );
    this.languageControl.setValue(getUserLanguage());
    this.roles = [
      { name: this.ts.instant('ROLES.INSTALLER'), value: 'INSTALLER' },
      {
        name: this.ts.instant('ROLES.BUILDINGMANAGER'),
        value: 'BUILDING_MANAGER'
      }
    ];
  }

  save() {
    if (this.languageControl.valid) {
      this.isLoading = true;
      localStorage.setItem('language', this.languageControl.value);
      location.reload();
      this.isLoading = false;
    }

    if (this.roleControl.valid) {
      this.isLoading = true;
      localStorage.setItem('role', this.roleControl.value);
      location.reload();
      this.isLoading = false;
    }
  }
}
