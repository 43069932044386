/**
 * License Manager API
 * API to manage licenses
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Device SIP license details
 */
export interface RenewMobexSIPLicense {
  /**
   * License duration
   */
  duration?: number;
  /**
   * License duration unit
   */
  durationUnit: RenewMobexSIPLicense.DurationUnitEnum;
  /**
   * License expiry fixed date
   */
  fixedDate?: string;
}
export namespace RenewMobexSIPLicense {
  export type DurationUnitEnum = 'MONTHS';
  export const DurationUnitEnum = {
    MONTHS: 'MONTHS' as DurationUnitEnum
  };
}
