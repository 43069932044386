<div class="not-allowed">
  <img
    id="not-allowed-logo"
    class="mb-4"
    [src]="image"
    [alt]="'GLOBAL.COMELIT_LOGO' | translate"
  />
  <br />
  <span
    [innerHTML]="
      authService.permissions.no_type
        ? ('GLOBAL.NOT_ALLOWED_NO_TYPE' | translate)
        : ('GLOBAL.NOT_ALLOWED' | translate)
    "
  ></span>
  <br />
  <br />
  <a
    href="javascript:void(0)"
    class="link-primary"
    (click)="authService.logout()"
    >{{ 'GLOBAL.LOGOUT' | translate }}</a
  >
</div>
