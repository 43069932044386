<label class="switch-label">
  <input
    [id]="inputId"
    type="checkbox"
    class="switch-input"
    [formControl]="control"
  />
  <i
    class="switch-controls"
    [ngClass]="{
      'switch-controls-disabled': disableLogic
    }"
  ></i>
</label>
