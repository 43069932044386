import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { LoginService, SafeLoginResponseDto } from 'src/app/api/dpcpf';
import { DeviceCompleteDto } from 'src/app/core/models';
import { checkFormValidity } from 'src/app/core/utils';

@Component({
  selector: 'app-pin-modal',
  templateUrl: './pin-modal.component.html',
  styleUrls: ['./pin-modal.component.scss']
})
export class PinModalComponent implements OnInit, AfterViewInit {
  ssid: number | undefined;
  device: DeviceCompleteDto;
  pinGroup: UntypedFormGroup;
  pinControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(4)
  ]);
  @ViewChild('pinInput', { static: true }) pinInput: ElementRef<HTMLElement>;
  pinInserted = new EventEmitter<number>();
  modalClosed = new EventEmitter<void>();
  isLoading = false;
  constructor(
    public modalRef: BsModalRef,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.pinGroup = new UntypedFormGroup({
      pin: this.pinControl
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.pinInput.nativeElement.focus();
    }, 500);
  }

  close() {
    this.modalRef.hide();
    this.modalClosed.next();
  }

  insert() {
    if (checkFormValidity(this.pinGroup)) {
      this.isLoading = true;
      this.loginService
        .upgradeLogin(this.device.resource.uuid!, this.ssid!, {
          pwd: this.pinControl.value
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((r: SafeLoginResponseDto) => {
          this.pinInserted.next(r.ssid!);
          this.isLoading = false;
        });
    }
  }
}
