import { SafeStyle } from '@angular/platform-browser';
import { SiteDto } from '../api/myportal/model/siteDto';

export interface SiteMarker {
  info: SiteDto;
  image: SafeStyle;
  marker: {
    position: {
      latitude: number;
      longitude: number;
    };
    icon: {
      url: string;
      scaledSize: {
        width: number;
        height: number;
      };
    };
    data?: string;
    title: string;
  };
}

export const mapStyles: any[] = [
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];
export const clusterStyles: any[] = [
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-danger.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  },
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-evacuation.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  },
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-wifi.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  },
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-normal.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  },
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-alarm.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  },
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-nodev.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  },
  {
    textColor: '#FFFFFF',
    url: 'assets/images/icons/clusters/cluster-expiring.png',
    height: 40,
    width: 40,
    lineHeight: 40,
    backgroundPosition: 'center -1px'
  }
];

export function calculator(markers: google.maps.Marker[]) {
  const calculated = { text: markers.length, index: 4 };

  switch (true) {
    case markers.filter((element) => element.getTitle() === 'evacuation')
      .length > 0:
      calculated.index = 1;
      break;
    case markers.filter((element) => element.getTitle() === 'danger').length >
      0:
      calculated.index = 2;
      break;
    case markers.filter((element) => element.getTitle() === 'wifi').length > 0:
      calculated.index = 3;
      break;
    case markers.filter((element) => element.getTitle() === 'alarm').length > 0:
      calculated.index = 5;
      break;
    case markers.filter((element) => element.getTitle() === 'normal').length >
      0:
      calculated.index = 4;
      break;
    default:
      calculated.index = 6;
      break;
  }

  return calculated;
}
