import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GroupResponseDtoList,
  GroupResponseExtendedDto,
  GroupUserResponseDto,
  GroupsService,
  SiteDto,
  SiteService
} from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { CreateSiteModalComponent } from 'src/app/sites/create-site-modal/create-site-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { SearchService } from '../search-input/search.service';
import { ShareUserModalComponent } from '../share-user-modal/share-user-modal.component';
import { SitesListService } from '../sites-list.service';
import { TransferOwnershipModalComponent } from '../transfer-ownership-modal/transfer-ownership-modal.component';
import { Roles, UserRole } from '../utils/users';

@Component({
  selector: 'app-sites-table',
  templateUrl: './sites-table.component.html',
  styleUrls: ['./sites-table.component.scss']
})
export class SitesTableComponent implements OnInit, OnDestroy {
  @Input() onlyAnomalies: boolean;
  @Input() hidePagination: boolean;
  private unsubscribe$ = new Subject<void>();

  siteTableList = new ClientFiltrableDataSource<SiteDto>();
  columns: TableColumn[] = [];
  userRoleForSelectedSite: UserRole | undefined;

  get isMobile() {
    return isMobile;
  }

  @ViewChild('nameTpl', { static: true }) nameTpl: ElementRef<HTMLElement>;
  @ViewChild('menuTpl', { static: true }) menuTpl: ElementRef<HTMLElement>;
  @ViewChild('ownerTpl', { static: true }) ownerTpl: ElementRef<HTMLElement>;
  @ViewChild('addressTpl', { static: true }) addressTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('dateTpl', { static: true }) dateTpl: ElementRef<HTMLElement>;
  @ViewChild('anomaliesTpl', { static: true }) anomaliesTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('connectionTpl', { static: true }) connectionTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;

  constructor(
    private ts: TranslateService,
    private siteListService: SitesListService,
    private modalService: BsModalService,
    private siteService: SiteService,
    public authService: AuthService,
    private toastrService: ToastrService,
    private groupsService: GroupsService,
    private searchService: SearchService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.columns = [
      {
        name: this.ts.instant('SITE.SITE_NAME'),
        prop: 'name',
        cellTemplate: this.nameTpl,
        canAutoResize: true,
        cellClass: 'flex',
        resizeable: false
      },
      {
        name: this.ts.instant('SITE.ADDRESS'),
        prop: 'address.city',
        cellTemplate: this.addressTpl,
        canAutoResize: true,
        resizeable: false
      },
      // TOADD LATER ON
      // {
      //   name: this.ts.instant('SITE.LAST_EDIT_DATE'),
      //   prop: 'date',
      //   cellTemplate: this.dateTpl,
      //   canAutoResize: true,
      //   resizeable: false
      // },
      {
        name: this.ts.instant('SITE.CONNECTION'),
        prop: 'offlineDevices',
        cellTemplate: this.connectionTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        name: this.ts.instant('SITE.ANOMALIES'),
        prop: 'deviceWithErrors',
        cellTemplate: this.anomaliesTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        name: this.ts.instant('SITE.OWNER'),
        prop: 'ownerUserIdentifier',
        cellTemplate: this.ownerTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        name: '',
        prop: 'authenticationId',
        cellTemplate: this.actionsTpl,
        canAutoResize: true,
        sortable: false,
        resizeable: false
      },
      {
        name: this.ts.instant('GLOBAL.ACTIONS'),
        cellTemplate: this.menuTpl,
        canAutoResize: true,
        sortable: false
      }
    ];

    this.siteListService.sites$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sites: SiteDto[]) => {
        if (this.onlyAnomalies) {
          sites = sites.filter(
            (x) =>
              x.deviceWithAlarms !== 0 ||
              x.deviceWithErrors !== 0 ||
              x.deviceWithAnomalies !== 0
          );
        }
        this.siteTableList.setItems(sites);
      });

    this.searchService.searchValueChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        v = v.toString().toLowerCase();
        this.siteTableList.setCustomFilter(
          (item) =>
            item.name.toLowerCase()?.indexOf(v) !== -1 ||
            item.address.city.toLowerCase()?.indexOf(v) !== -1 ||
            item.address.addressLine1.toLowerCase()?.indexOf(v) !== -1 ||
            item.address.zipCode?.indexOf(v) !== -1 ||
            item.address.country.toLowerCase()?.indexOf(v) !== -1
        );
        this.siteTableList.applyFilters();
      });
  }

  filter() {}

  retrieveSiteUserInfo(e: any, site: SiteDto) {
    e.preventDefault();
    this.groupsService
      .getGroups(
        this.authService.getToken(),
        undefined,
        undefined,
        site.authenticationId,
        true
      )
      .subscribe((res: GroupResponseDtoList) => {
        if (res.groups && res.groups.length > 0) {
          this.userRoleForSelectedSite = res.groups[0].name?.split(
            '_'
          )[0] as UserRole;
        }
      });
  }

  deleteSite(site: SiteDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: site.containsTPInt
        ? this.ts.instant('SITE.DELETE_SITE_W_AUTH_CODE')
        : this.ts.instant('SITE.DELETE_SITE'),
      description: this.ts.instant('SITE.DELETE_SITE_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.siteService
        .deleteSite(site.authenticationId!, this.authService.getToken())
        .subscribe(() => {
          modalRef.hide();
          this.toastrService.success(
            this.ts.instant('SITE.SITE_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.siteListService.loadSites().subscribe();
        });
    });
  }

  editSite(site: SiteDto) {
    const initialState: Partial<CreateSiteModalComponent> = {
      isEditing: true,
      site
    };

    const modalRef = this.modalService.show(CreateSiteModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteCreated.subscribe(() => {
      this.siteListService.loadSites().subscribe();
    });
  }

  shareSite(site: SiteDto) {
    this.groupsService
      .getGroups(
        this.authService.getToken(),
        undefined,
        undefined,
        site.authenticationId
      )
      .subscribe((res: GroupResponseDtoList) => {
        const users: string[] = [];
        const roles: Roles[] =
          res.groups?.map((element: GroupResponseExtendedDto) => {
            if (element.members && element.members.length > 0) {
              element.members?.forEach((e: GroupUserResponseDto) => {
                users.push(e.loginEmail!);
              });
            }

            return {
              authId: element.authenticationId || '',
              name: (element.name?.split('_')[0] as UserRole) || 'NOROLE'
            };
          }) || [];

        const initialState: Partial<ShareUserModalComponent> = {
          site,
          roleList: roles,
          siteUsers: users,
          userRole: this.userRoleForSelectedSite,
          currentUserEmail: this.authService.userProfile.data?.loginEmail || ''
        };

        const modalRef = this.modalService.show(ShareUserModalComponent, {
          initialState,
          ignoreBackdropClick: true,
          class: 'modal-lg'
        });

        modalRef.content!.shared.subscribe(() => {
          this.siteListService.loadSites().subscribe();
          this.toastrService.success(
            this.ts.instant('SITE.SITE_SHARED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        });
      });
  }

  tranfertOwnership(site: SiteDto) {
    this.groupsService
      .getGroups(
        this.authService.getToken(),
        undefined,
        undefined,
        site.authenticationId
      )
      .subscribe((res: GroupResponseDtoList) => {
        if (res.groups) {
          const currentOwner = res.groups[0].owner?.loginEmail;
          const roles: Roles[] =
            res.groups.map((element: GroupResponseExtendedDto) => {
              return {
                authId: element.authenticationId || '',
                name: (element.name?.split('_')[0] as UserRole) || 'NOROLE'
              };
            }) || [];

          const initialState: Partial<TransferOwnershipModalComponent> = {
            site,
            roles,
            currentOwner
          };

          const modalRef = this.modalService.show(
            TransferOwnershipModalComponent,
            {
              initialState,
              ignoreBackdropClick: true,
              class: 'modal-lg'
            }
          );

          modalRef.content!.transferred.subscribe(() => {
            this.toastrService.success(
              this.ts.instant('SITE.SITE_TRANSFERRED'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
            this.siteListService.loadSites().subscribe();
          });
        }
      });
  }
}
