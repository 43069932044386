import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MissingTranslationHandler,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {
  ApiModule as DocumentApiModule,
  DOCUMENT_BASE_PATH
} from './api/document';
import { ApiModule as LogsModule, LOGS_BASE_PATH } from './api/logs';
import { ApiModule as DpcpApiModule, DPCP_BASE_PATH } from './api/dpcp';
import { ApiModule as DpcpfApiModule, DPCPF_BASE_PATH } from './api/dpcpf';
import { ApiModule as FireApiModule, FIRE_BASE_PATH } from './api/fire';
import {
  ApiModule as LicensesApiModule,
  LICENSES_BASE_PATH
} from './api/licenses';
import { ApiModule as McApiModule, MC_BASE_PATH } from './api/mc';
import {
  ApiModule as MugConfApiModule,
  MUGCONF_BASE_PATH
} from './api/mugconf';
import {
  ApiModule as MyPortalApiModule,
  MYPORTAL_BASE_PATH
} from './api/myportal';
import { ApiModule as OptidApiModule, OPTID_BASE_PATH } from './api/optid';
import { ApiModule as OtpApiModule, OTP_BASE_PATH } from './api/otp';
import { ApiModule as SipApiModule, SIP_BASE_PATH } from './api/sip';
import { ApiModule as YardiApiModule, YARDI_BASE_PATH } from './api/yardi';
import {
  ApiModule as ThirdPartApiModule,
  THIRDPART_BASE_PATH
} from './api/thirdpart';
import { ApiModule as TreeApiModule, TREE_BASE_PATH } from './api/tree';
import {
  ApiModule as UserPortalApiModule,
  USERPORTAL_BASE_PATH
} from './api/userportal';
import { appInit } from './app-init';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { TokenInterceptor } from './core/token.interceptor';
import { DashboardDevicesComponent } from './dashboard/dashboard-devices/dashboard-devices.component';
import { DashboardSitesComponent } from './dashboard/dashboard-sites/dashboard-sites.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateDeviceModalComponent } from './devices/create-device-modal/create-device-modal.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationMobileComponent } from './layout/navigation-mobile/navigation-mobile.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from './shared/shared.module';
import { MyMissingTranslationHandler } from './core/utils';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavigationComponent,
    DashboardComponent,
    DashboardSitesComponent,
    DashboardDevicesComponent,
    CreateDeviceModalComponent,
    SettingsComponent,
    NavigationMobileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MugConfApiModule,
    DpcpApiModule,
    ThirdPartApiModule,
    DpcpfApiModule,
    YardiApiModule,
    UserPortalApiModule,
    TreeApiModule,
    SipApiModule,
    OtpApiModule,
    McApiModule,
    LogsModule,
    LicensesApiModule,
    DocumentApiModule,
    OptidApiModule,
    HttpClientModule,
    MyPortalApiModule,
    FireApiModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    }),
    NgxDatatableModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    NgSelectModule,
    PaginationModule.forRoot(),
    SharedModule,
    NgProgressModule.withConfig({
      color: environment.client === 'comelit' ? '#009640' : '#3366cc',
      spinner: false
    }),
    NgProgressHttpModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      titleClass: 'toast-title',
      messageClass: 'toast-message'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [TranslateService]
    },
    {
      provide: DPCP_BASE_PATH,
      useValue: environment.basePath + '/servicerest/dpcp'
    },
    {
      provide: DPCPF_BASE_PATH,
      useValue: environment.basePath + '/servicerest/dpcp'
    },
    {
      provide: MC_BASE_PATH,
      useValue: environment.basePath + '/servicerest/messagecenter'
    },
    {
      provide: MUGCONF_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: USERPORTAL_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: MYPORTAL_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: TREE_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: SIP_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: OTP_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: LICENSES_BASE_PATH,
      useValue: environment.basePath + '/servicerest/licensemanager'
    },
    {
      provide: DOCUMENT_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: OPTID_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: THIRDPART_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: LOGS_BASE_PATH,
      useValue: environment.basePath
    },
    {
      provide: YARDI_BASE_PATH,
      useValue: environment.basePath + '/servicerest/directory'
    },
    {
      provide: FIRE_BASE_PATH,
      useValue: environment.basePath + '/servicerest/fire'
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
