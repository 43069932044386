/**
 * Cloud Message Center API
 * API to interact with cloud message center
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Message read DTO
 */
export interface MessageReadV2Dto {
  /**
   * Message Id
   */
  readonly id?: string;
  /**
   * Message creation date
   */
  readonly date?: string;
  /**
   * Message title
   */
  readonly title?: string;
  /**
   * Message description
   */
  readonly description?: string;
  /**
   * Message is acknowledged
   */
  ack?: boolean;
  /**
   * Message expiration date
   */
  readonly expireDate?: string;
  /**
   * Recipient user id
   */
  readonly destAuthId?: string;
  /**
   * Source Device ID
   */
  readonly deviceUuid?: string;
  /**
   * Message category
   */
  readonly category?: MessageReadV2Dto.CategoryEnum;
  /**
   * Message event type
   */
  readonly eventType?: MessageReadV2Dto.EventTypeEnum;
  /**
   * Message event type description
   */
  readonly eventTypeDescr?: string;
  /**
   * Message priority
   */
  readonly priority?: number;
  /**
   * Message parameters
   */
  readonly parameters?: { [key: string]: string };
}
export namespace MessageReadV2Dto {
  export type CategoryEnum =
    | 'NOT_RECOGNIZED'
    | 'GENERIC_EVENT'
    | 'SAFE_EVENT'
    | 'PCAL_EVENT'
    | 'FIRE_EVENT';
  export const CategoryEnum = {
    NOTRECOGNIZED: 'NOT_RECOGNIZED' as CategoryEnum,
    GENERICEVENT: 'GENERIC_EVENT' as CategoryEnum,
    SAFEEVENT: 'SAFE_EVENT' as CategoryEnum,
    PCALEVENT: 'PCAL_EVENT' as CategoryEnum,
    FIREEVENT: 'FIRE_EVENT' as CategoryEnum
  };
  export type EventTypeEnum =
    | 'NOT_RECOGNIZED'
    | 'NO_TYPE'
    | 'OTHER'
    | 'EXCLUSIONS'
    | 'ANOMALIES'
    | 'SABOTAGE'
    | 'ALARMS'
    | 'BURGLAR_ALARMS'
    | 'FIRE_EVENT'
    | 'USER_ADD_ACKNOWLEDGMENT'
    | 'PIN_GENERATION'
    | 'DEFAULT_POLICY_UPDATE'
    | 'CUSTOM_POLICY_UPDATE'
    | 'RCV_PROVIDER_REVOKE';
  export const EventTypeEnum = {
    NOTRECOGNIZED: 'NOT_RECOGNIZED' as EventTypeEnum,
    NOTYPE: 'NO_TYPE' as EventTypeEnum,
    OTHER: 'OTHER' as EventTypeEnum,
    EXCLUSIONS: 'EXCLUSIONS' as EventTypeEnum,
    ANOMALIES: 'ANOMALIES' as EventTypeEnum,
    SABOTAGE: 'SABOTAGE' as EventTypeEnum,
    ALARMS: 'ALARMS' as EventTypeEnum,
    BURGLARALARMS: 'BURGLAR_ALARMS' as EventTypeEnum,
    FIREEVENT: 'FIRE_EVENT' as EventTypeEnum,
    USERADDACKNOWLEDGMENT: 'USER_ADD_ACKNOWLEDGMENT' as EventTypeEnum,
    PINGENERATION: 'PIN_GENERATION' as EventTypeEnum,
    DEFAULTPOLICYUPDATE: 'DEFAULT_POLICY_UPDATE' as EventTypeEnum,
    CUSTOMPOLICYUPDATE: 'CUSTOM_POLICY_UPDATE' as EventTypeEnum,
    RCVPROVIDERREVOKE: 'RCV_PROVIDER_REVOKE' as EventTypeEnum
  };
}
