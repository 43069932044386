/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DataStatisticsDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Loop is an integer value containing the loop index the device belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of device inside its loop.
   */
  address?: number;
  /**
   * Date & time of the entry is a string containing timestamp associated to the data record.
   */
  time?: string;
  /**
   * Contamination is an integer value representing the contamination level measured by device (in percentage).
   */
  contamination?: number;
  /**
   * Smoke level is an integer value representing the smoke level measured by device (in percentage).
   */
  smokeLevel?: number;
  /**
   * Temperature is an 8 bits unsigned integer - Temperature in units of 0.5 degrees Celsius. The range is from -25°C (value 0) to + 102.5°C (value 255). 0°C is represented as a value of 50
   */
  temperature?: number;
  /**
   * Communication quality is an integer value representing the quality of communication between the device and the control panel.
   */
  communicationQuality?: number;
}
