import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { AddressBookConfComponent } from './address-book-conf/address-book-conf.component';
import { AddressBookCreateComponent } from './address-book-conf/address-book-create/address-book-create.component';
import { ActuatorsCardComponent } from './address-book-conf/cards/actuators-card/actuators-card.component';
import { CreateActuatorModalComponent } from './address-book-conf/cards/actuators-card/create-actuator-modal/create-actuator-modal.component';
import { ButtonCardComponent } from './address-book-conf/cards/button-card/button-card.component';
import { ComelitButtonModalComponent } from './address-book-conf/cards/button-card/comelit-button-modal/comelit-button-modal.component';
import { CameraCardComponent } from './address-book-conf/cards/camera-card/camera-card.component';
import { CameraModalComponent } from './address-book-conf/cards/camera-card/camera-modal/camera-modal.component';
import { ExtPlaceModalComponent } from './address-book-conf/cards/internal-place-card/ext-place-modal/ext-place-modal.component';
import { InternalPlaceCardComponent } from './address-book-conf/cards/internal-place-card/internal-place-card.component';
import { CreateOpendorModalComponent } from './address-book-conf/cards/opendoor-card/create-opendor-modal/create-opendor-modal.component';
import { OpendoorCardComponent } from './address-book-conf/cards/opendoor-card/opendoor-card.component';
import { CreateSwitchboardModalComponent } from './address-book-conf/cards/switchboard-card/create-switchboard-modal/create-switchboard-modal.component';
import { SwitchboardCardComponent } from './address-book-conf/cards/switchboard-card/switchboard-card.component';
import { AddressPipe } from './address.pipe';
import { AnomalyStatusComponent } from './anomaly-status/anomaly-status.component';
import { ApartmentsTableComponent } from './apartments-table/apartments-table.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { ChangedPasswordModalComponent } from './changed-password-modal/changed-password-modal.component';
import { CodeFormatPipe } from './codeFormat.pipe';
import { CommunicationModalComponent } from './communication-modal/communication-modal.component';
import { ConditionsModalComponent } from './conditions-modal/conditions-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { ConnectivityActivationModalComponent } from './connectivity-activation-modal/connectivity-activation-modal.component';
import { ConnectivityModalComponent } from './connectivity-modal/connectivity-modal.component';
import { ControlErrorsComponent } from './control-errors/control-errors.component';
import { DatatableComponent } from './datatable/datatable.component';
import { DeviceImageComponent } from './device-image/device-image.component';
import { DevicesTableComponent } from './devices-table/devices-table.component';
import { EventLogComponent } from './event-log/event-log.component';
import { FavouriteButtonComponent } from './favourite-button/favourite-button.component';
import { FavouriteSitesComponent } from './favourite-sites/favourite-sites.component';
import { FavouritePipe } from './favourite.pipe';
import { FiltersComponent } from './filter/filters/filters.component';
import { HiddenPasswordPipe } from './hidden-password.pipe';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { LoadingComponent } from './loading/loading.component';
import { MobileTableComponent } from './mobile-table/mobile-table.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { PinModalComponent } from './pin-modal/pin-modal.component';
import { RenewConnectivityComponent } from './renew-connectivity/renew-connectivity.component';
import { ScannerButtonComponent } from './scanner-button/scanner-button.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ShareUserModalComponent } from './share-user-modal/share-user-modal.component';
import { SipActivationModalComponent } from './sip-provider/sip-activation/sip-activation-modal/sip-activation-modal.component';
import { SipActivationComponent } from './sip-provider/sip-activation/sip-activation.component';
import { SipConfigComponent } from './sip-provider/sip-config/sip-config.component';
import { SipLineModalComponent } from './sip-provider/sip-phone-lines/sip-line-modal/sip-line-modal.component';
import { SipPhoneLinesComponent } from './sip-provider/sip-phone-lines/sip-phone-lines.component';
import { SipProviderComponent } from './sip-provider/sip-provider.component';
import { SiteAssociationModalComponent } from './site-association-modal/site-association-modal.component';
import { SiteInfoComponent } from './site-info/site-info.component';
import { SitesMapComponent } from './sites-map/sites-map.component';
import { SitesTableComponent } from './sites-table/sites-table.component';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { SwitchComponent } from './switch/switch.component';
import { TransferOwnershipModalComponent } from './transfer-ownership-modal/transfer-ownership-modal.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UserDeviceCreateComponent } from './user-devices/user-device-create/user-device-create.component';
import { UserDeviceDetailComponent } from './user-devices/user-device-detail/user-device-detail.component';
import { UserPermissionModalComponent } from './user-permission-modal/user-permission-modal.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DateFilterComponent } from './filter/date-filter/date-filter.component';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    SearchInputComponent,
    DatatableComponent,
    ConnectionStatusComponent,
    AnomalyStatusComponent,
    ConfirmModalComponent,
    AddressPipe,
    CodeFormatPipe,
    FavouritePipe,
    HiddenPasswordPipe,
    ControlErrorsComponent,
    LoadingButtonComponent,
    UploadImageComponent,
    SitesMapComponent,
    FavouriteSitesComponent,
    NotAllowedComponent,
    FavouriteButtonComponent,
    DevicesTableComponent,
    SitesTableComponent,
    SiteAssociationModalComponent,
    SiteInfoComponent,
    BreadcrumbsComponent,
    ApartmentsTableComponent,
    ChangePasswordModalComponent,
    SiteInfoComponent,
    ChangedPasswordModalComponent,
    UserDeviceCreateComponent,
    UserDeviceDetailComponent,
    CommunicationModalComponent,
    SipProviderComponent,
    SipActivationComponent,
    SipConfigComponent,
    SipPhoneLinesComponent,
    SipLineModalComponent,
    SipActivationModalComponent,
    ShareUserModalComponent,
    UserPermissionModalComponent,
    TransferOwnershipModalComponent,
    MobileTableComponent,
    SwitchComponent,
    StatusIconComponent,
    InfoModalComponent,
    PinModalComponent,
    EventLogComponent,
    DeviceImageComponent,
    LoadingComponent,
    SwitchComponent,
    ConnectivityModalComponent,
    ConnectivityActivationModalComponent,
    ConditionsModalComponent,
    RenewConnectivityComponent,
    ScannerButtonComponent,
    AddressBookConfComponent,
    AddressBookCreateComponent,
    CameraModalComponent,
    InternalPlaceCardComponent,
    OpendoorCardComponent,
    SwitchboardCardComponent,
    CameraCardComponent,
    ActuatorsCardComponent,
    ButtonCardComponent,
    ExtPlaceModalComponent,
    ComelitButtonModalComponent,
    CreateOpendorModalComponent,
    CreateSwitchboardModalComponent,
    CreateActuatorModalComponent,
    FiltersComponent,
    DateFilterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxDatatableModule,
    BsDropdownModule,
    ModalModule,
    NgSelectModule,
    PaginationModule,
    ReactiveFormsModule,
    ToastrModule,
    RouterModule,
    FormsModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    PdfViewerModule,
    QRCodeModule,
    GoogleMapsModule,
    ZXingScannerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  exports: [
    CommonModule,
    TranslateModule,
    NgxDatatableModule,
    BsDropdownModule,
    ModalModule,
    NgSelectModule,
    PaginationModule,
    DatatableComponent,
    SearchInputComponent,
    ConnectionStatusComponent,
    FiltersComponent,
    AnomalyStatusComponent,
    ControlErrorsComponent,
    LoadingButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    TooltipModule,
    TabsModule,
    AddressPipe,
    CodeFormatPipe,
    FavouritePipe,
    HiddenPasswordPipe,
    UploadImageComponent,
    SitesMapComponent,
    FavouriteSitesComponent,
    NotAllowedComponent,
    FavouriteButtonComponent,
    DevicesTableComponent,
    SitesTableComponent,
    SiteInfoComponent,
    BreadcrumbsComponent,
    ApartmentsTableComponent,
    UserDeviceDetailComponent,
    SipProviderComponent,
    MobileTableComponent,
    SwitchComponent,
    StatusIconComponent,
    DeviceImageComponent,
    LoadingComponent,
    SwitchComponent,
    ScannerButtonComponent,
    QRCodeModule,
    GoogleMapsModule,
    ZXingScannerModule,
    DateFilterComponent,
    NgxEchartsModule
  ],
  providers: [
    // ClusterManager,
    TooltipModule,
    HiddenPasswordPipe
  ]
})
export class SharedModule {}
