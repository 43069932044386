import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export function mustMatch(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl) => {
    let validity: ValidationErrors | null = null;

    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (control === null || matchingControl === null) {
      return null;
    }

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      validity = { mustMatch: true };
    }

    matchingControl.setErrors(validity);
    return validity;
  };
}
