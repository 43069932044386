/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License data descriptor
 */
export interface LicenseDto {
  /**
   * License identifier
   */
  readonly id?: number;
  /**
   * License content
   */
  readonly contents?: string;
  /**
   * License accepted by license manager
   */
  readonly accepted?: boolean;
  /**
   * Number of master licenses
   */
  readonly master?: number;
  /**
   * Number of master time licenses
   */
  readonly mtime?: number;
  /**
   * Number of phone licenses
   */
  readonly phone?: number;
  /**
   * Number of slave licenses
   */
  readonly slave?: number;
}
