/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Actuator related data
 */
export interface ActuatorDirectoryDataDto {
  /**
   * Directory identifier (not required if new, otherwise required)
   */
  dir: number;
  /**
   * Subelement identifier (not required if new, otherwise required; present if data refers to subelement, otherwise not present)
   */
  id: number;
  /**
   * Actuator name
   */
  name?: string;
  /**
   * Actuator VIP address
   */
  addr?: string;
  /**
   * Output ID
   */
  outputid?: number;
  /**
   * Module ID
   */
  moduleid?: number;
}
