/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Apartment related data descriptor
 */
export interface ApartmentDto {
  /**
   * Apartment identifier
   */
  id?: number;
  /**
   * Set to true it enables apartment in MUG
   */
  enable?: boolean;
  /**
   * Description of the apartment
   */
  descr?: string;
  /**
   * VIP address of the apartment
   */
  vipaddr?: string;
  /**
   * License type (0: None, 1: Slave, 2: Master, 3: Phone, 4: Master time,
   */
  lictype?: ApartmentDto.LictypeEnum;
  /**
   * Enable forward call on busy
   */
  fwdbusy?: boolean;
  fwdaddr?: string;
  /**
   * Addressbook related to the apartment
   */
  addrbook?: number;
  /**
   * Enable external addressbook
   */
  extab?: boolean;
  /**
   * Email of the apartment administrator
   */
  email?: string;
  /**
   * Password of the apartment administrator
   */
  pwd?: string;
  /**
   * SNMP commands related codes to be sent to device (0: Do nothing, 1: Reset activation)
   */
  cmdcode?: ApartmentDto.CmdcodeEnum;
  /**
   * Change password
   */
  pwdchange?: number;
}
export namespace ApartmentDto {
  export type LictypeEnum =
    | 'NONE'
    | 'SLAVE'
    | 'MASTER'
    | 'PHONE'
    | 'MASTER_TIME';
  export const LictypeEnum = {
    NONE: 'NONE' as LictypeEnum,
    SLAVE: 'SLAVE' as LictypeEnum,
    MASTER: 'MASTER' as LictypeEnum,
    PHONE: 'PHONE' as LictypeEnum,
    MASTERTIME: 'MASTER_TIME' as LictypeEnum
  };
  export type CmdcodeEnum = 'DO_NOTHING' | 'RESET_ACTIVATION';
  export const CmdcodeEnum = {
    DONOTHING: 'DO_NOTHING' as CmdcodeEnum,
    RESETACTIVATION: 'RESET_ACTIVATION' as CmdcodeEnum
  };
}
