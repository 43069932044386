/**
 * User Document management API
 * API to interact with documents to be submitted to users
 *
 * The version of the OpenAPI document: 1.5.0+readPreferenceAtlasFix.r5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DocReqDto {
  /**
   * Documents target
   */
  target: DocReqDto.TargetEnum;
  /**
   * Documents tag
   */
  tag: string;
  /**
   * Documents default message
   */
  defaultMessage: string;
}
export namespace DocReqDto {
  export type TargetEnum = 'fire' | 'safe';
  export const TargetEnum = {
    Fire: 'fire' as TargetEnum,
    Safe: 'safe' as TargetEnum
  };
}
