import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { isApp, isMobile } from './utils';

export type DeviceSectStatus = {
  licenses?: boolean;
  settings?: boolean;
  safeParam?: boolean;
  fireParam?: boolean;
  safeNotif?: boolean;
  fireNotif?: boolean;
  fireReports?: boolean;
  info?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  accValuesChanged$ = new Subject<boolean[]>();
  accordions: boolean[];

  sectStatusChanged$ = new Subject<any>();
  deviceSectionsStatus: { [key: string]: boolean } = {
    licenses: false,
    settings: false,
    safeParam: false,
    fireParam: false,
    safeNotif: false,
    fireNotif: false,
    fireReports: false,
    info: false
  };

  get isApp() {
    return isApp;
  }
  get isMobile() {
    return isMobile;
  }
  constructor() {}

  appInfoSetup(title: any, backBtnVisibility: any, otherBtn?: any) {
    if (isApp && (window as any).webkit) {
      if ((window as any).webkit.messageHandlers.setNativeTitle) {
        (window as any).webkit.messageHandlers.setNativeTitle.postMessage(
          title
        );
      }
      if ((window as any).webkit.messageHandlers.isNativeBackButtonVisible) {
        (window as any).webkit.messageHandlers.isNativeBackButtonVisible.postMessage(
          backBtnVisibility
        );
      }
      if ((window as any).webkit.messageHandlers.setNativeRightButton) {
        (window as any).webkit.messageHandlers.setNativeRightButton.postMessage(
          otherBtn || {}
        );
      }
    } else if (isApp && !(window as any).webkit) {
      if ((window as any).setNativeTitle) {
        (window as any).setNativeTitle(JSON.stringify(title));
      }
      if ((window as any).isNativeBackButtonVisible) {
        (window as any).isNativeBackButtonVisible(
          JSON.stringify(backBtnVisibility)
        );
      }
      if ((window as any).setNativeRightButton) {
        (window as any).setNativeRightButton(JSON.stringify(otherBtn || {}));
      }
    }
  }

  openScan() {
    if ((window as any).webkit) {
      if ((window as any).webkit.messageHandlers.startScan) {
        (window as any).webkit.messageHandlers.startScan.postMessage({});
      }
    } else {
      if ((window as any).startScan) {
        (window as any).startScan(JSON.stringify({}));
      }
    }
  }

  toggleElement(n: number) {
    const origStatus = this.accordions[n];

    this.accordions = this.accordions.map((x) => (x = false));
    this.accordions[n] = !origStatus;

    this.accValuesChanged$.next(this.accordions);
  }

  setAccordionsValues(v: boolean[]) {
    this.accordions = v;
  }

  scrollManagement(event: Event) {
    if (isMobile) {
      (event.currentTarget as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  toggleSectionStatus(val: string) {
    this.deviceSectionsStatus[val] = !this.deviceSectionsStatus[val];
    this.sectStatusChanged$.next(this.deviceSectionsStatus);
  }

  setSectionStatus(v: any) {
    this.deviceSectionsStatus = v;
  }
}
