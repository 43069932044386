import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  CameraDirectoryDataDto,
  DirectoriesService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { CameraModalComponent } from './camera-modal/camera-modal.component';

@Component({
  selector: 'app-camera-card',
  templateUrl: './camera-card.component.html',
  styleUrls: ['./camera-card.component.scss']
})
export class CameraCardComponent implements OnInit {
  @Input() deviceId: string;
  @Input() dirId: number;
  columns: TableColumn[] = [];
  cameras: CameraDirectoryDataDto[] = [];
  dataSource = new ClientFiltrableDataSource<CameraDirectoryDataDto>();
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('addrTpl', { static: true }) addrTpl: ElementRef<HTMLElement>;

  get isMobile() {
    return isMobile;
  }

  constructor(
    private ts: TranslateService,
    private dirService: DirectoriesService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('ADDRESSBOOK.DESCRIPTION')
      },
      {
        prop: 'addr',
        name: this.ts.instant('ADDRESSBOOK.ADDRESS'),
        cellTemplate: this.addrTpl
      },
      {
        prop: 'id',
        cellTemplate: this.actionsTpl,
        maxWidth: 150,
        name: this.ts.instant('GLOBAL.ACTIONS')
      }
    ];

    this.getCameras();
  }

  getCameras() {
    this.dirService
      .getCameras(this.deviceId, this.dirId, this.authService.getToken())
      .subscribe((data: CameraDirectoryDataDto[]) => {
        this.dataSource.setItems(data);
        this.cameras = data;
      });
  }

  addCamera() {
    const initialState: Partial<CameraModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.CAMERA_CREATE'),
      dirId: this.dirId,
      id: this.calculateNextId()
    };

    const modalRef = this.modalService.show(CameraModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: CameraDirectoryDataDto) => {
      this.cameras = this.cameras.concat([v]);
      this.dirService
        .setCameras(this.deviceId, this.dirId, this.authService.getToken(), {
          cameras: this.cameras
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.CAMERA_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getCameras();
        });
    });
  }

  calculateNextId() {
    let newId = 0;
    this.cameras.forEach((x: CameraDirectoryDataDto) => {
      if (x.id > newId) {
        newId = x.id;
      }
    });
    return newId + 1;
  }

  edit(row: CameraDirectoryDataDto) {
    const initialState: Partial<CameraModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.CAMERA_EDIT'),
      dirId: this.dirId,
      camera: row
    };

    const modalRef = this.modalService.show(CameraModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: any) => {
      this.cameras.splice(
        this.cameras.findIndex((x) => x.id === row.id),
        1,
        v
      );
      this.dirService
        .setCameras(this.deviceId, this.dirId, this.authService.getToken(), {
          cameras: this.cameras
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.CAMERA_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getCameras();
        });
    });
  }

  delete(row: CameraDirectoryDataDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_CAMERA'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_CAMERA_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.dirService
        .deleteCamera(
          this.deviceId,
          this.dirId,
          row.id,
          this.authService.getToken()
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.CAMERA_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getCameras();
        });
    });
  }
}
