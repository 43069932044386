/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * SIP Additional configuration data
 */
export interface SipAdditionalCfgDto {
  /**
   * SIP configuration mode (manual = 0, auto = 1)
   */
  sipCfgMode?: number;
  /**
   * Allow or block SIP automatic configuration via Comelit Cloud
   */
  readonly sipAutoAllowed?: boolean;
  /**
   * Tell if a SIP license exists for the device (auto config mode only)
   */
  readonly sipLicenseExists?: boolean;
  /**
   * Tell if a SIP license is enabled for the device (auto config mode only)
   */
  readonly sipLicenseEnabled?: boolean;
}
