/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SafeRepeaterStateDto {
  /**
   * Tamper (vedo: ?, hub: Y, eclipse: ?)
   */
  tamper?: boolean;
  /**
   * Fault (vedo: ?, hub: Y, eclipse: ?)
   */
  fault?: boolean;
  /**
   * Low battery (vedo: ?, hub: Y, eclipse: ?)
   */
  lowBattery?: boolean;
  /**
   * Supervision (vedo: ?, hub: Y, eclipse: ?)
   */
  supervision?: boolean;
  /**
   * Power (vedo: ?, hub: Y, eclipse: ?)
   */
  power?: boolean;
}
