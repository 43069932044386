<div class="modal-header">
  <h2>
    {{ 'SITE.ASSOCIATE_SITE' | translate }}
  </h2>
</div>
<div class="modal-body">
  <p class="d-block">{{ 'SITE.ASSOCIATE_SITE_DESC' | translate }}</p>
  <b>{{ device.name }}</b>
  <br />

  <label class="custom-label" for="site">{{
    'SITE.SITE_NAME' | translate
  }}</label>
  <ng-select
    id="site"
    [items]="siteList"
    [ngClass]="{ small: addSmall }"
    bindLabel="name"
    [formControl]="siteControl"
    [searchable]="false"
    [clearable]="false"
  ></ng-select>
  <img
    id="add-site"
    (click)="openSiteCreation()"
    *ngIf="addSmall"
    src="assets/images/add_button.png"
    alt="add"
  />

  <div class="mt-5 d-flex justify-content-end">
    <button id="cancel" class="button-secondary big mr-2" (click)="close()">
      {{ 'GLOBAL.CANCEL' | translate }}
    </button>

    <app-loading-button
      id="associate"
      buttonClass="button-primary big"
      [isLoading]="isLoading"
      (click)="associate()"
      buttonType="button"
      [disabled]="!siteControl.value"
    >
      {{ 'GLOBAL.ASSOCIATE_SITE' | translate }}
    </app-loading-button>
  </div>
</div>
