/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GenerateMaintenanceReportDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Loop is an integer value containing the loop index the device belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of device inside its loop (i.e. from 1 to 250).
   */
  address?: number;
  /**
   * Zone is an integer value containing the zone index the device belongs to.
   */
  zone?: number;
  /**
   * Zone name is a string containing the zone name to whom device belongs. According to CBOR standard should be composed by Unicode characters encoded as UTF-8 RFC3629
   */
  zoneName?: string;
  /**
   * Device Name is a string containing the name of the device. According to CBOR standard should be composed by Unicode characters encoded as UTF-8 RFC3629
   */
  deviceName?: string;
  /**
   * Type is an integer defining the type of device.
   */
  type?: GenerateMaintenanceReportDto.TypeEnum;
  /**
   * Last test is the month and the year when the detector was last tested for maintenance. As string.
   */
  lastTest?: string;
  /**
   * Next test is the month and the year of next maintenance test. As string.
   */
  nextTest?: string;
  /**
   * Dust level is percentage value showing  dust contamination of the detector. For Smoke detectors. As integer 0 - 100.
   */
  dustLevel?: number;
  /**
   * Duty time are two values. First is the time that the detector was working in months. Separated with / after that, is the time in months, which is the detectors expected operating time.
   */
  dutyTime?: string;
  /**
   * Serial is a string containig serial number of the detector. According to CBOR standard should be composed by Unicode characters encoded as UTF-8 RFC3629
   */
  serialID?: string;
  /**
   * Battery is integer value showing how much of the battery capacity is left.
   */
  battery?: number;
}
export namespace GenerateMaintenanceReportDto {
  export type TypeEnum =
    | 'T110'
    | 'T110_IS'
    | 'S130'
    | 'S130_IS'
    | 'M140'
    | 'M140_IS'
    | 'TD'
    | 'SD'
    | 'MD'
    | 'VELLA_T110'
    | 'VELLA_T110_IS'
    | 'VELLA_S130'
    | 'VELLA_S130_IS'
    | 'VELLA_M140'
    | 'VELLA_M140_IS'
    | 'MCP150'
    | 'MCP50A'
    | 'WL_MCP'
    | 'COM_41RCS100'
    | 'COM_41RCS100_IS'
    | 'COM_NONE_41RFU100'
    | 'COM_41RFU100'
    | 'COM_NONE_41RML100'
    | 'COM_41RML100'
    | 'COM_45RCS100'
    | 'COM_45RFU100'
    | 'COM_45RML100'
    | 'COM_41PAM000'
    | 'COM_43PAM000'
    | 'COM_45PAM100';
  export const TypeEnum = {
    T110: 'T110' as TypeEnum,
    T110IS: 'T110_IS' as TypeEnum,
    S130: 'S130' as TypeEnum,
    S130IS: 'S130_IS' as TypeEnum,
    M140: 'M140' as TypeEnum,
    M140IS: 'M140_IS' as TypeEnum,
    TD: 'TD' as TypeEnum,
    SD: 'SD' as TypeEnum,
    MD: 'MD' as TypeEnum,
    VELLAT110: 'VELLA_T110' as TypeEnum,
    VELLAT110IS: 'VELLA_T110_IS' as TypeEnum,
    VELLAS130: 'VELLA_S130' as TypeEnum,
    VELLAS130IS: 'VELLA_S130_IS' as TypeEnum,
    VELLAM140: 'VELLA_M140' as TypeEnum,
    VELLAM140IS: 'VELLA_M140_IS' as TypeEnum,
    MCP150: 'MCP150' as TypeEnum,
    MCP50A: 'MCP50A' as TypeEnum,
    WLMCP: 'WL_MCP' as TypeEnum,
    COM41RCS100: 'COM_41RCS100' as TypeEnum,
    COM41RCS100IS: 'COM_41RCS100_IS' as TypeEnum,
    COMNONE41RFU100: 'COM_NONE_41RFU100' as TypeEnum,
    COM41RFU100: 'COM_41RFU100' as TypeEnum,
    COMNONE41RML100: 'COM_NONE_41RML100' as TypeEnum,
    COM41RML100: 'COM_41RML100' as TypeEnum,
    COM45RCS100: 'COM_45RCS100' as TypeEnum,
    COM45RFU100: 'COM_45RFU100' as TypeEnum,
    COM45RML100: 'COM_45RML100' as TypeEnum,
    COM41PAM000: 'COM_41PAM000' as TypeEnum,
    COM43PAM000: 'COM_43PAM000' as TypeEnum,
    COM45PAM100: 'COM_45PAM100' as TypeEnum
  };
}
