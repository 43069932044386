<div class="modal-header">
  <h2>
    {{
      (!isUnlockingDevice
        ? 'DEVICE.CREATE_NEW_DEVICE'
        : 'DEVICE.UNLOCK_DEVICE'
      ) | translate
    }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!deviceCreated && !deviceCreatedSafe">
    <form [formGroup]="deviceFormGroup">
      <ng-container *ngIf="!isUnlockingDevice">
        <p>{{ 'DEVICE.SELECT_DEVICE_TYPE' | translate }}</p>
        <ng-select
          id="device-type"
          [items]="deviceTypeList"
          bindLabel="name"
          bindValue="type"
          [formControl]="deviceTypeControl"
          [searchable]="false"
          [clearable]="false"
          (change)="deviceTypeChanged($event)"
        ></ng-select>
        <hr />
      </ng-container>

      <ng-container *ngIf="deviceTypeControl.value === 0">
        <p>{{ 'DEVICE.CREATE_NEW_DEVICE_DESCRIPTION' | translate }}</p>
        <label class="custom-label" for="device-id32">{{
          'DEVICE.ID32_CODE' | translate
        }}</label>
        <div class="row">
          <div class="col-10">
            <input
              id="device-id32"
              type="text"
              class="custom-input"
              [placeholder]="'DEVICE.ID32_CODE' | translate"
              [formControl]="deviceCodeControl"
            />
            <app-control-errors
              id="deviceId32"
              controlName="deviceId32"
            ></app-control-errors>
          </div>
          <div class="col-2">
            <app-scanner-button
              (codeRetrieved)="codeRetrieved($event)"
            ></app-scanner-button>
          </div>
        </div>

        <!-- TOADD LATER ON -->
        <!-- <p class="mt-4">
          {{ 'DEVICE.WHERE_TO_FIND_CODE' | translate }}
          <a id="find-more" href="javascript:void(0)" class="small-link">{{
            'DEVICE.FIND_MORE' | translate
          }}</a>
        </p> -->
      </ng-container>
      <ng-container *ngIf="deviceTypeControl.value === 1">
        <p>{{ 'DEVICE.CREATE_NEW_DEVICE_DESCRIPTION_OTP' | translate }}</p>
        <label class="custom-label" for="device-otp">{{
          'DEVICE.OTP' | translate
        }}</label>
        <input
          id="device-otp"
          type="text"
          class="custom-input"
          [placeholder]="'DEVICE.OTP_DESC' | translate"
          [formControl]="deviceOtpControl"
        />
        <app-control-errors
          id="otp-validator"
          controlName="otp"
        ></app-control-errors>

        <!-- TOADD LATER ON -->
        <!-- <p class="mt-4">
        {{ 'DEVICE.WHERE_TO_FIND_OTP' | translate }}
        <a id="find-more" href="javascript:void(0)" class="small-link">{{
          'DEVICE.FIND_MORE' | translate
        }}</a>
      </p> -->
      </ng-container>

      <div class="mt-5 mb-3 d-flex justify-content-end">
        <button id="cancel" class="button-secondary big" (click)="close()">
          {{ 'GLOBAL.CANCEL' | translate }}
        </button>
        <app-loading-button
          id="save"
          buttonClass="button-primary big ml-2"
          [isLoading]="isLoading"
          (click)="save()"
          buttonType="button"
          >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
        >
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="deviceCreated">
    <p *ngIf="!siteId" class="d-block">
      {{ 'DEVICE.DEVICES_CREATED_DESC' | translate }}
    </p>
    <p *ngIf="siteId" class="d-block">
      {{ 'DEVICE.DEVICES_CREATED_DESC_W_SITE' | translate }}
    </p>
    <label class="custom-label" for="device">{{
      'DEVICE.ID32_CODE' | translate
    }}</label>
    <b>{{ this.deviceCodeControl.value }}</b>
    <br />
    <label class="custom-label" for="device-description">{{
      'DEVICE.DESCRIPTION' | translate
    }}</label>
    <input
      id="device-description"
      type="text"
      class="custom-input"
      [placeholder]="'DEVICE.DESCRIPTION' | translate"
      [formControl]="deviceDescControl"
    />

    <ng-container *ngIf="false">
      <br /><br />
      <label for="backup" class="mb-1"
        ><input id="backup" type="checkbox" [formControl]="backupControl" />
        <b> {{ 'DEVICE.MUGSBC.BACKUP_ENABLE' | translate }}</b></label
      >
      <label
        class="custom-label"
        for="device-description"
        (click)="activateSbc()"
        >{{ 'DEVICE.MUGSBC.PASSWORD_ACCOUNT' | translate }}</label
      >
      <input
        id="device-description"
        type="text"
        class="custom-input"
        [placeholder]="'DEVICE.MUGSBC.PASSWORD_ACCOUNT' | translate"
        [formControl]="pswControl"
      />
      <small>{{ 'DEVICE.MUGSBC.PASSWORD_ACCOUNT_DESC' | translate }}</small>
      <!--
     <label class="custom-label" for="device-description">{{
        'DEVICE.MUGSBC.CODICE_ICCID_SIM' | translate
      }}</label>
      <br />
      <b>{{ '1234567891234567891' }}</b>

      <ng-container *ngIf="sbcOffline">
        <b>{{ 'DEVICE.MUGSBC.OFFLINE_DEV' | translate }}</b>
        <p>{{ 'DEVICE.MUGSBC.OFFLINE_DEV_DESC' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="!sbcOffline">
        <p>{{ 'DEVICE.MUGSBC.SIM_INFO' | translate }}</p>
        <app-loading-button
          id="activate"
          buttonClass="button-primary"
          [isLoading]="false"
          (click)="activateSbc()"
          buttonType="button"
          >{{ 'DEVICE.MUGSBC.ACTIVATE' | translate }}</app-loading-button
        >
      </ng-container>
    -->
    </ng-container>

    <div *ngIf="!siteId" class="mt-5 d-flex justify-content-end">
      <button
        id="associate-site"
        class="button-secondary big mr-2"
        (click)="openSiteAssociation()"
      >
        {{ 'SITE.ASSOCIATE_SITE' | translate }}
      </button>
      <button
        id="create-site"
        class="button-secondary big"
        (click)="createSite()"
      >
        {{ 'GLOBAL.CREATE_SITE' | translate }}
      </button>
    </div>
    <div *ngIf="siteId" class="mt-5 d-flex justify-content-end">
      <app-loading-button
        id="associate-site-directly"
        buttonClass="button-primary big"
        [isLoading]="isAssociating"
        (click)="associateWithGivenSite()"
        buttonType="button"
        >{{ 'GLOBAL.COMPLETE' | translate }}</app-loading-button
      >
    </div>
  </ng-container>
  <ng-container *ngIf="deviceCreatedSafe">
    <p class="d-block">
      {{
        (safeDevice.siteAuthenticationId || siteId
          ? 'DEVICE.DEVICES_CREATED_DESC_W_SITE_SAFE'
          : 'DEVICE.DEVICES_CREATED_DESC'
        ) | translate
      }}
    </p>
    <label class="custom-label" for="device">{{
      'DEVICE.OTP' | translate
    }}</label>
    <b>{{ deviceOtpControl.value }}</b>
    <br />
    <label class="custom-label" for="device">{{
      'DEVICE.DETECTED_DEVICE' | translate
    }}</label>
    <b>{{ detectedDevice || '--' }}</b>
    <br />
    <label class="custom-label" for="device-description">{{
      'DEVICE.DESCRIPTION' | translate
    }}</label>
    <b>{{ safeDevice.name || '--' }}</b>
    <ng-container *ngIf="!isUnlockingDevice">
      <div
        *ngIf="!safeDevice.siteAuthenticationId && !siteId"
        class="mt-5 d-flex justify-content-end"
      >
        <button
          id="associate-site"
          class="button-secondary big mr-2"
          (click)="openSiteAssociation()"
        >
          {{ 'SITE.ASSOCIATE_SITE' | translate }}
        </button>
        <button
          id="create-site"
          class="button-secondary big"
          (click)="createSite()"
        >
          {{ 'GLOBAL.CREATE_SITE' | translate }}
        </button>
      </div>
      <div *ngIf="siteId" class="mt-5 d-flex justify-content-end">
        <app-loading-button
          id="associate-site-directly"
          buttonClass="button-primary big"
          [isLoading]="isAssociating"
          (click)="associateWithGivenSite()"
          buttonType="button"
          >{{ 'GLOBAL.COMPLETE' | translate }}</app-loading-button
        >
      </div>
    </ng-container>
    <div
      *ngIf="safeDevice.siteAuthenticationId || isUnlockingDevice"
      class="mt-5 d-flex justify-content-end"
    >
      <button id="cancel" class="button-secondary big" (click)="closeAndLoad()">
        {{ 'GLOBAL.CLOSE' | translate }}
      </button>
    </div>
  </ng-container>
</div>
