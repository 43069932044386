/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Device manager configuration details
 */
export interface DeviceManagerDto {
  /**
   * Application ID
   */
  readonly applid?: string;
  /**
   * IP mode
   */
  readonly ipmode?: number;
  /**
   * Device IP address
   */
  readonly ipaddr?: string;
  /**
   * IP address netmask
   */
  readonly netmask?: string;
  /**
   * Default gateway IP address
   */
  readonly gwaddr?: string;
  /**
   * Gateway enabled
   */
  readonly gwenable?: boolean;
  /**
   * WLAN0 IP mode
   */
  readonly wlan0ipmode?: number;
  /**
   * Device WLAN0 IP address
   */
  readonly wlan0ipaddr?: string;
  /**
   * WLAN0 IP address netmask
   */
  readonly wlan0netmask?: string;
  /**
   * WLAN0 Default gateway IP address
   */
  readonly wlan0gwaddr?: string;
  /**
   * WLAN0 Gateway enabled
   */
  readonly wlan0gwenable?: boolean;
  /**
   * OUT1 IP mode
   */
  readonly out1ipmode?: number;
  /**
   * Device OUT1 IP address
   */
  readonly out1ipaddr?: string;
  /**
   * OUT1 IP address netmask
   */
  readonly out1netmask?: string;
  /**
   * OUT1 Default gateway IP address
   */
  readonly out1gwaddr?: string;
  /**
   * OUT1 Gateway enabled
   */
  readonly out1gwenable?: boolean;
  /**
   * DNS1 IP address
   */
  readonly dns1?: string;
  /**
   * DNS2 IP address
   */
  dns2?: string;
  /**
   * VIP Interface
   */
  readonly vipitf?: number;
  /**
   * SSH port
   */
  sshport?: number;
  /**
   * Device description
   */
  devicedescr?: string;
  /**
   * Device MAC address
   */
  readonly ethmac?: string;
  /**
   * HW ID
   */
  readonly hwid?: string;
  /**
   * Log VIP address
   */
  readonly logaddr?: string;
  /**
   * Model ID
   */
  readonly modelid?: string;
  /**
   * Subaddress
   */
  readonly subaddr?: number;
  /**
   * System ID
   */
  readonly systemid?: string;
  /**
   * Version code
   */
  readonly versioncode?: number;
  /**
   * Version string
   */
  readonly versionstr?: string;
}
