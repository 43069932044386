import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codeFormat'
})
export class CodeFormatPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return `${value.slice(0, 4)}${value.length > 4 ? '-' : ''}${value.slice(
      4,
      8
    )}${value.length > 8 ? '-' : ''}${value.slice(8, 12)}${
      value.length > 12 ? '-' : ''
    }${value.slice(12, 16)}`;
  }
}
