<div class="modal-header">
  <h2>
    {{ 'APARTMENT.PASSWORD_RESETTED' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label class="custom-label" for="device-id32">{{
    'APARTMENT.APARTMENT_DESCRIPTION' | translate
  }}</label>
  <br />
  <b>{{ apartmentName || '--' }}</b>
  <br /><br />
  <label class="custom-label" for="device-id32">{{
    'GLOBAL.NEW_PASSWORD' | translate
  }}</label>
  <br /><b>{{ password }}</b>
</div>
<div class="modal-footer">
  <button class="button-primary big" (click)="close()">
    {{ 'GLOBAL.OK' | translate }}
  </button>
</div>
