/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License renew code generation response
 */
export interface RenewCodeGenerationResponseDto {
  /**
   * Renew code
   */
  code: string;
  /**
   * Referenced license
   */
  license: string;
  /**
   * Duration
   */
  duration: string;
}
