import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  DirectoriesService,
  EntranceDirectoryDataDto
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ExtPlaceModalComponent } from './ext-place-modal/ext-place-modal.component';

@Component({
  selector: 'app-internal-place-card',
  templateUrl: './internal-place-card.component.html',
  styleUrls: ['./internal-place-card.component.scss']
})
export class InternalPlaceCardComponent implements OnInit {
  @Input() deviceId: string;
  @Input() dirId: number;
  columns: TableColumn[] = [];
  intercoms: EntranceDirectoryDataDto[] = [];
  dataSource = new ClientFiltrableDataSource<EntranceDirectoryDataDto>();
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;

  get isMobile() {
    return isMobile;
  }

  constructor(
    private ts: TranslateService,
    private dirService: DirectoriesService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('ADDRESSBOOK.DESCRIPTION')
      },
      {
        prop: 'id',
        cellTemplate: this.actionsTpl,
        maxWidth: 150,
        name: this.ts.instant('GLOBAL.ACTIONS')
      }
    ];
    this.getIntercoms();
  }

  getIntercoms() {
    this.dirService
      .getEntrances(this.deviceId, this.dirId, this.authService.getToken())
      .subscribe((data: EntranceDirectoryDataDto[]) => {
        this.dataSource.setItems(data);
        this.intercoms = data;
      });
  }

  edit(row: EntranceDirectoryDataDto) {
    const initialState: Partial<ExtPlaceModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.INTERNAL_PLACE'),
      data: row
    };

    const modalRef = this.modalService.show(ExtPlaceModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: EntranceDirectoryDataDto) => {
      this.intercoms.splice(
        this.intercoms.findIndex((x) => x.id === row.id),
        1,
        v
      );
      this.dirService
        .setEntrances(this.deviceId, this.dirId, this.authService.getToken(), {
          entrances: this.intercoms
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.INTERCOMS_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getIntercoms();
        });
    });
  }

  delete(row: EntranceDirectoryDataDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_INTERNAL_PLACE'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_INTERNAL_PLACE_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.dirService
        .deleteEntrance(
          this.deviceId,
          this.dirId,
          row.id,
          this.authService.getToken()
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.INTERCOMS_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getIntercoms();
        });
    });
  }
}
