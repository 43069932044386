/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Transfer a site ownership to a new user
 */
export interface TransferSiteDto {
  /**
   * Site new owner email (must be an existing cloud user)
   */
  newOwnerEmail: string;
  /**
   * Groups to which the new owner must be inserted in, identified by their authenticationId
   */
  groupsAuthIdsToGive: Array<string>;
  /**
   * Optional groups to which hte old owner must be inserted in, identified by their authenticationId
   */
  groupsAuthIdsToKeep?: Array<string>;
}
