import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  ActuatorDirectoryDataDto,
  DirectoriesService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { CreateActuatorModalComponent } from './create-actuator-modal/create-actuator-modal.component';

@Component({
  selector: 'app-actuators-card',
  templateUrl: './actuators-card.component.html',
  styleUrls: ['./actuators-card.component.scss']
})
export class ActuatorsCardComponent implements OnInit {
  @Input() deviceId: string;
  @Input() dirId: number;
  columns: TableColumn[] = [];
  dataSource = new ClientFiltrableDataSource<ActuatorDirectoryDataDto>();
  actuators: ActuatorDirectoryDataDto[] = [];
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('addrTpl', { static: true }) addrTpl: ElementRef<HTMLElement>;

  get isMobile() {
    return isMobile;
  }

  constructor(
    private ts: TranslateService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private authService: AuthService,
    private dirService: DirectoriesService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('ADDRESSBOOK.DESCRIPTION')
      },
      {
        prop: 'addr',
        name: this.ts.instant('ADDRESSBOOK.ADDRESS'),
        cellTemplate: this.addrTpl
      },
      {
        prop: 'id',
        cellTemplate: this.actionsTpl,
        maxWidth: 150,
        name: this.ts.instant('GLOBAL.ACTIONS')
      }
    ];

    this.getActuators();
  }

  getActuators() {
    this.dirService
      .getActuators(this.deviceId, this.dirId, this.authService.getToken())
      .subscribe((act: ActuatorDirectoryDataDto[]) => {
        this.dataSource.setItems(act);
        this.dataSource.limit = act.length;
        this.dataSource.loadData();
        this.actuators = act;
      });
  }

  addActuator() {
    const initialState: Partial<CreateActuatorModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.ACTUATOR_CREATE'),
      dirId: this.dirId,
      id: this.calculateNextId()
    };

    const modalRef = this.modalService.show(CreateActuatorModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: ActuatorDirectoryDataDto) => {
      this.actuators = this.actuators.concat([v]);
      this.dirService
        .setActuators(this.deviceId, this.dirId, this.authService.getToken(), {
          actuators: this.actuators
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.ACTUATOR_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getActuators();
        });
    });
  }

  calculateNextId() {
    let newId = 0;
    this.actuators.forEach((x: ActuatorDirectoryDataDto) => {
      if (x.id > newId) {
        newId = x.id;
      }
    });
    return newId + 1;
  }
  edit(row: ActuatorDirectoryDataDto) {
    const initialState: Partial<CreateActuatorModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.ACTUATOR_EDIT'),
      actuator: row,
      dirId: this.dirId
    };

    const modalRef = this.modalService.show(CreateActuatorModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: ActuatorDirectoryDataDto) => {
      this.actuators.splice(
        this.actuators.findIndex((x) => x.id === row.id),
        1,
        v
      );
      this.dirService
        .setActuators(this.deviceId, this.dirId, this.authService.getToken(), {
          actuators: this.actuators
        })
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.ACTUATOR_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getActuators();
        });
    });
  }

  delete(row: ActuatorDirectoryDataDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_ACTUATOR'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_ACTUATOR_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.dirService
        .deleteActuator(
          this.deviceId,
          this.dirId,
          row.id,
          this.authService.getToken()
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.ACTUATOR_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getActuators();
        });
    });
  }
}
