<div class="container-fluid">
  <div id="header-btns"></div>
  <h1>{{ pageName | translate }}</h1>

  <div class="row mt-3">
    <div class="col-12 col-xl-9">
      <div id="addr-book-selection" class="mt-3">
        <ng-select
          id="address-book"
          [items]="addrBookList"
          bindLabel="descr"
          [formControl]="addrBookControl"
          [searchable]="false"
          [clearable]="false"
        ></ng-select>
        <div id="button-box">
          <button id="new" class="button-secondary" (click)="new()">
            {{ 'GLOBAL.NEW' | translate }}
          </button>
          <button
            id="edit"
            [disabled]="!addrBookControl.value || false"
            class="button-secondary"
            (click)="edit()"
          >
            {{ 'GLOBAL.EDIT' | translate }}
          </button>
          <!-- <button
            id="copy"
            [disabled]="!addrBookControl.value || false"
            class="button-secondary"
            (click)="copy()"
          >
            {{ 'GLOBAL.COPY' | translate }}
          </button> -->
          <button
            id="delete"
            [disabled]="
              !addrBookControl.value || addrBookList.length === 1 || false
            "
            class="button-secondary"
            (click)="delete()"
          >
            {{ 'GLOBAL.DELETE' | translate }}
          </button>
        </div>
      </div>
      <ng-container *ngIf="dirId !== undefined">
        <app-internal-place-card
          [deviceId]="deviceId"
          [dirId]="dirId"
        ></app-internal-place-card>
        <app-opendoor-card
          [deviceId]="deviceId"
          [dirId]="dirId"
        ></app-opendoor-card>
        <app-switchboard-card
          [deviceId]="deviceId"
          [dirId]="dirId"
        ></app-switchboard-card>
        <app-camera-card
          [deviceId]="deviceId"
          [dirId]="dirId"
        ></app-camera-card>
        <app-actuators-card
          [deviceId]="deviceId"
          [dirId]="dirId"
        ></app-actuators-card>
        <app-button-card
          [deviceId]="deviceId"
          [dirId]="dirId"
        ></app-button-card>
      </ng-container>
    </div>

    <div class="col-12 col-xl-3 mt-3">
      <app-site-info [site]="site"></app-site-info>
    </div>
  </div>
</div>
