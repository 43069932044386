/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ErrorDto } from '../model/models';
import { LicenseInstanceDto } from '../model/models';
import { LicenseMigrateDto } from '../model/models';
import { LicenseRenewDto } from '../model/models';
import { LicenseRouterUpdateDto } from '../model/models';
import { MobexOptionsDto } from '../model/models';
import { SBCOptionsDto } from '../model/models';
import { TimOptionsDto } from '../model/models';

import { LICENSES_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class LicenseInstanceManagementDeviceService {
  protected basePath =
    'https://usvc-preprod.cloud.comelitgroup.com/servicerest/licensemanager';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(LICENSES_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Create new license instance for a specific device
   * @param licenseUuid Referenced License UUID
   * @param deviceUuid License device_uuid UUID
   * @param mobexOptionsDtoSBCOptionsDtoTimOptionsDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDeviceLicenseInstance(
    licenseUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ErrorDto>;
  public createDeviceLicenseInstance(
    licenseUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ErrorDto>>;
  public createDeviceLicenseInstance(
    licenseUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ErrorDto>>;
  public createDeviceLicenseInstance(
    licenseUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (licenseUuid === null || licenseUuid === undefined) {
      throw new Error(
        'Required parameter licenseUuid was null or undefined when calling createDeviceLicenseInstance.'
      );
    }
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling createDeviceLicenseInstance.'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<ErrorDto>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/license/${encodeURIComponent(String(licenseUuid))}/licenseinstance`,
      mobexOptionsDtoSBCOptionsDtoTimOptionsDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete license instance for a specific device
   * @param licenseInstanceUuid License instance UUID
   * @param deviceUuid License device_uuid UUID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDeviceLicenseInstance(
    licenseInstanceUuid: string,
    deviceUuid: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ErrorDto>;
  public deleteDeviceLicenseInstance(
    licenseInstanceUuid: string,
    deviceUuid: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ErrorDto>>;
  public deleteDeviceLicenseInstance(
    licenseInstanceUuid: string,
    deviceUuid: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ErrorDto>>;
  public deleteDeviceLicenseInstance(
    licenseInstanceUuid: string,
    deviceUuid: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (licenseInstanceUuid === null || licenseInstanceUuid === undefined) {
      throw new Error(
        'Required parameter licenseInstanceUuid was null or undefined when calling deleteDeviceLicenseInstance.'
      );
    }
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling deleteDeviceLicenseInstance.'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<ErrorDto>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/licenseinstance/${encodeURIComponent(String(licenseInstanceUuid))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get license instances by referenced license for a specific device
   * @param licenseUuid Referenced license UUID
   * @param deviceUuid License device_uuid UUID
   * @param status License instance status
   * @param limit Limit
   * @param offset Offset
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceLicenseInstancesByReferencedLicenseUuid(
    licenseUuid: string,
    deviceUuid: string,
    status?: Array<
      | 'CREATED'
      | 'ENABLED'
      | 'EXPIRED'
      | 'EXPIRING'
      | 'DISABLED'
      | 'DELETED'
      | 'RENEWED'
      | 'TRANSFERRED'
    >,
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<LicenseInstanceDto>>;
  public getDeviceLicenseInstancesByReferencedLicenseUuid(
    licenseUuid: string,
    deviceUuid: string,
    status?: Array<
      | 'CREATED'
      | 'ENABLED'
      | 'EXPIRED'
      | 'EXPIRING'
      | 'DISABLED'
      | 'DELETED'
      | 'RENEWED'
      | 'TRANSFERRED'
    >,
    limit?: number,
    offset?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<LicenseInstanceDto>>>;
  public getDeviceLicenseInstancesByReferencedLicenseUuid(
    licenseUuid: string,
    deviceUuid: string,
    status?: Array<
      | 'CREATED'
      | 'ENABLED'
      | 'EXPIRED'
      | 'EXPIRING'
      | 'DISABLED'
      | 'DELETED'
      | 'RENEWED'
      | 'TRANSFERRED'
    >,
    limit?: number,
    offset?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<LicenseInstanceDto>>>;
  public getDeviceLicenseInstancesByReferencedLicenseUuid(
    licenseUuid: string,
    deviceUuid: string,
    status?: Array<
      | 'CREATED'
      | 'ENABLED'
      | 'EXPIRED'
      | 'EXPIRING'
      | 'DISABLED'
      | 'DELETED'
      | 'RENEWED'
      | 'TRANSFERRED'
    >,
    limit?: number,
    offset?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (licenseUuid === null || licenseUuid === undefined) {
      throw new Error(
        'Required parameter licenseUuid was null or undefined when calling getDeviceLicenseInstancesByReferencedLicenseUuid.'
      );
    }
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling getDeviceLicenseInstancesByReferencedLicenseUuid.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (status) {
      status.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'status'
        );
      });
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<LicenseInstanceDto>>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/license/${encodeURIComponent(String(licenseUuid))}/licenseinstances`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Migrate all device\&#39;s licenses to a new device.
   * @param deviceUuid License device_uuid UUID
   * @param licenseMigrateDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public migrateDeviceLicenseInstance(
    deviceUuid: string,
    licenseMigrateDto?: LicenseMigrateDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ErrorDto>;
  public migrateDeviceLicenseInstance(
    deviceUuid: string,
    licenseMigrateDto?: LicenseMigrateDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ErrorDto>>;
  public migrateDeviceLicenseInstance(
    deviceUuid: string,
    licenseMigrateDto?: LicenseMigrateDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ErrorDto>>;
  public migrateDeviceLicenseInstance(
    deviceUuid: string,
    licenseMigrateDto?: LicenseMigrateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling migrateDeviceLicenseInstance.'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<ErrorDto>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/migrate`,
      licenseMigrateDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Renew license instance for a specific device
   * @param deviceUuid License device_uuid UUID
   * @param licenseRenewDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public renewDeviceLicenseInstance(
    deviceUuid: string,
    licenseRenewDto?: LicenseRenewDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ErrorDto>;
  public renewDeviceLicenseInstance(
    deviceUuid: string,
    licenseRenewDto?: LicenseRenewDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ErrorDto>>;
  public renewDeviceLicenseInstance(
    deviceUuid: string,
    licenseRenewDto?: LicenseRenewDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ErrorDto>>;
  public renewDeviceLicenseInstance(
    deviceUuid: string,
    licenseRenewDto?: LicenseRenewDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling renewDeviceLicenseInstance.'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<ErrorDto>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/licenseinstance/renew`,
      licenseRenewDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update license instance for a specific device
   * @param licenseInstanceUuid Referenced License UUID
   * @param deviceUuid License device_uuid UUID
   * @param mobexOptionsDtoSBCOptionsDtoTimOptionsDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDeviceLicenseInstanceMetadata(
    licenseInstanceUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ErrorDto>;
  public updateDeviceLicenseInstanceMetadata(
    licenseInstanceUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ErrorDto>>;
  public updateDeviceLicenseInstanceMetadata(
    licenseInstanceUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ErrorDto>>;
  public updateDeviceLicenseInstanceMetadata(
    licenseInstanceUuid: string,
    deviceUuid: string,
    mobexOptionsDtoSBCOptionsDtoTimOptionsDto?:
      | MobexOptionsDto
      | SBCOptionsDto
      | TimOptionsDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (licenseInstanceUuid === null || licenseInstanceUuid === undefined) {
      throw new Error(
        'Required parameter licenseInstanceUuid was null or undefined when calling updateDeviceLicenseInstanceMetadata.'
      );
    }
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling updateDeviceLicenseInstanceMetadata.'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<ErrorDto>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/license/licenseinstance/${encodeURIComponent(
        String(licenseInstanceUuid)
      )}/metadata`,
      mobexOptionsDtoSBCOptionsDtoTimOptionsDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update router license instance details for a specific device
   * @param licenseInstanceUuid Referenced License UUID
   * @param deviceUuid License device_uuid UUID
   * @param licenseRouterUpdateDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDeviceLicenseInstanceRouter(
    licenseInstanceUuid: string,
    deviceUuid: string,
    licenseRouterUpdateDto?: LicenseRouterUpdateDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ErrorDto>;
  public updateDeviceLicenseInstanceRouter(
    licenseInstanceUuid: string,
    deviceUuid: string,
    licenseRouterUpdateDto?: LicenseRouterUpdateDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ErrorDto>>;
  public updateDeviceLicenseInstanceRouter(
    licenseInstanceUuid: string,
    deviceUuid: string,
    licenseRouterUpdateDto?: LicenseRouterUpdateDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ErrorDto>>;
  public updateDeviceLicenseInstanceRouter(
    licenseInstanceUuid: string,
    deviceUuid: string,
    licenseRouterUpdateDto?: LicenseRouterUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (licenseInstanceUuid === null || licenseInstanceUuid === undefined) {
      throw new Error(
        'Required parameter licenseInstanceUuid was null or undefined when calling updateDeviceLicenseInstanceRouter.'
      );
    }
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling updateDeviceLicenseInstanceRouter.'
      );
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<ErrorDto>(
      `${this.configuration.basePath}/v2/device/${encodeURIComponent(
        String(deviceUuid)
      )}/license/licenseinstance/${encodeURIComponent(
        String(licenseInstanceUuid)
      )}/router`,
      licenseRouterUpdateDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
