import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BuzzerService } from './api/buzzer.service';
import { ControlPanelService } from './api/controlPanel.service';
import { DelayService } from './api/delay.service';
import { DetectorService } from './api/detector.service';
import { DeviceService } from './api/device.service';
import { DeviceHistoryService } from './api/deviceHistory.service';
import { EventService } from './api/event.service';
import { IoModuleService } from './api/ioModule.service';
import { LoopService } from './api/loop.service';
import { OutputService } from './api/output.service';
import { PanelService } from './api/panel.service';
import { PowerService } from './api/power.service';
import { SounderService } from './api/sounder.service';
import { StatisticService } from './api/statistic.service';
import { TestService } from './api/test.service';
import { ZoneService } from './api/zone.service';
import { ZoneHistoryService } from './api/zoneHistory.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: []
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
