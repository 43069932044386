/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LicenseDataDto } from './licenseDataDto';
import { MobexOptionsDtoAllOf } from './mobexOptionsDtoAllOf';

/**
 * Mobex-related SIP license options DTO
 */
export interface MobexOptionsDto {
  /**
   * License expiry fixed date
   */
  fixedDate?: string;
  /**
   * Device Authentication ID to which the license is attached to
   */
  deviceAuthId?: string;
  /**
   * Authentication ID of the user which the license belongs
   */
  holderAuthId?: string;
  /**
   * Duration unit of the license
   */
  durationUnit?: MobexOptionsDto.DurationUnitEnum;
  /**
   * Duration value
   */
  duration?: number;
  /**
   * Description
   */
  description?: string;
  /**
   * optional list of email of users to be notified on license changes
   */
  userToNotifyList?: Array<string>;
  /**
   * Optional custom <key, value> pairs of license-specific properties
   */
  customData?: { [key: string]: object };
  /**
   * Identify the DTO type to be serialized/deserialized, please use the value provided in the example
   */
  dto_type: MobexOptionsDto.DtoTypeEnum;
  /**
   * Number of lines to be activated
   */
  lines: number;
}
export namespace MobexOptionsDto {
  export type DurationUnitEnum =
    | 'MINUTES'
    | 'HOURS'
    | 'DAYS'
    | 'MONTHS'
    | 'YEARS'
    | 'FIXED_DATE'
    | 'PERPETUAL';
  export const DurationUnitEnum = {
    MINUTES: 'MINUTES' as DurationUnitEnum,
    HOURS: 'HOURS' as DurationUnitEnum,
    DAYS: 'DAYS' as DurationUnitEnum,
    MONTHS: 'MONTHS' as DurationUnitEnum,
    YEARS: 'YEARS' as DurationUnitEnum,
    FIXEDDATE: 'FIXED_DATE' as DurationUnitEnum,
    PERPETUAL: 'PERPETUAL' as DurationUnitEnum
  };
  export type DtoTypeEnum =
    | 'create_bundle'
    | 'create_mobex'
    | 'update_mobex'
    | 'create_sbc'
    | 'update_sbc'
    | 'create_tim'
    | 'renew'
    | 'update_router';
  export const DtoTypeEnum = {
    CreateBundle: 'create_bundle' as DtoTypeEnum,
    CreateMobex: 'create_mobex' as DtoTypeEnum,
    UpdateMobex: 'update_mobex' as DtoTypeEnum,
    CreateSbc: 'create_sbc' as DtoTypeEnum,
    UpdateSbc: 'update_sbc' as DtoTypeEnum,
    CreateTim: 'create_tim' as DtoTypeEnum,
    Renew: 'renew' as DtoTypeEnum,
    UpdateRouter: 'update_router' as DtoTypeEnum
  };
}
