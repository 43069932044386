/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SafeAnomalyStateDto {
  /**
   * Zone fault (vedo: Y, hub: Y, eclipse: ?)
   */
  zoneFault?: boolean;
  /**
   * Bell Fault (vedo: Y, hub: Y, eclipse: ?)
   */
  bellFault?: boolean;
  /**
   * Remote Fault (vedo: Y, hub: Y, eclipse: ?)
   */
  remoteFault?: boolean;
  /**
   * Bus power supply fuse fault (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyFuseFault?: boolean;
  /**
   * Bus power supply battery fault (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyBatteryFault?: boolean;
  /**
   * Radio output battery fault (vedo: Y, hub: Y, eclipse: ?)
   */
  radioOutputBatteryFault?: boolean;
  /**
   * Bell battery fault (vedo: Y, hub: Y, eclipse: ?)
   */
  bellBatteryFault?: boolean;
  /**
   * Bus power supply network fault (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyNetworkFault?: boolean;
  /**
   * Radio zone battery fault (vedo: Y, hub: Y, eclipse: ?)
   */
  radioZoneBatteryFault?: boolean;
  /**
   * Remote battery fault (vedo: Y, hub: Y, eclipse: ?)
   */
  remoteBatteryFault?: boolean;
  /**
   * Bus power supply anomalous voltage level - Input (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyAnomalousVoltageLevelInput?: boolean;
  /**
   * Bus power supply anomalous voltage level - Output (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyAnomalousVoltageLevelOutput?: boolean;
  /**
   * Radio zones monitoring (vedo: Y, hub: Y, eclipse: ?)
   */
  radioZonesMonitoring?: boolean;
  /**
   * Radio outputs monitoring (vedo: Y, hub: Y, eclipse: ?)
   */
  radioOutputsMonitoring?: boolean;
  /**
   * Output fault (vedo: Y, hub: Y, eclipse: ?)
   */
  outputFault?: boolean;
  /**
   * Camera fault (vedo: Y, hub: Y, eclipse: ?)
   */
  cameraFault?: boolean;
  /**
   * GSM bus expansion - No network (vedo: Y, hub: Y, eclipse: ?)
   */
  gsmBusExpansionNoNetwork?: boolean;
  /**
   * Bus telephone line fault (vedo: Y, hub: Y, eclipse: ?)
   */
  busTelephoneLineFault?: boolean;
  /**
   * Communication failed (vedo: Y, hub: Y, eclipse: ?)
   */
  communicationFailed?: boolean;
  /**
   * Robbery zone Fault (vedo: Y, hub: Y, eclipse: ?)
   */
  robberyZoneFault?: boolean;
  /**
   * Activation failed (vedo: Y, hub: Y, eclipse: ?)
   */
  activationFailed?: boolean;
  /**
   * IP bus expansion fault (vedo: Y, hub: N, eclipse: ?)
   */
  ipBusExpansionFault?: boolean;
  /**
   * Movement sensor jamming (vedo: Y, hub: N, eclipse: ?)
   */
  movementSensorJamming?: boolean;
  /**
   * Repeater supervision (vedo: ?, hub: Y, eclipse: ?)
   */
  repeaterSupervision?: boolean;
  /**
   * Repeater battery (vedo: ?, hub: Y, eclipse: ?)
   */
  repeaterBattery?: boolean;
  /**
   * Input/Output expansion fault (vedo: Y, hub: N, eclipse: ?)
   */
  inputOutputExpansionFault?: boolean;
  /**
   * Keyboard fault (vedo: Y, hub: N, eclipse: ?)
   */
  keyboardFault?: boolean;
  /**
   * RFID reader fault (vedo: Y, hub: N, eclipse: ?)
   */
  rfidReaderFault?: boolean;
  /**
   * Safetouch fault (vedo: Y, hub: N, eclipse: ?)
   */
  safetouchFault?: boolean;
  /**
   * Generic touch device fault - The device can be: Planux, Minitouch, Icona or Maxi (vedo: Y, hub: N, eclipse: ?)
   */
  genericTouchDeviceFault?: boolean;
  /**
   * Bus power supply isolator fault (vedo: Y, hub: N, eclipse: ?)
   */
  busPowerSupplyIsolatorFault?: boolean;
  /**
   * Radio receiver fault (vedo: Y, hub: Y, eclipse: ?)
   */
  radioReceiverFault?: boolean;
  /**
   * Bus bell fault (vedo: Y, hub: N, eclipse: ?)
   */
  busBellFault?: boolean;
  /**
   * Bus alarm notification device fault (vedo: Y, hub: N, eclipse: ?)
   */
  busAlarmNotificationDeviceFault?: boolean;
  /**
   * IP expansion fault (vedo: Y, hub: N, eclipse: ?)
   */
  ipExpansionFault?: boolean;
  /**
   * IP video expansion fault (vedo: Y, hub: N, eclipse: ?)
   */
  ipVideoExpansionFault?: boolean;
  /**
   * Repeater power  fault (vedo: ?, hub: Y, eclipse: ?)
   */
  repeaterPowerFault?: boolean;
  /**
   * Repeater fault (vedo: ?, hub: Y, eclipse: ?)
   */
  repeaterFault?: boolean;
  /**
   * Control panel battery fault (vedo: Y, hub: x fault, eclipse: ?)
   */
  controlPanelBatteryFault?: boolean;
  /**
   * Main power supply fault (vedo: Y, hub: Y, eclipse: ?)
   */
  mainPowerSupplyFault?: boolean;
  /**
   * PSTN fault (vedo: Y, hub: N, eclipse: ?)
   */
  pstnFault?: boolean;
  /**
   * GSM fault (vedo: Y, hub: Y, eclipse: ?)
   */
  gsmFault?: boolean;
  /**
   * IP fault (vedo: Y, hub: N, eclipse: ?)
   */
  ipFault?: boolean;
  /**
   * Voice synthesis fault (vedo: Y, hub: Y, eclipse: ?)
   */
  voiceSynthesisFault?: boolean;
  /**
   * Intercom fault (vedo: Y, hub: N, eclipse: ?)
   */
  intercomFault?: boolean;
  /**
   * Domotics fault (vedo: Y, hub: N, eclipse: ?)
   */
  domoticsFault?: boolean;
  /**
   * Aux fuse fault (vedo: Y, hub: N, eclipse: ?)
   */
  auxFuseFault?: boolean;
  /**
   * Bus fuse fault (vedo: Y, hub: N, eclipse: ?)
   */
  busFuseFault?: boolean;
  /**
   * Bell fuse fault (vedo: Y, hub: N, eclipse: ?)
   */
  bellFuseFault?: boolean;
  /**
   * Voltage level low (vedo: Y, hub: N, eclipse: ?)
   */
  voltageLevelLow?: boolean;
  /**
   * Control panel bus fault (vedo: N, hub: N, eclipse: ?)
   */
  controlPanelBusFault?: boolean;
  /**
   * Telephone line fault (vedo: Y, hub: N, eclipse: ?)
   */
  telephoneLineFault?: boolean;
  /**
   * GSM network fault (vedo: Y, hub: Y, eclipse: ?)
   */
  gsmNetworkFault?: boolean;
  /**
   * GPRS network fault (vedo: Y, hub: Y, eclipse: ?)
   */
  gprsNetworkFault?: boolean;
  /**
   * Date and time not set (vedo: Y, hub: Y, eclipse: ?)
   */
  dateAndTimeNotSet?: boolean;
  /**
   * SD card removed (vedo: ?, hub: Y, eclipse: ?)
   */
  sdCardRemoved?: boolean;
  /**
   * SD card failure - read write failure on SD card (vedo: ?, hub: Y, eclipse: ?)
   */
  sdCardFailure?: boolean;
  /**
   * Cloud connection fault (vedo: Y, hub: Y, eclipse: Y)
   */
  cloudConnectionFault?: boolean;
  /**
   * Sim Card Abscent (vedo: ?, hub: Y, eclipse: ?)
   */
  simCardAbscent?: boolean;
  /**
   * GSM low signal (vedo: ?, hub: Y, eclipse: ?)
   */
  gsmLowSignal?: boolean;
  /**
   * Wifi network failure (vedo: ?, hub: Y, eclipse: ?)
   */
  wifiNetworkFailure?: boolean;
  /**
   * Wifi low signal (vedo: ?, hub: Y, eclipse: ?)
   */
  wifiLowSignal?: boolean;
  /**
   * Wifi disabled (vedo: ?, hub: Y, eclipse: ?)
   */
  wifiDisabled?: boolean;
  /**
   * Ethernet network fault (vedo: ?, hub: Y, eclipse: ?)
   */
  ethernetNetworkFault?: boolean;
  /**
   * Ethernet disconnected (vedo: ?, hub: Y, eclipse: ?)
   */
  ethernetDisconnected?: boolean;
}
