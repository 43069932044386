/**
 * CCAPI HTTP /servicerest/log
 * CCAPI HTTP /servicerest/log
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface MultipartFile {
  name?: string;
  empty?: boolean;
  bytes?: Array<string>;
  size?: number;
  inputStream?: object;
  originalFilename?: string;
  contentType?: string;
}
