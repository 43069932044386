import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-devices',
  templateUrl: './dashboard-devices.component.html',
  styleUrls: ['./dashboard-devices.component.scss']
})
export class DashboardDevicesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
