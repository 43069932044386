/**
 * CCAPI HTTP /servicerest/log
 * CCAPI HTTP /servicerest/log
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ByteArrayResource } from '../model/models';
import { ErrorDto } from '../model/models';

import { LOGS_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class CCAPIHTTPServicerestDownloadTracesFromS3Service {
  protected basePath = 'https://usvc-preprod.cloud.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(LOGS_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * log/downloadTracesFromS3
   * Download traces file from S3
   * @param type Target type
   * @param id Target
   * @param uuid File uuid
   * @param authorization Authorization with ccs token
   * @param apikey Authorization with apikey
   * @param clientId Client Id
   * @param numUtilisateur Num Utilisateur
   * @param email Email
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public logDownloadTracesFromS3(
    type: string,
    id: string,
    uuid: string,
    authorization?: string,
    apikey?: string,
    clientId?: number,
    numUtilisateur?: number,
    email?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/octet-stream' | 'application/json';
    }
  ): Observable<ByteArrayResource>;
  public logDownloadTracesFromS3(
    type: string,
    id: string,
    uuid: string,
    authorization?: string,
    apikey?: string,
    clientId?: number,
    numUtilisateur?: number,
    email?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/octet-stream' | 'application/json';
    }
  ): Observable<HttpResponse<ByteArrayResource>>;
  public logDownloadTracesFromS3(
    type: string,
    id: string,
    uuid: string,
    authorization?: string,
    apikey?: string,
    clientId?: number,
    numUtilisateur?: number,
    email?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/octet-stream' | 'application/json';
    }
  ): Observable<HttpEvent<ByteArrayResource>>;
  public logDownloadTracesFromS3(
    type: string,
    id: string,
    uuid: string,
    authorization?: string,
    apikey?: string,
    clientId?: number,
    numUtilisateur?: number,
    email?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/octet-stream' | 'application/json';
    }
  ): Observable<any> {
    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling logDownloadTracesFromS3.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling logDownloadTracesFromS3.'
      );
    }
    if (uuid === null || uuid === undefined) {
      throw new Error(
        'Required parameter uuid was null or undefined when calling logDownloadTracesFromS3.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (uuid !== undefined && uuid !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>uuid,
        'uuid'
      );
    }
    if (clientId !== undefined && clientId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>clientId,
        'clientId'
      );
    }
    if (numUtilisateur !== undefined && numUtilisateur !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>numUtilisateur,
        'numUtilisateur'
      );
    }
    if (email !== undefined && email !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>email,
        'email'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/octet-stream',
        'application/json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<ByteArrayResource>(
      `${
        this.configuration.basePath
      }/servicerest/log/downloadTracesFromS3/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
