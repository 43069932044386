export * from './areaCommands.service';
import { AreaCommandsService } from './areaCommands.service';
export * from './generalCommands.service';
import { GeneralCommandsService } from './generalCommands.service';
export * from './log.service';
import { LogService } from './log.service';
export * from './login.service';
import { LoginService } from './login.service';
export * from './logout.service';
import { LogoutService } from './logout.service';
export * from './outputCommands.service';
import { OutputCommandsService } from './outputCommands.service';
export * from './peripherals.service';
import { PeripheralsService } from './peripherals.service';
export * from './prosteCommands.service';
import { ProsteCommandsService } from './prosteCommands.service';
export * from './realtimeAnomalies.service';
import { RealtimeAnomaliesService } from './realtimeAnomalies.service';
export * from './realtimeAreas.service';
import { RealtimeAreasService } from './realtimeAreas.service';
export * from './realtimeCameras.service';
import { RealtimeCamerasService } from './realtimeCameras.service';
export * from './realtimeControlPanelUsers.service';
import { RealtimeControlPanelUsersService } from './realtimeControlPanelUsers.service';
export * from './realtimeGenerics.service';
import { RealtimeGenericsService } from './realtimeGenerics.service';
export * from './realtimeOutputs.service';
import { RealtimeOutputsService } from './realtimeOutputs.service';
export * from './realtimeRepeaters.service';
import { RealtimeRepeatersService } from './realtimeRepeaters.service';
export * from './realtimeRfidKeys.service';
import { RealtimeRfidKeysService } from './realtimeRfidKeys.service';
export * from './realtimeSabotages.service';
import { RealtimeSabotagesService } from './realtimeSabotages.service';
export * from './realtimeScenarios.service';
import { RealtimeScenariosService } from './realtimeScenarios.service';
export * from './realtimeUserAccounts.service';
import { RealtimeUserAccountsService } from './realtimeUserAccounts.service';
export * from './realtimeZoneAlarms.service';
import { RealtimeZoneAlarmsService } from './realtimeZoneAlarms.service';
export * from './realtimeZones.service';
import { RealtimeZonesService } from './realtimeZones.service';
export * from './testCommand.service';
import { TestCommandService } from './testCommand.service';
export * from './testPost.service';
import { TestPostService } from './testPost.service';
export * from './testRealtime.service';
import { TestRealtimeService } from './testRealtime.service';
export * from './zoneCommands.service';
import { ZoneCommandsService } from './zoneCommands.service';
export const APIS = [
  AreaCommandsService,
  GeneralCommandsService,
  LogService,
  LoginService,
  LogoutService,
  OutputCommandsService,
  PeripheralsService,
  ProsteCommandsService,
  RealtimeAnomaliesService,
  RealtimeAreasService,
  RealtimeCamerasService,
  RealtimeControlPanelUsersService,
  RealtimeGenericsService,
  RealtimeOutputsService,
  RealtimeRepeatersService,
  RealtimeRfidKeysService,
  RealtimeSabotagesService,
  RealtimeScenariosService,
  RealtimeUserAccountsService,
  RealtimeZoneAlarmsService,
  RealtimeZonesService,
  TestCommandService,
  TestPostService,
  TestRealtimeService,
  ZoneCommandsService
];
