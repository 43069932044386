import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ApartmentsAndUsersService, UserDto } from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { UsersData } from 'src/app/core/models';

@Injectable({
  providedIn: 'root'
})
export class UdeviceDetailResolverService implements Resolve<any> {
  users$ = new BehaviorSubject<UsersData>({ users: [{}], user: {} });
  constructor(
    private aptService: ApartmentsAndUsersService,
    private authService: AuthService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<UsersData> | Observable<never> {
    const deviceId = route.params.deviceId;
    const apartamentId = route.params.aptId;
    const userId = route.params.userId;
    return this.loadAll(deviceId, apartamentId, userId);
  }

  loadAll(deviceId: string, apartamentId: number, userId: number) {
    return this.aptService
      .getApartmentUsers(deviceId, apartamentId, this.authService.getToken())
      .pipe(
        take(1),
        map((users: UserDto[]) => {
          const us = {
            users,
            user: users.find((x) => x.id === +userId)
          } as UsersData;
          this.users$.next(us);
          return us;
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return EMPTY;
        })
      );
  }

  loadUserDevice(deviceId: string, apartamentId: number, userId: number) {
    return this.aptService.getApartmentUsers(
      deviceId,
      apartamentId,
      this.authService.getToken()
    );
  }
}
