export * from './buzzer.service';
import { BuzzerService } from './buzzer.service';
export * from './controlPanel.service';
import { ControlPanelService } from './controlPanel.service';
export * from './delay.service';
import { DelayService } from './delay.service';
export * from './detector.service';
import { DetectorService } from './detector.service';
export * from './device.service';
import { DeviceService } from './device.service';
export * from './deviceHistory.service';
import { DeviceHistoryService } from './deviceHistory.service';
export * from './event.service';
import { EventService } from './event.service';
export * from './ioModule.service';
import { IoModuleService } from './ioModule.service';
export * from './loop.service';
import { LoopService } from './loop.service';
export * from './output.service';
import { OutputService } from './output.service';
export * from './panel.service';
import { PanelService } from './panel.service';
export * from './power.service';
import { PowerService } from './power.service';
export * from './sounder.service';
import { SounderService } from './sounder.service';
export * from './statistic.service';
import { StatisticService } from './statistic.service';
export * from './test.service';
import { TestService } from './test.service';
export * from './zone.service';
import { ZoneService } from './zone.service';
export * from './zoneHistory.service';
import { ZoneHistoryService } from './zoneHistory.service';
export const APIS = [
  BuzzerService,
  ControlPanelService,
  DelayService,
  DetectorService,
  DeviceService,
  DeviceHistoryService,
  EventService,
  IoModuleService,
  LoopService,
  OutputService,
  PanelService,
  PowerService,
  SounderService,
  StatisticService,
  TestService,
  ZoneService,
  ZoneHistoryService
];
