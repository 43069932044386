/**
 * License Manager API
 * API to manage licenses
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * License creation details
 */
export interface CreateMobexSIPLicense {
  /**
   * License duration
   */
  duration: number;
  /**
   * License duration unit
   */
  durationUnit: CreateMobexSIPLicense.DurationUnitEnum;
  /**
   * License description
   */
  description?: string;
  /**
   * License lines numbern
   */
  lines: number;
  /**
   * Users to be notified on license operation
   */
  userToNotifyList?: string;
  /**
   * Address of the plant where the device is installed (line 1)
   */
  address1?: string;
  /**
   * Address of the plant where the device is installed (line 2)
   */
  address2?: string;
  /**
   * City of the plant where the device is installed
   */
  city?: string;
  /**
   * ZIP code of the plant where the device is installed
   */
  zipcode?: string;
  /**
   * Country of the plant where the device is installed
   */
  country?: string;
}
export namespace CreateMobexSIPLicense {
  export type DurationUnitEnum = 'MONTHS';
  export const DurationUnitEnum = {
    MONTHS: 'MONTHS' as DurationUnitEnum
  };
}
