/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UserAssignmentResponseDto {
  /**
   * Contains the device authid
   */
  deviceAuthId?: string;
  /**
   * Contains the device uuid
   */
  deviceUuid?: string;
  /**
   * Contains the device model id
   */
  modelId?: string;
  /**
   * Contains the device model description
   */
  modelDescription?: string;
}
