/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GenerateReportDto {
  /**
   * Filter date from
   */
  dateFrom?: number;
  /**
   * Filter date to
   */
  dateTo?: number;
  /**
   * Offset
   */
  count?: number;
  /**
   * Number of rows
   */
  offset?: number;
}
