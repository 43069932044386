/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { SiteCompanyDto } from '../model/models';
import { SiteDto } from '../model/models';

import { MYPORTAL_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  protected basePath = 'https://api.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(MYPORTAL_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get a list of sites with their related data
   * Retrieve all sites data
   * @param name Site name to search for
   * @param grantedFromGroup Groups from which the access to the site is granted
   * @param description Site description to search for
   * @param type Site type to search for
   * @param address Site address to search for
   * @param city Site city to search for
   * @param country Site country to search for
   * @param zipCode Site zipCode to search for
   * @param companyAuthId Filters for sites attached to given company
   * @param requiredDeviceFamilies Keep only sites that contains at least one of the family specified
   * @param skip skip elements
   * @param limit maximum number to receive
   * @param apiKey ApiKey
   * @param authorization Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSites(
    name?: string,
    grantedFromGroup?: Array<
      | 'ADMIN'
      | 'INSTALLER'
      | 'BUILDINGMANAGER'
      | 'INSTALLER_COLLABORATOR'
      | 'BUILDINGMANAGER_COLLABORATOR'
      | 'MAINTAINER'
    >,
    description?: string,
    type?: string,
    address?: string,
    city?: string,
    country?: string,
    zipCode?: string,
    companyAuthId?: string,
    requiredDeviceFamilies?: Array<'PCAL' | 'SK' | 'SAFE' | 'DOMO' | 'FIRE'>,
    skip?: number,
    limit?: number,
    apiKey?: string,
    authorization?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SiteDto>>;
  public getSites(
    name?: string,
    grantedFromGroup?: Array<
      | 'ADMIN'
      | 'INSTALLER'
      | 'BUILDINGMANAGER'
      | 'INSTALLER_COLLABORATOR'
      | 'BUILDINGMANAGER_COLLABORATOR'
      | 'MAINTAINER'
    >,
    description?: string,
    type?: string,
    address?: string,
    city?: string,
    country?: string,
    zipCode?: string,
    companyAuthId?: string,
    requiredDeviceFamilies?: Array<'PCAL' | 'SK' | 'SAFE' | 'DOMO' | 'FIRE'>,
    skip?: number,
    limit?: number,
    apiKey?: string,
    authorization?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SiteDto>>>;
  public getSites(
    name?: string,
    grantedFromGroup?: Array<
      | 'ADMIN'
      | 'INSTALLER'
      | 'BUILDINGMANAGER'
      | 'INSTALLER_COLLABORATOR'
      | 'BUILDINGMANAGER_COLLABORATOR'
      | 'MAINTAINER'
    >,
    description?: string,
    type?: string,
    address?: string,
    city?: string,
    country?: string,
    zipCode?: string,
    companyAuthId?: string,
    requiredDeviceFamilies?: Array<'PCAL' | 'SK' | 'SAFE' | 'DOMO' | 'FIRE'>,
    skip?: number,
    limit?: number,
    apiKey?: string,
    authorization?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SiteDto>>>;
  public getSites(
    name?: string,
    grantedFromGroup?: Array<
      | 'ADMIN'
      | 'INSTALLER'
      | 'BUILDINGMANAGER'
      | 'INSTALLER_COLLABORATOR'
      | 'BUILDINGMANAGER_COLLABORATOR'
      | 'MAINTAINER'
    >,
    description?: string,
    type?: string,
    address?: string,
    city?: string,
    country?: string,
    zipCode?: string,
    companyAuthId?: string,
    requiredDeviceFamilies?: Array<'PCAL' | 'SK' | 'SAFE' | 'DOMO' | 'FIRE'>,
    skip?: number,
    limit?: number,
    apiKey?: string,
    authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (name !== undefined && name !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>name,
        'name'
      );
    }
    if (grantedFromGroup) {
      grantedFromGroup.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'grantedFromGroup'
        );
      });
    }
    if (description !== undefined && description !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>description,
        'description'
      );
    }
    if (type !== undefined && type !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>type,
        'type'
      );
    }
    if (address !== undefined && address !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>address,
        'address'
      );
    }
    if (city !== undefined && city !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>city,
        'city'
      );
    }
    if (country !== undefined && country !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>country,
        'country'
      );
    }
    if (zipCode !== undefined && zipCode !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>zipCode,
        'zipCode'
      );
    }
    if (companyAuthId !== undefined && companyAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>companyAuthId,
        'companyAuthId'
      );
    }
    if (requiredDeviceFamilies) {
      requiredDeviceFamilies.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'requiredDeviceFamilies'
        );
      });
    }
    if (skip !== undefined && skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>skip,
        'skip'
      );
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }

    let headers = this.defaultHeaders;
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SiteDto>>(
      `${this.configuration.basePath}/servicerest/auth/sites`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get a list of users with their related data
   * Retrieve all sites data
   * @param authorization Authorization
   * @param apiKey ApiKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSitesUsers(
    authorization?: string,
    apiKey?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<SiteCompanyDto>>;
  public getSitesUsers(
    authorization?: string,
    apiKey?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<SiteCompanyDto>>>;
  public getSitesUsers(
    authorization?: string,
    apiKey?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<SiteCompanyDto>>>;
  public getSitesUsers(
    authorization?: string,
    apiKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SiteCompanyDto>>(
      `${this.configuration.basePath}/servicerest/auth/sites/users`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
