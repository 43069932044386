<div class="modal-header">
  <h2>
    {{ 'USER_DEVICE.CREATE_USER_DEVICE' | translate }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="udFormGroup">
    <label class="custom-label" for="device-type">{{
      'USER_DEVICE.TYPE' | translate
    }}</label>
    <ng-select
      id="device-type"
      [items]="typesToUse"
      bindLabel="label"
      bindValue="index"
      [formControl]="typeControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>

    <ng-container *ngIf="typeControl.value !== 1">
      <label class="custom-label" for="device-desc">{{
        'USER_DEVICE.DESCRIPTION' | translate
      }}</label>
      <input
        id="device-desc"
        [formControl]="descrControl"
        type="text"
        [placeholder]="'USER_DEVICE.DESCRIPTION' | translate"
        class="custom-input"
      />
    </ng-container>

    <ng-container *ngIf="typeControl.value === 3">
      <label class="custom-label" for="phone">{{
        'USER_DEVICE.PHONE' | translate
      }}</label>
      <input
        id="phone"
        [formControl]="phoneControl"
        [placeholder]="'USER_DEVICE.PHONE' | translate"
        type="text"
        class="custom-input"
      />
      <app-control-errors
        id="phone-validator"
        controlName="sipnum"
        [customMessages]="{
          pattern: 'USER_DEVICE.PHONE_VALIDATION_MESSAGE' | translate
        }"
      ></app-control-errors>
    </ng-container>

    <ng-container *ngIf="typeControl.value !== 3 && typeControl.value !== 1">
      <label class="custom-label" for="device-email">{{
        'USER_DEVICE.EMAIL' | translate
      }}</label>
      <input
        id="device-email"
        [formControl]="emailControl"
        type="text"
        [placeholder]="'USER_DEVICE.EMAIL' | translate"
        class="custom-input"
      />
      <app-control-errors
        id="device-email-validator"
        controlName="email"
        [customMessages]="{
          pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
        }"
      ></app-control-errors>
    </ng-container>

    <ng-container *ngIf="!isSbc">
      <label class="custom-label" for="address-call">{{
        'USER_DEVICE.ADDRESS_CALL' | translate
      }}</label>
      <div class="switch-container">
        <input
          id="address-call"
          [formControl]="extabnameControl"
          type="text"
          [placeholder]="'USER_DEVICE.ADDRESS_CALL_DESC' | translate"
          class="custom-input w-80"
        />
        <div class="switch mt-2">
          <app-switch
            [control]="extabControl"
            inputId="address-call-switch"
            [disableLogic]="extabControl.disabled"
          ></app-switch>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="typeControl.value === 2 && aptType !== 1">
      <label class="custom-label" for="backup-phone">{{
        'USER_DEVICE.BACKUP_PHONECALL' | translate
      }}</label>

      <div class="switch-container">
        <ng-container *ngIf="remainingLines > 1; else noSpots">
          <span
            class="abilitation"
            [ngClass]="{ green: backupControl.value }"
          ></span>
          <b>{{
            (backupControl.value
              ? 'USER_DEVICE.ENABLED'
              : 'USER_DEVICE.DISABLED'
            ) | translate
          }}</b>
        </ng-container>
        <ng-template #noSpots>
          <div class="switch-text">
            {{ 'USER_DEVICE.NO_SPOTS_AVAILABLES' | translate }}
          </div>
        </ng-template>

        <div class="switch">
          <app-switch
            [control]="backupControl"
            inputId="backup-switch"
            [disableLogic]="backupControl.disabled || remainingLines < 2"
          ></app-switch>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="backupControl.value && typeControl.value !== 3">
      <label class="custom-label" for="phone">{{
        'USER_DEVICE.PHONE_BACKUP' | translate
      }}</label>
      <input
        id="phone-backup"
        [formControl]="phoneBackupControl"
        [placeholder]="'USER_DEVICE.PHONE_BACKUP' | translate"
        type="text"
        class="custom-input"
      />
      <div
        id="phone-backup-validator"
        *ngIf="
          phoneBackupControl.invalid &&
          (phoneBackupControl.touched || phoneBackupControl.dirty)
        "
        class="input-field-error"
      >
        {{ 'USER_DEVICE.PHONE_VALIDATION_MESSAGE' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="typeControl.value !== 3">
      <label class="custom-label" for="direct-video">{{
        'USER_DEVICE.DIRECT_VIDEO' | translate
      }}</label>
      <div class="switch-container">
        <ng-container *ngIf="canAddVideo; else noVideo">
          <span
            class="abilitation"
            [ngClass]="{ green: directVideoControl.value }"
          ></span>
          <b>{{
            (directVideoControl.value
              ? 'USER_DEVICE.ENABLED'
              : 'USER_DEVICE.DISABLED'
            ) | translate
          }}</b>
        </ng-container>
        <ng-template #noVideo>
          <div class="switch-text">
            {{ 'USER_DEVICE.NO_VIDEO_ALLOWED' | translate }}
          </div>
        </ng-template>

        <div class="switch">
          <app-switch
            [control]="directVideoControl"
            inputId="video-switch"
            [disableLogic]="directVideoControl.disabled || !canAddVideo"
          ></app-switch>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button id="cancel" class="button-secondary mr-3" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    id="save"
    buttonClass="button-primary"
    [isLoading]="isLoading"
    (click)="save()"
    buttonType="button"
    >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
  >
</div>
