export * from './appconfig.service';
import { AppconfigService } from './appconfig.service';
export * from './companies.service';
import { CompaniesService } from './companies.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './companyPerm.service';
import { CompanyPermService } from './companyPerm.service';
export * from './companyResource.service';
import { CompanyResourceService } from './companyResource.service';
export * from './firebase.service';
import { FirebaseService } from './firebase.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './groupPerm.service';
import { GroupPermService } from './groupPerm.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './site.service';
import { SiteService } from './site.service';
export * from './sites.service';
import { SitesService } from './sites.service';
export * from './userCompany.service';
import { UserCompanyService } from './userCompany.service';
export * from './usermetadata.service';
import { UsermetadataService } from './usermetadata.service';
export const APIS = [
  AppconfigService,
  CompaniesService,
  CompanyService,
  CompanyPermService,
  CompanyResourceService,
  FirebaseService,
  GroupService,
  GroupPermService,
  GroupsService,
  SiteService,
  SitesService,
  UserCompanyService,
  UsermetadataService
];
