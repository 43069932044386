/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Group data
 */
export interface GroupRequestDto {
  /**
   * Company authenticationId
   */
  companyAuthId: string;
  /**
   * Group name
   */
  name: string;
  /**
   * Group description
   */
  description: string;
}
