/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Site address
 */
export interface AddressDto {
  /**
   * First address line
   */
  addressLine1: string;
  /**
   * Second address line
   */
  addressLine2?: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
  /**
   * ZIP code
   */
  zipCode?: string;
  /**
   * Site latitude
   */
  latitude?: number;
  /**
   * Site longitude
   */
  longitude?: number;
}
