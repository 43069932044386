import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  UrlTree
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  protected authenticated: boolean;
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): Promise<boolean | UrlTree> {
    return this.handleAuthentication();
  }

  canActivateChild(): Promise<boolean | UrlTree> {
    return this.handleAuthentication();
  }

  canLoad(): Promise<boolean | UrlTree> {
    return this.handleAuthentication();
  }

  private async handleAuthentication(): Promise<boolean | UrlTree> {
    const currentUrl = window.location.href.replace('/not-allowed', '');

    try {
      const authenticated = await this.authService.isLoggedIn();
      if (!authenticated) {
        window.location.href = this.getLoginUrl(currentUrl);
      }

      const role = this.authService.userProfile.data?.userType || null;
      const betaUser = this.authService.userProfile.data?.betaUser || null;

      if (environment.betaUserActivated && !betaUser) {
        return this.router.createUrlTree(['/not-allowed']);
      }

      if (
        role !== 'INSTALLER' &&
        role !== 'BUILDING_MANAGER' &&
        role !== 'SYSTEM_INTEGRATOR'
      ) {
        return this.router.createUrlTree(['/not-allowed']);
      }

      return authenticated;
    } catch (error) {
      window.location.href = this.getLoginUrl(currentUrl);
      return false;
    }
  }

  getLoginUrl(currentUrl: string) {
    return environment.loginUrl + currentUrl;
  }
}
