<div class="modal-header">
  <h2>
    {{ 'SHARE.SHARE_WITH_USER' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="shareGroup" (keyup.enter)="send()">
    <label class="custom-label" for="site">{{ 'SITE.SITE' | translate }}</label>
    <input
      id="site"
      type="text"
      class="custom-input"
      [disabled]="true"
      [value]="site.name"
    />

    <label class="custom-label" for="email">{{
      'SHARE.EMAIL' | translate
    }}</label>
    <input
      id="email"
      type="email"
      class="custom-input"
      [placeholder]="'SHARE.EMAIL' | translate"
      [formControl]="emailControl"
    />
    <app-control-errors
      id="email-validator"
      controlName="user"
      [customMessages]="{
        pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate,
        sameOwner: 'VALIDATE.INSERTED_MAIL_IS_YOURS' | translate,
        alreadyUser: 'VALIDATE.INSERTED_MAIL_IS_ALREADY_A_USER' | translate
      }"
    ></app-control-errors>
    <small>{{ 'SHARE.EMAIL_DESC' | translate }}</small>

    <label class="custom-label" for="role">{{
      'SHARE.ROLE' | translate
    }}</label>
    <ng-select
      id="role"
      [placeholder]="'SHARE.ROLE' | translate"
      [items]="roles"
      bindLabel="label"
      bindValue="authId"
      [searchable]="false"
      [clearable]="false"
      [formControl]="roleControl"
    ></ng-select>
    <app-control-errors
      id="role-validator"
      controlName="role"
    ></app-control-errors>
  </form>
</div>

<div class="modal-footer">
  <app-loading-button
    id="save"
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="send()"
    buttonType="button"
    >{{ 'GLOBAL.SEND' | translate }}</app-loading-button
  >
</div>
