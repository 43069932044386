/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface MaintenanceReportDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Device Name is a string containing the name of the device.
   */
  deviceName?: string;
  /**
   * Zone is an integer value containing the zone index the device belongs to.
   */
  zone?: number;
  /**
   * Loop is an integer value containing the loop index the device belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of device inside its loop (i.e. from 1 to 250).
   */
  address?: number;
  /**
   * Last test date is a timestamp that denote when was the last maintenance test for the detector.
   */
  lastTested?: string;
  /**
   * Next test date is a timestamp that denote when should be the next maintenance test for the detector.
   */
  nextTest?: string;
}
