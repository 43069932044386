/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Apartment user related data descriptor
 */
export interface UserDto {
  /**
   * Apartment user identifier
   */
  id?: number;
  /**
   * Apartment identifier
   */
  readonly apt?: number;
  /**
   * Enable user in apartment
   */
  enable?: boolean;
  /**
   * Phone number of the user
   */
  phone?: string;
  /**
   * Enable external addressbook
   */
  extab?: boolean;
  /**
   * External addressbook user description (requires MUG with \"CFG_ExtB\" capability version 2 or higher, introduced since \"1.10.0\" firmware release)
   */
  extabname?: string;
  /**
   * User description
   */
  descr?: string;
  /**
   * User email, must be set to enable app
   */
  email?: string;
  /**
   * SNMP commands related codes to be sent to device (0: Do nothing, 1: Add activation code, 2: Remove activation code, 3: Reset user data, 4: Set user fields to default values, 5: Send user invitation email)
   */
  cmdcode?: UserDto.CmdcodeEnum;
  /**
   *  Device type (0: None, 1: Internal, 2: App, 3: Phone
   */
  devtype?: UserDto.DevtypeEnum;
  /**
   * User vipertoken
   */
  readonly vipertoken?: string;
  /**
   * Enable backup
   */
  backen?: boolean;
  /**
   * Activation code, generated by device
   */
  readonly actcode?: string;
  /**
   * SIP number
   */
  sipnum?: string;
  /**
   * Backup line
   */
  backline?: number;
  /**
   * Cloud sync status
   */
  readonly cloudsync?: boolean;
  /**
   * Seconds since last app connection
   */
  readonly appconnelaps?: string;
  /**
   * Id used by external systems
   */
  readonly externalid?: string;
}
export namespace UserDto {
  export type CmdcodeEnum =
    | 'DO_NOTHING'
    | 'ADD_ACTIVATION_CODE'
    | 'REMOVE_ACTIVATION_CODE'
    | 'USER_DATA_RESET'
    | 'USER_FIELDS_TO_DEFAULT'
    | 'INVITE';
  export const CmdcodeEnum = {
    DONOTHING: 'DO_NOTHING' as CmdcodeEnum,
    ADDACTIVATIONCODE: 'ADD_ACTIVATION_CODE' as CmdcodeEnum,
    REMOVEACTIVATIONCODE: 'REMOVE_ACTIVATION_CODE' as CmdcodeEnum,
    USERDATARESET: 'USER_DATA_RESET' as CmdcodeEnum,
    USERFIELDSTODEFAULT: 'USER_FIELDS_TO_DEFAULT' as CmdcodeEnum,
    INVITE: 'INVITE' as CmdcodeEnum
  };
  export type DevtypeEnum = 'NONE' | 'INTERNAL' | 'APP' | 'PHONE';
  export const DevtypeEnum = {
    NONE: 'NONE' as DevtypeEnum,
    INTERNAL: 'INTERNAL' as DevtypeEnum,
    APP: 'APP' as DevtypeEnum,
    PHONE: 'PHONE' as DevtypeEnum
  };
}
