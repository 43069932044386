/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * RFID Keys details
 */
export interface SafeRfidKeysResponseDto {
  /**
   * RFID key row ID
   */
  id: number;
  /**
   * Name of the RFID key
   */
  name?: string;
  /**
   * Color of the RFID key
   */
  color?: SafeRfidKeysResponseDto.ColorEnum;
}
export namespace SafeRfidKeysResponseDto {
  export type ColorEnum =
    | 'GRAY'
    | 'BLUE'
    | 'YELLOW'
    | 'ORANGE'
    | 'RED'
    | 'GREEN'
    | 'GRAY_BLUE'
    | 'GRAY_YELLOW'
    | 'GRAY_ORANGE'
    | 'GRAY_RED'
    | 'GRAY_GREEN'
    | 'NOT_VALID'
    | 'NOT_RECOGNIZED';
  export const ColorEnum = {
    GRAY: 'GRAY' as ColorEnum,
    BLUE: 'BLUE' as ColorEnum,
    YELLOW: 'YELLOW' as ColorEnum,
    ORANGE: 'ORANGE' as ColorEnum,
    RED: 'RED' as ColorEnum,
    GREEN: 'GREEN' as ColorEnum,
    GRAYBLUE: 'GRAY_BLUE' as ColorEnum,
    GRAYYELLOW: 'GRAY_YELLOW' as ColorEnum,
    GRAYORANGE: 'GRAY_ORANGE' as ColorEnum,
    GRAYRED: 'GRAY_RED' as ColorEnum,
    GRAYGREEN: 'GRAY_GREEN' as ColorEnum,
    NOTVALID: 'NOT_VALID' as ColorEnum,
    NOTRECOGNIZED: 'NOT_RECOGNIZED' as ColorEnum
  };
}
