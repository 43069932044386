/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SafeGenericStateDto {
  /**
   * Zone test - At least one zone is on testing (vedo: Y, hub: Y, eclipse: ?)
   */
  zoneTest?: boolean;
  /**
   * Exclusion on area - At least one zone is excluded on areas (vedo: Y, hub: N, eclipse: ?)
   */
  exclusionOnArea?: boolean;
  /**
   * Not ready - Control panel is not ready for arming (vedo: Y, hub: Y, eclipse: ?)
   */
  notReady?: boolean;
  /**
   * Chime - Chime is on on at least one zone (vedo: Y, hub: Y, eclipse: ?)
   */
  chime?: boolean;
  /**
   * User logged (vedo: Y, hub: Y, eclipse: ?)
   */
  userLogged?: boolean;
  /**
   * Alarms memory - There are events stored in alarms memory (vedo: Y, hub: Y, eclipse: ?)
   */
  alarmsMemory?: boolean;
  /**
   * Technical programming - Programming of control panel ongoing (vedo: Y, hub: Y, eclipse: ?)
   */
  technicalProgramming?: boolean;
  /**
   * In service (vedo: Y, hub: Y, eclipse: ?)
   */
  inService?: boolean;
  /**
   * Phone call (vedo: Y, hub: Y, eclipse: ?)
   */
  phoneCall?: boolean;
  /**
   * Alarm ongoing (vedo: Y, hub: Y, eclipse: ?)
   */
  alarmOngoing?: boolean;
  /**
   * P1 or P2 - A partial arm (P1 or P2) is active on control panel (vedo: Y, hub: Y, eclipse: ?)
   */
  p1OrP2?: boolean;
  /**
   * Phone call incoming (vedo: Y, hub: Y, eclipse: ?)
   */
  phoneCallIncoming?: boolean;
  /**
   * SMS send ongoing (vedo: Y, hub: N, eclipse: ?)
   */
  smsSendOngoing?: boolean;
  /**
   * Email send ongoing (vedo: Y, hub: N, eclipse: ?)
   */
  emailSendOngoing?: boolean;
  /**
   * MMS send ongoing (vedo: Y, hub: N, eclipse: ?)
   */
  mmsSendOngoing?: boolean;
  /**
   * No arming on area (vedo: Y, hub: Y, eclipse: ?)
   */
  noArmingOnArea?: boolean;
  /**
   * Arming (vedo: Y, hub: Y, eclipse: ?)
   */
  arming?: boolean;
  /**
   * Disarming (vedo: Y, hub: N, eclipse: ?)
   */
  disarming?: boolean;
  /**
   * Default codes - Codes for users are set as defualt (vedo: Y, hub: Y, eclipse: ?)
   */
  defaultCodes?: boolean;
}
