/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceAlertDto {
  /**
   * Alert code
   */
  readonly code?: string;
  /**
   * Whether or not alert is active
   */
  readonly active?: boolean;
}
