<div class="card-small mb-3">
  <div class="card-header">
    <h4>{{ 'SITE.INFO' | translate }}</h4>
    <a href="javascript:void(0)" class="link-primary" (click)="openDetail()"
      >{{ 'SITE.DETAILS' | translate }}
      <i class="material-icons">keyboard_arrow_right</i></a
    >
  </div>
  <div class="card-body py-2 px-3">
    <div
      *ngIf="imageUrl"
      class="image-box mb-4"
      [ngStyle]="{
        backgroundImage: imageUrl
      }"
    ></div>
    <ul id="reference-info">
      <li>
        <b>{{ site.name }}</b> <br />
        {{ site.address | address }}
      </li>
      <li>
        <b>{{ 'SITE.REFERENCE' | translate }}</b> <br />
        {{
          site.userMetadata && site.userMetadata.contacts
            ? site.userMetadata.contacts![0].name +
              ' ' +
              site.userMetadata.contacts![0].surname
            : '--'
        }}
      </li>
      <li>
        <b>{{ 'SITE.EMAIL' | translate }}</b> <br />
        {{
          site.userMetadata && site.userMetadata.contacts
            ? site.userMetadata.contacts![0].email
            : '--'
        }}
      </li>
      <!-- TOADD LATER ON -->
      <!-- <li>
        <b>{{ 'SITE.LAST_EDIT_DATE' | translate }}</b> <br />
        DATE TO ADD
      </li> -->
      <li>
        <b>{{ 'SITE.OWNER' | translate }}</b> <br />
        {{ owner }}
      </li>
      <li>
        <b>{{ 'SITE.MY_ROLE' | translate }}</b> <br />
        {{ rolesLabels[userRole || 'NOROLE'] | translate }}
      </li>
      <li *ngIf="deviceName">
        <b>{{ 'APARTMENT.DEVICE' | translate }}</b> <br />
        {{ deviceName }}
      </li>
      <!-- TOADD LATER ON -->
      <!-- <li>
        <b>{{ 'GLOBAL.TAGS' | translate }}</b> <br />
        {{ getTags() }}
      </li> -->
    </ul>
  </div>
</div>
