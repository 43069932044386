<div class="modal-header">
  <h2>
    {{
      (readOnly
        ? 'SITE.SITE_DETAILS'
        : isEditing
        ? 'SITE.EDIT_SITE_DETAILS'
        : 'SITE.CREATE_NEW_SITE'
      ) | translate
    }}
  </h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" (keyup.enter)="!readOnly ? save() : enableEdit()">
  <form [formGroup]="siteFormGroup">
    <h3>{{ 'GLOBAL.INFORMATIONS' | translate }}</h3>
    <div class="row">
      <div
        class="col-12"
        [ngClass]="imageUrl || !readOnly ? 'col-md-6' : 'mb-1'"
      >
        <label class="custom-label" for="site-name"
          >{{ 'SITE.SITE_NAME' | translate }}*</label
        >
        <input
          id="site-name"
          type="text"
          class="custom-input"
          [placeholder]="'SITE.SITE_NAME' | translate"
          [formControl]="siteNameControl"
        />
        <app-control-errors
          id="site-name-validator"
          controlName="name"
        ></app-control-errors>
      </div>
      <div *ngIf="imageUrl || !readOnly" class="col-12 col-md-6">
        <app-upload-image
          *ngIf="!readOnly"
          [imageUrl]="imageUrl"
          (imageChanged)="imageChanged($event)"
        ></app-upload-image>

        <div
          *ngIf="readOnly"
          class="show-image-box mb-4"
          [ngStyle]="{
            backgroundImage: imageUrl
          }"
        >
          <span *ngIf="!imageUrl">{{ 'SITE.NO_PICTURE' | translate }}</span>
        </div>
      </div>
    </div>

    <google-map
      *ngIf="!isMobile"
      id="map"
      [options]="options"
      [center]="{ lat: mapLatLng.latitude, lng: mapLatLng.longitude }"
      [zoom]="5"
      (mapClick)="calculateAddress($event)"
    >
      <map-marker
        *ngIf="selectedLocation"
        [position]="{
          lat: selectedLocation.latitude,
          lng: selectedLocation.longitude
        }"
        [options]="{ icon: selectedLocation.icon }"
      >
      </map-marker>
    </google-map>

    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="site-address"
          >{{ 'SITE.ADDRESS' | translate }}*</label
        >
        <input
          id="site-address"
          type="text"
          class="custom-input"
          [placeholder]="'SITE.ADDRESS' | translate"
          [formControl]="siteAddressControl"
        />
        <app-control-errors
          id="site-address-validator"
          controlName="address.addressLine1"
        ></app-control-errors>
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="site-city"
          >{{ 'SITE.CITY' | translate }}*</label
        >
        <input
          id="site-city"
          type="text"
          class="custom-input"
          [placeholder]="'SITE.CITY' | translate"
          [formControl]="siteCityControl"
        />
        <app-control-errors
          id="site-city-validator"
          controlName="address.city"
        ></app-control-errors>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="site-zip"
          >{{ 'SITE.ZIP' | translate }}*</label
        >
        <input
          id="site-zip"
          type="text"
          class="custom-input"
          [placeholder]="'SITE.ZIP' | translate"
          [formControl]="siteZipControl"
        />
        <app-control-errors
          id="site-zip-validator"
          controlName="address.zipCode"
        ></app-control-errors>
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="site-country"
          >{{ 'SITE.COUNTRY' | translate }}*</label
        >
        <input
          id="site-country"
          type="text"
          class="custom-input"
          [placeholder]="'SITE.COUNTRY' | translate"
          [formControl]="siteCountryControl"
        />
        <app-control-errors
          id="site-country-validator"
          controlName="address.country"
        ></app-control-errors>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="site-type"
          >{{ 'SITE.TYPE' | translate }}*</label
        >
        <ng-select
          id="site-type"
          [placeholder]="'SITE.TYPE' | translate"
          [items]="types"
          bindLabel="name"
          bindValue="value"
          [formControl]="siteTypeControl"
          [searchable]="false"
          [clearable]="false"
        ></ng-select>
        <app-control-errors
          id="site-type-validator"
          controlName="type"
        ></app-control-errors>
      </div>
    </div>
    <label class="custom-label">{{ 'SITE.NOTES' | translate }}</label>
    <textarea
      [formControl]="siteNotesControl"
      class="custom-input"
      rows="4"
    ></textarea>
  </form>
  <hr />
  <form [formGroup]="userFormGroup">
    <h3>{{ 'GLOBAL.CONTACTS' | translate }}</h3>
    <div class="mt-3 error-label" *ngIf="userFormGroup.invalid">
      {{ 'USER.ERROR' | translate }}
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="user-name">{{
          'USER.NAME' | translate
        }}</label>
        <input
          id="user-name"
          type="text"
          class="custom-input"
          [placeholder]="'USER.NAME' | translate"
          [formControl]="userNameControl"
        />
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="user-surname">{{
          'USER.SURNAME' | translate
        }}</label>
        <input
          id="user-surname"
          type="text"
          class="custom-input"
          [placeholder]="'USER.SURNAME' | translate"
          [formControl]="userSurnameControl"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="user-cellular">{{
          'USER.CELLULAR' | translate
        }}</label>
        <input
          id="user-cellular"
          type="text"
          class="custom-input"
          [placeholder]="'USER.CELLULAR' | translate"
          [formControl]="userCellControl"
        />
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="user-phone">{{
          'USER.PHONE' | translate
        }}</label>
        <input
          id="user-phone"
          type="text"
          class="custom-input"
          [placeholder]="'USER.PHONE' | translate"
          [formControl]="userPhoneControl"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="user-email">{{
          'USER.EMAIL' | translate
        }}</label>
        <input
          id="user-email"
          type="email"
          class="custom-input"
          [placeholder]="'USER.EMAIL' | translate"
          [formControl]="userEmailControl"
        />
        <app-control-errors
          id="user-email-validator"
          controlName="email"
          [customMessages]="{
            pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
          }"
        ></app-control-errors>
      </div>
    </div>
  </form>
  <!-- TOADD LATER ON -->
  <!-- <hr />
  <h3 class="mb-3">{{ 'GLOBAL.TAGS' | translate }}</h3>
  <div id="tag-box">
    <div *ngFor="let tag of tagsArrayControl.value; let i = index" class="tag">
      {{ tag }}
      <i *ngIf="!readOnly" class="material-icons notranslate" (click)="removeTag(i)"
        >close</i
      >
    </div>
    <p *ngIf="tagsArrayControl.value.length === 0 && readOnly">
      {{ 'TAG.NO_TAGS' | translate }}
    </p>
  </div>
  <ng-container *ngIf="!readOnly">
    <input
      id="tag"
      type="text"
      class="custom-input tag-input"
      [placeholder]="'TAG.INSERT_TAG' | translate"
      [formControl]="tagFormControl"
    />
    <button class="button-secondary ml-3 mt-1" (click)="addTag()">
      {{ 'TAG.ADD_TAG' | translate }}
    </button>
  </ng-container> -->
</div>

<div class="modal-footer">
  <p class="ml-0 mr-auto">
    {{ 'GLOBAL.REQUIRED_FIELDS_DESC' | translate }}
  </p>
  <ng-container *ngIf="!readOnly">
    <button
      id="cancel"
      *ngIf="!readOnly"
      class="button-secondary big"
      (click)="close()"
    >
      {{ 'GLOBAL.CANCEL' | translate }}
    </button>
    <app-loading-button
      id="save"
      buttonClass="button-primary big"
      [isLoading]="isLoading"
      (click)="save()"
      buttonType="button"
      >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
    >
  </ng-container>
  <button
    id="edit-data"
    *ngIf="readOnly"
    class="button-secondary big"
    (click)="enableEdit()"
  >
    <i class="material-icons notranslate">edit</i
    >{{ 'SITE.EDIT_DATA' | translate }}
  </button>
</div>
