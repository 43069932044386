<div class="modal-header">
  <h2>
    {{ 'DEVICE.EDIT_DEVICE' | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" (keyup.enter)="editDevice()">
  <form [formGroup]="deviceFormGroup">
    <h3>{{ 'GLOBAL.INFORMATIONS' | translate }}</h3>
    <label class="custom-label" for="device-name"
      >{{ 'DEVICE.DEVICE_NAME' | translate }}*</label
    >
    <input
      id="device-name"
      type="text"
      class="custom-input"
      [placeholder]="'DEVICE.DEVICE_NAME' | translate"
      [formControl]="nameControl"
    />
    <app-control-errors
      id="device-name-validator"
      controlName="label"
    ></app-control-errors>
  </form>
  <hr />
  <form [formGroup]="siteFormGroup">
    <h3>{{ 'DEVICE.SITE' | translate }}</h3>
    <label class="custom-label" for="device-serial"
      >{{ 'DEVICE.SITE' | translate }}*</label
    >
    <ng-select
      id="site-type"
      [placeholder]="'DEVICE.SITE' | translate"
      [items]="siteList"
      bindLabel="name"
      [formControl]="siteControl"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>
  </form>
</div>

<div class="modal-footer">
  <button id="cancel" class="button-secondary big" (click)="close()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <app-loading-button
    id="save"
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="editDevice()"
    buttonType="button"
    >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
  >
</div>
