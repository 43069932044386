import { Pipe, PipeTransform } from '@angular/core';
import { SiteDto } from '../api/myportal/model/siteDto';

@Pipe({
  name: 'favourite'
})
export class FavouritePipe implements PipeTransform {
  transform(value: SiteDto, args?: any): any {
    if (value.userMetadata) {
      return value.userMetadata.preferred ? 'star' : 'star_border';
    }
    return 'star_border';
  }
}
