import { Component, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { AuthService } from 'src/app/core/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})
export class NotAllowedComponent implements OnInit {
  image: SafeStyle;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.image = `assets/images/${environment.client}-logo-cloud.svg`;
    this.authService.isLoggedIn();
  }
}
