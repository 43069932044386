/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Output details
 */
export interface SafeUserAccountResponseDto {
  /**
   * Output row ID
   */
  id: number;
  /**
   * Safe User state (itemId: 0)
   */
  readonly state?: SafeUserAccountResponseDto.StateEnum;
  /**
   * Safe User role (itemId: 3, 0)
   */
  readonly role?: SafeUserAccountResponseDto.RoleEnum;
  /**
   * contains the email associated to user (itemId: 1)
   */
  readonly email?: string;
  /**
   * List of areas to which the user is enabled to operate (itemId: 2)
   */
  readonly areas?: Array<boolean>;
  /**
   * If true the user is the owner of device (itemId: 4)
   */
  readonly isOwner?: boolean;
  /**
   * Contains the PIN retrieved from cloud (itemId: 5)
   */
  readonly pin?: string;
}
export namespace SafeUserAccountResponseDto {
  export type StateEnum =
    | 'FREE'
    | 'REQUEST'
    | 'NOT_READY'
    | 'EVALUATED'
    | 'ACTIVATED';
  export const StateEnum = {
    FREE: 'FREE' as StateEnum,
    REQUEST: 'REQUEST' as StateEnum,
    NOTREADY: 'NOT_READY' as StateEnum,
    EVALUATED: 'EVALUATED' as StateEnum,
    ACTIVATED: 'ACTIVATED' as StateEnum
  };
  export type RoleEnum = 'NONE' | 'USER' | 'INSTALLER';
  export const RoleEnum = {
    NONE: 'NONE' as RoleEnum,
    USER: 'USER' as RoleEnum,
    INSTALLER: 'INSTALLER' as RoleEnum
  };
}
