/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { BasePortalApiResponseDtoGetMyProfileResponseDto } from '../model/models';
import { BasePortalApiResponseDtoRegistrationResponseDto } from '../model/models';
import { BasePortalApiResponseDtoResetPasswordResponseDto } from '../model/models';
import { BasePortalApiResponseDtoSetMyProfileResponseDto } from '../model/models';
import { BasePortalApiResponseDtoVoid } from '../model/models';
import { ChangePasswordDto } from '../model/models';
import { EndPasswordResettingDto } from '../model/models';
import { RegistrationDto } from '../model/models';
import { ResetPasswordDto } from '../model/models';
import { SetMyProfileRequestDto } from '../model/models';

import { USERPORTAL_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class UserPortalService {
  protected basePath = 'https://usvc-preprod.cloud.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(USERPORTAL_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * changeMyPassword
   * Change the user\&#39;s password
   * @param authorization Authorization
   * @param apikey Application API key
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changeMyPassword(
    authorization: string,
    apikey?: string,
    body?: ChangePasswordDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<BasePortalApiResponseDtoVoid>;
  public changeMyPassword(
    authorization: string,
    apikey?: string,
    body?: ChangePasswordDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<BasePortalApiResponseDtoVoid>>;
  public changeMyPassword(
    authorization: string,
    apikey?: string,
    body?: ChangePasswordDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<BasePortalApiResponseDtoVoid>>;
  public changeMyPassword(
    authorization: string,
    apikey?: string,
    body?: ChangePasswordDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling changeMyPassword.'
      );
    }

    let headers = this.defaultHeaders;
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<BasePortalApiResponseDtoVoid>(
      `${this.configuration.basePath}/servicerest/user/portal/changemypassword`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * endPasswordResetting
   * End a user\&#39;s password reset process
   * @param apikey Application API key
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public endPasswordResetting(
    apikey: string,
    body?: EndPasswordResettingDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<BasePortalApiResponseDtoVoid>;
  public endPasswordResetting(
    apikey: string,
    body?: EndPasswordResettingDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<BasePortalApiResponseDtoVoid>>;
  public endPasswordResetting(
    apikey: string,
    body?: EndPasswordResettingDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<BasePortalApiResponseDtoVoid>>;
  public endPasswordResetting(
    apikey: string,
    body?: EndPasswordResettingDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (apikey === null || apikey === undefined) {
      throw new Error(
        'Required parameter apikey was null or undefined when calling endPasswordResetting.'
      );
    }

    let headers = this.defaultHeaders;
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<BasePortalApiResponseDtoVoid>(
      `${this.configuration.basePath}/servicerest/user/portal/endpwdresetting`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getMyProfile
   * Retrieve the user\&#39;s profile
   * @param authorization Authorization
   * @param apikey Application API key
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMyProfile(
    authorization: string,
    apikey?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<BasePortalApiResponseDtoGetMyProfileResponseDto>;
  public getMyProfile(
    authorization: string,
    apikey?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<BasePortalApiResponseDtoGetMyProfileResponseDto>>;
  public getMyProfile(
    authorization: string,
    apikey?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<BasePortalApiResponseDtoGetMyProfileResponseDto>>;
  public getMyProfile(
    authorization: string,
    apikey?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getMyProfile.'
      );
    }

    let headers = this.defaultHeaders;
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<BasePortalApiResponseDtoGetMyProfileResponseDto>(
      `${this.configuration.basePath}/servicerest/user/portal/getmyprofile`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * registerUser
   * Register a new user
   * @param apikey Application API key
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerUser(
    apikey: string,
    body?: RegistrationDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<BasePortalApiResponseDtoRegistrationResponseDto>;
  public registerUser(
    apikey: string,
    body?: RegistrationDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<BasePortalApiResponseDtoRegistrationResponseDto>>;
  public registerUser(
    apikey: string,
    body?: RegistrationDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<BasePortalApiResponseDtoRegistrationResponseDto>>;
  public registerUser(
    apikey: string,
    body?: RegistrationDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (apikey === null || apikey === undefined) {
      throw new Error(
        'Required parameter apikey was null or undefined when calling registerUser.'
      );
    }

    let headers = this.defaultHeaders;
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<
      BasePortalApiResponseDtoRegistrationResponseDto
    >(`${this.configuration.basePath}/servicerest/user/portal/register`, body, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * resetPassword
   * Start a user\&#39;s password reset process
   * @param apikey Application API key
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resetPassword(
    apikey: string,
    body?: ResetPasswordDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<BasePortalApiResponseDtoResetPasswordResponseDto>;
  public resetPassword(
    apikey: string,
    body?: ResetPasswordDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<BasePortalApiResponseDtoResetPasswordResponseDto>>;
  public resetPassword(
    apikey: string,
    body?: ResetPasswordDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<BasePortalApiResponseDtoResetPasswordResponseDto>>;
  public resetPassword(
    apikey: string,
    body?: ResetPasswordDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (apikey === null || apikey === undefined) {
      throw new Error(
        'Required parameter apikey was null or undefined when calling resetPassword.'
      );
    }

    let headers = this.defaultHeaders;
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<
      BasePortalApiResponseDtoResetPasswordResponseDto
    >(
      `${this.configuration.basePath}/servicerest/user/portal/resetpassword`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * setMyProfile
   * Set the user\&#39;s profile
   * @param authorization Authorization
   * @param apikey Application API key
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setMyProfile(
    authorization: string,
    apikey?: string,
    body?: SetMyProfileRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<BasePortalApiResponseDtoSetMyProfileResponseDto>;
  public setMyProfile(
    authorization: string,
    apikey?: string,
    body?: SetMyProfileRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpResponse<BasePortalApiResponseDtoSetMyProfileResponseDto>>;
  public setMyProfile(
    authorization: string,
    apikey?: string,
    body?: SetMyProfileRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<HttpEvent<BasePortalApiResponseDtoSetMyProfileResponseDto>>;
  public setMyProfile(
    authorization: string,
    apikey?: string,
    body?: SetMyProfileRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling setMyProfile.'
      );
    }

    let headers = this.defaultHeaders;
    if (apikey !== undefined && apikey !== null) {
      headers = headers.set('Apikey', String(apikey));
    }
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<BasePortalApiResponseDtoSetMyProfileResponseDto>(
      `${this.configuration.basePath}/servicerest/user/portal/setmyprofile`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
