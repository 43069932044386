import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ApartmentDto,
  ApartmentsAndUsersService,
  BasicDirectoryDataDto,
  StatusAndConfigurationService
} from 'src/app/api/mugconf';
import { DirectoriesService } from 'src/app/api/mugconf/api/directories.service';
import { AuthService } from 'src/app/core/auth.service';
import { MobileService } from 'src/app/core/mobile.service';
import {
  ApartmentCompleteDto,
  DeviceCompleteDto,
  FreeAptList
} from 'src/app/core/models';
import { isMobile, isSbc } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ApartmentCreateModalComponent } from 'src/app/sites/apartments/apartment-create-modal/apartment-create-modal.component';
import { ApartmentsService } from '../../sites/apartments/apartments.service';
import { SearchService } from '../search-input/search.service';
import { UserRole, licensesLabels } from '../utils/users';
import { GetApartmentInfoDto, SiteService } from 'src/app/api/yardi';

@Component({
  selector: 'app-apartments-table',
  templateUrl: './apartments-table.component.html',
  styleUrls: ['./apartments-table.component.scss']
})
export class ApartmentsTableComponent implements OnInit, OnDestroy {
  addrBookMap: BasicDirectoryDataDto[] = [];
  private unsubscribe$ = new Subject<void>();

  isLoading = false;
  hasSbc = false;
  siteId: string;
  columns: TableColumn[] = [];
  apartmentList = new ClientFiltrableDataSource<ApartmentCompleteDto>();
  completeAptList: ApartmentCompleteDto[] = [];
  devices: DeviceCompleteDto[];
  @Input() singleDevice = false;

  get isMobile() {
    return isMobile;
  }

  isSbc = isSbc;

  @ViewChild('nameTpl', { static: true }) nameTpl: ElementRef<HTMLElement>;
  @ViewChild('deviceTpl', { static: true }) deviceTpl: ElementRef<HTMLElement>;
  @ViewChild('indexTpl', { static: true }) indexTpl: ElementRef<HTMLElement>;
  @ViewChild('enableTpl', { static: true }) enableTpl: ElementRef<HTMLElement>;
  @ViewChild('addressBookTpl', { static: true }) addressBookTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('licenseTpl', { static: true }) licenseTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('menuTpl', { static: true }) menuTpl: ElementRef<HTMLElement>;
  userRole: UserRole;

  sorting!: boolean | undefined;

  constructor(
    private ts: TranslateService,
    private route: ActivatedRoute,
    private aptUsersService: ApartmentsAndUsersService,
    private aptsService: ApartmentsService,
    public mobileService: MobileService,
    public authService: AuthService,
    private directoriesService: DirectoriesService,
    private apartmentService: ApartmentsService,
    private toastr: ToastrService,
    private router: Router,
    private searchService: SearchService,
    private modalService: BsModalService,
    private ySiteService: SiteService,
    private statusConfService: StatusAndConfigurationService
  ) {
    this.apartmentService.selectedDeviceChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: DeviceCompleteDto[]) => {
        this.devices = res;
        this.checkIfSbcPresent();
        if (this.addrBookMap.length === 0 && res.length > 0) {
          this.directoriesService
            .getDirs(res[0].resource.uuid!, this.authService.getToken())
            .subscribe((r) => {
              this.addrBookMap = r;
            });
        }
        if (this.completeAptList.length === 0) {
          this.loadAllApartments(res);
        } else {
          this.loadApartments(res);
        }

        if (this.devices.length) {
          this.columns.splice(0, 1);
        }
        this.updateColumns();
      });
  }

  checkApt(event: any, row: ApartmentCompleteDto) {
    row.loading = true;
    event.stopPropagation();
    this.ySiteService
      .apartmentDeviceUuidIndexGet(row.deviceId, row.id!)
      .pipe(finalize(() => (row.loading = false)))
      .subscribe((res: GetApartmentInfoDto) => {
        row.bound = res.info?.domain?.externalSystemBound;
      });
  }

  checkIfSbcPresent() {
    this.hasSbc = false;
    this.devices.map((element: DeviceCompleteDto) => {
      if (isSbc(element.resource.deviceModelId)) {
        this.hasSbc = true;
        return;
      }
    });
  }

  getAddressBook(value: number) {
    if (this.addrBookMap.find((x) => x.dir === value)) {
      return this.addrBookMap.find((x) => x.dir === value)!.descr;
    } else {
      return '--';
    }
  }

  getLicense(value: number, modelId: string) {
    return isSbc(modelId)
      ? '--'
      : value !== -1
      ? this.ts.instant(
          licensesLabels[Object.keys(ApartmentDto.LictypeEnum)[value]]
        ) || '--'
      : '--';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.completeAptList = [];
  }

  ngOnInit(): void {
    this.siteId = this.route.snapshot.params.siteId;
    this.sorting = this.route.snapshot.queryParams.enabled
      ? this.route.snapshot.queryParams.enabled === 'true'
      : undefined;
    this.userRole = this.route.snapshot.data.userRole;

    this.updateColumns();
    this.apartmentList.limit = 50;

    this.searchService.searchValueChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        v = v.toString().toLowerCase();
        this.apartmentList.setCustomFilter(
          (item) =>
            item.vipaddr?.toLowerCase().indexOf(v) !== -1 ||
            item.descr?.toLowerCase().indexOf(v) !== -1
        );
        this.apartmentList.applyFilters();
      });
  }

  updateColumns() {
    this.columns = [
      {
        prop: 'deviceLabel',
        name: this.ts.instant('APARTMENT.DEVICE'),
        cellTemplate: this.deviceTpl
      },
      {
        prop: 'vipaddr',
        name: this.hasSbc
          ? this.ts.instant('APARTMENT.ADDRESS')
          : ![
              'BUILDINGMANAGER-COLLABORATOR',
              'MAINTAINER',
              'BUILDINGMANAGER'
            ].includes(this.userRole)
          ? this.ts.instant('APARTMENT.VIP_ADDRESS')
          : this.ts.instant('APARTMENT.APARTMENT_ID'),
        cellTemplate: this.nameTpl
      },
      {
        prop: 'descr',
        name: this.ts.instant('APARTMENT.DESCRIPTION')
      }
    ];

    if (!this.singleDevice || !this.hasSbc) {
      this.columns.push({
        prop: 'lictype',
        name: this.ts.instant('APARTMENT.LICENSE_TYPE'),
        cellTemplate: this.licenseTpl
      });
    }

    this.columns.push(
      {
        prop: 'addrbook',
        name: this.ts.instant('APARTMENT.DIRECTORY'),
        cellTemplate: this.addressBookTpl
      },
      {
        prop: 'enable',
        name: this.ts.instant('APARTMENT.ENABLING'),
        cellTemplate: this.enableTpl
      },
      {
        prop: '',
        name: this.ts.instant('GLOBAL.ACTIONS'),
        cellTemplate: this.menuTpl
      }
    );

    if (this.singleDevice) {
      this.columns.splice(0, 1, {
        prop: 'id',
        name: this.ts.instant('APARTMENT.INDEX'),
        cellTemplate: this.indexTpl
      });
    }
  }

  loadApartments(devices: DeviceCompleteDto[]) {
    let aptList: ApartmentCompleteDto[] = [];
    const devicesId = devices.map((x) => x.resource.uuid);

    aptList = this.completeAptList.filter(
      (x) => devicesId.indexOf(x.deviceId) !== -1
    );
    this.apartmentList.setItems(
      aptList.filter((x) => x.vipaddr !== '' || (x.lictype as any) !== 0)
    );
    this.apartmentList.setPage(0);
  }

  canAddApt() {
    return this.completeAptList.find(
      (x) => x.vipaddr === '' && (x.lictype as any) == 0
    );
  }

  loadAllApartments(devices: DeviceCompleteDto[]) {
    this.completeAptList = [];

    devices.forEach((element, index) => {
      this.isLoading = true;
      this.aptUsersService
        .getApartments(element.resource.uuid!, this.authService.getToken())
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((res: ApartmentDto[]) => {
          res.forEach((el: ApartmentDto) => {
            const apt: ApartmentCompleteDto = {
              ...el,
              deviceModel: element.resource.deviceModelId!,
              deviceLabel: element.resource.name!,
              deviceId: element.resource.uuid!
            };
            this.completeAptList.push(apt);
          });
          this.apartmentList.setItems(
            this.completeAptList.filter(
              (x) => x.vipaddr !== '' || (x.lictype as any) !== 0
            )
          );

          if (this.sorting !== undefined) {
            this.apartmentList.setSort({
              dir: this.sorting ? 'desc' : 'asc',
              prop: 'enable'
            });
            this.apartmentList.applyFilters();
          }
        });
    });
  }

  selectApt(e: any) {
    this.router.navigate([
      'sites',
      this.siteId,
      'apartments',
      e.selected[0].deviceId,
      e.selected[0].id
    ]);
  }

  resetApt(apt: ApartmentCompleteDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('APARTMENT.RESET_APARTMENT'),
      description: isSbc(apt.deviceModel)
        ? this.ts.instant('APARTMENT.RESET_APARTMENT_DESC_SBC')
        : this.ts.instant('APARTMENT.RESET_APARTMENT_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      const aptToChange = { ...apt } as any;
      aptToChange.cmdcode = 1;

      this.aptUsersService
        .setApartments(apt.deviceId, this.authService.getToken(), {
          apartments: [aptToChange as ApartmentDto]
        })
        .subscribe(() => {
          apt = this.aptsService.resetApt(apt);
          this.apartmentList.loadData();
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('APARTMENT.RESET_SUCCESSFULLY'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        });
    });
  }

  deleteApt(apt: ApartmentCompleteDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('APARTMENT.DELETE_APARTMENT'),
      description: this.ts.instant('APARTMENT.DELETE_APARTMENT_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      // APARTMENT preparation
      apt.descr = '';
      apt.vipaddr = '';
      apt.descr = '';
      apt.lictype = 0 as any;
      apt.pwd = '';
      apt.email = '';
      apt.fwdbusy = false;
      apt.fwdaddr = '';
      apt.addrbook = undefined;

      this.aptUsersService
        .setApartments(apt.deviceId, this.authService.getToken(), {
          apartments: [apt]
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.loadAllApartments(this.devices);
          this.toastr.success(
            this.ts.instant('APARTMENT.APARTMENT_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          modalRef.hide();
        });
    });
  }

  disableApt(apt: ApartmentCompleteDto) {
    apt.enable = !apt.enable;
    this.aptUsersService
      .setApartments(apt.deviceId, this.authService.getToken(), {
        apartments: [apt as ApartmentDto]
      })
      .subscribe(() => {
        this.toastr.success(
          apt.enable
            ? this.ts.instant('APARTMENT.ENABLED_SUCCESSFULLY')
            : this.ts.instant('APARTMENT.DISABLED_SUCCESSFULLY'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  createNewApt() {
    const apartments: FreeAptList = {};
    const deviceSelectable: DeviceCompleteDto[] = [];

    this.devices.forEach((element: DeviceCompleteDto) => {
      const freeApt = this.completeAptList.find((x) => {
        return (
          x.deviceId === element.resource.uuid! &&
          (x.lictype as any) === 0 &&
          x.vipaddr === ''
        );
      });

      if (freeApt) {
        deviceSelectable.push(element);
        if (!apartments[element.resource.uuid!]) {
          apartments[element.resource.uuid!] = freeApt;
        }
      }
    });

    const initialState: Partial<ApartmentCreateModalComponent> = {
      deviceList: deviceSelectable,
      apartments,
      userRole: this.userRole
    };

    const modalRef = this.modalService.show(ApartmentCreateModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.aptCreated.subscribe(() => {
      this.loadAllApartments(this.devices);
    });
  }

  sendEmailAllSelectedDevices() {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('DEVICE.SEND_MAIL'),
      description: this.ts.instant('DEVICE.SEND_MAIL_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.devices.forEach((element) => {
        this.statusConfService
          .setInvitationMailCfg(
            element.resource.uuid!,
            this.authService.getToken(),
            { inviteall: 1 }
          )
          .subscribe(() => {
            modalRef.hide();
            this.toastr.success(
              this.ts.instant('DEVICE.EMAIL_SENT'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
          });
      });
    });
  }
}
