/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GenerateMaintenanceReportPortalFiltersDto {
  testType?: GenerateMaintenanceReportPortalFiltersDto.TestTypeEnum;
  dateFrom?: string;
  dateTo?: string;
}
export namespace GenerateMaintenanceReportPortalFiltersDto {
  export type TestTypeEnum = 'COMPLETE' | 'DAILY' | 'PARTIAL';
  export const TestTypeEnum = {
    COMPLETE: 'COMPLETE' as TestTypeEnum,
    DAILY: 'DAILY' as TestTypeEnum,
    PARTIAL: 'PARTIAL' as TestTypeEnum
  };
}
