<div class="modal-header">
  <h2>{{ 'DEVICE.FIRE.DOCS.TITLE' | translate }}</h2>
  <button
    id="close"
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pb-0">
  <ng-container *ngFor="let item of docs"
    ><span
      class="single-doc"
      [innerHTML]="
        messagesText[item.tag]
          ? (messagesText[item.tag] | translate)
          : item.defaultMessage
      "
    ></span>
  </ng-container>
  <label class="mt-3" for="accept-docs"
    ><input id="accept-docs" type="checkbox" [formControl]="acceptControl" />{{
      'DEVICE.FIRE.DOCS.ACCEPT' | translate
    }}</label
  >
</div>

<div class="modal-footer">
  <app-loading-button
    id="ok"
    buttonClass="button-primary big"
    [isLoading]="isLoading"
    (click)="goOn()"
    buttonType="button"
    >{{ 'GLOBAL.OK' | translate }}</app-loading-button
  >
</div>
