<div class="modal-header">
  <h2>
    {{ (addrBook ? 'ADDRESSBOOK.EDIT' : 'ADDRESSBOOK.CREATE_NEW') | translate }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="addressBookControl">
  <label class="custom-label" for="description">{{
    'ADDRESSBOOK.DESCRIPTION' | translate
  }}</label>
  <input
    id="description"
    type="text"
    class="custom-input"
    [placeholder]="'ADDRESSBOOK.DESCRIPTION' | translate"
    formControlName="descr"
  />
  <label class="custom-label" for="abilitation">{{
    'ADDRESSBOOK.ABILITATION' | translate
  }}</label>

  <div class="switch-container">
    <span
      class="abilitation"
      [ngClass]="{ green: abilitationControl.value }"
    ></span>
    {{
      (abilitationControl.value ? 'APARTMENT.ENABLED' : 'APARTMENT.DISABLED')
        | translate
    }}
    <div class="switch">
      <app-switch
        [control]="abilitationControl"
        inputId="abilitation"
      ></app-switch>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button
    id="close"
    class="button-secondary big mr-3"
    (click)="close()"
    type="button"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    id="save"
    class="button-primary big mr-3"
    [disabled]="!addressBookControl.valid"
    (click)="save()"
    type="button"
  >
    {{ 'GLOBAL.SAVE' | translate }}
  </button>
</div>
