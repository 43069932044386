/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DisableOutputDto {
  /**
   * Periheral address referring the peripheral to whom output belongs
   */
  deviceAddress: DisableOutputDto.DeviceAddressEnum;
  /**
   * Output index is an integer value containing the index of the output in peripheral\'s output list
   */
  outputIndex: number;
}
export namespace DisableOutputDto {
  export type DeviceAddressEnum =
    | 'POWER_SUPPLY'
    | 'POWER_SUPPLY_EXT'
    | 'OUTPUT'
    | 'OUT_EXT'
    | 'LOOP_1'
    | 'LOOP_2'
    | 'LOOP_3'
    | 'LOOP_4'
    | 'LOOP_5'
    | 'LOOP_6'
    | 'LOOP_7'
    | 'LOOP_8';
  export const DeviceAddressEnum = {
    POWERSUPPLY: 'POWER_SUPPLY' as DeviceAddressEnum,
    POWERSUPPLYEXT: 'POWER_SUPPLY_EXT' as DeviceAddressEnum,
    OUTPUT: 'OUTPUT' as DeviceAddressEnum,
    OUTEXT: 'OUT_EXT' as DeviceAddressEnum,
    LOOP1: 'LOOP_1' as DeviceAddressEnum,
    LOOP2: 'LOOP_2' as DeviceAddressEnum,
    LOOP3: 'LOOP_3' as DeviceAddressEnum,
    LOOP4: 'LOOP_4' as DeviceAddressEnum,
    LOOP5: 'LOOP_5' as DeviceAddressEnum,
    LOOP6: 'LOOP_6' as DeviceAddressEnum,
    LOOP7: 'LOOP_7' as DeviceAddressEnum,
    LOOP8: 'LOOP_8' as DeviceAddressEnum
  };
}
