import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SitesService } from '../api/myportal';
import { SiteDto } from '../api/myportal/model/siteDto';
import { AuthService } from '../core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SitesListService {
  sites$ = new BehaviorSubject<SiteDto[]>([]);
  constructor(
    private authService: AuthService,
    private sitesService: SitesService
  ) {}

  loadSites(): Observable<SiteDto[]> {
    return this.sitesService
      .getSites(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.authService.getToken()
      )
      .pipe(
        tap((res) => {
          this.sites$.next(res);
        })
      );
  }
}
