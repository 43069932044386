/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The user to be added or removed
 */
export interface GroupUserRequestDto {
  /**
   * The user\'s authenticationId
   */
  userAuthId?: string;
  /**
   * The user\'s login email
   */
  readonly loginEmail?: string;
}
