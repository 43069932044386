<div class="modal-header">
  <h2>
    {{
      (replace
        ? 'DEVICE.MUGSBC.REPLACE_ROUTER'
        : onlyContacts
        ? 'DEVICE.MUGSBC.EDIT_CONTACTS'
        : 'DEVICE.MUGSBC.CONNECTIVITY.TITLE'
      ) | translate
    }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="connectyvityFormGroup">
  <ng-container *ngIf="phase === 1">
    <div class="text-center">
      <b *ngIf="!replace" class="mb-3 d-block"
        >{{ 'DEVICE.MUGSBC.CONNECTIVITY.INFO1' | translate }}
      </b>

      <p
        id="info"
        [innerHTML]="'DEVICE.MUGSBC.CONNECTIVITY.INFO2' | translate"
      ></p>
      <p>
        {{ 'DEVICE.MUGSBC.CONNECTIVITY.INFO3' | translate }}
      </p>
    </div>
    <img
      class="d-block mx-auto"
      src="assets/images/iccid.png"
      alt="ICCID Code"
    />
    <label class="custom-label" for="device-iccid">{{
      'DEVICE.MUGSBC.CODICE_ICCID_SIM' | translate
    }}</label>
    <div class="row mb-4">
      <div class="col-10">
        <input
          id="device-iccid"
          type="text"
          class="custom-input"
          [maxlength]="20"
          [placeholder]="'DEVICE.MUGSBC.CODICE_ICCID_SIM' | translate"
          [formControl]="deviceCodeControl"
        />
        <app-control-errors
          id="device-iccid-validator"
          controlName="deviceCode"
          [customMessages]="{
            sameId: 'VALIDATE.SAME_ICCID' | translate
          }"
        ></app-control-errors>
      </div>
      <div class="col-2">
        <app-scanner-button
          [isBarcode]="true"
          (codeRetrieved)="codeRetrieved($event)"
        ></app-scanner-button>
      </div>
    </div>
    <div class="switch-container mr-4">
      <label for="conditions-switch">{{
        'DEVICE.MUGSBC.GENERAL_CONDITIONS' | translate
      }}</label>
      <div
        class="switch"
        [tooltip]="
          !deviceCodeControl.valid
            ? ('DEVICE.MUGSBC.INSERT_IDCCID' | translate)
            : ''
        "
      >
        <label class="switch-label">
          <input
            id="conditions-switch"
            type="checkbox"
            class="switch-input"
            [ngClass]="{ disabled: !deviceCodeControl.valid }"
            (click)="
              deviceCodeControl.valid
                ? openConditions($event)
                : $event.preventDefault()
            "
            [formControl]="conditionsControl"
          />
          <i class="switch-controls"></i>
        </label>
      </div>
    </div>
    <div class="switch-container mr-4">
      <label for="conditions2-switch">{{
        'DEVICE.MUGSBC.GENERAL_CONDITIONS_PT2' | translate
      }}</label>
      <div class="switch">
        <app-switch
          [control]="conditions2Control"
          inputId="conditions2-switch"
        ></app-switch>
      </div>
    </div>
    <div class="text-center mt-3">
      <app-loading-button
        id="save"
        buttonClass="button-primary big"
        [isLoading]="false"
        (click)="phase = checkLocation() ? 2 : 3"
        buttonType="button"
        [disabled]="!connectyvityFormGroup.valid"
        >{{ 'GLOBAL.CONTINUE' | translate }}</app-loading-button
      >
    </div>
  </ng-container>
  <ng-container *ngIf="phase === 2">
    <div class="text-center">
      <p>{{ 'DEVICE.MUGSBC.CONNECTIVITY.INFO4' | translate }}</p>
      <button
        class="button-primary mr-3"
        (click)="
          phase = 4;
          connectyvityFormGroup.controls['sendComelit'].setValue(true)
        "
      >
        {{ 'CONNECTIVITY.YES' | translate }}
      </button>
      <button class="button-primary" (click)="phase = 3">
        {{ 'CONNECTIVITY.NO' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!onlyContacts">
    <ng-container *ngIf="phase === 3">
      <ng-container
        *ngTemplateOutlet="addresses; context: { form: connectyvityFormGroup }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="buttons; context: { form: connectyvityFormGroup }"
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="phase === 4">
      <ng-container
        *ngTemplateOutlet="contacts; context: { form: connectyvityFormGroup }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="buttons; context: { form: connectyvityFormGroup }"
      ></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="onlyContacts">
    <ng-container *ngIf="phase === 3">
      <ng-container
        *ngTemplateOutlet="addresses; context: { form: connectyvityFormGroup }"
      ></ng-container>
      <ng-container *ngIf="checkLocation()">
        <ng-container
          *ngTemplateOutlet="contacts; context: { form: connectyvityFormGroup }"
        ></ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="buttons; context: { form: connectyvityFormGroup }"
      ></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #addresses let-form="form">
  <div [formGroup]="form">
    <label class="custom-label" for="device-addresses">{{
      'DEVICE.MUGSBC.ADDRESSES' | translate
    }}</label>
    <input
      id="device-addresses"
      type="text"
      class="custom-input tag-input mb-2"
      [placeholder]="'DEVICE.MUGSBC.EMAIL_ADDRESS' | translate"
      [formControl]="tagFormControl"
    />
    <button
      [disabled]="
        !tagFormControl.value ||
        !tagFormControl.valid ||
        tagsArrayControl.value.length > 4
      "
      class="button-secondary ml-2 pl-2 pr-1"
      (click)="addTag()"
    >
      <i class="material-icons">add</i>
    </button>
    <app-control-errors
      id="device-addresses-validator"
      [customMessages]="{
        pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate,
        alreadyPresent: 'VALIDATE.ALREADY_PRESENT' | translate
      }"
      controlName="tag"
    ></app-control-errors>

    <div id="tag-box">
      <div
        *ngFor="let tag of tagsArrayControl.value; let i = index"
        class="tag"
      >
        {{ tag }}
        <i class="material-icons" (click)="removeTag(i)">close</i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contacts let-form="form">
  <div [formGroup]="form">
    <label for="backup" class="mt-3">
      <ng-container *ngIf="onlyContacts">
        <input id="backup" type="checkbox" formControlName="sendComelit" />
        <b> {{ 'DEVICE.MUGSBC.SEND_COMELIT' | translate }}</b
        ><br />
      </ng-container>
      {{ 'DEVICE.MUGSBC.SEND_COMELIT_DESC' | translate }}</label
    >
    <div
      class="row"
      *ngIf="
        !onlyContacts || connectyvityFormGroup.controls['sendComelit'].value
      "
    >
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="device-name">{{
          'DEVICE.MUGSBC.NAME' | translate
        }}</label>
        <input
          id="device-name"
          type="text"
          class="custom-input"
          [placeholder]="'DEVICE.MUGSBC.NAME' | translate"
          formControlName="name"
        />
        <app-control-errors
          id="device-name-validator"
          controlName="name"
        ></app-control-errors>
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="device-surname">{{
          'DEVICE.MUGSBC.SURNAME' | translate
        }}</label>
        <input
          id="device-surname"
          type="text"
          class="custom-input"
          [placeholder]="'DEVICE.MUGSBC.SURNAME' | translate"
          formControlName="surname"
        />
        <app-control-errors
          id="device-surname-validator"
          controlName="surname"
        ></app-control-errors>
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="device-phone">{{
          'DEVICE.MUGSBC.PHONE' | translate
        }}</label>
        <input
          id="device-phone"
          type="text"
          class="custom-input"
          [placeholder]="'DEVICE.MUGSBC.PHONE' | translate"
          formControlName="phone"
        />
        <app-control-errors
          id="device-phone-validator"
          controlName="phone"
        ></app-control-errors>
      </div>
      <div class="col-12 col-sm-6">
        <label class="custom-label" for="device-email">{{
          'DEVICE.MUGSBC.EMAIL' | translate
        }}</label>
        <input
          id="device-email"
          type="text"
          class="custom-input"
          [placeholder]="'DEVICE.MUGSBC.EMAIL' | translate"
          formControlName="email"
        />
        <app-control-errors
          id="device-email-validator"
          controlName="email"
        ></app-control-errors>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttons let-form="form">
  <div [formGroup]="form">
    <div class="text-center mt-5">
      <button
        *ngIf="checkLocation() && !onlyContacts"
        id="close"
        class="button-secondary big mr-3"
        (click)="phase = 2"
        type="button"
      >
        {{ 'GLOBAL.BACK' | translate }}
      </button>
      <app-loading-button
        *ngIf="!onlyContacts"
        id="activate"
        buttonClass="button-primary big"
        [isLoading]="false"
        (click)="activateSbc()"
        buttonType="button"
        [disabled]="!connectyvityFormGroup.valid"
        >{{ 'DEVICE.MUGSBC.ACTIVATE' | translate }}</app-loading-button
      >
      <app-loading-button
        *ngIf="onlyContacts"
        id="save"
        buttonClass="button-primary big"
        [isLoading]="false"
        (click)="saveContacts()"
        buttonType="button"
        [disabled]="!connectyvityFormGroup.valid"
        >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
      >
    </div>
  </div>
</ng-template>
