import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LicenseInstanceManagementDeviceService } from 'src/app/api/licenses';
import { LicenseRouterUpdateDto } from 'src/app/api/licenses/model/licenseRouterUpdateDto';
import { AuthService } from 'src/app/core/auth.service';
import {
  checkIfAlreadyPresent,
  ifComelitIsRequired,
  notCurrentID32
} from 'src/app/core/utils';
import { LicenseInstanceExpanded } from 'src/app/sites/site-detail/site-connectivity/site-connectivity.component';
import { environment } from 'src/environments/environment';
import { ConditionsModalComponent } from '../conditions-modal/conditions-modal.component';

@Component({
  selector: 'app-connectivity-activation-modal',
  templateUrl: './connectivity-activation-modal.component.html',
  styleUrls: ['./connectivity-activation-modal.component.scss']
})
export class ConnectivityActivationModalComponent implements OnInit {
  phase = 1;
  replace? = false;
  addingOnlyConnection? = false;
  onlyContacts = false;
  deviceId: string;
  deviceAuthId: string;
  oldIccid: string;
  comelitMail = 'UFFICIOSATCO2020@comelitit.onmicrosoft.com';
  deviceCodeControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
    Validators.minLength(20),
    Validators.maxLength(20)
  ]);
  conditionsControl = new UntypedFormControl(false, Validators.requiredTrue);
  conditions2Control = new UntypedFormControl(false, Validators.requiredTrue);
  tagsArrayControl = new UntypedFormArray([]);

  tagFormControl = new UntypedFormControl('');

  connectyvityFormGroup: UntypedFormGroup;

  get nameControl() {
    return this.connectyvityFormGroup.controls['name'] as UntypedFormControl;
  }
  get surnameControl() {
    return this.connectyvityFormGroup.controls['surname'] as UntypedFormControl;
  }
  get emailControl() {
    return this.connectyvityFormGroup.controls['email'] as UntypedFormControl;
  }
  get phoneControl() {
    return this.connectyvityFormGroup.controls['phone'] as UntypedFormControl;
  }

  connectivityCompleted = new EventEmitter<void>();

  connectivityValue: LicenseInstanceExpanded;

  constructor(
    private modalRef: BsModalRef,
    private licenseService: LicenseInstanceManagementDeviceService,
    private modalService: BsModalService,
    public authService: AuthService,
    private toastrService: ToastrService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    this.phase = this.onlyContacts ? 3 : 1;
    if (this.replace) {
      this.deviceCodeControl.setValidators([
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.minLength(20),
        Validators.maxLength(20),
        notCurrentID32(this.connectivityValue.optionalLicenseData?.iccid as any)
      ]);
    }

    this.connectyvityFormGroup = new UntypedFormGroup({
      deviceCode: this.deviceCodeControl,
      conditions: this.conditionsControl,
      conditions2: this.conditions2Control,
      addresses: this.tagsArrayControl,
      sendComelit: new UntypedFormControl(false),
      name: new UntypedFormControl('', ifComelitIsRequired()),
      surname: new UntypedFormControl('', ifComelitIsRequired()),
      phone: new UntypedFormControl('', ifComelitIsRequired()),
      email: new UntypedFormControl('', [
        ifComelitIsRequired(),
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
      ]),
      tag: this.tagFormControl
    });

    this.connectyvityFormGroup.controls['sendComelit'].valueChanges.subscribe(
      (x) => {
        this.nameControl.setValue('');
        this.nameControl.updateValueAndValidity();
        this.surnameControl.setValue('');
        this.surnameControl.updateValueAndValidity();
        this.emailControl.setValue('');
        this.emailControl.updateValueAndValidity();
        this.phoneControl.setValue('');
        this.phoneControl.updateValueAndValidity();
      }
    );

    if (this.connectivityValue) {
      this.connectyvityFormGroup.patchValue(this.connectivityValue);
      if (!this.replace) {
        this.deviceCodeControl.setValue(
          this.connectivityValue.optionalLicenseData?.iccid || ''
        );
      }
      this.conditionsControl.setValue(true);
      this.conditions2Control.setValue(true);
      (this.connectivityValue.notificationData?.userToNotifyList || []).forEach(
        (a: string) => {
          if (a !== this.comelitMail) {
            this.tagsArrayControl.push(new UntypedFormControl(a));
          }
        }
      );

      this.connectyvityFormGroup.controls['sendComelit'].setValue(
        this.connectivityValue.notificationData?.userToNotifyList?.indexOf(
          this.comelitMail
        ) !== -1
      );
      this.nameControl.setValue(
        this.connectivityValue.optionalLicenseData?.name || ''
      );
      this.surnameControl.setValue(
        this.connectivityValue.optionalLicenseData?.surname || ''
      );
      this.emailControl.setValue(
        this.connectivityValue.optionalLicenseData?.email || ''
      );
      this.phoneControl.setValue(
        this.connectivityValue.optionalLicenseData?.phone || ''
      );

      if (this.replace) {
        this.oldIccid = this.connectivityValue.optionalLicenseData
          ?.iccid as any;
      }
    }

    this.tagFormControl.setValidators([
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
      checkIfAlreadyPresent(this.tagsArrayControl.value)
    ]);
  }

  close() {
    this.modalRef.hide();
  }

  saveContacts() {
    const emailArr = this.manageEmails();

    let data: any = {
      dto_type: 'create_tim',
      deviceAuthId: undefined,
      holderAuthId: '',
      durationUnit: 'YEARS',
      iccid: this.deviceCodeControl.value,
      duration: 1,
      description: `${this.deviceId} ${this.deviceCodeControl.value}`,
      userToNotifyList: emailArr,
      customData: {
        name: this.nameControl.value,
        surname: this.surnameControl.value,
        email: this.emailControl.value,
        phone: this.phoneControl.value,
        iccid: this.deviceCodeControl.value
      }
    };

    this.licenseService
      .updateDeviceLicenseInstanceMetadata(
        this.connectivityValue.uuid!,
        this.deviceId,
        data
      )
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('DEVICE.CONTACTS_CONNECTIVITY_UPDATED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
        this.modalRef.hide();
        this.connectivityCompleted.next();
      });
  }

  checkLocation() {
    return this.authService.getCountryCodeNoDefault() === 'IT';
  }

  manageEmails() {
    let emailArr = [...this.connectyvityFormGroup.controls['addresses'].value];
    const index = emailArr.indexOf(this.comelitMail);
    if (
      this.connectyvityFormGroup.controls['sendComelit'].value &&
      index === -1
    ) {
      emailArr.push(this.comelitMail);
    } else if (
      !this.connectyvityFormGroup.controls['sendComelit'].value &&
      index !== -1
    ) {
      emailArr.splice(index, 1);
    }

    return emailArr;
  }

  activateSbc() {
    const emailArr = this.manageEmails();
    if (this.replace) {
      let data: LicenseRouterUpdateDto = {
        dto_type: 'update_router',
        deviceAuthId: undefined,
        holderAuthId: '',
        durationUnit: 'YEARS',
        duration: 1,
        description: `${this.deviceId} ${this.deviceCodeControl.value}`,
        userToNotifyList: emailArr,
        customData: {
          name: this.nameControl.value,
          surname: this.surnameControl.value,
          email: this.emailControl.value,
          phone: this.phoneControl.value
        },
        iccid: this.deviceCodeControl.value,
        oldIccid: this.oldIccid
      };

      this.licenseService
        .updateDeviceLicenseInstanceRouter(
          this.connectivityValue.uuid!,
          this.deviceId,
          data
        )
        .subscribe(() => {
          this.toastrService.success(
            this.ts.instant('DEVICE.DEVICE_RENEWED_CONNECTIVITY'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.modalRef.hide();
          this.connectivityCompleted.next();
        });
    } else {
      let data: any = {
        dto_type: 'create_tim',
        deviceAuthId: undefined,
        holderAuthId: '',
        iccid: this.deviceCodeControl.value,
        durationUnit: 'YEARS',
        duration: 1,
        description: `${this.deviceId} ${this.deviceCodeControl.value}`,
        userToNotifyList: emailArr,
        customData: {
          name: this.nameControl.value,
          surname: this.surnameControl.value,
          email: this.emailControl.value,
          phone: this.phoneControl.value
        }
      };
      this.licenseService
        .createDeviceLicenseInstance(environment.licenseId, this.deviceId, data)
        .subscribe(
          () => {
            this.toastrService.success(
              this.addingOnlyConnection
                ? this.ts.instant('DEVICE.CREATED_CONNECTIVITY')
                : this.ts.instant('DEVICE.DEVICE_CREATED_CONNECTIVITY'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
            this.modalRef.hide();
            this.connectivityCompleted.next();
          },
          (error) => {
            switch (error.error.errorCode) {
              case 'LICMANAGER:LICENSE:EXISTING_LICENSE_FOUND':
                this.toastrService.clear();
                this.toastrService.error(
                  this.ts.instant('DEVICE.MUGSBC.LICENSE_EXISTS'),
                  this.ts.instant('GLOBAL.ERROR')
                );
                break;
              case 'LICMANAGER:LICENSE:EXISTING_LICENCE_ICCID_FOUND':
                this.toastrService.clear();
                this.toastrService.error(
                  this.ts.instant('DEVICE.MUGSBC.LICENSE_ICCID_EXISTS'),
                  this.ts.instant('GLOBAL.ERROR')
                );
                break;
              default:
                break;
            }
          }
        );
    }
  }

  activateLaterSbc() {
    this.modalRef.hide();
    this.connectivityCompleted.next();
  }

  checkMobile() {
    return false;
  }

  openConditions(event: PointerEvent) {
    event.preventDefault();
    if (!this.conditionsControl.value) {
      const initialState: Partial<ConditionsModalComponent> = {
        deviceAuthId: this.deviceAuthId,
        iccid: this.deviceCodeControl.value
      };

      const modalRef = this.modalService.show(ConditionsModalComponent, {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      });

      modalRef.content!.conditionsRead.subscribe(() => {
        this.conditionsControl.setValue(true);
      });
    }
  }
  codeRetrieved(res: string) {
    this.deviceCodeControl.setValue(res);
  }

  addTag() {
    if (this.tagFormControl.value) {
      this.tagsArrayControl.value.push(this.tagFormControl.value);
      this.tagFormControl.setValue('');
    }
  }

  removeTag(index: number) {
    this.tagsArrayControl.value.splice(index, 1);
  }
}
