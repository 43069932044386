/**
 * CCAPI HTTP /servicerest/log
 * CCAPI HTTP /servicerest/log
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OutputTraceDto {
  traceId?: number;
  tag?: string;
  eventCode?: string;
  source?: string;
  sourceType?: string;
  targetType?: string;
  target?: string;
  eventDate?: string;
  severity?: OutputTraceDto.SeverityEnum;
  uidFlow?: string;
  authorization?: number;
  data?: { [key: string]: object };
}
export namespace OutputTraceDto {
  export type SeverityEnum =
    | 'INFO'
    | 'WARNING'
    | 'CRITICAL'
    | 'DEBUG'
    | 'ALERT'
    | 'ERROR';
  export const SeverityEnum = {
    INFO: 'INFO' as SeverityEnum,
    WARNING: 'WARNING' as SeverityEnum,
    CRITICAL: 'CRITICAL' as SeverityEnum,
    DEBUG: 'DEBUG' as SeverityEnum,
    ALERT: 'ALERT' as SeverityEnum,
    ERROR: 'ERROR' as SeverityEnum
  };
}
