<div class="search-box">
  <label for="search"><i class="material-icons notranslate">search</i></label>
  <input
    id="search"
    type="text"
    class="custom-input"
    [placeholder]="'GLOBAL.SEARCH' | translate"
    [formControl]="formControl"
  />
</div>
