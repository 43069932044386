import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActuatorDirectoryDataDto } from 'src/app/api/mugconf';

@Component({
  selector: 'app-create-actuator-modal',
  templateUrl: './create-actuator-modal.component.html',
  styleUrls: ['./create-actuator-modal.component.scss']
})
export class CreateActuatorModalComponent implements OnInit {
  actuatorGroup: UntypedFormGroup;
  actuator?: ActuatorDirectoryDataDto;
  title: string;
  dirId: number;
  id?: number;
  created = new EventEmitter<ActuatorDirectoryDataDto>();
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.actuatorGroup = new UntypedFormGroup({
      dir: new UntypedFormControl(this.dirId),
      id: new UntypedFormControl(this.id),
      outputid: new UntypedFormControl(1),
      moduleid: new UntypedFormControl(1),
      name: new UntypedFormControl('', Validators.required),
      addr: new UntypedFormControl('', [
        Validators.min(1),
        Validators.max(255),
        Validators.maxLength(3),
        Validators.minLength(3),
        Validators.pattern('[0-9]*'),
        Validators.required
      ])
    });

    if (this.actuator) {
      this.actuatorGroup.patchValue(this.actuator);
      this.actuatorGroup.controls['addr'].setValue(
        this.actuator.addr?.split('SBACT')[1]
      );
    }
  }

  close() {
    this.modalRef.hide();
  }
  save() {
    if (this.actuatorGroup.valid) {
      this.created.next({
        ...this.actuatorGroup.value,
        addr: `SBACT${this.actuatorGroup.controls['addr'].value}`
      });
    }
  }
}
