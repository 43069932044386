/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Action command options
 */
export interface SafeActionCommandOptionsDto {
  /**
   * Action type
   */
  actionType?: SafeActionCommandOptionsDto.ActionTypeEnum;
}
export namespace SafeActionCommandOptionsDto {
  export type ActionTypeEnum =
    | 'EVENT_KEY1'
    | 'EVENT_KEY2'
    | 'STOP_ALARMS'
    | 'RESET_ALARMS'
    | 'STOP_PHONE_CALLS'
    | 'AUTHORIZE_INSTALLER'
    | 'LOGIN_UPGRADE'
    | 'AUTHORIZE_PRODUCER'
    | 'ENABLE_PRODUCER';
  export const ActionTypeEnum = {
    EVENTKEY1: 'EVENT_KEY1' as ActionTypeEnum,
    EVENTKEY2: 'EVENT_KEY2' as ActionTypeEnum,
    STOPALARMS: 'STOP_ALARMS' as ActionTypeEnum,
    RESETALARMS: 'RESET_ALARMS' as ActionTypeEnum,
    STOPPHONECALLS: 'STOP_PHONE_CALLS' as ActionTypeEnum,
    AUTHORIZEINSTALLER: 'AUTHORIZE_INSTALLER' as ActionTypeEnum,
    LOGINUPGRADE: 'LOGIN_UPGRADE' as ActionTypeEnum,
    AUTHORIZEPRODUCER: 'AUTHORIZE_PRODUCER' as ActionTypeEnum,
    ENABLEPRODUCER: 'ENABLE_PRODUCER' as ActionTypeEnum
  };
}
