/**
 * CCAPI HTTP /servicerest/log
 * CCAPI HTTP /servicerest/log
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UplS3ResponseBodyDto {
  unavailable?: boolean;
  id?: string;
  error?: string;
}
