import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-changed-password-modal',
  templateUrl: './changed-password-modal.component.html',
  styleUrls: ['./changed-password-modal.component.scss']
})
export class ChangedPasswordModalComponent implements OnInit {
  apartmentName: string;
  password: string;
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {}

  close() {
    this.modalRef.hide();
  }
}
