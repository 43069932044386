/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Company address
 */
export interface CompanyAddressDto {
  /**
   * Address line
   */
  address?: string;
  /**
   * City
   */
  city?: string;
  /**
   * State / Province / Region
   */
  state?: string;
  /**
   * ZIP / Postal code
   */
  zipCode?: string;
  /**
   * Country
   */
  country?: string;
}
