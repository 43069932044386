/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.7+develop-018
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OptionalIdDto } from './optionalIdDto';

/**
 * User device list
 */
export interface DeviceListElementDto {
  /**
   * Device AuthenticationId
   */
  readonly authenticationId?: string;
  /**
   * UUID of the device
   */
  readonly uuid?: string;
  /**
   * Device ID32
   */
  deviceId32?: string;
  /**
   * Device name
   */
  name?: string;
  /**
   * Device name
   */
  resourceContext?: string;
  /**
   * Number of devices reporting anomalies in the site
   */
  readonly anomalies?: number;
  /**
   * Site AuthenticationId
   */
  siteAuthenticationId?: string;
  /**
   * Test Site
   */
  readonly siteName?: string;
  /**
   * Owner AuthenticationId
   */
  readonly ownerAuthenticationId?: string;
  /**
   * Owner email
   */
  readonly ownerUserIdentifier?: string;
  /**
   * Flagged true if new
   */
  readonly newFlag?: boolean;
  /**
   * Device Model Id
   */
  deviceModelId?: string;
  /**
   * Device Model Description
   */
  readonly deviceModelDescription?: string;
  /**
   * Device Model Group
   */
  readonly deviceModelGroup?: string;
  /**
   * Device system type
   */
  readonly system?: string;
  /**
   * Device Optional ids
   */
  readonly optionalIds?: Array<OptionalIdDto>;
  /**
   * TRUE if the device is in privacy safe mode and can be accessed only from locally enabled users
   */
  readonly privacySafeModeEnabled?: boolean;
  /**
   * TRUE if the device is in privacy safe mode and the user has no right to access to it
   */
  readonly privacySafeModeActive?: boolean;
  /**
   * Unlink token
   */
  readonly unlinkToken?: string;
  /**
   * Current device status
   */
  readonly status?: DeviceListElementDto.StatusEnum;
  /**
   * Current device service status
   */
  readonly serviceStatus?: DeviceListElementDto.ServiceStatusEnum;
  /**
   * Current device application status
   */
  readonly applicationStatus?: DeviceListElementDto.ApplicationStatusEnum;
  /**
   * FW version currently installed on the device
   */
  readonly currentFWVersion?: string;
  /**
   * Hardware version of the device
   */
  readonly hwVersion?: string;
}
export namespace DeviceListElementDto {
  export type StatusEnum = 'ready' | 'offline';
  export const StatusEnum = {
    Ready: 'ready' as StatusEnum,
    Offline: 'offline' as StatusEnum
  };
  export type ServiceStatusEnum = 'error' | 'warning' | 'ok';
  export const ServiceStatusEnum = {
    Error: 'error' as ServiceStatusEnum,
    Warning: 'warning' as ServiceStatusEnum,
    Ok: 'ok' as ServiceStatusEnum
  };
  export type ApplicationStatusEnum =
    | 'standby'
    | 'pre-alarm'
    | 'alarm'
    | 'evacuation';
  export const ApplicationStatusEnum = {
    Standby: 'standby' as ApplicationStatusEnum,
    PreAlarm: 'pre-alarm' as ApplicationStatusEnum,
    Alarm: 'alarm' as ApplicationStatusEnum,
    Evacuation: 'evacuation' as ApplicationStatusEnum
  };
}
