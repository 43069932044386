/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Error reporting DTO
 */
export interface ErrorDto {
  /**
   * Error code
   */
  readonly error_code?: string;
  /**
   * Error message
   */
  readonly error_message?: string;
  /**
   * Error ID
   */
  readonly error_id?: string;
}
