/**
 * CCAPI HTTP /servicerest/log
 * CCAPI HTTP /servicerest/log
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProviderData } from './providerData';

export interface LoginDto {
  uuid?: string;
  secret?: string;
  provider?: LoginDto.ProviderEnum;
  providerData?: ProviderData;
  pin?: string;
  token?: string;
  apiKey?: string;
}
export namespace LoginDto {
  export type ProviderEnum = 'NATIVE' | 'FACEBOOK';
  export const ProviderEnum = {
    NATIVE: 'NATIVE' as ProviderEnum,
    FACEBOOK: 'FACEBOOK' as ProviderEnum
  };
}
