/**
 * Authentication - Device Optional ID service
 * Device Optional ID management services
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Optional ID values for device
 */
export interface OptionalIdDto {
  /**
   * ID type
   */
  type: string;
  /**
   * Device ID
   */
  id: string;
}
