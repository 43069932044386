/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Loop status.
 */
export interface LoopStatusBitMaskDto {
  moduleFault?: boolean;
  autoAddressingIsolator?: boolean;
  autoAddressingId?: boolean;
  systemMode?: boolean;
  loopOpen?: boolean;
  earthFault?: boolean;
  shortCircuit?: boolean;
}
