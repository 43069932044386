import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  DirectoriesService,
  SwitchboardDirectoryDataDto
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { isMobile } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { CreateSwitchboardModalComponent } from './create-switchboard-modal/create-switchboard-modal.component';

@Component({
  selector: 'app-switchboard-card',
  templateUrl: './switchboard-card.component.html',
  styleUrls: ['./switchboard-card.component.scss']
})
export class SwitchboardCardComponent implements OnInit {
  @Input() deviceId: string;
  @Input() dirId: number;
  columns: TableColumn[] = [];
  switchBoards: SwitchboardDirectoryDataDto[] = [];
  dataSource = new ClientFiltrableDataSource<SwitchboardDirectoryDataDto>();
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;
  @ViewChild('addrTpl', { static: true }) addrTpl: ElementRef<HTMLElement>;

  get isMobile() {
    return isMobile;
  }

  constructor(
    private ts: TranslateService,
    private dirService: DirectoriesService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('ADDRESSBOOK.DESCRIPTION')
      },
      {
        prop: 'addr',
        name: this.ts.instant('ADDRESSBOOK.ADDRESS'),
        cellTemplate: this.addrTpl
      },
      {
        prop: 'id',
        cellTemplate: this.actionsTpl,
        maxWidth: 150,
        name: this.ts.instant('GLOBAL.ACTIONS')
      }
    ];
    this.getSwitchboard();
  }

  getSwitchboard() {
    this.dirService
      .getSwitchBoards(this.deviceId, this.dirId, this.authService.getToken())
      .subscribe((data: SwitchboardDirectoryDataDto[]) => {
        this.dataSource.setItems(data);
        this.switchBoards = data;
      });
  }

  addSwitchboard() {
    const initialState: Partial<CreateSwitchboardModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.SWITCHBOARD_CREATE'),
      id: this.calculateNextId(),
      dirId: this.dirId
    };

    const modalRef = this.modalService.show(CreateSwitchboardModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: SwitchboardDirectoryDataDto) => {
      this.switchBoards = this.switchBoards.concat([v]);
      this.dirService
        .setSwitchboards(
          this.deviceId,
          this.dirId,
          this.authService.getToken(),
          { switchboards: this.switchBoards }
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.SWITCHBOARD_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getSwitchboard();
        });
    });
  }

  calculateNextId() {
    let newId = 0;
    this.switchBoards.forEach((x: SwitchboardDirectoryDataDto) => {
      if (x.id > newId) {
        newId = x.id;
      }
    });
    return newId + 1;
  }

  edit(row: SwitchboardDirectoryDataDto) {
    const initialState: Partial<CreateSwitchboardModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.SWITCHBOARD_EDIT'),
      data: row
    };

    const modalRef = this.modalService.show(CreateSwitchboardModalComponent, {
      initialState
    });

    modalRef.content!.created.subscribe((v: any) => {
      this.switchBoards.splice(
        this.switchBoards.findIndex((x) => x.id === row.id),
        1,
        v
      );
      this.dirService
        .setSwitchboards(
          this.deviceId,
          this.dirId,
          this.authService.getToken(),
          { switchboards: this.switchBoards }
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.SWITCHBOARD_EDITED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getSwitchboard();
        });
    });
  }

  delete(row: SwitchboardDirectoryDataDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('ADDRESSBOOK.DELETE_SWITCHBOARD'),
      description: this.ts.instant('ADDRESSBOOK.DELETE_SWITCHBOARD_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.dirService
        .deleteSwitchboard(
          this.deviceId,
          this.dirId,
          row.id,
          this.authService.getToken()
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('ADDRESSBOOK.SWITCHBOARD_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.getSwitchboard();
        });
    });
  }
}
