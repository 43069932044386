import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EntranceDirectoryDataDto } from 'src/app/api/mugconf';

@Component({
  selector: 'app-ext-place-modal',
  templateUrl: './ext-place-modal.component.html',
  styleUrls: ['./ext-place-modal.component.scss']
})
export class ExtPlaceModalComponent implements OnInit {
  dataGroup: UntypedFormGroup;
  data: EntranceDirectoryDataDto;
  dirId: number;
  id: number;
  title: string;
  created = new EventEmitter<EntranceDirectoryDataDto>();
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.dataGroup = new UntypedFormGroup({
      dir: new UntypedFormControl(this.dirId),
      id: new UntypedFormControl(this.id),
      name: new UntypedFormControl('', Validators.required),
      addr: new UntypedFormControl()
    });

    if (this.data) {
      this.dataGroup.patchValue(this.data);
    }
  }

  close() {
    this.modalRef.hide();
  }
  save() {
    if (this.dataGroup.valid) {
      this.created.next(this.dataGroup.value);
    }
  }
}
