import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BasicDirectoryDataDto, DirectoriesService } from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { DeviceCompleteDto } from 'src/app/core/models';

@Component({
  selector: 'app-address-book-create',
  templateUrl: './address-book-create.component.html',
  styleUrls: ['./address-book-create.component.scss']
})
export class AddressBookCreateComponent implements OnInit {
  addrBook?: BasicDirectoryDataDto;
  addrBookList: BasicDirectoryDataDto[];
  addressBookControl: UntypedFormGroup;
  abilitationControl = new UntypedFormControl(false);
  id?: number;

  created = new EventEmitter<void>();
  device: DeviceCompleteDto;
  constructor(
    private modalRef: BsModalRef,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ts: TranslateService,
    private dirService: DirectoriesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.addressBookControl = new UntypedFormGroup({
      dir: new UntypedFormControl(this.id),
      descr: new UntypedFormControl(''),
      enedit: this.abilitationControl
    });

    if (this.addrBook) {
      this.addressBookControl.patchValue(this.addrBook);
    }
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    if (this.addrBook) {
      this.addrBookList.splice(
        this.addrBookList.findIndex((x) => x.dir === this.addrBook!.dir),
        1,
        this.addressBookControl.value
      );
    } else {
      this.addrBookList = this.addrBookList.concat([
        this.addressBookControl.value
      ]);
    }
    this.dirService
      .setDirs(this.device.resource.uuid!, this.authService.getToken(), {
        dirs: this.addrBookList
      })
      .subscribe(() => {
        this.created.next();
        this.modalRef.hide();
        this.toastr.success(
          this.addrBook
            ? this.ts.instant('ADDRESSBOOK.ADDRESSBOOK_EDITED')
            : this.ts.instant('ADDRESSBOOK.ADDRESSBOOK_CREATED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }
}
