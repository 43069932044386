/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RegistrationDto {
  password: string;
  loginEmail: string;
  isoCountryCode?: RegistrationDto.IsoCountryCodeEnum;
  isoLanguageCode?: RegistrationDto.IsoLanguageCodeEnum;
  address?: string;
  businessName?: string;
  cellnum?: string;
  city?: string;
  company?: string;
  country?: string;
  dateOfBirth?: string;
  distributor?: string;
  firstName?: string;
  lastName?: string;
  language?: string;
  profilePictureBase64?: string;
  region?: string;
  state?: string;
  streetNumber?: string;
  vatNumber?: string;
  zipCode?: string;
  phoneNumber?: string;
  userType?: RegistrationDto.UserTypeEnum;
  crmUserId?: string;
  receiveNewsletter?: boolean;
  preferredContactMethod?: RegistrationDto.PreferredContactMethodEnum;
  navigationLanguage?: string;
}
export namespace RegistrationDto {
  export type IsoCountryCodeEnum =
    | 'AF'
    | 'AX'
    | 'AL'
    | 'DZ'
    | 'AS'
    | 'AD'
    | 'AO'
    | 'AI'
    | 'AQ'
    | 'AG'
    | 'AR'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BS'
    | 'BH'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BM'
    | 'BT'
    | 'BO'
    | 'BQ'
    | 'BA'
    | 'BW'
    | 'BV'
    | 'BR'
    | 'IO'
    | 'BN'
    | 'BG'
    | 'BF'
    | 'BI'
    | 'KH'
    | 'CM'
    | 'CA'
    | 'CV'
    | 'KY'
    | 'CF'
    | 'TD'
    | 'CL'
    | 'CN'
    | 'CX'
    | 'CC'
    | 'CO'
    | 'KM'
    | 'CG'
    | 'CD'
    | 'CK'
    | 'CR'
    | 'CI'
    | 'HR'
    | 'CU'
    | 'CW'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DJ'
    | 'DM'
    | 'DO'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'GQ'
    | 'ER'
    | 'EE'
    | 'ET'
    | 'FK'
    | 'FO'
    | 'FJ'
    | 'FI'
    | 'FR'
    | 'GF'
    | 'PF'
    | 'TF'
    | 'GA'
    | 'GM'
    | 'GE'
    | 'DE'
    | 'GH'
    | 'GI'
    | 'GR'
    | 'GL'
    | 'GD'
    | 'GP'
    | 'GU'
    | 'GT'
    | 'GG'
    | 'GN'
    | 'GW'
    | 'GY'
    | 'HT'
    | 'HM'
    | 'VA'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IM'
    | 'IL'
    | 'IT'
    | 'JM'
    | 'JP'
    | 'JE'
    | 'JO'
    | 'KZ'
    | 'KE'
    | 'KI'
    | 'KP'
    | 'KR'
    | 'KW'
    | 'KG'
    | 'LA'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MO'
    | 'MK'
    | 'MG'
    | 'MW'
    | 'MY'
    | 'MV'
    | 'ML'
    | 'MT'
    | 'MH'
    | 'MQ'
    | 'MR'
    | 'MU'
    | 'YT'
    | 'MX'
    | 'FM'
    | 'MD'
    | 'MC'
    | 'MN'
    | 'ME'
    | 'MS'
    | 'MA'
    | 'MZ'
    | 'MM'
    | 'NA'
    | 'NR'
    | 'NP'
    | 'NL'
    | 'NC'
    | 'NZ'
    | 'NI'
    | 'NE'
    | 'NG'
    | 'NU'
    | 'NF'
    | 'MP'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PW'
    | 'PS'
    | 'PA'
    | 'PG'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PN'
    | 'PL'
    | 'PT'
    | 'PR'
    | 'QA'
    | 'RE'
    | 'RO'
    | 'RU'
    | 'RW'
    | 'BL'
    | 'SH'
    | 'KN'
    | 'LC'
    | 'MF'
    | 'PM'
    | 'VC'
    | 'WS'
    | 'SM'
    | 'ST'
    | 'SA'
    | 'SN'
    | 'RS'
    | 'SC'
    | 'SL'
    | 'SG'
    | 'SX'
    | 'SK'
    | 'SI'
    | 'SB'
    | 'SO'
    | 'ZA'
    | 'GS'
    | 'SS'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SR'
    | 'SJ'
    | 'SZ'
    | 'SE'
    | 'CH'
    | 'SY'
    | 'TW'
    | 'TJ'
    | 'TZ'
    | 'TH'
    | 'TL'
    | 'TG'
    | 'TK'
    | 'TO'
    | 'TT'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'TC'
    | 'TV'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UM'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'VG'
    | 'VI'
    | 'WF'
    | 'EH'
    | 'YE'
    | 'ZM'
    | 'ZW';
  export const IsoCountryCodeEnum = {
    AF: 'AF' as IsoCountryCodeEnum,
    AX: 'AX' as IsoCountryCodeEnum,
    AL: 'AL' as IsoCountryCodeEnum,
    DZ: 'DZ' as IsoCountryCodeEnum,
    AS: 'AS' as IsoCountryCodeEnum,
    AD: 'AD' as IsoCountryCodeEnum,
    AO: 'AO' as IsoCountryCodeEnum,
    AI: 'AI' as IsoCountryCodeEnum,
    AQ: 'AQ' as IsoCountryCodeEnum,
    AG: 'AG' as IsoCountryCodeEnum,
    AR: 'AR' as IsoCountryCodeEnum,
    AM: 'AM' as IsoCountryCodeEnum,
    AW: 'AW' as IsoCountryCodeEnum,
    AU: 'AU' as IsoCountryCodeEnum,
    AT: 'AT' as IsoCountryCodeEnum,
    AZ: 'AZ' as IsoCountryCodeEnum,
    BS: 'BS' as IsoCountryCodeEnum,
    BH: 'BH' as IsoCountryCodeEnum,
    BD: 'BD' as IsoCountryCodeEnum,
    BB: 'BB' as IsoCountryCodeEnum,
    BY: 'BY' as IsoCountryCodeEnum,
    BE: 'BE' as IsoCountryCodeEnum,
    BZ: 'BZ' as IsoCountryCodeEnum,
    BJ: 'BJ' as IsoCountryCodeEnum,
    BM: 'BM' as IsoCountryCodeEnum,
    BT: 'BT' as IsoCountryCodeEnum,
    BO: 'BO' as IsoCountryCodeEnum,
    BQ: 'BQ' as IsoCountryCodeEnum,
    BA: 'BA' as IsoCountryCodeEnum,
    BW: 'BW' as IsoCountryCodeEnum,
    BV: 'BV' as IsoCountryCodeEnum,
    BR: 'BR' as IsoCountryCodeEnum,
    IO: 'IO' as IsoCountryCodeEnum,
    BN: 'BN' as IsoCountryCodeEnum,
    BG: 'BG' as IsoCountryCodeEnum,
    BF: 'BF' as IsoCountryCodeEnum,
    BI: 'BI' as IsoCountryCodeEnum,
    KH: 'KH' as IsoCountryCodeEnum,
    CM: 'CM' as IsoCountryCodeEnum,
    CA: 'CA' as IsoCountryCodeEnum,
    CV: 'CV' as IsoCountryCodeEnum,
    KY: 'KY' as IsoCountryCodeEnum,
    CF: 'CF' as IsoCountryCodeEnum,
    TD: 'TD' as IsoCountryCodeEnum,
    CL: 'CL' as IsoCountryCodeEnum,
    CN: 'CN' as IsoCountryCodeEnum,
    CX: 'CX' as IsoCountryCodeEnum,
    CC: 'CC' as IsoCountryCodeEnum,
    CO: 'CO' as IsoCountryCodeEnum,
    KM: 'KM' as IsoCountryCodeEnum,
    CG: 'CG' as IsoCountryCodeEnum,
    CD: 'CD' as IsoCountryCodeEnum,
    CK: 'CK' as IsoCountryCodeEnum,
    CR: 'CR' as IsoCountryCodeEnum,
    CI: 'CI' as IsoCountryCodeEnum,
    HR: 'HR' as IsoCountryCodeEnum,
    CU: 'CU' as IsoCountryCodeEnum,
    CW: 'CW' as IsoCountryCodeEnum,
    CY: 'CY' as IsoCountryCodeEnum,
    CZ: 'CZ' as IsoCountryCodeEnum,
    DK: 'DK' as IsoCountryCodeEnum,
    DJ: 'DJ' as IsoCountryCodeEnum,
    DM: 'DM' as IsoCountryCodeEnum,
    DO: 'DO' as IsoCountryCodeEnum,
    EC: 'EC' as IsoCountryCodeEnum,
    EG: 'EG' as IsoCountryCodeEnum,
    SV: 'SV' as IsoCountryCodeEnum,
    GQ: 'GQ' as IsoCountryCodeEnum,
    ER: 'ER' as IsoCountryCodeEnum,
    EE: 'EE' as IsoCountryCodeEnum,
    ET: 'ET' as IsoCountryCodeEnum,
    FK: 'FK' as IsoCountryCodeEnum,
    FO: 'FO' as IsoCountryCodeEnum,
    FJ: 'FJ' as IsoCountryCodeEnum,
    FI: 'FI' as IsoCountryCodeEnum,
    FR: 'FR' as IsoCountryCodeEnum,
    GF: 'GF' as IsoCountryCodeEnum,
    PF: 'PF' as IsoCountryCodeEnum,
    TF: 'TF' as IsoCountryCodeEnum,
    GA: 'GA' as IsoCountryCodeEnum,
    GM: 'GM' as IsoCountryCodeEnum,
    GE: 'GE' as IsoCountryCodeEnum,
    DE: 'DE' as IsoCountryCodeEnum,
    GH: 'GH' as IsoCountryCodeEnum,
    GI: 'GI' as IsoCountryCodeEnum,
    GR: 'GR' as IsoCountryCodeEnum,
    GL: 'GL' as IsoCountryCodeEnum,
    GD: 'GD' as IsoCountryCodeEnum,
    GP: 'GP' as IsoCountryCodeEnum,
    GU: 'GU' as IsoCountryCodeEnum,
    GT: 'GT' as IsoCountryCodeEnum,
    GG: 'GG' as IsoCountryCodeEnum,
    GN: 'GN' as IsoCountryCodeEnum,
    GW: 'GW' as IsoCountryCodeEnum,
    GY: 'GY' as IsoCountryCodeEnum,
    HT: 'HT' as IsoCountryCodeEnum,
    HM: 'HM' as IsoCountryCodeEnum,
    VA: 'VA' as IsoCountryCodeEnum,
    HN: 'HN' as IsoCountryCodeEnum,
    HK: 'HK' as IsoCountryCodeEnum,
    HU: 'HU' as IsoCountryCodeEnum,
    IS: 'IS' as IsoCountryCodeEnum,
    IN: 'IN' as IsoCountryCodeEnum,
    ID: 'ID' as IsoCountryCodeEnum,
    IR: 'IR' as IsoCountryCodeEnum,
    IQ: 'IQ' as IsoCountryCodeEnum,
    IE: 'IE' as IsoCountryCodeEnum,
    IM: 'IM' as IsoCountryCodeEnum,
    IL: 'IL' as IsoCountryCodeEnum,
    IT: 'IT' as IsoCountryCodeEnum,
    JM: 'JM' as IsoCountryCodeEnum,
    JP: 'JP' as IsoCountryCodeEnum,
    JE: 'JE' as IsoCountryCodeEnum,
    JO: 'JO' as IsoCountryCodeEnum,
    KZ: 'KZ' as IsoCountryCodeEnum,
    KE: 'KE' as IsoCountryCodeEnum,
    KI: 'KI' as IsoCountryCodeEnum,
    KP: 'KP' as IsoCountryCodeEnum,
    KR: 'KR' as IsoCountryCodeEnum,
    KW: 'KW' as IsoCountryCodeEnum,
    KG: 'KG' as IsoCountryCodeEnum,
    LA: 'LA' as IsoCountryCodeEnum,
    LV: 'LV' as IsoCountryCodeEnum,
    LB: 'LB' as IsoCountryCodeEnum,
    LS: 'LS' as IsoCountryCodeEnum,
    LR: 'LR' as IsoCountryCodeEnum,
    LY: 'LY' as IsoCountryCodeEnum,
    LI: 'LI' as IsoCountryCodeEnum,
    LT: 'LT' as IsoCountryCodeEnum,
    LU: 'LU' as IsoCountryCodeEnum,
    MO: 'MO' as IsoCountryCodeEnum,
    MK: 'MK' as IsoCountryCodeEnum,
    MG: 'MG' as IsoCountryCodeEnum,
    MW: 'MW' as IsoCountryCodeEnum,
    MY: 'MY' as IsoCountryCodeEnum,
    MV: 'MV' as IsoCountryCodeEnum,
    ML: 'ML' as IsoCountryCodeEnum,
    MT: 'MT' as IsoCountryCodeEnum,
    MH: 'MH' as IsoCountryCodeEnum,
    MQ: 'MQ' as IsoCountryCodeEnum,
    MR: 'MR' as IsoCountryCodeEnum,
    MU: 'MU' as IsoCountryCodeEnum,
    YT: 'YT' as IsoCountryCodeEnum,
    MX: 'MX' as IsoCountryCodeEnum,
    FM: 'FM' as IsoCountryCodeEnum,
    MD: 'MD' as IsoCountryCodeEnum,
    MC: 'MC' as IsoCountryCodeEnum,
    MN: 'MN' as IsoCountryCodeEnum,
    ME: 'ME' as IsoCountryCodeEnum,
    MS: 'MS' as IsoCountryCodeEnum,
    MA: 'MA' as IsoCountryCodeEnum,
    MZ: 'MZ' as IsoCountryCodeEnum,
    MM: 'MM' as IsoCountryCodeEnum,
    NA: 'NA' as IsoCountryCodeEnum,
    NR: 'NR' as IsoCountryCodeEnum,
    NP: 'NP' as IsoCountryCodeEnum,
    NL: 'NL' as IsoCountryCodeEnum,
    NC: 'NC' as IsoCountryCodeEnum,
    NZ: 'NZ' as IsoCountryCodeEnum,
    NI: 'NI' as IsoCountryCodeEnum,
    NE: 'NE' as IsoCountryCodeEnum,
    NG: 'NG' as IsoCountryCodeEnum,
    NU: 'NU' as IsoCountryCodeEnum,
    NF: 'NF' as IsoCountryCodeEnum,
    MP: 'MP' as IsoCountryCodeEnum,
    NO: 'NO' as IsoCountryCodeEnum,
    OM: 'OM' as IsoCountryCodeEnum,
    PK: 'PK' as IsoCountryCodeEnum,
    PW: 'PW' as IsoCountryCodeEnum,
    PS: 'PS' as IsoCountryCodeEnum,
    PA: 'PA' as IsoCountryCodeEnum,
    PG: 'PG' as IsoCountryCodeEnum,
    PY: 'PY' as IsoCountryCodeEnum,
    PE: 'PE' as IsoCountryCodeEnum,
    PH: 'PH' as IsoCountryCodeEnum,
    PN: 'PN' as IsoCountryCodeEnum,
    PL: 'PL' as IsoCountryCodeEnum,
    PT: 'PT' as IsoCountryCodeEnum,
    PR: 'PR' as IsoCountryCodeEnum,
    QA: 'QA' as IsoCountryCodeEnum,
    RE: 'RE' as IsoCountryCodeEnum,
    RO: 'RO' as IsoCountryCodeEnum,
    RU: 'RU' as IsoCountryCodeEnum,
    RW: 'RW' as IsoCountryCodeEnum,
    BL: 'BL' as IsoCountryCodeEnum,
    SH: 'SH' as IsoCountryCodeEnum,
    KN: 'KN' as IsoCountryCodeEnum,
    LC: 'LC' as IsoCountryCodeEnum,
    MF: 'MF' as IsoCountryCodeEnum,
    PM: 'PM' as IsoCountryCodeEnum,
    VC: 'VC' as IsoCountryCodeEnum,
    WS: 'WS' as IsoCountryCodeEnum,
    SM: 'SM' as IsoCountryCodeEnum,
    ST: 'ST' as IsoCountryCodeEnum,
    SA: 'SA' as IsoCountryCodeEnum,
    SN: 'SN' as IsoCountryCodeEnum,
    RS: 'RS' as IsoCountryCodeEnum,
    SC: 'SC' as IsoCountryCodeEnum,
    SL: 'SL' as IsoCountryCodeEnum,
    SG: 'SG' as IsoCountryCodeEnum,
    SX: 'SX' as IsoCountryCodeEnum,
    SK: 'SK' as IsoCountryCodeEnum,
    SI: 'SI' as IsoCountryCodeEnum,
    SB: 'SB' as IsoCountryCodeEnum,
    SO: 'SO' as IsoCountryCodeEnum,
    ZA: 'ZA' as IsoCountryCodeEnum,
    GS: 'GS' as IsoCountryCodeEnum,
    SS: 'SS' as IsoCountryCodeEnum,
    ES: 'ES' as IsoCountryCodeEnum,
    LK: 'LK' as IsoCountryCodeEnum,
    SD: 'SD' as IsoCountryCodeEnum,
    SR: 'SR' as IsoCountryCodeEnum,
    SJ: 'SJ' as IsoCountryCodeEnum,
    SZ: 'SZ' as IsoCountryCodeEnum,
    SE: 'SE' as IsoCountryCodeEnum,
    CH: 'CH' as IsoCountryCodeEnum,
    SY: 'SY' as IsoCountryCodeEnum,
    TW: 'TW' as IsoCountryCodeEnum,
    TJ: 'TJ' as IsoCountryCodeEnum,
    TZ: 'TZ' as IsoCountryCodeEnum,
    TH: 'TH' as IsoCountryCodeEnum,
    TL: 'TL' as IsoCountryCodeEnum,
    TG: 'TG' as IsoCountryCodeEnum,
    TK: 'TK' as IsoCountryCodeEnum,
    TO: 'TO' as IsoCountryCodeEnum,
    TT: 'TT' as IsoCountryCodeEnum,
    TN: 'TN' as IsoCountryCodeEnum,
    TR: 'TR' as IsoCountryCodeEnum,
    TM: 'TM' as IsoCountryCodeEnum,
    TC: 'TC' as IsoCountryCodeEnum,
    TV: 'TV' as IsoCountryCodeEnum,
    UG: 'UG' as IsoCountryCodeEnum,
    UA: 'UA' as IsoCountryCodeEnum,
    AE: 'AE' as IsoCountryCodeEnum,
    GB: 'GB' as IsoCountryCodeEnum,
    US: 'US' as IsoCountryCodeEnum,
    UM: 'UM' as IsoCountryCodeEnum,
    UY: 'UY' as IsoCountryCodeEnum,
    UZ: 'UZ' as IsoCountryCodeEnum,
    VU: 'VU' as IsoCountryCodeEnum,
    VE: 'VE' as IsoCountryCodeEnum,
    VN: 'VN' as IsoCountryCodeEnum,
    VG: 'VG' as IsoCountryCodeEnum,
    VI: 'VI' as IsoCountryCodeEnum,
    WF: 'WF' as IsoCountryCodeEnum,
    EH: 'EH' as IsoCountryCodeEnum,
    YE: 'YE' as IsoCountryCodeEnum,
    ZM: 'ZM' as IsoCountryCodeEnum,
    ZW: 'ZW' as IsoCountryCodeEnum
  };
  export type IsoLanguageCodeEnum =
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  export const IsoLanguageCodeEnum = {
    Aa: 'aa' as IsoLanguageCodeEnum,
    Ab: 'ab' as IsoLanguageCodeEnum,
    Ae: 'ae' as IsoLanguageCodeEnum,
    Af: 'af' as IsoLanguageCodeEnum,
    Ak: 'ak' as IsoLanguageCodeEnum,
    Am: 'am' as IsoLanguageCodeEnum,
    An: 'an' as IsoLanguageCodeEnum,
    Ar: 'ar' as IsoLanguageCodeEnum,
    As: 'as' as IsoLanguageCodeEnum,
    Av: 'av' as IsoLanguageCodeEnum,
    Ay: 'ay' as IsoLanguageCodeEnum,
    Az: 'az' as IsoLanguageCodeEnum,
    Ba: 'ba' as IsoLanguageCodeEnum,
    Be: 'be' as IsoLanguageCodeEnum,
    Bg: 'bg' as IsoLanguageCodeEnum,
    Bh: 'bh' as IsoLanguageCodeEnum,
    Bi: 'bi' as IsoLanguageCodeEnum,
    Bm: 'bm' as IsoLanguageCodeEnum,
    Bn: 'bn' as IsoLanguageCodeEnum,
    Bo: 'bo' as IsoLanguageCodeEnum,
    Br: 'br' as IsoLanguageCodeEnum,
    Bs: 'bs' as IsoLanguageCodeEnum,
    Ca: 'ca' as IsoLanguageCodeEnum,
    Ce: 'ce' as IsoLanguageCodeEnum,
    Ch: 'ch' as IsoLanguageCodeEnum,
    Co: 'co' as IsoLanguageCodeEnum,
    Cr: 'cr' as IsoLanguageCodeEnum,
    Cs: 'cs' as IsoLanguageCodeEnum,
    Cu: 'cu' as IsoLanguageCodeEnum,
    Cv: 'cv' as IsoLanguageCodeEnum,
    Cy: 'cy' as IsoLanguageCodeEnum,
    Da: 'da' as IsoLanguageCodeEnum,
    De: 'de' as IsoLanguageCodeEnum,
    Dv: 'dv' as IsoLanguageCodeEnum,
    Dz: 'dz' as IsoLanguageCodeEnum,
    Ee: 'ee' as IsoLanguageCodeEnum,
    El: 'el' as IsoLanguageCodeEnum,
    En: 'en' as IsoLanguageCodeEnum,
    Eo: 'eo' as IsoLanguageCodeEnum,
    Es: 'es' as IsoLanguageCodeEnum,
    Et: 'et' as IsoLanguageCodeEnum,
    Eu: 'eu' as IsoLanguageCodeEnum,
    Fa: 'fa' as IsoLanguageCodeEnum,
    Ff: 'ff' as IsoLanguageCodeEnum,
    Fi: 'fi' as IsoLanguageCodeEnum,
    Fj: 'fj' as IsoLanguageCodeEnum,
    Fo: 'fo' as IsoLanguageCodeEnum,
    Fr: 'fr' as IsoLanguageCodeEnum,
    Fy: 'fy' as IsoLanguageCodeEnum,
    Ga: 'ga' as IsoLanguageCodeEnum,
    Gd: 'gd' as IsoLanguageCodeEnum,
    Gl: 'gl' as IsoLanguageCodeEnum,
    Gn: 'gn' as IsoLanguageCodeEnum,
    Gu: 'gu' as IsoLanguageCodeEnum,
    Gv: 'gv' as IsoLanguageCodeEnum,
    Ha: 'ha' as IsoLanguageCodeEnum,
    He: 'he' as IsoLanguageCodeEnum,
    Hi: 'hi' as IsoLanguageCodeEnum,
    Ho: 'ho' as IsoLanguageCodeEnum,
    Hr: 'hr' as IsoLanguageCodeEnum,
    Ht: 'ht' as IsoLanguageCodeEnum,
    Hu: 'hu' as IsoLanguageCodeEnum,
    Hy: 'hy' as IsoLanguageCodeEnum,
    Hz: 'hz' as IsoLanguageCodeEnum,
    Ia: 'ia' as IsoLanguageCodeEnum,
    Id: 'id' as IsoLanguageCodeEnum,
    Ie: 'ie' as IsoLanguageCodeEnum,
    Ig: 'ig' as IsoLanguageCodeEnum,
    Ii: 'ii' as IsoLanguageCodeEnum,
    Ik: 'ik' as IsoLanguageCodeEnum,
    Io: 'io' as IsoLanguageCodeEnum,
    Is: 'is' as IsoLanguageCodeEnum,
    It: 'it' as IsoLanguageCodeEnum,
    Iu: 'iu' as IsoLanguageCodeEnum,
    Ja: 'ja' as IsoLanguageCodeEnum,
    Jv: 'jv' as IsoLanguageCodeEnum,
    Ka: 'ka' as IsoLanguageCodeEnum,
    Kg: 'kg' as IsoLanguageCodeEnum,
    Ki: 'ki' as IsoLanguageCodeEnum,
    Kj: 'kj' as IsoLanguageCodeEnum,
    Kk: 'kk' as IsoLanguageCodeEnum,
    Kl: 'kl' as IsoLanguageCodeEnum,
    Km: 'km' as IsoLanguageCodeEnum,
    Kn: 'kn' as IsoLanguageCodeEnum,
    Ko: 'ko' as IsoLanguageCodeEnum,
    Kr: 'kr' as IsoLanguageCodeEnum,
    Ks: 'ks' as IsoLanguageCodeEnum,
    Ku: 'ku' as IsoLanguageCodeEnum,
    Kv: 'kv' as IsoLanguageCodeEnum,
    Kw: 'kw' as IsoLanguageCodeEnum,
    Ky: 'ky' as IsoLanguageCodeEnum,
    La: 'la' as IsoLanguageCodeEnum,
    Lb: 'lb' as IsoLanguageCodeEnum,
    Lg: 'lg' as IsoLanguageCodeEnum,
    Li: 'li' as IsoLanguageCodeEnum,
    Ln: 'ln' as IsoLanguageCodeEnum,
    Lo: 'lo' as IsoLanguageCodeEnum,
    Lt: 'lt' as IsoLanguageCodeEnum,
    Lu: 'lu' as IsoLanguageCodeEnum,
    Lv: 'lv' as IsoLanguageCodeEnum,
    Mg: 'mg' as IsoLanguageCodeEnum,
    Mh: 'mh' as IsoLanguageCodeEnum,
    Mi: 'mi' as IsoLanguageCodeEnum,
    Mk: 'mk' as IsoLanguageCodeEnum,
    Ml: 'ml' as IsoLanguageCodeEnum,
    Mn: 'mn' as IsoLanguageCodeEnum,
    Mr: 'mr' as IsoLanguageCodeEnum,
    Ms: 'ms' as IsoLanguageCodeEnum,
    Mt: 'mt' as IsoLanguageCodeEnum,
    My: 'my' as IsoLanguageCodeEnum,
    Na: 'na' as IsoLanguageCodeEnum,
    Nb: 'nb' as IsoLanguageCodeEnum,
    Nd: 'nd' as IsoLanguageCodeEnum,
    Ne: 'ne' as IsoLanguageCodeEnum,
    Ng: 'ng' as IsoLanguageCodeEnum,
    Nl: 'nl' as IsoLanguageCodeEnum,
    Nn: 'nn' as IsoLanguageCodeEnum,
    No: 'no' as IsoLanguageCodeEnum,
    Nr: 'nr' as IsoLanguageCodeEnum,
    Nv: 'nv' as IsoLanguageCodeEnum,
    Ny: 'ny' as IsoLanguageCodeEnum,
    Oc: 'oc' as IsoLanguageCodeEnum,
    Oj: 'oj' as IsoLanguageCodeEnum,
    Om: 'om' as IsoLanguageCodeEnum,
    Or: 'or' as IsoLanguageCodeEnum,
    Os: 'os' as IsoLanguageCodeEnum,
    Pa: 'pa' as IsoLanguageCodeEnum,
    Pi: 'pi' as IsoLanguageCodeEnum,
    Pl: 'pl' as IsoLanguageCodeEnum,
    Ps: 'ps' as IsoLanguageCodeEnum,
    Pt: 'pt' as IsoLanguageCodeEnum,
    Qu: 'qu' as IsoLanguageCodeEnum,
    Rm: 'rm' as IsoLanguageCodeEnum,
    Rn: 'rn' as IsoLanguageCodeEnum,
    Ro: 'ro' as IsoLanguageCodeEnum,
    Ru: 'ru' as IsoLanguageCodeEnum,
    Rw: 'rw' as IsoLanguageCodeEnum,
    Sa: 'sa' as IsoLanguageCodeEnum,
    Sc: 'sc' as IsoLanguageCodeEnum,
    Sd: 'sd' as IsoLanguageCodeEnum,
    Se: 'se' as IsoLanguageCodeEnum,
    Sg: 'sg' as IsoLanguageCodeEnum,
    Si: 'si' as IsoLanguageCodeEnum,
    Sk: 'sk' as IsoLanguageCodeEnum,
    Sl: 'sl' as IsoLanguageCodeEnum,
    Sm: 'sm' as IsoLanguageCodeEnum,
    Sn: 'sn' as IsoLanguageCodeEnum,
    So: 'so' as IsoLanguageCodeEnum,
    Sq: 'sq' as IsoLanguageCodeEnum,
    Sr: 'sr' as IsoLanguageCodeEnum,
    Ss: 'ss' as IsoLanguageCodeEnum,
    St: 'st' as IsoLanguageCodeEnum,
    Su: 'su' as IsoLanguageCodeEnum,
    Sv: 'sv' as IsoLanguageCodeEnum,
    Sw: 'sw' as IsoLanguageCodeEnum,
    Ta: 'ta' as IsoLanguageCodeEnum,
    Te: 'te' as IsoLanguageCodeEnum,
    Tg: 'tg' as IsoLanguageCodeEnum,
    Th: 'th' as IsoLanguageCodeEnum,
    Ti: 'ti' as IsoLanguageCodeEnum,
    Tk: 'tk' as IsoLanguageCodeEnum,
    Tl: 'tl' as IsoLanguageCodeEnum,
    Tn: 'tn' as IsoLanguageCodeEnum,
    To: 'to' as IsoLanguageCodeEnum,
    Tr: 'tr' as IsoLanguageCodeEnum,
    Ts: 'ts' as IsoLanguageCodeEnum,
    Tt: 'tt' as IsoLanguageCodeEnum,
    Tw: 'tw' as IsoLanguageCodeEnum,
    Ty: 'ty' as IsoLanguageCodeEnum,
    Ug: 'ug' as IsoLanguageCodeEnum,
    Uk: 'uk' as IsoLanguageCodeEnum,
    Ur: 'ur' as IsoLanguageCodeEnum,
    Uz: 'uz' as IsoLanguageCodeEnum,
    Ve: 've' as IsoLanguageCodeEnum,
    Vi: 'vi' as IsoLanguageCodeEnum,
    Vo: 'vo' as IsoLanguageCodeEnum,
    Wa: 'wa' as IsoLanguageCodeEnum,
    Wo: 'wo' as IsoLanguageCodeEnum,
    Xh: 'xh' as IsoLanguageCodeEnum,
    Yi: 'yi' as IsoLanguageCodeEnum,
    Yo: 'yo' as IsoLanguageCodeEnum,
    Za: 'za' as IsoLanguageCodeEnum,
    Zh: 'zh' as IsoLanguageCodeEnum,
    Zu: 'zu' as IsoLanguageCodeEnum
  };
  export type UserTypeEnum =
    | 'INSTALLER'
    | 'DISTRIBUTOR'
    | 'DESIGNER'
    | 'BUILDING_MANAGER'
    | 'EXCELLENCE_PARTNER'
    | 'END_USER'
    | 'ARCHITECT'
    | 'SYSTEM_INTEGRATOR';
  export const UserTypeEnum = {
    INSTALLER: 'INSTALLER' as UserTypeEnum,
    DISTRIBUTOR: 'DISTRIBUTOR' as UserTypeEnum,
    DESIGNER: 'DESIGNER' as UserTypeEnum,
    BUILDINGMANAGER: 'BUILDING_MANAGER' as UserTypeEnum,
    EXCELLENCEPARTNER: 'EXCELLENCE_PARTNER' as UserTypeEnum,
    ENDUSER: 'END_USER' as UserTypeEnum,
    ARCHITECT: 'ARCHITECT' as UserTypeEnum,
    SYSTEMINTEGRATOR: 'SYSTEM_INTEGRATOR' as UserTypeEnum
  };
  export type PreferredContactMethodEnum = 'EMAIL';
  export const PreferredContactMethodEnum = {
    EMAIL: 'EMAIL' as PreferredContactMethodEnum
  };
}
