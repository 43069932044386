<a id="scanner-btn" href="javascript:void(0)" (click)="openScanner()">
  <img
    id="code-btn"
    [src]="
      isBarcode
        ? 'assets/images/device-barcode.svg'
        : 'assets/images/device-code.svg'
    "
    [alt]="'DEVICE.GET_ID32_CODE' | translate"
  />

  <div *ngIf="!checkMobile()" class="popup">
    <span>{{
      isBarcode
        ? ('DEVICE.SCAN_NOT_AVAILABLE_DESC_BAR' | translate)
        : ('DEVICE.SCAN_NOT_AVAILABLE_DESC' | translate)
    }}</span>
  </div>
</a>
<zxing-scanner
  *ngIf="showScanner"
  class="scanner"
  [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
  (scanSuccess)="getCode($event)"
></zxing-scanner>
