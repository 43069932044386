/**
 * CCAPI HTTP /servicerest/otp
 * CCAPI HTTP /servicerest/otp
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProviderData {
  id?: string;
  password?: string;
  token?: string;
}
