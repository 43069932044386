import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorsAlreadyManaged = [
    'COMMON:CORE:FORBIDDEN',
    'COMMON:INTERNAL:SERVICE_UNAVAILABLE'
  ];
  constructor(private toastr: ToastrService, private ts: TranslateService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);

        if (this.hasToBeReported(error)) {
          console.error(error);
          this.toastr.error(
            this.ts.instant('GLOBAL.HTTP_ERROR'),
            this.ts.instant('GLOBAL.ERROR')
          );
        }

        return throwError(error);
      })
    );
  }

  hasToBeReported(error: HttpErrorResponse): boolean {
    return !this.isManaged(error);
  }

  isManaged(error: HttpErrorResponse): boolean {
    return this.errorsAlreadyManaged.includes(
      (error?.error?.errorCode || error?.error?.error_id) ?? []
    );
  }
}
