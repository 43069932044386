import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  ApartmentDto,
  ApartmentsAndUsersService,
  AppSetupDto,
  StatusAndConfigurationService,
  UserDto
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { MobileService } from 'src/app/core/mobile.service';
import {
  ApartmentCompleteDto,
  DeviceCompleteDto,
  UDTypes,
  UsersData
} from 'src/app/core/models';
import { checkFormValidity, isSbc } from 'src/app/core/utils';
import { udTypesLabels, UserRole } from '../../utils/users';
import { UdeviceDetailResolverService } from './udevice-detail-resolver.service';

type UserSaveDto = Omit<
  UserDto,
  'apt' | 'vipertoken' | 'actcode' | 'cloudsync'
>;

@Component({
  selector: 'app-user-device-detail',
  templateUrl: './user-device-detail.component.html',
  styleUrls: ['./user-device-detail.component.scss']
})
export class UserDeviceDetailComponent implements OnInit {
  isEditing = false;
  isLoading = false;
  isLoadingAC = false;
  isLoadingMail = false;
  cloudactEnabeld = false;

  oldDevice: UserDto;
  userDevice: UserDto;
  backupLine: UserDto | undefined;
  deviceId: string;
  aptId: string;
  apt: ApartmentCompleteDto;
  device: DeviceCompleteDto;

  isSbc = isSbc;

  deviceTypes = Object.values(UserDto.DevtypeEnum);
  typesToUse: UDTypes[] = [];

  udFormGroup: UntypedFormGroup;
  typeControl = new UntypedFormControl('');
  descrControl = new UntypedFormControl('');
  enableControl = new UntypedFormControl(false);
  emailControl = new UntypedFormControl(
    '',
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
  );
  emailSentControl = new UntypedFormControl(false);
  phoneControl = new UntypedFormControl(
    '',
    Validators.pattern('[-0-9a-zA-Z.+_@:*]*')
  );
  phoneBackupControl = new UntypedFormControl(
    '',
    Validators.pattern('[-0-9a-zA-Z.+_@:*]*')
  );
  syncControl = new UntypedFormControl(false);
  backupControl = new UntypedFormControl(false);
  extabControl = new UntypedFormControl(false);
  extabnameControl = new UntypedFormControl('');
  aptType: number;

  remainingLines = 0;
  phoneLineAvailable = 0;
  devices: UserDto[];
  userRole: UserRole;

  _pageName: string;
  get pageName(): string {
    return this._pageName;
  }

  set pageName(name: string) {
    this._pageName = name;
  }

  constructor(
    private route: ActivatedRoute,
    private aptUserService: ApartmentsAndUsersService,
    private authService: AuthService,
    private mobileService: MobileService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private statusService: StatusAndConfigurationService,
    private uDeviceService: UdeviceDetailResolverService
  ) {}

  ngOnInit(): void {
    this.userRole = this.route.snapshot.data.userRole;
    this.apt = this.route.snapshot.data.apt;
    this.aptType = this.route.snapshot.data.apt.lictype;
    this.deviceId = this.route.snapshot.params.deviceId;
    this.device = this.route.snapshot.data.device;
    this.aptId = this.route.snapshot.params.aptId;

    // Cloudact enabling management
    this.statusService
      .getAppSetup(this.deviceId, this.authService.getToken())
      .subscribe((r: AppSetupDto[]) => {
        this.cloudactEnabeld = r[0].cloudacten || false;
      });

    // User device type management
    this.deviceTypes.forEach((element, index) => {
      if (element !== 'NONE') {
        if (this.aptType === 3) {
          if (element !== 'APP') {
            this.typesToUse.push({
              element,
              index,
              label: this.ts.instant(udTypesLabels[element])
            });
          }
        } else {
          this.typesToUse.push({
            element,
            index,
            label: this.ts.instant(udTypesLabels[element])
          });
        }
      }
    });
    if (
      [
        'BUILDINGMANAGER-COLLABORATOR',
        'BUILDINGMANAGER',
        'MAINTAINER'
      ].includes(this.userRole) ||
      this.isSbc(this.device.resource.deviceModelId)
    ) {
      this.typesToUse.splice(
        this.typesToUse.findIndex((x) => x.element === 'INTERNAL'),
        1
      );
    }

    this.udFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      backline: new UntypedFormControl(0),
      cloudsync: this.syncControl,
      cmdcode: new UntypedFormControl(0),
      sipnum: this.phoneControl,
      devtype: this.typeControl,
      descr: this.descrControl,
      enable: this.enableControl,
      email: this.emailControl,
      emailSent: this.emailSentControl,
      phone: new UntypedFormControl(''),
      backen: this.backupControl,
      extab: this.extabControl,
      extabname: this.extabnameControl
    });

    this.udFormGroup.disable();
    this.phoneBackupControl.disable();

    this.typeControl.valueChanges.subscribe((v: number) => {
      if (v !== (this.userDevice.devtype as any)) {
        switch (v) {
          case 1:
            this.descrControl.setValue('');
            this.emailControl.setValue('');
            this.enableControl.setValue(false);
            this.backupControl.setValue(false);
            this.phoneBackupControl.setValue('');
            this.phoneControl.setValue('');
            break;
          case 3:
            this.emailControl.setValue('');
            this.enableControl.setValue(false);
            this.backupControl.setValue(false);
            this.phoneBackupControl.setValue('');
            break;

          default:
            break;
        }
      }
    });

    this.uDeviceService.users$.subscribe((userData: UsersData) => {
      this.userDevice = userData.user;
      this.udFormGroup.patchValue(this.userDevice);

      // Remaining User devices management
      this.devices = userData.users;
      if (this.checkRemainingLines(this.devices)) {
        this.remainingLines = this.checkRemainingLines(this.devices);
        this.phoneLineAvailable = this.findPhoneLineAvailable(this.devices);
      }

      // Backup line management
      this.backupLine = this.devices.find(
        (x: UserDto) => x.backline === this.userDevice.id
      );
      if (this.backupLine) {
        this.backupControl.setValue(true);
        this.phoneControl.setValue('');
        this.phoneBackupControl.setValue(this.backupLine.sipnum);
      }

      this.pageName =
        this.userDevice.descr || this.userDevice.id?.toString() || '--';

      this.mobileService.appInfoSetup(
        { title: this.ts.instant(this.pageName) },
        { visibility: true }
      );
    });
  }

  prepareUser(user: UserDto): UserSaveDto {
    const userSave = { ...user };
    delete userSave.apt;
    delete userSave.vipertoken;
    delete userSave.actcode;
    delete userSave.appconnelaps;
    delete userSave.cloudsync;
    return userSave;
  }

  saveUser(userDev: UserDto[]) {
    const users = userDev.map((x) => this.prepareUser(x));

    return this.aptUserService
      .setApartmentUsers(
        this.deviceId,
        parseInt(this.aptId),
        this.authService.getToken(),
        {
          apartmentusers: users
        }
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(() => {
          return EMPTY;
        })
      );
  }

  sendInvite() {
    this.isLoadingMail = true;
    const user = this.userDevice as any;
    user.cmdcode = 5;

    this.saveUser([user])
      .pipe(finalize(() => (this.isLoadingMail = false)))
      .subscribe(() => {
        this.toastr.success(
          this.ts.instant('USER_DEVICE.USER_DEVICE_MAIL_SENT'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  resetCode() {
    this.isLoadingAC = true;
    const user = this.userDevice as any;
    user.cmdcode = 3;

    this.saveUser([user])
      .pipe(finalize(() => (this.isLoadingAC = false)))
      .subscribe(() => {
        this.toastr.success(
          this.ts.instant('USER_DEVICE.CODE_RESETTED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  generateCode() {
    this.isLoadingAC = true;
    const user = this.userDevice as any;
    user.cmdcode = 1;

    this.saveUser([user]).subscribe(() => {
      this.uDeviceService
        .loadUserDevice(
          this.deviceId,
          parseInt(this.aptId),
          this.userDevice.id!
        )
        .pipe(finalize(() => (this.isLoadingAC = false)))
        .subscribe((r: UserDto[]) => {
          this.userDevice = r.find((x) => x.id === this.userDevice.id)!;
          this.udFormGroup.patchValue(this.userDevice);
          this.toastr.success(
            this.ts.instant('USER_DEVICE.CODE_GENERATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        });
    });
  }

  deleteActivationCode() {
    this.isLoadingAC = true;
    const user = this.userDevice as any;
    user.cmdcode = 2;

    this.saveUser([user]).subscribe(() => {
      this.uDeviceService
        .loadUserDevice(
          this.deviceId,
          parseInt(this.aptId),
          this.userDevice.id!
        )
        .pipe(finalize(() => (this.isLoadingAC = false)))
        .subscribe((r: UserDto[]) => {
          this.userDevice = r.find((x) => x.id === this.userDevice.id)!;
          this.udFormGroup.patchValue(this.userDevice);
          this.toastr.success(
            this.ts.instant('USER_DEVICE.CODE_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        });
    });
  }

  editUserDevice() {
    this.isEditing = true;
    this.udFormGroup.enable();
    this.phoneBackupControl.enable();
  }

  closeEdit(cancel?: boolean) {
    if (cancel) {
      this.uDeviceService.users$.next({
        user: this.userDevice,
        users: this.devices
      });
    } else {
      this.uDeviceService
        .loadAll(this.deviceId, +this.aptId, this.userDevice.id!)
        .subscribe();
    }

    this.udFormGroup.disable();
    this.phoneBackupControl.disable();
    this.isEditing = false;
  }

  updateUserDevice() {
    if (checkFormValidity(this.udFormGroup) && !this.isLoading) {
      this.isLoading = true;
      if (
        this.typeControl.dirty &&
        this.userDevice.devtype !== this.typeControl.value
      ) {
        if (this.typeControl.value === 3) {
          this.oldDevice = { ...this.udFormGroup.value };
          this.udFormGroup.controls['id'].setValue(this.phoneLineAvailable);
        }
      }

      const aptUsers = [this.udFormGroup.value];
      aptUsers[0].backen = false;
      if (this.backupControl.value) {
        aptUsers.push({
          id: this.backupLine ? this.backupLine.id : this.phoneLineAvailable,
          devtype: 3,
          descr:
            this.ts.instant('USER_DEVICE.BACKUP_CALL_FOR') +
            this.descrControl.value,
          email: '',
          sipnum: this.phoneBackupControl.value,
          backen: true,
          extab: false,
          extabname: '',
          backline: this.udFormGroup.value.id
        });
      } else if (!this.backupControl.value && this.backupLine) {
        const bkline = this.prepareUser(this.backupLine) as any;
        bkline.enable = false;
        bkline.cmdcode = 3;
        bkline.devtype = 0;

        aptUsers.push(bkline);
      }

      if (this.typeControl.value === 3 && this.oldDevice) {
        const userDevice: any = this.prepareUser(this.oldDevice);
        userDevice.enable = false;
        userDevice.cmdcode = 3;
        userDevice.devtype = 0;
        aptUsers.push(userDevice);
      }

      this.saveUser(aptUsers).subscribe(() => {
        this.userDevice = this.prepareUser(this.udFormGroup.value);
        this.toastr.success(
          this.ts.instant('USER_DEVICE.USER_DEVICE_EDITED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
        this.closeEdit();
      });
    }
  }

  findNextLineAvailable(devices: UserDto[]) {
    const position = devices.findIndex((x: any) => x.devtype === 0);
    return position + 1;
  }

  checkRemainingLines(devices: UserDto[]) {
    const devicesAvailables = devices.filter((x: any) => x.devtype === 0);
    return devicesAvailables.length;
  }

  findPhoneLineAvailable(devices: UserDto[]) {
    for (let index = devices.length; index > 0; index--) {
      const element = devices[index - 1] as any;
      if (element.devtype === 0) {
        return index;
      }
    }
    return -1;
  }
}
