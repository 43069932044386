/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Result common structure
 */
export interface ResultDto {
  /**
   * `true` if an error occurred, otherwise `false`
   */
  readonly hasErrors?: boolean;
  /**
   * `true` if no error occurred, otherwise `false`
   */
  readonly isSuccessful?: boolean;
  /**
   * List of occurred errors
   */
  readonly errorMessages?: Array<string>;
  /**
   * Last error that occurred
   */
  readonly errorMessage?: string;
  /**
   * Warning message, if any
   */
  readonly warningMessage?: string;
  /**
   * Information message if any
   */
  readonly informationMessage?: string;
  /**
   * Success message if any
   */
  readonly successMessage?: string;
}
