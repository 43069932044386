/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Device SIP license details
 */
export interface UpdateMobexOptionsDto {
  /**
   * Holder Authentication ID
   */
  holderAuthId?: string;
  /**
   * License duration
   */
  duration?: UpdateMobexOptionsDto.DurationEnum;
  /**
   * License duration unit
   */
  durationUnit: UpdateMobexOptionsDto.DurationUnitEnum;
  /**
   * License expiry fixed date
   */
  fixedDate?: string;
  /**
   * Generic note. e.g. sales order number
   */
  note?: string;
  /**
   * Disable notifications
   */
  disableNotifications?: boolean;
  /**
   * Device Authentication ID to which the license is attached to
   */
  deviceAuthId?: string;
  /**
   * Description
   */
  description?: string;
  /**
   * optional list of email of users to be notified on license changes
   */
  userToNotifyList?: Array<string>;
  /**
   * Optional custom <key, value> pairs of license-specific properties
   */
  customData?: { [key: string]: object };
  /**
   * Identify the DTO type to be serialized/deserialized, please use the value provided in the example
   */
  dto_type: UpdateMobexOptionsDto.DtoTypeEnum;
}
export namespace UpdateMobexOptionsDto {
  export type DurationEnum = null;
  export const DurationEnum = {
    NUMBER_null: null as DurationEnum
  };
  export type DurationUnitEnum = 'MONTHS';
  export const DurationUnitEnum = {
    MONTHS: 'MONTHS' as DurationUnitEnum
  };
  export type DtoTypeEnum =
    | 'create_bundle'
    | 'create_mobex'
    | 'update_mobex'
    | 'create_sbc'
    | 'update_sbc'
    | 'create_tim'
    | 'renew'
    | 'update_router';
  export const DtoTypeEnum = {
    CreateBundle: 'create_bundle' as DtoTypeEnum,
    CreateMobex: 'create_mobex' as DtoTypeEnum,
    UpdateMobex: 'update_mobex' as DtoTypeEnum,
    CreateSbc: 'create_sbc' as DtoTypeEnum,
    UpdateSbc: 'update_sbc' as DtoTypeEnum,
    CreateTim: 'create_tim' as DtoTypeEnum,
    Renew: 'renew' as DtoTypeEnum,
    UpdateRouter: 'update_router' as DtoTypeEnum
  };
}
