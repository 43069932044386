import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConnectivityActivationModalComponent } from '../connectivity-activation-modal/connectivity-activation-modal.component';

@Component({
  selector: 'app-connectivity-modal',
  templateUrl: './connectivity-modal.component.html',
  styleUrls: ['./connectivity-modal.component.scss']
})
export class ConnectivityModalComponent implements OnInit {
  deviceId: string;
  deviceAuthId: string;
  constructor(
    private modalRef: BsModalRef,
    private ts: TranslateService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  close() {
    this.modalRef.hide();
  }

  comelitConnection() {
    const initialState: Partial<ConnectivityActivationModalComponent> = {
      deviceId: this.deviceId,
      deviceAuthId: this.deviceAuthId
    };

    const modalRef = this.modalService.show(
      ConnectivityActivationModalComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      }
    );

    modalRef.content!.connectivityCompleted.subscribe(() => {
      this.modalRef.hide();
    });
  }

  noComelitConnection() {
    this.toastrService.success(
      this.ts.instant('DEVICE.DEVICE_CREATED'),
      this.ts.instant('GLOBAL.SUCCESS')
    );
    this.modalRef.hide();
  }
}
