import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { GeneralCommandsService } from 'src/app/api/dpcp/api/generalCommands.service';
import { SiteDto, SitesService } from 'src/app/api/myportal';
import { AddElementDto, TreeService } from 'src/app/api/tree';
import { AuthService } from 'src/app/core/auth.service';
import { DeviceCompleteDto } from 'src/app/core/models';
import { checkFormValidity, isSafeModel } from 'src/app/core/utils';
import { isFireModel } from '../../core/utils';
import { CommandExecutionResultDto, PanelService } from '../../api/fire';

@Component({
  selector: 'app-edit-device-modal',
  templateUrl: './edit-device-modal.component.html',
  styleUrls: ['./edit-device-modal.component.scss']
})
export class EditDeviceModalComponent implements OnInit {
  @Input() device: DeviceCompleteDto;
  ssid: number | undefined;
  deviceFormGroup: UntypedFormGroup;
  siteFormGroup: UntypedFormGroup;
  nameControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(100),
    Validators.minLength(1)
  ]);
  siteControl = new UntypedFormControl('');
  siteList: SiteDto[] = [];
  isLoading = false;

  isSafeModel = isSafeModel;

  @Output() deviceUpdated = new EventEmitter<string>();

  constructor(
    private treeService: TreeService,
    private authService: AuthService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private modalRef: BsModalRef,
    private panelService: PanelService,
    private sitesService: SitesService
  ) {}

  ngOnInit(): void {
    this.deviceFormGroup = new UntypedFormGroup({
      label: this.nameControl
    });

    this.siteFormGroup = new UntypedFormGroup({
      site: this.siteControl
    });

    this.nameControl.setValue(this.device.resource.name);
    if (!this.device.resource.siteAuthenticationId) {
      this.nameControl.disable();
    }

    this.sitesService
      .getSites(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.authService.getToken()
      )
      .subscribe((res: SiteDto[]) => {
        if (
          !isSafeModel(this.device.resource.system) &&
          !this.device.resource.newFlag
        ) {
          this.siteList = res.filter(
            (x: SiteDto) =>
              x.ownerAuthenticationId ===
              this.device.resource.ownerAuthenticationId
          );
        } else {
          this.siteList = res;
        }

        this.siteControl.setValue(
          this.siteList.find(
            (x) =>
              x.authenticationId === this.device.resource.siteAuthenticationId
          )
        );
      });
  }

  close() {
    this.modalRef.hide();
  }

  editDevice() {
    if (checkFormValidity(this.siteFormGroup) && this.siteFormGroup.touched) {
      this.isLoading = true;
      if (this.device.resource.siteAuthenticationId) {
        this.treeService
          .unlinkDevice(this.device.resource.uuid!, this.authService.getToken())
          .subscribe(() => {
            this.addToNewSite();
          });
      } else {
        this.addToNewSite();
      }
    } else {
      this.deviceRenameManagement();
    }
  }

  deviceRenameManagement() {
    if (
      checkFormValidity(this.deviceFormGroup) &&
      this.deviceFormGroup.touched
    ) {
      this.renameDevice();
    } else {
      if (checkFormValidity(this.deviceFormGroup)) {
        this.completeEdit();
      }
    }
  }

  renameDevice() {
    this.isLoading = true;
    if (isFireModel(this.device.status.system!)) {
      this.panelService
        .cmdLabelPut(this.device.resource.uuid!, {
          label: this.nameControl.value
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (x: CommandExecutionResultDto) => {
            this.completeEdit(x);
          },
          (error: any) => {
            switch (error.error.errorCode) {
              case 'COMMON:CORE:DEVICE_NO_DEV_STATUS':
                this.toastr.clear();
                this.toastr.error(
                  this.ts.instant('DEVICE.DEVICE_NO_DEV_STATUS'),
                  this.ts.instant('GLOBAL.ERROR')
                );
                break;
              case 'COMMON:CORE:AMQP_TIMEOUT':
                this.toastr.clear();
                this.toastr.error(
                  this.ts.instant('DEVICE.DEVICE_AMQP_TIMEOUT'),
                  this.ts.instant('GLOBAL.ERROR')
                );
                break;

              default:
                break;
            }
          }
        );
    } else {
      this.treeService
        .renameDevice(
          this.device.resource.uuid!,
          this.authService.getToken(),
          undefined,
          {
            label: this.nameControl.value
          }
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(() => {
          this.completeEdit();
        });
    }
  }

  completeEdit(x?: any) {
    this.isLoading = false;
    this.toastr.success(
      x && x.outcome === 'ACCEPTED'
        ? this.ts.instant('DEVICE.DEVICE_EDITED_OFFLINE')
        : this.ts.instant('DEVICE.DEVICE_EDITED'),
      this.ts.instant('GLOBAL.SUCCESS')
    );
    this.modalRef.hide();
    this.deviceUpdated.next(this.siteControl.value.authenticationId);
  }

  addToNewSite() {
    const device: AddElementDto = {
      id32: this.device.resource.deviceId32!,
      elementUuid: this.device.resource.deviceId32
        ? undefined
        : this.device.resource.uuid,
      type: 'DEVICE',
      siteAuthenticationId: this.siteControl.value.authenticationId,
      label: this.device.resource.name
    };
    this.treeService
      .addElements(
        this.siteControl.value.rootNode,
        this.authService.getToken(),
        device
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.deviceRenameManagement();
      });
  }
}
