import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  SipLineCfgDto,
  StatusAndConfigurationService
} from 'src/app/api/mugconf';
import { AuthService } from 'src/app/core/auth.service';
import { SipLineCfgDtoExpanded } from 'src/app/core/models';
import { checkFormValidity } from 'src/app/core/utils';

@Component({
  selector: 'app-sip-line-modal',
  templateUrl: './sip-line-modal.component.html',
  styleUrls: ['./sip-line-modal.component.scss']
})
export class SipLineModalComponent implements OnInit {
  sipLines: SipLineCfgDto[];
  sipLine?: SipLineCfgDtoExpanded;
  deviceId: string;
  isLoading = false;
  lineFormGroup: UntypedFormGroup;
  usernameControl = new UntypedFormControl('', Validators.required);
  passwordControl = new UntypedFormControl('', Validators.required);
  userIdControl = new UntypedFormControl('');
  lineCreated = new EventEmitter<void>();

  constructor(
    private modalRef: BsModalRef,
    private statusConfService: StatusAndConfigurationService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.lineFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      sipUsername: this.usernameControl,
      sipPassword: this.passwordControl,
      sipUserAuth: this.userIdControl
    });

    if (this.sipLine) {
      this.lineFormGroup.patchValue(this.sipLine);
    }
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    if (checkFormValidity(this.lineFormGroup) && !this.isLoading) {
      this.isLoading = true;
      if (this.sipLine) {
        this.sipLines[this.sipLine.index] = this.lineFormGroup.value;
      } else {
        const availableSpotIndex = this.sipLines.findIndex(
          (x) => x.sipUsername === '' && x.sipPassword === ''
        );

        this.lineFormGroup.controls['id'].setValue(availableSpotIndex);
        this.sipLines[availableSpotIndex] = this.lineFormGroup.value;
      }

      this.statusConfService
        .setSipLinesCfg(this.deviceId, this.authService.getToken(), {
          sipLines: this.sipLines
        })
        .pipe(finalize(() => (this.isLoading = true)))
        .subscribe(() => {
          this.toastr.success(
            this.sipLine
              ? this.ts.instant('SIP.SIP_LINE_EDITED')
              : this.ts.instant('SIP.SIP_LINE_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.modalRef.hide();
          this.lineCreated.next();
        });
    }
  }
}
