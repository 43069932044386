<div class="card-table mb-4">
  <div class="card-header">
    <h2>{{ 'DASHBOARD.DEVICES_WITH_ANOMALIES' | translate }}</h2>
  </div>

  <div class="card-body">
    <app-devices-table [onlyAnomalies]="true"></app-devices-table>
  </div>
  <div class="card-footer">
    <a id="device-list-link" [routerLink]="['/devices']" class="link-primary"
      >{{ 'DASHBOARD.DEVICE_LIST' | translate }}
      <i class="material-icons">keyboard_arrow_right</i></a
    >
  </div>
</div>
