import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { getUserLanguage } from 'src/app/app-init';
import { AuthService } from 'src/app/core/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  userInfoIsOpen = false;
  menuOpen = false;
  userImage: SafeStyle;
  userName = '';
  image: SafeStyle;
  secondaryImage: SafeStyle;
  public environment = environment;

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (this.menuOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.menuOpen = false;
    }
  }

  constructor(
    public authService: AuthService,
    private eRef: ElementRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.image = `assets/images/${environment.client}-logo-cloud.svg`;
    this.secondaryImage = `assets/images/${environment.client}-logo.svg`;
    this.userName = this.authService.getName();
    this.userImage = this.authService.getImage()
      ? `url('${this.authService.getImage()}?v=${Date.now()}')`
      : 'url(assets/images/user-placeholder.png)';
  }

  openDoc() {
    const langList = [
      'DE',
      'EN',
      'ES',
      'FR',
      'IT',
      'NL',
      'PL',
      'PT',
      'RU',
      'TR'
    ];
    const lang = getUserLanguage().toUpperCase();

    window.open(
      `https://staticpro.comelitgroup.com/filescms/mysites/mu_mysites_${
        this.authService.permissions.installator_view
          ? 'installer'
          : 'buildingmanager'
      }_${langList.indexOf(lang) !== -1 ? lang : 'EN'}.pdf`
    );
  }
}
