/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CommandExecutionResultDto {
  /**
   * Represents the execution outcome of a command.EXECUTED means that the command completed its execution with a positive outcome.ACCEPTED means that the command has been correctly accepted but not yet executed.
   */
  readonly outcome?: CommandExecutionResultDto.OutcomeEnum;
}
export namespace CommandExecutionResultDto {
  export type OutcomeEnum = 'EXECUTED' | 'ACCEPTED';
  export const OutcomeEnum = {
    EXECUTED: 'EXECUTED' as OutcomeEnum,
    ACCEPTED: 'ACCEPTED' as OutcomeEnum
  };
}
