<div class="container-fluid">
  <h1>{{ pageName | translate }}</h1>

  <div class="card-table mt-3">
    <div class="card-header">
      <div class="card-filters">
        <!-- <app-filters
          (filtersChanged)="filtersChanged($event)"
          [options]="filterOptions"
          [onlyOne]="true"
        ></app-filters> -->
      </div>
    </div>
    <div class="card-body">
      <ngx-datatable
        (scroll)="scroll($event)"
        class="material"
        bodyHeight="tableHeight"
        [rowHeight]="undefined"
        [columns]="columns"
        [rows]="notificationList"
        [scrollbarH]="true"
        columnMode="force"
      >
        <ng-content></ng-content>
      </ngx-datatable>

      <app-loading
        [isLoading]="isLoadingStart"
        [label]="'APARTMENT.DOWNLOAD_IN_PROCESS' | translate"
      ></app-loading>
    </div>
  </div>
</div>
<ng-template #msgTpl let-row="row">
  {{ eventActions[row.logEventAction] | translate }} - {{ row.msg }}
</ng-template>
<ng-template #typeTpl let-value="value">
  {{ eventLogType[value] | translate }}
</ng-template>
