/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenerateMaintenanceReportPortalRowDto } from './generateMaintenanceReportPortalRowDto';
import { GenerateMaintenanceReportPortalMaintainerDto } from './generateMaintenanceReportPortalMaintainerDto';

export interface GenerateMaintenanceReportPortalParametersDto {
  reportName: string;
  date: string;
  place: string;
  businessName: string;
  address: string;
  buildingManager: string;
  typeOfSystem: string;
  testType: GenerateMaintenanceReportPortalParametersDto.TestTypeEnum;
  testNotes: string;
  nonCompliances: boolean;
  nonCompliancesFixed: boolean;
  deviceReplaced: boolean;
  clientInformed: boolean;
  rows: Array<GenerateMaintenanceReportPortalRowDto>;
  comments: string;
  maintainer1: GenerateMaintenanceReportPortalMaintainerDto;
  maintainer2: GenerateMaintenanceReportPortalMaintainerDto;
  maintainer3: GenerateMaintenanceReportPortalMaintainerDto;
  maintainer4: GenerateMaintenanceReportPortalMaintainerDto;
  company: GenerateMaintenanceReportPortalParametersDto.CompanyEnum;
}
export namespace GenerateMaintenanceReportPortalParametersDto {
  export type TestTypeEnum =
    | 'COMMISSIONING'
    | 'FIRTS'
    | 'SEMESTRAL'
    | 'EXTRAORDINARY'
    | 'OTHER';
  export const TestTypeEnum = {
    COMMISSIONING: 'COMMISSIONING' as TestTypeEnum,
    FIRTS: 'FIRTS' as TestTypeEnum,
    SEMESTRAL: 'SEMESTRAL' as TestTypeEnum,
    EXTRAORDINARY: 'EXTRAORDINARY' as TestTypeEnum,
    OTHER: 'OTHER' as TestTypeEnum
  };
  export type CompanyEnum = 'COMELIT' | 'TELETEK';
  export const CompanyEnum = {
    COMELIT: 'COMELIT' as CompanyEnum,
    TELETEK: 'TELETEK' as CompanyEnum
  };
}
