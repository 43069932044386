<div class="modal-header">
  <h2>
    {{ title }}
  </h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="cameraGroup">
  <label class="custom-label" for="description">{{
    'ADDRESSBOOK.DESCRIPTION' | translate
  }}</label>
  <input
    id="description"
    type="text"
    class="custom-input"
    [placeholder]="'ADDRESSBOOK.DESCRIPTION' | translate"
    formControlName="name"
  />

  <app-control-errors
    id="description-validator"
    controlName="name"
  ></app-control-errors>

  <label class="custom-label" for="address">{{
    'ADDRESSBOOK.ADDRESS' | translate
  }}</label>
  <input
    id="address"
    type="text"
    class="custom-input"
    [placeholder]="'ADDRESSBOOK.ADDRESS' | translate"
    formControlName="addr"
  />
  <app-control-errors
    id="address-validator"
    controlName="addr"
  ></app-control-errors>
</div>
<div class="modal-footer justify-content-center">
  <button
    id="close"
    class="button-secondary big mr-3"
    (click)="close()"
    type="button"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    id="save"
    class="button-primary big mr-3"
    [disabled]="!cameraGroup.valid"
    (click)="save()"
    type="button"
  >
    {{ 'GLOBAL.SAVE' | translate }}
  </button>
</div>
