/**
 * License Manager API - v2
 * API to manage licenses
 *
 * The version of the OpenAPI document: 2.0.0-next-159
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Licence renew request.
 */
export interface LicenseMigrateDto {
  /**
   * Device id 32.
   */
  deviceId32: string;
}
