/**
 * Cloud Message Center API
 * API to interact with cloud message center
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Message update DTO
 */
export interface MessageUpdateDto {
  /**
   * Message is acknowledged
   */
  ack?: boolean;
}
