/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Custom activation command options
 */
export interface SafeCustomActivationCommandOptionsDto {
  /**
   * Action to apply
   */
  action?: SafeCustomActivationCommandOptionsDto.ActionEnum;
  /**
   * Areas to which the action applies
   */
  areas?: number;
  /**
   * Areas to which the activation must be immediate
   */
  immediateAreas?: number;
  /**
   * Areas activation options
   */
  options?: number;
}
export namespace SafeCustomActivationCommandOptionsDto {
  export type ActionEnum =
    | 'NONE'
    | 'TOTAL_ACTIVATION'
    | 'P1P2_ACTIVATION'
    | 'P1_ACTIVATION'
    | 'P2_ACTIVATION'
    | 'TOTAL_DEACTIVATION';
  export const ActionEnum = {
    NONE: 'NONE' as ActionEnum,
    TOTALACTIVATION: 'TOTAL_ACTIVATION' as ActionEnum,
    P1P2ACTIVATION: 'P1P2_ACTIVATION' as ActionEnum,
    P1ACTIVATION: 'P1_ACTIVATION' as ActionEnum,
    P2ACTIVATION: 'P2_ACTIVATION' as ActionEnum,
    TOTALDEACTIVATION: 'TOTAL_DEACTIVATION' as ActionEnum
  };
}
