export * from './apartmentsAndUsers.service';
import { ApartmentsAndUsersService } from './apartmentsAndUsers.service';
export * from './appLink.service';
import { AppLinkService } from './appLink.service';
export * from './directories.service';
import { DirectoriesService } from './directories.service';
export * from './licenses.service';
import { LicensesService } from './licenses.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './residents.service';
import { ResidentsService } from './residents.service';
export * from './statusAndConfiguration.service';
import { StatusAndConfigurationService } from './statusAndConfiguration.service';
export const APIS = [
  ApartmentsAndUsersService,
  AppLinkService,
  DirectoriesService,
  LicensesService,
  ReportService,
  ResidentsService,
  StatusAndConfigurationService
];
