import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface User {
  email: string;
  authId: string;
  role: string;
  roleAuthId: string;
}

export interface Roles {
  authId: string;
  name: UserRole | 'NOROLE';
}

export type RolesWLabel = Roles & { label: string };

export const rolesLabels: { [key: string]: string } = {
  'INSTALLER-COLLABORATOR': _('ROLES.INSTALLER_COLLABORATOR'),
  INSTALLER: _('ROLES.INSTALLER'),
  ADMIN: _('ROLES.ADMIN'),
  BUILDINGMANAGER: _('ROLES.BUILDINGMANAGER'),
  'BUILDINGMANAGER-COLLABORATOR': _('ROLES.BUILDINGMANAGER_COLLABORATOR'),
  'MONITOR-USER': _('ROLES.SUPER_USER'),
  MAINTAINER: _('ROLES.MAINTAINER'),
  NOROLE: _('ROLES.NO_ROLE')
};

export const licensesLabels: { [key: string]: string } = {
  NONE: _('LICENSES.TYPES.NONE'),
  SLAVE: _('LICENSES.TYPES.SLAVE'),
  MASTER: _('LICENSES.TYPES.MASTER'),
  PHONE: _('LICENSES.TYPES.PHONE'),
  MASTERTIME: _('LICENSES.TYPES.MASTER_TIME')
};

export const udTypesLabels: { [key: string]: string } = {
  NONE: _('USER_DEVICE.TYPES.NONE'),
  INTERNAL: _('USER_DEVICE.TYPES.INTERNAL'),
  APP: _('USER_DEVICE.TYPES.APP'),
  PHONE: _('USER_DEVICE.TYPES.PHONE')
};

export type UserRole =
  | 'MONITOR-USER'
  | 'INSTALLER'
  | 'BUILDINGMANAGER'
  | 'INSTALLER-COLLABORATOR'
  | 'BUILDINGMANAGER-COLLABORATOR'
  | 'MAINTAINER'
  | 'ADMIN';
