/**
 * Authentication - Comelit MyPortal API
 * API to interact with authentication service to manage sites, companies and groups
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupUserRequestDto } from './groupUserRequestDto';

/**
 * User roles over a group
 */
export interface GroupUserRoleRequestDto {
  user: GroupUserRequestDto;
  /**
   * The roles
   */
  roles: Array<GroupUserRoleRequestDto.RolesEnum>;
}
export namespace GroupUserRoleRequestDto {
  export type RolesEnum = 'GROUP_ADMIN' | 'GROUP_READER' | 'GROUP_WRITER';
  export const RolesEnum = {
    ADMIN: 'GROUP_ADMIN' as RolesEnum,
    READER: 'GROUP_READER' as RolesEnum,
    WRITER: 'GROUP_WRITER' as RolesEnum
  };
}
