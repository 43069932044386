import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-renew-connectivity',
  templateUrl: './renew-connectivity.component.html',
  styleUrls: ['./renew-connectivity.component.scss']
})
export class RenewConnectivityComponent implements OnInit {
  deviceCodeControl = new UntypedFormControl('', Validators.required);
  renewForm: UntypedFormGroup;
  renewed = new EventEmitter<boolean>();

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.renewForm = new UntypedFormGroup({
      code: this.deviceCodeControl
    });
  }

  codeRetrieved(res: string) {
    this.deviceCodeControl.setValue(res);
  }

  renew() {
    this.renewed.next(true);
  }
  close() {
    this.modalRef.hide();
  }
}
