import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SiteDto } from 'src/app/api/myportal';
import { ExternalCallsService } from 'src/app/api/sip';
import { AuthService } from 'src/app/core/auth.service';
import { checkFormValidity } from 'src/app/core/utils';
import { SipService } from '../../sip.service';

@Component({
  selector: 'app-sip-activation-modal',
  templateUrl: './sip-activation-modal.component.html',
  styleUrls: ['./sip-activation-modal.component.scss']
})
export class SipActivationModalComponent implements OnInit {
  site: SiteDto;
  deviceId: string;
  isLoading = false;
  activationFormGroup: UntypedFormGroup;
  siteNameControl = new UntypedFormControl({ value: '', disabled: true });
  siteAddressControl = new UntypedFormControl({ value: '', disabled: true });
  siteCityControl = new UntypedFormControl({ value: '', disabled: true });
  siteZipControl = new UntypedFormControl({ value: '', disabled: true });
  siteCountryControl = new UntypedFormControl({ value: '', disabled: true });

  devDescControl = new UntypedFormControl();
  emailControl = new UntypedFormControl('');
  email1Control = new UntypedFormControl(
    '',
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
  );
  email2Control = new UntypedFormControl(
    '',
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
  );

  licenseCreated = new EventEmitter<void>();

  constructor(
    private modalRef: BsModalRef,
    private sipService: SipService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private authService: AuthService,
    private externalCallsService: ExternalCallsService
  ) {}

  ngOnInit(): void {
    this.activationFormGroup = new UntypedFormGroup({
      duration: new UntypedFormControl(14),
      durationUnit: new UntypedFormControl('MONTHS'),
      description: this.devDescControl,
      lines: new UntypedFormControl(10),
      userToNotifyList: this.emailControl,
      address1: this.siteAddressControl,
      address2: new UntypedFormControl(''),
      city: this.siteCityControl,
      zipcode: this.siteZipControl,
      country: this.siteCountryControl
    });

    this.siteNameControl.setValue(this.site.name);
    this.siteAddressControl.setValue(this.site.address.addressLine1);
    this.siteCityControl.setValue(this.site.address.city);
    this.siteZipControl.setValue(this.site.address.zipCode);
    this.siteCountryControl.setValue(this.site.address.country);
  }

  save() {
    if (checkFormValidity(this.activationFormGroup) && !this.isLoading) {
      this.isLoading = true;
      const emails = [];
      if (this.email1Control.value) {
        emails.push(this.email1Control.value);
      }
      if (this.email2Control.value) {
        emails.push(this.email2Control.value);
      }
      this.emailControl.setValue(emails.join(','));

      this.sipService
        .createLicense(this.deviceId, this.activationFormGroup.getRawValue(), {
          token: this.authService.getToken()
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error.error_code === '') {
              this.toastr.error(
                this.ts.instant('SIP.LICENSE_EXISTS'),
                this.ts.instant('GLOBAL.ERROR')
              );
            }

            return throwError(error);
          }),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(() => {
          this.toastr.success(
            this.ts.instant('SIP.LICENSE_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.modalRef.hide();
          this.licenseCreated.next();
        });
    }
  }
  close() {
    this.modalRef.hide();
  }
}
