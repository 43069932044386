import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() inputId: string;
  @Input() disableLogic: any;
  constructor() {}

  ngOnInit(): void {
    if (this.disableLogic) {
      this.control.disable();
    }
  }
}
