import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  LicenseInstanceDto,
  LicenseInstanceManagementDeviceService
} from 'src/app/api/licenses';
import { SitesService } from 'src/app/api/myportal';
import { SiteDto } from 'src/app/api/myportal/model/siteDto';
import {
  AddElementDto,
  DeviceListElementDto,
  TreeService
} from 'src/app/api/tree';
import { AuthService } from 'src/app/core/auth.service';
import { isSbc } from 'src/app/core/utils';
import { CreateSiteModalComponent } from 'src/app/sites/create-site-modal/create-site-modal.component';
import { environment } from 'src/environments/environment';
import { ConnectivityModalComponent } from '../connectivity-modal/connectivity-modal.component';

@Component({
  selector: 'app-site-association-modal',
  templateUrl: './site-association-modal.component.html',
  styleUrls: ['./site-association-modal.component.scss']
})
export class SiteAssociationModalComponent implements OnInit {
  siteControl = new UntypedFormControl('', Validators.required);
  siteList: SiteDto[] = [];
  safeDeviceCreation = false;
  device: DeviceListElementDto;
  siteAssociated = new EventEmitter<void>();
  isLoading = false;
  addSmall = false;

  constructor(
    private authService: AuthService,
    private licenseService: LicenseInstanceManagementDeviceService,
    private sitesService: SitesService,
    private treeService: TreeService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private ts: TranslateService,
    private modalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.sitesService
      .getSites(
        undefined,
        ['ADMIN', 'INSTALLER', 'BUILDINGMANAGER', 'INSTALLER_COLLABORATOR'],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.authService.getToken()
      )
      .subscribe((res) => {
        this.siteList = res;
      });
  }

  close() {
    this.modalRef.hide();
  }

  openSiteCreation() {
    const initialState: Partial<CreateSiteModalComponent> = {};

    const modalRef = this.modalService.show(CreateSiteModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteCreated.subscribe((site: SiteDto) => {
      this.siteList = this.siteList.concat([site]);
      this.siteControl.setValue(site);
    });
  }

  associate() {
    this.isLoading = true;

    if (this.safeDeviceCreation) {
      const dev: AddElementDto = {
        id32: this.device.deviceId32!,
        type: 'DEVICE',
        siteAuthenticationId: this.siteControl.value.authenticationId,
        label: this.device.name
      };

      this.treeService
        .addElements(
          this.siteControl.value.rootNode,
          this.authService.getToken(),
          dev
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.modalRef.hide();
            this.siteAssociated.next();
          },
          (error) => {
            if (error.error.error_id === 'AUTH:TREE:ALREADY_LINKED') {
              this.toastrService.clear();
              this.toastrService.error(
                this.ts.instant('DEVICE.HTTP_ERROR_ALREADY_LINKED_SITE'),
                this.ts.instant('GLOBAL.ERROR')
              );
            }
          }
        );
    } else {
      const deviceOpt: DeviceListElementDto = {
        ...this.device,
        siteAuthenticationId: this.siteControl.value.authenticationId
      };

      this.treeService
        .addDeviceToResourceList(
          this.authService.getToken(),
          undefined,
          false,
          deviceOpt
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (res: DeviceListElementDto) => {
            if (isSbc(res.deviceModelId) && res.deviceModelId !== 'SBEn') {
              this.activateSbc(res);
            }

            this.modalRef.hide();
            this.siteAssociated.next();
          },
          (error) => {
            if (error.error.error_id === 'AUTH:RESLIST:ALREADY_LINKED') {
              this.toastrService.clear();
              this.toastrService.error(
                this.ts.instant('DEVICE.HTTP_ERROR_ALREADY_LINKED'),
                this.ts.instant('GLOBAL.ERROR')
              );
            }
          }
        );
    }
  }

  activateSbc(dev: DeviceListElementDto) {
    this.licenseService
      .getDeviceLicenseInstancesByReferencedLicenseUuid(
        environment.licenseId,
        dev.uuid!,
        ['EXPIRED', 'EXPIRING', 'ENABLED', 'DISABLED']
      )
      .subscribe((res: LicenseInstanceDto[]) => {
        if (res.length === 0) {
          const initialState: Partial<ConnectivityModalComponent> = {
            deviceId: dev.uuid,
            deviceAuthId: dev.authenticationId
          };

          const modalRef = this.modalService.show(ConnectivityModalComponent, {
            initialState,
            ignoreBackdropClick: true,
            class: 'modal-lg'
          });
        }
      });
  }
}
