<ng-template #actionsTpl let-row="row" let-rowIndex="rowIndex">
  <i
    id="button-edit-{{ rowIndex }}"
    class="material-icons single-menu-icon"
    (click)="edit(row)"
    >edit</i
  >
  <i
    id="button-delete-{{ rowIndex }}"
    class="material-icons single-menu-icon ml-2"
    (click)="delete(row)"
    >delete</i
  >
</ng-template>
<div class="card-table mt-3">
  <div class="card-header">
    <h2>{{ 'ADDRESSBOOK.COMELIT_APP_BUTTON' | translate }}</h2>
    <button
      *ngIf="addAct.length === 0"
      id="add-camera"
      class="button-secondary"
      (click)="addButton()"
    >
      <i class="material-icons">add</i>
      {{ 'GLOBAL.ADD' | translate }}
    </button>
  </div>
  <div class="card-body">
    <app-datatable
      *ngIf="!isMobile"
      [columns]="columns"
      [dataSource]="dataSource"
      [hidePagination]="true"
    ></app-datatable>
    <app-mobile-table *ngIf="isMobile" [dataSource]="dataSource">
      <div id="mobile-buttons" class="mobile-table">
        <div
          *ngFor="let item of dataSource.items; let i = index"
          class="mobile-card btn text-left"
        >
          <span class="mobile-card-title"
            ><ng-container
              *ngTemplateOutlet="
                statusTpl;
                context: { row: item, rowIndex: i, value: item.enabled }
              "
            ></ng-container
          ></span>
          <span class="mobile-card-subtitle">
            <ng-container
              *ngTemplateOutlet="
                addressTpl;
                context: { value: item.addr, rowIndex: i }
              "
            ></ng-container>
          </span>
          <span class="mobile-card-actions">
            <ng-container
              *ngTemplateOutlet="
                actionsTpl;
                context: { row: item, rowIndex: i }
              "
            ></ng-container>
          </span>
        </div>
      </div>
    </app-mobile-table>
  </div>
</div>
<ng-template #statusTpl let-value="value" let-rowIndex="rowIndex">
  <span id="button-status-{{ rowIndex }}">
    <span class="abilitation" [ngClass]="{ green: value }"></span>
    {{ (value ? 'GLOBAL.ACTIVE' : 'GLOBAL.NOT_ACTIVE') | translate }}</span
  >
</ng-template>
<ng-template #addressTpl let-value="value" let-rowIndex="rowIndex">
  <span id="button-address-{{ rowIndex }}">{{ value.split('SBACT')[1] }}</span>
</ng-template>
