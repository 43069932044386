/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DetectorStatusBitMaskDto } from './detectorStatusBitMaskDto';

export interface DetectorDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Loop is an integer value containing the loop index the detector belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of detector inside its loop.
   */
  address?: number;
  /**
   * Type is an integer defining the type of detector.
   */
  type?: DetectorDto.TypeEnum;
  status?: DetectorStatusBitMaskDto;
  /**
   * Zone is an integer value containing the zone index the detector belongs to.
   */
  zone?: number;
  /**
   * Zone name is a string containing the zone name to whom device belongs.
   */
  zoneName?: string;
  /**
   * Sensitivity during night is an integer value describing the sensitivity during night of the detector.
   */
  nightSensitivity?: DetectorDto.NightSensitivityEnum;
  /**
   * Sensitivity during day is an integer value describing the sensitivity during day of the detector.
   */
  daySensitivity?: DetectorDto.DaySensitivityEnum;
  /**
   * Contamination is an integer value representing the contamination level measured by device (in percentage).
   */
  contamination?: number;
  /**
   * Class during night.
   */
  nightClass?: DetectorDto.NightClassEnum;
  /**
   * Class during night.
   */
  nightDay?: DetectorDto.NightDayEnum;
  /**
   * Name is a string containing the name of detector.
   */
  name?: string;
  /**
   * Communication quality is an integer value representing the quality of communication between detector and control panel.
   */
  communicationQuality?: number;
  /**
   * Serial is a string containing serial number of detector.
   */
  serial?: string;
  /**
   * Zonal Address of the detector.
   */
  zonalAddress?: number;
  /**
   * Lifecycle duty time of the detector.
   */
  lifecycleDutyTime?: number;
  /**
   * Lifecycle duty time left of the detector.
   */
  lifecycleDutyTimeLeft?: number;
  /**
   * Last 12 dust levels of the detector.
   */
  dustLevel?: Array<number>;
}
export namespace DetectorDto {
  export type TypeEnum =
    | 'T110'
    | 'T110_IS'
    | 'S130'
    | 'S130_IS'
    | 'M140'
    | 'M140_IS'
    | 'TD'
    | 'SD'
    | 'MD'
    | 'VELLA_T110'
    | 'VELLA_T110_IS'
    | 'VELLA_S130'
    | 'VELLA_S130_IS'
    | 'VELLA_M140'
    | 'VELLA_M140_IS'
    | 'MCP150'
    | 'MCP50A'
    | 'WL_MCP'
    | 'COM_41RCS100'
    | 'COM_41RCS100_IS'
    | 'COM_NONE_41RFU100'
    | 'COM_41RFU100'
    | 'COM_NONE_41RML100'
    | 'COM_41RML100'
    | 'COM_45RCS100'
    | 'COM_45RFU100'
    | 'COM_45RML100'
    | 'COM_41PAM000'
    | 'COM_43PAM000'
    | 'COM_45PAM100';
  export const TypeEnum = {
    T110: 'T110' as TypeEnum,
    T110IS: 'T110_IS' as TypeEnum,
    S130: 'S130' as TypeEnum,
    S130IS: 'S130_IS' as TypeEnum,
    M140: 'M140' as TypeEnum,
    M140IS: 'M140_IS' as TypeEnum,
    TD: 'TD' as TypeEnum,
    SD: 'SD' as TypeEnum,
    MD: 'MD' as TypeEnum,
    VELLAT110: 'VELLA_T110' as TypeEnum,
    VELLAT110IS: 'VELLA_T110_IS' as TypeEnum,
    VELLAS130: 'VELLA_S130' as TypeEnum,
    VELLAS130IS: 'VELLA_S130_IS' as TypeEnum,
    VELLAM140: 'VELLA_M140' as TypeEnum,
    VELLAM140IS: 'VELLA_M140_IS' as TypeEnum,
    MCP150: 'MCP150' as TypeEnum,
    MCP50A: 'MCP50A' as TypeEnum,
    WLMCP: 'WL_MCP' as TypeEnum,
    COM41RCS100: 'COM_41RCS100' as TypeEnum,
    COM41RCS100IS: 'COM_41RCS100_IS' as TypeEnum,
    COMNONE41RFU100: 'COM_NONE_41RFU100' as TypeEnum,
    COM41RFU100: 'COM_41RFU100' as TypeEnum,
    COMNONE41RML100: 'COM_NONE_41RML100' as TypeEnum,
    COM41RML100: 'COM_41RML100' as TypeEnum,
    COM45RCS100: 'COM_45RCS100' as TypeEnum,
    COM45RFU100: 'COM_45RFU100' as TypeEnum,
    COM45RML100: 'COM_45RML100' as TypeEnum,
    COM41PAM000: 'COM_41PAM000' as TypeEnum,
    COM43PAM000: 'COM_43PAM000' as TypeEnum,
    COM45PAM100: 'COM_45PAM100' as TypeEnum
  };
  export type NightSensitivityEnum =
    | 'SENS_HIGH'
    | 'SENS_NORM'
    | 'SENS_MED'
    | 'SENS_LOW'
    | 'SENS_OPT_OFF'
    | 'SENS_HIGH_TERM_OFF'
    | 'SENS_NORM_TERM_OFF'
    | 'SENS_MED_TERM_OFF'
    | 'SENS_LOW_TEM_OFF';
  export const NightSensitivityEnum = {
    HIGH: 'SENS_HIGH' as NightSensitivityEnum,
    NORM: 'SENS_NORM' as NightSensitivityEnum,
    MED: 'SENS_MED' as NightSensitivityEnum,
    LOW: 'SENS_LOW' as NightSensitivityEnum,
    OPTOFF: 'SENS_OPT_OFF' as NightSensitivityEnum,
    HIGHTERMOFF: 'SENS_HIGH_TERM_OFF' as NightSensitivityEnum,
    NORMTERMOFF: 'SENS_NORM_TERM_OFF' as NightSensitivityEnum,
    MEDTERMOFF: 'SENS_MED_TERM_OFF' as NightSensitivityEnum,
    LOWTEMOFF: 'SENS_LOW_TEM_OFF' as NightSensitivityEnum
  };
  export type DaySensitivityEnum =
    | 'SENS_HIGH'
    | 'SENS_NORM'
    | 'SENS_MED'
    | 'SENS_LOW'
    | 'SENS_OPT_OFF'
    | 'SENS_HIGH_TERM_OFF'
    | 'SENS_NORM_TERM_OFF'
    | 'SENS_MED_TERM_OFF'
    | 'SENS_LOW_TEM_OFF';
  export const DaySensitivityEnum = {
    HIGH: 'SENS_HIGH' as DaySensitivityEnum,
    NORM: 'SENS_NORM' as DaySensitivityEnum,
    MED: 'SENS_MED' as DaySensitivityEnum,
    LOW: 'SENS_LOW' as DaySensitivityEnum,
    OPTOFF: 'SENS_OPT_OFF' as DaySensitivityEnum,
    HIGHTERMOFF: 'SENS_HIGH_TERM_OFF' as DaySensitivityEnum,
    NORMTERMOFF: 'SENS_NORM_TERM_OFF' as DaySensitivityEnum,
    MEDTERMOFF: 'SENS_MED_TERM_OFF' as DaySensitivityEnum,
    LOWTEMOFF: 'SENS_LOW_TEM_OFF' as DaySensitivityEnum
  };
  export type NightClassEnum =
    | 'A1R'
    | 'A2R'
    | 'BR'
    | 'CR'
    | 'A1S'
    | 'A2S'
    | 'BS'
    | 'CS';
  export const NightClassEnum = {
    A1R: 'A1R' as NightClassEnum,
    A2R: 'A2R' as NightClassEnum,
    BR: 'BR' as NightClassEnum,
    CR: 'CR' as NightClassEnum,
    A1S: 'A1S' as NightClassEnum,
    A2S: 'A2S' as NightClassEnum,
    BS: 'BS' as NightClassEnum,
    CS: 'CS' as NightClassEnum
  };
  export type NightDayEnum =
    | 'A1R'
    | 'A2R'
    | 'BR'
    | 'CR'
    | 'A1S'
    | 'A2S'
    | 'BS'
    | 'CS';
  export const NightDayEnum = {
    A1R: 'A1R' as NightDayEnum,
    A2R: 'A2R' as NightDayEnum,
    BR: 'BR' as NightDayEnum,
    CR: 'CR' as NightDayEnum,
    A1S: 'A1S' as NightDayEnum,
    A2S: 'A2S' as NightDayEnum,
    BS: 'BS' as NightDayEnum,
    CS: 'CS' as NightDayEnum
  };
}
