<div class="modal-header">
  <h2>{{ 'ADDRESSBOOK.COMELIT_APP_BUTTON' | translate }}</h2>
  <button
    type="button"
    class="close pull-right"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="dataGroup">
  <label class="custom-label" for="abilitation">{{
    'ADDRESSBOOK.COMELIT_APP_BUTTON_ABILITE' | translate
  }}</label>

  <div class="switch-container">
    <span
      class="abilitation"
      [ngClass]="{ green: abilitationControl.value }"
    ></span>
    {{
      (abilitationControl.value ? 'APARTMENT.ENABLED' : 'APARTMENT.DISABLED')
        | translate
    }}
    <div class="switch">
      <app-switch
        [control]="abilitationControl"
        inputId="abilitation"
      ></app-switch>
    </div>
  </div>

  <label class="custom-label" for="address">{{
    'ADDRESSBOOK.ADDRESS' | translate
  }}</label>
  <input
    id="address"
    type="text"
    class="custom-input"
    [placeholder]="'ADDRESSBOOK.ADDRESS' | translate"
    formControlName="addr"
    (blur)="
      dataGroup.controls['addr'].setValue(
        dataGroup.controls['addr'].value.padStart(3, '0')
      )
    "
  />

  <app-control-errors
    id="address-validator"
    controlName="addr"
  ></app-control-errors>
</div>
<div class="modal-footer justify-content-center">
  <button
    id="close"
    class="button-secondary big mr-3"
    (click)="close()"
    type="button"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    id="save"
    class="button-primary big mr-3"
    [disabled]="!dataGroup.valid"
    (click)="save()"
    type="button"
  >
    {{ 'GLOBAL.SAVE' | translate }}
  </button>
</div>
