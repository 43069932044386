/**
 * DPCP - Device Parameters Communication Protocol API
 * API to interact with devices using DPCP
 *
 * The version of the OpenAPI document: 0.3.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Output state details
 */
export interface SafeOutputStateDto {
  /**
   * Active
   */
  readonly active?: boolean;
  /**
   * Absent
   */
  readonly absent?: boolean;
  /**
   * Isolated
   */
  readonly isolated?: boolean;
  /**
   * Fault
   */
  readonly fault?: boolean;
  /**
   * Tamper
   */
  readonly tamper?: boolean;
  /**
   * Low Battery
   */
  lowBattery?: boolean;
  /**
   * Active when a radio output does not reply to interrogation
   */
  supervision?: boolean;
  /**
   * Active when a radio output is in DB but still not acquired
   */
  stored?: boolean;
}
