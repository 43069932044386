import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GroupResponseDtoList,
  GroupResponseExtendedDto,
  GroupUserResponseDto,
  GroupsService
} from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import { UserAndRoles } from 'src/app/core/models';
import { Roles, User, UserRole } from './users';

@Injectable({
  providedIn: 'root'
})
export class UserService implements Resolve<UserRole> {
  userRole$ = new BehaviorSubject<UserRole>('INSTALLER');
  rolesAndUsers$ = new BehaviorSubject<UserAndRoles | undefined>(undefined);

  constructor(
    private groupsService: GroupsService,
    private authService: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<UserRole> | Observable<never> {
    const siteId: string = route.params.siteId;
    return this.getUserRole(siteId);
  }

  getUserRole(siteAuthId: string): Observable<UserRole> | Observable<never> {
    return this.groupsService
      .getGroups(
        this.authService.getToken(),
        undefined,
        undefined,
        siteAuthId,
        true
      )
      .pipe(
        map((res: GroupResponseDtoList) => {
          if (res.groups && res.groups.length > 0) {
            this.userRole$.next(res.groups[0].name?.split('_')[0] as UserRole);
          }

          if (
            res.groups &&
            res.groups.length > 0 &&
            res.groups[0].name?.split('_')
          ) {
            return res.groups[0].name?.split('_')[0] as UserRole;
          } else {
            return 'INSTALLER';
          }
        })
      );
  }

  getAllSiteUsersAndRoles(siteAuthId: string) {
    this.groupsService
      .getGroups(this.authService.getToken(), undefined, undefined, siteAuthId)
      .subscribe((res: GroupResponseDtoList) => {
        const users: User[] = [];
        const roles: Roles[] = [];
        if (res.groups) {
          res.groups.forEach((element: GroupResponseExtendedDto) => {
            roles.push({
              authId: element.authenticationId || '',
              name: (element.name?.split('_')[0] as UserRole) || 'NOROLE'
            });
            if (element.members) {
              element.members.forEach((el: GroupUserResponseDto) => {
                users.push({
                  email: el.loginEmail || '',
                  authId: el.userAuthId || '',
                  role: (element.name?.split('_')[0] as UserRole) || 'NOROLE',
                  roleAuthId: element.authenticationId || ''
                });
              });
            }
          });
        }
        this.rolesAndUsers$.next({ users, roles });
      });
  }
}
