import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SiteDto } from 'src/app/api/myportal/model/siteDto';
import { UserAndRoles } from 'src/app/core/models';
import { CreateSiteModalComponent } from 'src/app/sites/create-site-modal/create-site-modal.component';
import { SiteDetailResolverService } from 'src/app/sites/site-detail-resolver.service';
import { UserService } from '../utils/user.service';
import { rolesLabels, UserRole } from '../utils/users';

@Component({
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss']
})
export class SiteInfoComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() site: SiteDto;
  @Input() deviceName?: string;
  @Input() showImage? = false;
  userRole: UserRole | undefined;
  owner: string;
  rolesLabels = rolesLabels;
  imageUrl: SafeStyle;

  constructor(
    private modalService: BsModalService,
    private ts: TranslateService,
    private userService: UserService,
    private siteDetailResolver: SiteDetailResolverService
  ) {}

  ngOnInit(): void {
    if (this.showImage) {
      this.imageUrl = this.site.imageUrl
        ? `url('${this.site.imageUrl}?v=${Date.now()}')`
        : '';
    }
    this.userService.userRole$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r: UserRole | undefined) => {
        this.userRole = r;
      });

    this.userService.rolesAndUsers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r: UserAndRoles | undefined) => {
        if (r) {
          this.owner = r.users.find((x) => x.role === 'ADMIN')?.email || '--';
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDetail() {
    const initialState: Partial<CreateSiteModalComponent> = {
      readOnly: true,
      site: this.site
    };

    this.openModal(initialState);
  }

  openModal(initialState: Partial<CreateSiteModalComponent>) {
    const modalRef = this.modalService.show(CreateSiteModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteCreated.subscribe(() => {
      this.siteDetailResolver
        .getSite(this.site.authenticationId!)
        .subscribe((r) => {
          this.site = r;
        });
    });
  }

  getTags() {
    if (
      this.site.userMetadata &&
      this.site.userMetadata.tags &&
      this.site.userMetadata.tags.length !== 0
    ) {
      let tags = [...this.site.userMetadata.tags];
      tags = tags.map((v) => '#' + v);
      return tags.join(', ');
    } else {
      return this.ts.instant('TAG.NO_TAGS');
    }
  }
}
