import { Component, Host, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

const defaultErrorMessages: { [key: string]: string } = {
  required: _('VALIDATE.REQUIRED'),
  email: _('VALIDATE.EMAIL'),
  maxlength: _('VALIDATE.MAX_LENGTH'),
  minlength: _('VALIDATE.MIN_LENGTH'),
  min: _('VALIDATE.MIN'),
  max: _('VALIDATE.MAX')
};

@Component({
  selector: 'app-control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss']
})
export class ControlErrorsComponent implements OnInit {
  /**
   * The name of the FormControl for which the errors should be displayed
   */
  @Input() controlName: string;
  /**
   * Map of custom messages (same structure of defaultErrorMessages)
   */
  @Input() customMessages: { [key: string]: string } = {};

  control: AbstractControl;
  errorMessage?: string = undefined;
  constructor(
    private translateService: TranslateService,
    @Host()
    @Optional()
    private container: ControlContainer
  ) {}

  ngOnInit(): void {
    if (!this.container) {
      throw new Error(
        'Cannot find parent form group for controlName: ' + this.controlName
      );
    }

    const control = (this.container.control as AbstractControl).get(
      this.controlName
    );

    if (!control) {
      throw new Error(
        `Cannot find control with name ${this.controlName} in the parent formGroup/formArray`
      );
    }

    this.control = control;
    control.statusChanges.subscribe((status) => {
      this.setErrorMessage();
    });

    this.setErrorMessage();
  }

  setErrorMessage() {
    if (this.control.errors == null) {
      this.errorMessage = undefined;
      return;
    }

    Object.keys(this.control.errors).forEach((key) => {
      this.errorMessage = this.getValidatorMessage(
        key,
        this.control.errors![key]
      );
    });
  }

  getValidatorMessage(validatorName: string, validatorValue?: any) {
    return this.translateService.instant(
      this.getValidatorMessageKey(validatorName),
      { value: validatorValue }
    );
  }

  private getValidatorMessageKey(validatorName: string): string {
    if (this.customMessages.hasOwnProperty(validatorName)) {
      return this.customMessages[validatorName];
    }

    if (defaultErrorMessages.hasOwnProperty(validatorName)) {
      return defaultErrorMessages[validatorName];
    }

    return _('VALIDATE.GENERIC_ERROR');
  }
}
