/**
 * MUG Configurator API
 * API to remotely configure MUG from ComelitCloud
 *
 * The version of the OpenAPI document: 2.0.114
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Basic directory data
 */
export interface BasicDirectoryDataDto {
  /**
   * Directory identifier (not required if new, otherwise required)
   */
  dir: number;
  /**
   * Directory description
   */
  descr?: string;
  /**
   * Enable directory elements editing
   */
  enedit?: boolean;
}
