<div class="card mt-3 pb-3">
  <div class="card-header">
    <h2>{{ 'SIP.SIP_SERVER_CONFIG' | translate }}</h2>
    <button
      id="edit-config"
      *ngIf="!isEditing"
      class="button-secondary"
      (click)="editSip()"
    >
      <i class="material-icons notranslate">edit</i>
      {{ 'USER_DEVICE.EDIT_DATA' | translate }}
    </button>
    <div class="button-box" *ngIf="isEditing">
      <button id="cancel" class="button-secondary mr-3" (click)="closeEdit()">
        {{ 'GLOBAL.CANCEL' | translate }}
      </button>
      <app-loading-button
        id="save"
        buttonClass="button-primary"
        [isLoading]="isLoading"
        (click)="updateSip()"
        buttonType="button"
        >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
      >
    </div>
  </div>
  <div class="card-body pt-2">
    <form [formGroup]="sipFormGroup">
      <ul class="info-list">
        <li *ngIf="showAll">
          <span>{{ 'SIP.SIP_PROVIDER_IP' | translate }}</span>
          <span
            ><b *ngIf="sipProviderIpControl.value === '' && !isEditing">--</b>
            <input
              *ngIf="sipProviderIpControl.value !== '' || isEditing"
              type="text"
              id="provider-ip"
              [placeholder]="'SIP.SIP_PROVIDER_IP_DESC' | translate"
              [formControl]="sipProviderIpControl"
              class="custom-input"
            />
            <app-control-errors
              id="provider-ip-validator"
              controlName="sipPbxHostname"
            ></app-control-errors>
          </span>
        </li>
        <li *ngIf="showAll">
          <span>{{ 'SIP.SIP_SERVER_PORT' | translate }}</span>
          <span>
            <b *ngIf="serverPortControl.value === '' && !isEditing">--</b>
            <input
              *ngIf="serverPortControl.value !== '' || isEditing"
              type="text"
              id="server-port"
              [formControl]="serverPortControl"
              class="custom-input"
            />
            <app-control-errors
              id="server-port-validator"
              controlName="sipPbxPort"
              [customMessages]="{
                pattern: 'SIP.SIP_PORT_VALIDATION_MESSAGE' | translate
              }"
            ></app-control-errors>
          </span>
        </li>
        <li *ngIf="showAll">
          <span>{{ 'SIP.SIP_PROXY' | translate }}</span>
          <span>
            <b *ngIf="sipProxy.value === '' && !isEditing">--</b>
            <input
              *ngIf="sipProxy.value !== '' || isEditing"
              type="text"
              [placeholder]="'SIP.SIP_PROXY_DESC' | translate"
              id="sip-proxy"
              [formControl]="sipProxy"
              class="custom-input"
            />
          </span>
        </li>
        <li *ngIf="showAll">
          <span>{{ 'SIP.SIP_PROTOCOL' | translate }}</span>
          <span>
            <b *ngIf="sipProtocolControl.value === '' && !isEditing">--</b>
            <ng-select
              *ngIf="sipProtocolControl.value !== '' || isEditing"
              id="sip-protocol"
              [items]="sipProtocols"
              bindLabel="name"
              bindValue="value"
              [formControl]="sipProtocolControl"
              [searchable]="false"
              [clearable]="false"
            ></ng-select>
            <p *ngIf="isEditing">
              {{ 'SIP.SIP_PROTOCOL_DESC' | translate }}
            </p></span
          >
        </li>
        <li *ngIf="showAll">
          <span>{{ 'SIP.CODEC_PREFERENCE' | translate }}</span>
          <span>
            <b *ngIf="codecPrefControl.value === '' && !isEditing">--</b>
            <ng-select
              *ngIf="codecPrefControl.value !== '' || isEditing"
              id="codec"
              [items]="codecList"
              [formControl]="codecPrefControl"
              [searchable]="false"
              [clearable]="false"
            ></ng-select>
            <p *ngIf="isEditing">
              {{ 'SIP.CODEC_PREFERENCE_DESC' | translate }}
            </p></span
          >
        </li>
        <li>
          <span>{{ 'SIP.DTMF_RELAY_1' | translate }}</span>
          <span>
            <b *ngIf="relay1Control.value === '' && !isEditing">--</b>
            <input
              *ngIf="relay1Control.value !== '' || isEditing"
              type="text"
              id="relay-1"
              [formControl]="relay1Control"
              class="custom-input smaller"
            />
            <p *ngIf="isEditing">
              {{ 'SIP.DTMF_RELAY_1_DESC' | translate }}
            </p></span
          >
        </li>
        <li>
          <span>{{ 'SIP.DTMF_RELAY_2' | translate }}</span>
          <span>
            <b *ngIf="relay2Control.value === '' && !isEditing">--</b>
            <input
              *ngIf="relay2Control.value !== '' || isEditing"
              type="text"
              id="relay-2"
              [formControl]="relay2Control"
              class="custom-input smaller"
            />
            <p *ngIf="isEditing">
              {{ 'SIP.DTMF_RELAY_2_DESC' | translate }}
            </p></span
          >
        </li>
        <li>
          <span>{{ 'SIP.DTMF_RELAY_3' | translate }}</span>
          <span>
            <b *ngIf="relay3Control.value === '' && !isEditing">--</b>
            <input
              *ngIf="relay3Control.value !== '' || isEditing"
              type="text"
              id="relay-3"
              [formControl]="relay3Control"
              class="custom-input smaller"
            />
            <p *ngIf="isEditing">{{ 'SIP.DTMF_RELAY_3_DESC' | translate }}</p>
          </span>
        </li>
      </ul>
    </form>
  </div>
</div>
